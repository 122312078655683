import { Container, makeStyles } from '@material-ui/core'
import React from 'react'

import Meetings from './Meetings'

const useStyles = makeStyles( {
  container: {
    padding: '3em',
  },
} )

const Dashboard = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Meetings />

    </Container>
  )
}

export default Dashboard
