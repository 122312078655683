const NO_PREFERENCE = 'No preference'
const AGNOSTIC = 'Agnostic'
const ANY = 'Any'
const GLOBAL = 'Global'

const FIELDS = {
  thesisIndustries: {
    label: 'Industries',
    tooltip: 'Preferred investment industries and verticals',
    placeholder: AGNOSTIC,
  },
  thesisHighlights: {
    label: 'Product Highlights',
    tooltip: 'Preferred product focus and models',
    placeholder: AGNOSTIC,
  },
  thesisLocations: {
    label: 'Geography',
    tooltip: 'Preferred location of startup HQ or operations',
    placeholder: GLOBAL,
  },
  seisRequired: {
    label: 'S/EIS Required',
    tooltip: 'Do startups require S/EIS approval?',
    placeholder: NO_PREFERENCE,
  },
  thesisStages: {
    label: 'Fundraise Round',
    tooltip: 'Preferred fundraising round for startups',
    placeholder: ANY,
  },
  roundPreference: {
    label: 'Round Participation Preference',
    tooltip: 'Preference for the type of participation',
    placeholder: NO_PREFERENCE,
  },
  followUpRounds: {
    label: 'Follow-Up Rounds',
    tooltip: 'Open to follow-up investments',
    placeholder: NO_PREFERENCE,
  },
  ticketSizeMinimum: {
    label: 'Minimum Ticket Size',
    tooltip: 'Minimum ticket size',
    placeholder: NO_PREFERENCE,
  },
  ticketSizeMaximum: {
    label: 'Maximum Ticket Size',
    tooltip: 'Maximum ticket size',
    placeholder: NO_PREFERENCE,
  },
  ticketSizeRange: {
    label: 'Ticket Size Range',
    tooltip: 'Minimum and maximum ticket sizes',
    placeholder: NO_PREFERENCE,
  },
}

export default FIELDS
