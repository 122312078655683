import { MEETING_STATUSES } from '@kaiku/shared'
import { withStopPropagation } from '@kaiku/ui'
import { Button } from '@material-ui/core'
import { CallEnd, Check, PhoneForwarded, Schedule, SettingsPhone } from '@material-ui/icons'
import { func, node, oneOf, string } from 'prop-types'
import React from 'react'

export const getEngagedIcon = ( engagedStatus ) => {
  if ( engagedStatus === MEETING_STATUSES.meetingRequested ) {
    return <PhoneForwarded color="#FFA726" />
  }
  if ( engagedStatus === MEETING_STATUSES.meetingBooked ) {
    return <SettingsPhone />
  }
  if ( engagedStatus === MEETING_STATUSES.declined ) {
    return <CallEnd style={{ color: '#F44336' }} />
  }
  if ( engagedStatus === MEETING_STATUSES.met ) {
    return <Check />
  }
  return <Schedule />
}

const getColor = ( engagedStatus ) => {
  if ( engagedStatus === MEETING_STATUSES.meetingRequested ) {
    return '#FFA726'
  }
  if ( engagedStatus === MEETING_STATUSES.meetingBooked ) {
    return 'primary'
  }
  if ( engagedStatus === MEETING_STATUSES.declined ) {
    return '#F44336'
  }
  if ( engagedStatus === MEETING_STATUSES.met ) {
    return 'primary'
  }
  return 'inherit'
}

const EngageButton = ( {
  complete,
  engaged,
  onClick,
  incomplete,
  icon,
  ...props
} ) => (
  <Button
    key={complete}
    size="small"
    style={{ color: getColor( engaged ) }}
    startIcon={getEngagedIcon( engaged )}
    onClick={withStopPropagation( onClick )}
    variant="text"
    {...props}
  >
    {engaged || 'Request Meeting'}
  </Button>
)

EngageButton.propTypes = {
  complete: string.isRequired,
  engaged: oneOf( [ ...Object.values( MEETING_STATUSES ), false ] ).isRequired,
  onClick: func,
  icon: node.isRequired,
  incomplete: string.isRequired,
}

EngageButton.defaultProps = {
  onClick: () => {},

}

export default EngageButton
