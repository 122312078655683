import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core'
import { Check, Clear, ExpandMore } from '@material-ui/icons'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
  },
  sectionCompletedSymbol: {
    marginRight: '10px',
    height: '20px',
  },
  completedFraction: {
    fontSize: '11px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  mainDiv: {
    display: 'flex',
    marginLeft: '20px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  p: {
    margin: '0 5px',
  },
  check: {
    color: palette.primary.main,
  },
  cross: {
    color: 'red',
  },
  pointer: {
    cursor: 'pointer',
  },
  accordionSummaryComplete: {
    margin: '-3px 0',
    cursor: 'default !important',
  },
  accordianSummaryTitleComplete: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: 'grey',
  },
  accordionSummaryIncomplete: {
    margin: '-3px 0',
    cursor: 'pointer',
  },
  accordianSummaryTitleIncomplete: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: null,
  },
} ) )

const profile = [
  { title: 'About',
    refIndex: 0,
    attributes: {
      name: 'Name',
      logoUrl: 'Logo',
      website: 'Website',
      foundedYear: 'Year Founded',
      oneLiner: 'One Liner',
      description: 'Description',
      stage: 'Stage',
      stageDescription: 'Stage Description',
      pitchDeckUrl: 'Pitch Deck',
    } },
  { title: 'Business',
    refIndex: 1,
    attributes: {
      businessModel: 'Business Model',
    } },
  { title: 'Fundraise',
    refIndex: 2,
    attributes: {
      'fundraisers[0].amountRaised': 'Amount Raised',
      'fundraisers[0].targetEquity': 'Target Equity',
      'fundraisers[0].targetRaise': 'Target Raise',
      'fundraisers[0].ticketSize': 'Ticket Size',
      'fundraisers[0].investors': 'Investors',
    } },
  { title: 'Legals',
    refIndex: 3,
    attributes: {
      seisStatus: 'SEIS Status',
      incorporatedDate: 'Incorporated Date',
      incorporatedLocation: 'Incorporated Location',
      headquartersLocation: 'HQ Location',
    } },
  { title: 'Team',
    refIndex: 4,
    attributes: {
      'members[0].role': 'Role',
      'members[0].description': 'Description',
    } },
]

export default function MissingFieldsAccordion( { data, refs } ) {
  const classes = useStyles()
  const [ expanded, setExpanded ] = useState( 0 )

  const isFieldComplete = ( ...params ) => get( data, ...params ) !== null

  const sortAttributes = ( attributes ) => Object.keys( attributes )
    .sort( ( att1, att2 ) => ( ( isFieldComplete( att1 ) && !isFieldComplete( att2 ) ) ? 1 : -1 ) )
    .reduce( ( acc, c ) => { acc[ c ] = attributes[ c ]; return acc }, {} )

  const attributesCompleted = ( section ) => (
    Object.keys( section.attributes ).filter( ( att ) => isFieldComplete( att ) ).length
  )

  const sectionCompleted = ( section ) => (
    attributesCompleted( section ) === Object.keys( section.attributes ).length
  )

  const profileComplete = () => (
    profile.filter( sectionCompleted ).length === profile.length
  )

  const percentageComplete = ( section ) => (
    attributesCompleted( section ) / Object.keys( section.attributes ).length
  )

  const sortSections = ( sections ) => sections.sort(
    ( sec1, sec2 ) => (
      ( percentageComplete( sec1 ) < percentageComplete( sec2 ) ) ? -1 : 0
    ),
  )

  if ( profileComplete() ) {
    return null
  }

  sortSections( profile )
  return (
    <>
      {profile.map( ( section, index ) => {
        const completed = sectionCompleted( section )
        const attributes = completed ? section.attributes : sortAttributes( section.attributes )
        return (
          <Accordion
            expanded={expanded === index}
            onChange={completed ? null : () => setExpanded( expanded === index ? false : index )}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={completed ? null : <ExpandMore />}
              className={classes[ completed ? 'accordionSummaryComplete' : 'accordionSummaryIncomplete' ]}
            >
              <div className={classes.sectionCompletedSymbol}>
                {completed
                  ? <Check className={classes.check} /> : <Clear className={classes.cross} />}
              </div>
              <Typography className={classes[ completed ? 'accordianSummaryTitleComplete' : 'accordianSummaryTitleIncomplete' ]}>
                {section.title}
                {!completed && (
                <span className={classes.completedFraction}>
                  {`${attributesCompleted( section )}/${Object.keys( attributes ).length}`}
                </span>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                onClick={() => window.scrollTo( { behavior: 'smooth', top: refs[ section.refIndex ].current.offsetTop } )}
                className={classes.pointer}
              >
                {Object.entries( attributes ).map( ( [ field, formatted ] ) => (
                  <div className={classes.mainDiv} key={field}>
                    {isFieldComplete( field )
                      ? <Check className={classes.check} />
                      : <Clear className={classes.cross} />}
                    <p className={classes.p}>{formatted}</p>
                  </div>
                ) )}
              </div>
            </AccordionDetails>
          </Accordion>
        )
      } )}
    </>
  )
}

MissingFieldsAccordion.propTypes = {
  data: PropTypes.objectOf( PropTypes.object ).isRequired,
  refs: PropTypes.objectOf( PropTypes.object ).isRequired,
}

