import { formatDateAndTime } from '@kaiku/ui'
import { Container } from '@material-ui/core'
import React from 'react'

import { meetingInfoPropTypes } from '../propTypes'

const MeetingBooked = ( { meetingInfo } ) => (
  <Container>
    {`This meeting has been booked for ${formatDateAndTime( meetingInfo.acceptedTime )}.`}
    <br />
    Please check your email for the Zoom meeting link.
    <br />
    <br />
    If you can no longer attend this meeting but would like
    to reschedule, please contact the startup directly using the
    email address above to arrange a different time.
    Note: The Zoom link is valid for 30 days after the initial meeting date.
  </Container>
)

MeetingBooked.propTypes = meetingInfoPropTypes

export default MeetingBooked
