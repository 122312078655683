import { useUser } from '@kaiku/ui'
import { useState } from 'react'

/**
 * Provides a sticky toggle that resets per session.
 * @param {string} key The localstorage key to use.
 */
const usePerSession = ( key ) => {
  const [ { auth_time: authTime } ] = useUser()
  const [ keyAuthTime, setKeyAuthTime ] = useState( +localStorage.getItem( key ) )

  const toggle = () => {
    localStorage.setItem( key, authTime )
    setKeyAuthTime( authTime )
  }

  return [ authTime === keyAuthTime, toggle ]
}

export default usePerSession
