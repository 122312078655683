import { Chip, FormControl, FormGroup, FormHelperText, Grid, makeStyles } from '@material-ui/core'
import { Field } from 'formik'
import { Checkbox } from 'formik-material-ui'
import { arrayOf, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles(
  {
    chipContainer: {
      justifyContent: 'center',
    },
    chip: {
      marginLeft: '0px',
      marginRight: '0px',
    },
    checkbox: {
      padding: '0px',
      marginLeft: '3px',
      marginRight: '3px',
    },
    helperText: {
      textAlign: 'center',
      marginTop: '10px',
    },
  },
)

const ChipCheckboxes = ( { fieldName, options, error, helperText } ) => {
  const classes = useStyles()
  return (
    <FormControl error={!!error}>
      <FormGroup>
        <Grid className={classes.chipContainer} container spacing={1} direction="row">

          {options.map( ( option ) => (

            <Field
              key={option}
              type="checkbox"
              component={Checkbox}
              className={classes.checkbox}
              checkedIcon={<Chip className={classes.chip} label={option} />}
              icon={<Chip className={classes.chip} variant="outlined" label={option} />}
              value={option}
              name={fieldName}
            />

          ) )}
        </Grid>

      </FormGroup>
      <FormHelperText className={classes.helperText}>
        {error || helperText}
      </FormHelperText>

    </FormControl>
  )
}

ChipCheckboxes.propTypes = {
  fieldName: string.isRequired,
  options: arrayOf( string ).isRequired,
  error: string,
  helperText: string,
}

ChipCheckboxes.defaultProps = {
  error: null,
  helperText: '',
}

export default ChipCheckboxes
