import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles( ( { breakpoints } ) => ( {
  block: {
    '&.startup': {
      [ breakpoints.up( 'lg' ) ]: {
        paddingTop: '6em',
      },
    },
    '&.investor': {
      [ breakpoints.up( 'md' ) ]: {
        paddingTop: '6em',
      },
    },

  },
  container: {
    paddingLeft: '3.5em',
    paddingRight: '3.5em',
    minHeight: '4.5em',
    maxWidth: '500px',
    '& > *': {
      '& > *': {
        width: '100%',
      },
    },
  },
  avatarContainer: {
    zIndex: 10,
    position: 'absolute',
    top: '3.5em',
    bottom: 0,
    margin: 'auto',
    width: '11em',
    height: '11em',
    border: '4px solid #EEEEEE',
    borderRadius: '100%',
    boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
    background: '#FFFFFF',
    '&.startup': {
      [ breakpoints.down( 'md' ) ]: {
        position: 'relative !important',
        top: '1em',
      },
    },
    '&.investor': {
      [ breakpoints.down( 'sm' ) ]: {
        position: 'relative !important',
        top: '1em',
      },
    },
  },
  avatar: {
    width: '100%',
    height: '100%',
    fontSize: '0.8em',
  },
  box: {
    '& > *': {
      width: 'calc(50% - 1em)',
    },
  },
  noMargin: {
    margin: '0 !important',
  },
} ) )

export default useStyles
