import { Button, makeStyles, Paper } from '@material-ui/core'
import PropTypes, { arrayOf, func, string } from 'prop-types'
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'

import EquityScatterGraph from './EquityScatterChart'
import IndustriesPieChart from './IndustriesPieChart'
import MapChart from './MapChart'
import RaisesBarChart from './RaisesBarChart'

const useStyles = makeStyles( {
  chartsPaper: {
    borderRadius: '0.35em',
    height: '400px',
    padding: '15px',
  },
  chartCardTitle: {
    margin: 0,
  },
  mapDiv: {
    width: '100%',
    height: window.innerWidth < 400 ? '40vh' : '100%',
    borderRadius: '0.35em',
    padding: '5px',
    position: 'relative',
  },
  mapResetBtn: {
    position: 'absolute',
    bottom: '5%',
    left: '2.5%',
  },
} )

const props = {
  title: string.isRequired,
  startupData: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
}

export const EquityScatterCard = ( { title, startupData } ) => {
  const classes = useStyles()
  return (
    <Paper className={classes.chartsPaper}>
      <h3 className={classes.chartCardTitle}>{title}</h3>
      <EquityScatterGraph data={startupData} />
    </Paper>
  )
}

export const RaisesBarCard = ( { title, startupData } ) => {
  const classes = useStyles()
  return (
    <Paper className={classes.chartsPaper}>
      <h3 className={classes.chartCardTitle}>{title}</h3>
      <RaisesBarChart data={startupData} />
    </Paper>
  )
}

export const IndustriesPieCard = ( { title, startupData } ) => {
  const classes = useStyles()
  return (
    <Paper className={classes.chartsPaper}>
      <h3 className={classes.chartCardTitle}>{title}</h3>
      <IndustriesPieChart data={startupData} />
    </Paper>
  )
}

export const MapCard = ( { startupData, country, mapsvgpath, setCountry } ) => {
  const classes = useStyles()
  const [ content, setContent ] = useState( '' )
  return (
    <Paper className={classes.mapDiv}>
      <MapChart
        data={startupData}
        setTooltipContent={setContent}
        country={country}
        mapsvgpath={mapsvgpath}
        onClick={setCountry}
      />
      <ReactTooltip>{content}</ReactTooltip>
      {country && <Button className={classes.mapResetBtn} onClick={() => setCountry( '' )}>Reset</Button>}
    </Paper>
  )
}

EquityScatterCard.propTypes = props
RaisesBarCard.propTypes = props
IndustriesPieCard.propTypes = props
MapCard.propTypes = {
  startupData: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
  country: string.isRequired,
  setCountry: func.isRequired,
  mapsvgpath: string.isRequired,
}
