const FIELDS = {
  oneLiner: {
    label: 'One Liner',
    tooltip: "Brief description of the startup's offering",
  },
  description: {
    label: 'Description',
    tooltip: 'Comprehensive overview of the startup',
  },
  stage: {
    label: 'Stage',
    tooltip: 'Stage of development the startup has reached',
  },
  stageDescription: {
    label: 'Stage Description',
    tooltip: 'Further detail on current stage and objectives',
  },
  pitchDeckUrl: {
    label: 'Pitch Deck',
    tooltip: 'Latest investment deck (PDF)',
  },
  onePagerUrl: {
    label: 'One Pager',
    tooltip: 'One-page executive summary of the startup (PDF)',
  },
  headquartersLocation: {
    label: 'Startup HQ',
    tooltip: 'Headquarters are where you have a physical presence and if you work remotely, the largest target market you are addressing',
  },
  operationsLocations: {
    label: 'Startup Operations',
    tooltip: 'Operations are where you have a physical presence in addition to the HQ, such as another office. If you work remotely, the target markets you addressing in addition to one listed in HQ',
  },
}

export default FIELDS
