import { isInvestmentCompany } from '@kaiku/shared'
import { Avatar, Box, Container, makeStyles, Typography } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { shape, string } from 'prop-types'
import React from 'react'

import HeaderBlockCommonStyles from '../../../lib/header-block-common-styles'
import ExternalLink from '../../ExternalLink'
import HeaderBlock from '../../HeaderBlock'

const useStyles = makeStyles( ( { breakpoints } ) => ( {
  information: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5em',
    '& > *': {
      fontSize: '1.25em',
    },
    '& > :not(:last-child):after': {
      margin: '0 0.75em',
      content: '"•"',
      fontSize: '0.75em',
    },
  },
  type: {
    textTransform: 'capitalize',
  },
  boxContainer: {
    display: 'flex',
    minHeight: '4.5em',
    flexDirection: 'column',
    justifyContent: 'center',
    [ breakpoints.up( 'md' ) ]: {
      marginLeft: '3.5em',
    },
  },
} ) )

const DefaultHeaderBlock = ( {
  type,
  companyName,
  companyLogoUrl,
  companyWebsite,
  representative,
} ) => {
  const isCompany = isInvestmentCompany( type )
  const fallback = isCompany && !companyName

  const classes = { ...HeaderBlockCommonStyles(), ...useStyles() }

  const { name, website, pictureUrl } = isCompany
    ? { name: companyName, pictureUrl: companyLogoUrl, website: companyWebsite }
    : { ...representative, name: [ representative.firstName, representative.lastName ].join( ' ' ) }

  const ResponsiveHeader = (
    <>
      <HeaderBlock
        className={`${classes.block} investor`}
        fallbackMessage="Get started by filling in some core details."
        fallback={fallback}
        renderAvatar={() => (
          <Box className={`${classes.avatarContainer} investor`}>
            <Avatar className={classes.avatar} src={pictureUrl} alt={name}>
              <Business fontSize="large" />
            </Avatar>
          </Box>
        )}
      >
        <Box className={classes.boxContainer}>
          <Typography variant="h3" align="center">{name}</Typography>

          <Container className={classes.information}>
            <Typography className={classes.type}>{type}</Typography>
            {website && <ExternalLink href={website} />}
          </Container>

        </Box>
      </HeaderBlock>
    </>
  )

  return ResponsiveHeader
}

DefaultHeaderBlock.propTypes = {
  companyName: string,
  companyWebsite: string,
  companyLogoUrl: string,
  type: string,
  representative: shape( { id: string } ),
}

DefaultHeaderBlock.defaultProps = {
  companyName: null,
  companyWebsite: null,
  companyLogoUrl: null,
  type: null,
  representative: {},
}

export default DefaultHeaderBlock
