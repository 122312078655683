import { routes } from '@kaiku/shared'
import { Checkbox, HomeForm, LoaderButton, OrDivider, ValidatedForm } from '@kaiku/ui'
import { Button, FormHelperText, Link, Typography } from '@material-ui/core'
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { bool, object, ref, string } from 'yup'

import { register } from '../../api'
import useCommonStyles from '../Login/styles'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsAcceptedAt: false,
  referralCode: null,
}

const createSchema = () => object().shape( {
  firstName: string().required(),
  lastName: string().required(),
  email: string().required().email(),
  password: string().required().min( 6 ),
  confirmPassword: string().oneOf( [ ref( 'password' ), null ], 'Passwords do not match' ).required( 'Passwords do not match' ),
  termsAcceptedAt: bool().test(
    'are-terms-accepted',
    'You must accept the Terms of Use to continue',
    ( v ) => !!v,
  ),
  referralCode: string().nullable(),
} )

const Register = () => {
  const referralCode = new URLSearchParams( window.location.search ).get( 'referralCode' )
  const [ error, setError ] = useState()
  const [ hasReferralCode, setHasReferralCode ] = useState( !!referralCode )

  const onRegister = async ( body ) => {
    setError()
    return register( {
      ...body,
      termsAcceptedAt: new Date().toJSON(),
    } )
      .then( () => signInWithEmailAndPassword( getAuth(), body.email, body.password ) )
      .then( () => sendEmailVerification( getAuth().currentUser ) )
      .catch( setError )
  }

  const classes = useCommonStyles()

  return (
    <HomeForm logo>
      <Typography className={classes.header} variant="h3">Sign up</Typography>
      <ValidatedForm
        initialValues={{ ...initialValues, referralCode }}
        schema={createSchema()}
        onSubmit={onRegister}
      >
        {( { isSubmitting, submitForm } ) => (
          <>

            <Field
              component={TextField}
              name="firstName"
              label="First name"
            />

            <Field
              component={TextField}
              name="lastName"
              label="Last name"
            />

            <Field
              component={TextField}
              name="email"
              label="Email"
              type="email"
            />

            <Field
              component={TextField}
              name="password"
              label="Password"
              type="password"
            />

            <Field
              component={TextField}
              name="confirmPassword"
              label="Confirm Password"
              type="password"
            />

            <Field
              component={Checkbox}
              name="termsAcceptedAt"
              label={[
                'I have read and agree to the',
                <Link key="terms" href="https://www.kaiku.co/terms-of-use" target="_blank">Terms of Use</Link>,
                'and',
                <Link key="policy" href="https://www.kaiku.co/privacy-policy" target="_blank">Privacy Policy</Link>,
              ].reduce( ( curr, next ) => [ curr, ' ', next ] )}
            />

            {hasReferralCode ? (
              <Field
                component={TextField}
                name="referralCode"
                label="Referral Code"
              />
            ) : (
              <span
                className={classes.link}
                onClick={() => setHasReferralCode( true )}
              >
                I have a referral code
              </span>
            )}

            <FormHelperText className={classes.errorText} error>
              {error && error.message}
            </FormHelperText>

            <LoaderButton
              className={classes.mainButton}
              loading={isSubmitting}
              onClick={submitForm}
            >
              Create Account
            </LoaderButton>

          </>
        ) }

      </ValidatedForm>

      <OrDivider />

      <Button variant="outlined" component={RouterLink} to={routes.LOGIN}>
        Log in
      </Button>
    </HomeForm>
  )
}

export default Register
