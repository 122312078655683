const FIELDS = {
  incorporated: {
    label: 'Incorporated Company',
    tooltip: 'Is the startup a legally incorporated company?',
  },
  incorporatedDate: {
    label: 'Incorporated On',
    tooltip: 'The date that the startup was incorporated on',
  },
  incorporatedLocation: {
    label: 'Incorporated Location',
    tooltip: 'The country that the startup is registered as incorporated',
  },
  seisStatus: {
    label: 'S/EIS Status',
    tooltip: 'The (Seed) Enterprise Investment Scheme(s) are UK government schemes to help smaller companies raise finance. Click for more information.',
    href: 'https://www.gov.uk/guidance/venture-capital-schemes-apply-to-use-the-seed-enterprise-investment-scheme',

  },
  patents: {
    label: 'Patents',
    tooltip: 'Does the startup own any patents?',
  },
}

export default FIELDS
