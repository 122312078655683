import { isEqual } from 'lodash'
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'

const events = {
  selectOption: 'select-option',
  removeOption: 'remove-option',
}

const not = ( fn ) => ( ...params ) => !fn( ...params )

/**
 * Displays placeholder as chip and option
 */
const withAutocompletePlaceholder = ( Autocomplete ) => {
  const Component = ( {
    getPlaceholderOption = () => null,
    value,
    options,
    onChange,
    ...props
  } ) => {
    const placeholderOptions = [ getPlaceholderOption( props ) ].filter( ( x ) => x )

    const augmentedOnChange = ( event, value, selectEvent, { option } = {} ) => {
      const isPlaceholder = ( option ) => !!placeholderOptions.find(
        ( placeholderOption ) => isEqual( option, placeholderOption ),
      )

      const placeholderClick = (
        isPlaceholder( option )
        && selectEvent === events.selectOption
      )

      // Remove all values if placeholder is clicked, otherwise filter it out
      const filteredValue = placeholderClick ? [] : value.filter( not( isPlaceholder ) )

      onChange( event, filteredValue, selectEvent, { option } )
    }
    const augmentedOptions = [ ...placeholderOptions, ...options ]
    const augmentedValue = value.length ? value : [ ...placeholderOptions ]
    const Component = (
      <Autocomplete
        {...props}
        multiple
        options={augmentedOptions}
        value={augmentedValue}
        onChange={augmentedOnChange}
      />
    )

    return (
      Component
    )
  }

  Component.propTypes = {
    onSubmit: func.isRequired,
    value: arrayOf( shape( {
      name: string,
      value: oneOfType( [ string, number ] ),
    } ) ).isRequired,
    getPlaceholderOption: func.isRequired,
    options: arrayOf( string ).isRequired,
    onChange: func.isRequired,
  }
  return Component
}

export default withAutocompletePlaceholder
