import { getCountryName, MEETING_STATUSES } from '@kaiku/shared'
import { ExpandableChip, ExternalLink, getShortList, HorizontalItems, MatchBadge, withStopPropagation } from '@kaiku/ui'
import { Avatar, Box, Card, CardActions, CardContent, Chip, colors, makeStyles, Typography } from '@material-ui/core'
import { Business, Clear, Star } from '@material-ui/icons'
import numeral from 'numeral'
import { arrayOf, bool, func, number, oneOf, string } from 'prop-types'
import React from 'react'

import ActionButton from './ActionButton'
import EngageButton from './EngageButton'

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    position: 'relative',
    width: '450px',
    height: '350px',
    padding: '0.75em',
    borderRadius: '0.35em',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    marginTop: '6.5em',
  },
  avatar: {
    position: 'absolute',
    top: '-4em',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '7em',
    height: '7em',
    border: '4px solid #EEEEEE',
    borderRadius: '100%',
    boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
    backgroundColor: '#FFFFFF',
  },
  industries: {
    textTransform: 'capitalize',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: '1.25em',
  },
  raiseDetails: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    top: 0,
    width: '5em',
    height: '5em',
    background: palette.primary.main,
    color: palette.primary.contrastText,
    borderRadius: '100em',
    '& > *': {
      fontSize: '1em',
      lineHeight: '1.35em',
    },
  },
  title: ( { name } ) => ( {
    fontSize: name.length < 18 ? '1.5em' : '1.25em',
    textAlign: 'center',
    padding: '0 5em',
  } ),
  chips: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1.5em',
    margin: '0.5em',
    '& > *': {
      paddingTop: '0.15em',
      marginRight: '1em',
    },
  },
  oneLiner: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    height: '4.5em',
  },
  content: {
    marginTop: 'auto',
    marginBottom: '-1em',
  },
  actions: {
    '& > *': {
      lineHeight: '1.5em',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  ignore: {
    color: ( { ignored } ) => ( ignored ? colors.orange.A400 : 'inherit' ),
  },
} ) )

const StartupCard = ( {
  name,
  logoUrl,
  website,
  industries,
  foundedYear,
  targetEquity,
  targetRaise,
  fundraiseStage,
  stage,
  oneLiner,
  locations,
  match,
  ignored,
  engaged,
  bookmarked,
  onMeetClick,
  onIgnoreClick,
  onBookmarkClick,
  onClick,
} ) => {
  const classes = useStyles( { name, ignored } )

  const industry = getShortList( industries )
  const location = getShortList( locations.map( getCountryName ) )

  return (
    <Card className={classes.root} raised onClick={onClick}>

      <CardContent className={classes.content}>

        <Avatar className={classes.avatar} src={logoUrl} alt={name}>
          <Business fontSize="large" />
        </Avatar>

        <Box className={classes.header}>

          <MatchBadge value={Math.round( match )} />

          <Box className={classes.raiseDetails}>
            <Typography variant="overline">
              £
              {numeral( targetRaise ).format( '0.[0a]a' )}
            </Typography>

            <Typography variant="overline">
              {Number( targetEquity ).toFixed( 1 )}
              %
            </Typography>
          </Box>

        </Box>

        <Typography className={classes.title} variant="h3" gutterBottom>
          {name}
        </Typography>

        <HorizontalItems>
          {!!foundedYear && <Typography>{foundedYear}</Typography>}

          <Typography className={classes.industries}>{industry}</Typography>

          {website && (
            <ExternalLink
              onClick={withStopPropagation()}
              href={website}
            >
              Website
            </ExternalLink>
          )}
        </HorizontalItems>

        <Box className={classes.chips}>
          <Chip label={stage} color="primary" />
          <Chip label={fundraiseStage} color="primary" />
          <ExpandableChip label={location} color="primary" />
        </Box>

        <Typography className={classes.oneLiner}>{oneLiner}</Typography>

      </CardContent>

      <CardActions className={classes.actions} disableSpacing>

        <ActionButton
          state={bookmarked}
          complete="Bookmarked"
          incomplete="Bookmark"
          icon={<Star />}
          onClick={onBookmarkClick}
        />

        <EngageButton
          engaged={engaged}
          onClick={onMeetClick}
        />

        <ActionButton
          className={classes.ignore}
          state={ignored}
          complete="Ignored"
          incomplete="Ignore"
          icon={<Clear />}
          onClick={onIgnoreClick}
        />

      </CardActions>

    </Card>
  )
}

StartupCard.propTypes = {
  name: string.isRequired,
  logoUrl: string,
  website: string,
  industries: arrayOf( string ),
  foundedYear: number.isRequired,
  targetEquity: number.isRequired,
  targetRaise: number.isRequired,
  fundraiseStage: string.isRequired,
  stage: string.isRequired,
  oneLiner: string.isRequired,
  locations: arrayOf( string ),
  match: number.isRequired,
  bookmarked: bool,
  engaged: oneOf( [ ...Object.values( MEETING_STATUSES ), false ] ),
  ignored: bool,
  onBookmarkClick: func,
  onMeetClick: func,
  onIgnoreClick: func,
  onClick: func,
}

StartupCard.defaultProps = {
  logoUrl: null,
  website: null,
  industries: [],
  locations: [],
  bookmarked: false,
  engaged: false,
  ignored: false,
  onBookmarkClick: () => {},
  onMeetClick: () => {},
  onIgnoreClick: () => {},
  onClick: () => {},
}

export default StartupCard
