import { Collapse, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { arrayOf, shape, string } from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

const drawerWidth = '300px'

const useStyles = makeStyles( {
  drawerPaper: {
    zIndex: 1000,
    width: drawerWidth,
  },
  list: {
    padding: '0px',
  },
  subListItem: {
    paddingLeft: '40px',
    backgroundColor: '#f6f6f6',
  },
} )

const Sidebar = ( { items } ) => {
  const classes = useStyles()
  const initialOpen = Array( items.length ).fill( false )
  initialOpen[ 0 ] = true
  const [ open, setOpen ] = useState( initialOpen )
  const history = useHistory()

  const handleClick = ( index ) => {
    setOpen( open.map( ( value, i ) => i === index && !value ) )
  }

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
      open
      variant="permanent"
    >
      <Toolbar />
      { items.map( ( item, index ) => (
        <List key={item.label} className={classes.list}>
          <ListItem button onClick={() => handleClick( index )}>
            <ListItemText primary={item.label} />
            <ListItemIcon>
              {open[ index ] ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
          </ListItem>
          <Divider />
          <Collapse in={open[ index ]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map( ( subItem ) => (
                <ListItem
                  button
                  key={subItem.label}
                  className={classes.subListItem}
                  onClick={() => history.push( subItem.path )}
                >
                  <ListItemText
                    primary={subItem.label}
                  />
                </ListItem>
              ) )}
            </List>
          </Collapse>
        </List>
      ) )}
    </Drawer>
  )
}

Sidebar.propTypes = {
  items: arrayOf( shape( {
    label: string,
    subItems: arrayOf( shape( {
      label: string,
      path: string,
    } ) ),
  } ) ).isRequired,
}

export default Sidebar
