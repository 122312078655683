import * as PropTypes from 'prop-types'

export const meetingInfoPropTypes = {
  startupId: PropTypes.string.isRequired,
  investorId: PropTypes.string.isRequired,
  meetingTimes: PropTypes.arrayOf( PropTypes.string ).isRequired,
  acceptedTime: PropTypes.string,
  acceptedAt: PropTypes.string,
  declinedBy: PropTypes.string,
  declinedAt: PropTypes.string,
  meetingId: PropTypes.number,
  message: PropTypes.string,
}

export const meetingInfoDefaultProps = {
  acceptedTime: null,
  acceptedAt: null,
  declinedBy: null,
  declinedAt: null,
  meetingId: null,
  message: null,
}
