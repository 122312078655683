import { makeStyles, Paper } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React from 'react'

const useStyles = (
  { pos, width, arrowPos, prevClick, nextClick, open, rotationPosition, leftPosition },
) => makeStyles( {
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  divider: {
    position: 'absolute',
    left: pos[ 0 ],
    top: pos[ 1 ],
    opacity: '0%',
    animationDelay: '0s',
    animation: open ? '$fadeIn 1s forwards' : null,
    zIndex: open ? 5 : 1,
  },
  text: {
    textAlign: 'center',
    margin: 0,
    fontSize: '1rem',
  },
  rectangle: {
    position: 'relative',
    padding: '1em 1em 0.3em 1em',
    boxSizing: 'border-box',
    background: '#fff',
    border: '1px solid #fafafa',
    boxShadow: '0px 3px 3px 0 rgba(0, 0, 0, 0.4)',
    width,
  },
  after: {
    zIndex: '-10',
    position: 'absolute',
    width: 0,
    height: 0,
    margin: 0,
    bottom: 0,
    top: arrowPos === 'top' ? 0 : 'calc(50% - 5px)',
    left: leftPosition[ arrowPos ],
    right: arrowPos === 'right' ? '-10px' : null,
    border: '5px solid #fff',
    transformOrigin: '0 0',
    transform: rotationPosition[ arrowPos ],
  },
  before: {
    zIndex: '10 !important',
    content: '',
  },
  controlDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 0 0',
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  nextDiv: {
    cursor: nextClick ? 'pointer' : 'default',
  },
  prevDiv: {
    cursor: prevClick ? 'pointer' : 'default',
  },
  button: {
    color: 'grey',
  },
  progress: {
    width: '100%',
    margin: '0px 0px 5px 0px',
    textAlign: 'center',
  },
} )

const ToolTip = ( { pos, text, width, arrowPos, prevClick, nextClick, open, progress } ) => {
  const rotationPosition = {
    left: 'rotate(45deg)',
    right: 'rotate(225deg)',
    top: 'rotate(135deg)',
  }

  const leftPosition = {
    left: 0,
    top: '50%',
  }

  const classes = useStyles(
    { pos, width, arrowPos, prevClick, nextClick, open, rotationPosition, leftPosition },
  )()
  if ( !open ) return null
  return (
    <div className={classes.divider}>
      <div className={`${classes.after} ${classes.before}`} />
      <Paper className={classes.rectangle}>
        <p className={classes.progress}>
          {progress.index}
          /
          {progress.length}
        </p>
        <p className={classes.text}>{text}</p>
        <div className={classes.controlDiv}>
          {prevClick ? (
            <div onClick={open ? prevClick : null} className={`${classes.buttonDiv} ${classes.prevDiv}`}>
              <NavigateBefore className={classes.button} />
              <p>Prev </p>
            </div>
          ) : <div />}
          <div onClick={open ? nextClick : null} className={`${classes.buttonDiv} ${classes.nextDiv}`}>
            {progress.index === progress.length ? <p> Finish</p> : <p>Next </p>}
            <NavigateNext className={classes.button} />
          </div>

        </div>
      </Paper>
      <div className={classes.after} />
    </div>
  )
}

ToolTip.propTypes = {
  pos: arrayOf( number ).isRequired,
  text: string.isRequired,
  width: number,
  arrowPos: string,
  prevClick: func,
  nextClick: func,
  open: bool,
  progress: shape( { index: number, length: number } ).isRequired,
}

ToolTip.defaultProps = {
  width: null,
  arrowPos: 'left',
  prevClick: null,
  nextClick: null,
  open: true,
}

export default ToolTip
