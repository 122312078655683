import * as yup from 'yup'

const setupYup = () => {
  const locale = {
    mixed: {
      required: 'Required',
    },
    string: {
      min: ( { min } ) => `Must be at least ${min} characters`,
      max: ( { max } ) => `Cannot be longer than  ${max} characters`,
      email: 'Invalid email address',
      url: 'Must be a valid URL',
    },
    number: {
      min: ( { min } ) => `Must be greater than ${min}`,
      max: ( { max } ) => `Cannot be greater than ${max}`,
    },
    array: {
      max: ( { max } ) => `Must have up to ${max} values`,
    },
  }

  yup.setLocale( locale )

  // Add custom url validator with optional schema
  const urlRegex = /^(((https?):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i
  yup.addMethod( yup.string, 'url', function validateWebsite( message = locale.string.url ) {
    return this.matches( urlRegex, { name: 'url', message, excludeEmptyString: true } )
  } )

  // Add a schema submit function
  function submit( value ) {
    return this.cast( value, { context: { output: true } } )
  }

  yup.addMethod( yup.mixed, 'submit', submit )

  // Cast schema to a format suitable for submission by setting empty strings to null
  const emptyToNull = ( value ) => ( [ undefined, '', null ].includes( value ) ? null : value )

  // Add http:// to websites missing schema
  function addUriScheme( value ) {
    const urlTest = this.tests.find( ( { OPTIONS: { name } } ) => name === 'url' )

    if ( !value || !urlTest || value.match( /^((https?):)?\/\// ) ) return value

    return `http://${value}`
  }

  function castToOutput( value, { context: { output } = {} } = {} ) {
    if ( !output ) return value

    return [
      addUriScheme,
      emptyToNull,
    ].reduce( ( value, fn ) => fn.bind( this )( value ), value )
  }

  [ yup.number, yup.string, yup.date, yup.boolean ].forEach( ( type ) => yup.addMethod( type, 'cast', castToOutput ) )
}

export default setupYup
