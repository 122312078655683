import { fetcher } from '@kaiku/shared'

const API_ROOT = '/api/users'

export const register = ( body ) => fetcher( `${API_ROOT}/register`, { method: 'POST', body } )

export const getCompleteStartupRegistrationUrl = () => `${API_ROOT}/register/startup`

export const getCompleteInvestorRegistrationUrl = () => `${API_ROOT}/register/investor`

export const getUserInfomationUrl = () => `${API_ROOT}/getUserInformation`
