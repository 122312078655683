import { USER_TYPES } from '@kaiku/shared'
import { useUser } from '@kaiku/ui'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import React, { useEffect, useState } from 'react'

import { usePerSession } from '../hooks'

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    background: `linear-gradient(bottom, ${palette.primary.main}, ${palette.primary.dark})`,
    width: '100%',
    height: '50px',
    display: 'flex',
    zIndex: 1100,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  link: {
    color: '-webkit-link',
    cursor: 'pointer',
  },
} ) )

const EmailVerificationReminder = () => {
  const [ opened, save ] = usePerSession( 'verificationReminder' )
  const [ showReminder, setShowReminder ] = useState( !opened )
  const [ reminderDismissed, setReminderDismissed ] = useState( false )
  const [ emailVerificationSent, setEmailVerificationSent ] = useState( false )

  const [ user ] = useUser()
  const classes = useStyles()

  useEffect( () => {
    if ( !opened && !reminderDismissed
      && user
      && user.type !== USER_TYPES.undetermined
      && !user.email_verified ) {
      setShowReminder( true )
    } else {
      setShowReminder( false )
    }
  }, [ user, reminderDismissed, opened ] )

  if ( user === false ) {
    return ( <div /> )
  }

  const handleResendEmailVerification = () => {
    sendEmailVerification( getAuth().currentUser )
    setEmailVerificationSent( true )
  }

  const handleDismiss = () => {
    save()
    setReminderDismissed( true )
  }

  if ( showReminder ) {
    return (
      <div className={classes.root}>
        {emailVerificationSent ? (
          <Typography>Email sent! Please check your inbox.</Typography>
        )
          : (
            <Typography>
              Email not verified. Please check your inbox or
              <span className={classes.link} onClick={handleResendEmailVerification}> resend </span>
              the verification email.
            </Typography>
          )}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleDismiss}
        >
          <Close fontSize="small" />
        </IconButton>
      </div>
    )
  }
  return <></>
}

export default EmailVerificationReminder
