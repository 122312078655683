import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { node, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5em',
    '& > *': {
      fontSize: '1.25em',
    },
    '& > :not(:last-child):after': {
      margin: '0 0.75em',
      content: '"•"',
      fontSize: '0.75em',
    },
  },
} )

const HorizontalItems = ( { className, children } ) => {
  const classes = useStyles()

  return <Box className={clsx( classes.root, className )}>{children}</Box>
}

HorizontalItems.propTypes = {
  className: string,
  children: node.isRequired,
}

HorizontalItems.defaultProps = {
  className: null,
}

export default HorizontalItems
