import { Box, Fab, makeStyles } from '@material-ui/core'
import { arrayOf, bool, elementType, shape, string } from 'prop-types'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import BackButton from './BackButton'
import FabIcon from './FabIcon'

const useStyles = makeStyles( ( { typography: { body1 } } ) => ( {
  itemContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    marginBottom: '0.5em',
  },
  container: {
    position: 'sticky',
    top: '7.55em',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    margin: '3em 1em',
  },
  subItem: {
    fontFamily: body1.fontFamily,
    textTransform: 'none',
    margin: '0.5em 3.5em',
    padding: '0.25em',
    height: '1.75em',
  },
  fab: {
    margin: '1.25em',
  },
} ) )

const SideNavbar = ( { items, withBackButton } ) => {
  const history = useHistory()
  const { pathname } = useLocation()

  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {withBackButton && (
      <Box className={classes.itemContainer}>
        <BackButton className={classes.fab} />
      </Box>
      ) }
      {items.map( ( { icon: Icon, name, route, subItems = [], ...props } ) => (
        <Box key={`${name}-${route}`} className={classes.itemContainer}>
          <FabIcon
            className={classes.fab}
            variant="extended"
            color={pathname.includes( route ) ? 'primary' : 'inherit'}
            onClick={() => history.push( route )}
            icon={Icon && <Icon />}
            label={name}
            {...props}
          />

          {pathname.includes( route ) && subItems.map( ( { name, route } ) => (
            <Fab
              key={name}
              className={classes.subItem}
              color={pathname.includes( route ) ? 'primary' : 'inherit'}
              variant="extended"
              onClick={() => history.push( route )}
            >
              {name}
            </Fab>
          ) )}
        </Box>
      ) )}
    </Box>
  )
}

const itemPropType = {
  name: string.isRequired,
  route: string,
  icon: elementType,
}

SideNavbar.propTypes = {
  items: arrayOf( shape( {
    ...itemPropType,
    subItems: arrayOf( shape( itemPropType ) ),
  } ) ),
  withBackButton: bool,
}

SideNavbar.defaultProps = {
  items: [],
  withBackButton: false,
}

export default SideNavbar
