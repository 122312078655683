import { Avatar, Box, makeStyles, Paper, Typography } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { arrayOf, bool, func, number, string } from 'prop-types'
import React from 'react'

import { withStopPropagation } from '../lib/event-handler-factories'
import getShortList from '../lib/get-short-list'
import ExternalLink from './ExternalLink'
import HorizontalItems from './HorizontalItems'

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    borderRadius: '100em',
    width: '100%',
    height: ( { mini } ) => ( mini ? 'auto' : '10em' ),
    display: 'flex',
    cursor: 'pointer',
    boxShadow: '0 0 0.3em 0.025em rgba(0, 0, 0, 0.25)',
  },
  avatar: {
    border: '4px solid #EEEEEE',
    borderRadius: '100em',
    height: ( { mini } ) => ( mini ? 'auto' : '7em' ),
    width: ( { mini } ) => ( mini ? '4em' : '7em' ),
  },
  content: {
    width: '100%',
    padding: ( { mini } ) => ( mini ? '1em' : '1.5em 2em 1.5em 4em' ),
  },
  name: {
    fontSize: ( { name } ) => ( name.length > 30 ? '1.25em' : '1.5em' ),
    maxWidth: '400px',
  },
  match: {
    marginLeft: 'auto',
    marginRight: '1.5em',
    fontSize: '1.25em',
    color: palette.primary.main,
  },
  industries: {
    textTransform: 'capitalize',
  },
  subheader: {
    justifyContent: 'flex-start',
    marginTop: 0,
    marginBottom: '0.5em',
  },
  oneLiner: {
    height: '3.125em',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    paddingRight: '1.5em',
    overflow: 'hidden',
  },
} ) )

const StartupResult = ( {
  name,
  logoUrl,
  website,
  industries,
  foundedYear,
  oneLiner,
  match,
  mini,
  onClick,
} ) => {
  const classes = useStyles( { name, mini } )

  return (
    <Paper className={classes.root} onClick={onClick} elevation={1}>
      <Avatar className={classes.avatar} src={logoUrl} alt={name}>
        <Business fontSize="large" />
      </Avatar>

      <Box className={classes.content}>
        <Box display="flex">
          <Typography className={classes.name} variant="h3">{name}</Typography>
          {match && <Typography className={classes.match} variant="h3">{`${Math.round( match )}% Match`}</Typography>}
        </Box>

        <HorizontalItems className={classes.subheader}>
          {!!foundedYear && <Typography>{foundedYear}</Typography>}
          <Typography className={classes.industries}>{getShortList( industries )}</Typography>
          {!mini && website && <ExternalLink onClick={withStopPropagation()} href={website} />}
        </HorizontalItems>

        {!mini && <Typography className={classes.oneLiner}>{oneLiner}</Typography>}
      </Box>
    </Paper>
  )
}

StartupResult.propTypes = {
  name: string.isRequired,
  logoUrl: string,
  website: string,
  industries: arrayOf( string ),
  foundedYear: number.isRequired,
  oneLiner: string.isRequired,
  match: number.isRequired,
  onClick: func,
  mini: bool,
}

StartupResult.defaultProps = {
  logoUrl: null,
  website: null,
  industries: [],
  onClick: () => {},
  mini: false,
}

export default StartupResult
