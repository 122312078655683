import { countries, GENDERS, getCountryName, ROLES, uuid } from '@kaiku/shared'
import { FormControl, InputLabel, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { FastField, Field, FieldArray, getIn } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { mapValues } from 'lodash'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import Accordion from '../../Accordion'
import ContentBlock from '../../ContentBlock'
import { Autocomplete } from '../../Formik/Autocomplete'
import EditAvatar from '../../Formik/EditAvatar'
import ValidatedForm from '../../Formik/Form'
import UploadButton, { UPLOAD_TYPES } from '../../Formik/UploadButton'

const useStyles = makeStyles( {
  form: {
    marginTop: '2em',
  },
  avatar: {
    width: '5rem',
    height: '5rem',
    fontSize: '0.75em',
    margin: 'auto',
  },
} )

const createSchema = () => yup.object().shape( {
  members: yup.array().of( yup.object().shape( {
    id: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().nullable(),
    gender: yup.string().oneOf( Object.values( GENDERS ) ).nullable(),
    role: yup.string().oneOf( Object.values( ROLES ) ).required(),
    description: yup.string().nullable(),
    linkedinUrl: yup.string().url().nullable(),
    cvUrl: yup.string().nullable(),
    pictureUrl: yup.string().nullable(),
    identityDocumentUrl: yup.string().nullable(),
    location: yup.string().nullable(),
    nationality: yup.string().nullable(),
    website: yup.string().url().nullable(),
  } ) ),
} )

const initialMemberValues = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  gender: '',
  role: '',
  description: '',
  linkedinUrl: '',
  cvUrl: '',
  pictureUrl: '',
  identityDocumentUrl: '',
  location: '',
  nationality: '',
  website: '',
  permanent: false,
}

const Title = ( { firstName, lastName, index, error } ) => {
  const name = ( firstName || lastName )
    ? `${firstName} ${lastName}${index === 0 ? ' (You)' : ''}`
    : 'New Employee'

  return (
    <Typography color={error ? 'error' : 'inherit'}>
      {name}
    </Typography>
  )
}

Title.propTypes = {
  firstName: string,
  lastName: string,
  index: number.isRequired,
  error: bool,
}

Title.defaultProps = {
  firstName: null,
  lastName: null,
  error: false,
}

const Details = ( { index } ) => {
  const classes = useStyles()

  return (
    <>
      <FastField
        name={`members[${index}].pictureUrl`}
        component={EditAvatar}
        className={classes.avatar}
      />

      <Field
        component={TextField}
        name={`members[${index}].firstName`}
        label="First Name*"
        disabled={index === 0}
      />

      <Field
        component={TextField}
        name={`members[${index}].lastName`}
        label="Last Name*"
        disabled={index === 0}
      />

      <FormControl>
        <InputLabel>Gender</InputLabel>

        <Field
          component={Select}
          name={`members[${index}].gender`}
        >
          {Object.values( GENDERS ).map( ( gender ) => (
            <MenuItem
              key={gender}
              value={gender}
            >
              {gender}
            </MenuItem>
          ) )}
        </Field>
      </FormControl>

      <FormControl>
        <InputLabel>Role</InputLabel>

        <Field
          component={Select}
          name={`members[${index}].role`}
        >
          {Object.values( ROLES ).map( ( role ) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ) )}
        </Field>
      </FormControl>

      <FastField
        component={TextField}
        name={`members[${index}].description`}
        label="Profile Summary"
        multiline
      />

      <Field
        component={Autocomplete}
        name={`members[${index}].location`}
        options={countries.map( ( { code } ) => code )}
        getOptionLabel={( code ) => getCountryName( code ) || ''}
        label="Location"
      />

      <Field
        component={Autocomplete}
        name={`members[${index}].nationality`}
        options={countries.filter( ( { nationality } ) => nationality ).map( ( { code } ) => code )}
        getOptionLabel={( code ) => getCountryName( code ) || ''}
        label="Nationality"
      />

      <Field
        component={TextField}
        name={`members[${index}].linkedinUrl`}
        label="LinkedIn Profile"
      />

      <Field
        component={TextField}
        name={`members[${index}].website`}
        label="Website"
      />

      <Field
        component={UploadButton}
        name={`members[${index}].cvUrl`}
        label="CV"
        {...UPLOAD_TYPES.documents}
      />

      <Field
        component={UploadButton}
        name={`members[${index}].identityDocumentUrl`}
        label="Identity Document"
        {...UPLOAD_TYPES.images}
      />

    </>
  )
}

Details.propTypes = {
  index: number.isRequired,
}

const EditTeamBlock = ( { members, withSubmit, onSubmit } ) => {
  const initialValues = {
    members: members.map( ( member ) => mapValues(
      initialMemberValues,
      ( initialValue, key ) => member[ key ] || initialValue,
    ) ),
  }

  initialValues.members[ 0 ].permanent = true

  const classes = useStyles()
  const schema = createSchema()

  return (
    <ContentBlock title="Team">
      <ValidatedForm
        className={classes.form}
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <FieldArray name="members">
            {( { form: { values: { members }, errors }, push, remove } ) => (
              <Accordion
                onAdd={() => push( { ...initialMemberValues, id: uuid() } )}
                onDelete={remove}
                items={members}
                renderTitle={( props, index ) => <Title {...props} index={index} error={!!Object.keys( getIn( errors, [ 'members', index ], {} ) ).length} />}
                renderDetails={( _, index ) => <Details index={index} />}
              />

            )}
          </FieldArray>

        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

EditTeamBlock.propTypes = {
  members: arrayOf( shape( {
    id: string,
    firstName: string.isRequired,
    lastName: string.isRequired,
    role: string,
    description: string,
    linkedinUrl: string,
    pictureUrl: string,
    cvUrl: string,
    identityDocumentUrl: string,
    nationality: string,
    location: string,
    website: string,
  } ) ),
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
}

EditTeamBlock.defaultProps = {
  members: [],
}

export default EditTeamBlock
