import { countries, getCountryName, SEIS_PROGRESS } from '@kaiku/shared'
import { MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { Select } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import { mapValues } from 'lodash'
import { bool, func, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import { Autocomplete } from '../../Formik/Autocomplete'
import ValidatedForm from '../../Formik/Form'
import fields from './fields'

const createSchema = () => yup.object().shape( {
  incorporated: yup.boolean(),
  //! Using .date() does not typecast to new Date(dateStr) correctly
  incorporatedDate: yup.lazy( ( value ) => ( value instanceof Date
    ? yup.date()
    : yup.string().nullable() ) ),
  incorporatedLocation: yup.string().nullable(),
  seisStatus: yup.string().oneOf( Object.values( SEIS_PROGRESS ) ).nullable(),
  patents: yup.boolean().nullable(),
} )

const LegalsBlock = ( {
  incorporatedDate,
  incorporatedLocation,
  incorporated,
  seisStatus,
  patents,
  withSubmit,
  onSubmit,
} ) => {
  const initialValues = {
    ...mapValues( { incorporated, seisStatus, patents, incorporatedLocation }, ( value ) => value || '' ),
    incorporatedDate,
  }

  const schema = createSchema()

  const doSubmit = ( {
    incorporated,
    incorporatedDate,
    incorporatedLocation,
    ...form
  } ) => onSubmit( {
    ...schema.submit( { ...form, incorporated } ),
    ...( incorporated
      ? { incorporatedDate: new Date( incorporatedDate ).toJSON().split( 'T' )[ 0 ], incorporatedLocation }
      : { incorporatedDate: null, incorporatedLocation: null } ),
  } )

  return (
    <ContentBlock title="Legals">
      <ValidatedForm schema={schema} initialValues={initialValues} onSubmit={doSubmit}>
        {withSubmit( ( { values: { incorporated } } ) => (
          <>

            <CaptionField
              {...fields.incorporated}
              renderValue={() => (
                <Field component={Select} name="incorporated">
                  <MenuItem value>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Field>
              )}
            />

            {incorporated && (
              <>

                <CaptionField
                  {...fields.incorporatedDate}
                  renderValue={() => (
                    <Field
                      component={DatePicker}
                      name="incorporatedDate"
                      format="do MMMM yyyy"
                      openTo="year"
                      disableFuture
                      autoOk
                    />
                  )}
                />

                <CaptionField
                  {...fields.incorporatedLocation}
                  renderValue={() => (
                    <Field
                      component={Autocomplete}
                      name="incorporatedLocation"
                      options={countries.map( ( { code } ) => code )}
                      getOptionLabel={( code ) => getCountryName( code ) || ''}
                    />
                  )}
                />

              </>
            )}

            <CaptionField
              {...fields.seisStatus}
              renderValue={() => (
                <Field component={Select} name="seisStatus">
                  {Object.values( SEIS_PROGRESS ).map( ( value ) => (
                    <MenuItem key={value} value={value}>{value}</MenuItem>
                  ) )}
                </Field>
              )}
            />

            <CaptionField
              {...fields.patents}
              renderValue={() => (
                <Field component={Select} name="patents">
                  <MenuItem value>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Field>
              )}
            />

          </>
        ) )}

      </ValidatedForm>
    </ContentBlock>
  )
}

LegalsBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  incorporated: bool,
  incorporatedDate: string,
  incorporatedLocation: string,
  seisStatus: string,
  patents: bool,
}

LegalsBlock.defaultProps = {
  incorporated: null,
  incorporatedDate: null,
  incorporatedLocation: null,
  seisStatus: null,
  patents: null,
}

export default LegalsBlock
