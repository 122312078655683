import { makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import PropTypes, { arrayOf, func, string } from 'prop-types'
import React, { memo } from 'react'
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps'

const useStyles = makeStyles( ( theme ) => ( {
  main: {
    overflow: 'hidden',
  },
  map: {
    [ theme.breakpoints.down( 'md' ) ]: {
      height: '30vh',
      marginLeft: '-25vw',
      marginTop: '30px',
    },
  },
} ) )

const MapChart = ( { data, setTooltipContent, country, mapsvgpath, onClick } ) => {
  const theme = useTheme()
  const classes = useStyles()
  const getStats = () => {
    const array = Object.values( data )
      .filter( ( o ) => o.startupCount )
      .map( ( o ) => o.startupCount )
    const n = array.length
    const mean = array.reduce( ( a, b ) => a + b ) / n
    const sd = Math.sqrt(
      array.map( ( x ) => ( ( x - mean ) ** 2 ) ).reduce( ( a, b ) => a + b ) / n,
    ) || 1 // standardDeviation
    return { mean, sd }
  }

  const getColour = ( startupsInLocation ) => {
    if ( startupsInLocation === 0 ) return 'rgb(214, 214, 218)'
    const { mean, sd } = getStats()
    const greyColour = [ 214, 214, 218 ]
    const kaikuColour = [ 113, 204, 152 ]
    const baseColour = Array( 3 ).fill( 0 ).map(
      ( _, i ) => ( ( greyColour[ i ] - kaikuColour[ i ] ) * 0.25 ) + kaikuColour[ i ],
    )
    const diffColour = Array( 3 ).fill( 0 ).map(
      ( rgb, i ) => kaikuColour[ i ] - baseColour[ i ],
    )
    const finalColour = baseColour.map(
      ( baseRGB, i ) => {
        const newRGB = baseRGB + ( diffColour[ i ] * ( ( startupsInLocation - mean ) / sd ) )
        if ( newRGB > greyColour[ i ] ) return greyColour[ i ]
        if ( newRGB < kaikuColour[ i ] ) return kaikuColour[ i ]
        return newRGB
      },
    )
    return `rgb(${finalColour[ 0 ]}, ${finalColour[ 1 ]}, ${finalColour[ 2 ]})`
  }

  const mapWidth = 750
  const mapHeight = 290

  return (
    <div className={classes.main}>
      <ComposableMap
        data-tip=""
        width={mapWidth}
        height={mapHeight}
        className={classes.map}
        projectionConfig={{ scale: 108, yOffset: 2000 }}
      >
        <ZoomableGroup
          zoom={1}
          translateExtent={[
            [ 0, 0 ],
            [ mapWidth, mapHeight ],
          ]}
        >
          <Geographies geography={mapsvgpath}>
            {( { geographies } ) => geographies.filter( ( g ) => !( g.properties.name === 'Antarctica' ) ).map( ( geo ) => {
              const { name, ISO_A2 } = geo.properties
              const countryObj = data[ ISO_A2 ]
              const startupsInLocation = countryObj ? countryObj.startupCount : 0
              const colour = getColour( startupsInLocation )
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent( country === '' ? `${name} - ${startupsInLocation}` : name )
                  }}
                  onMouseLeave={() => {
                    setTooltipContent( '' )
                  }}
                  onClick={() => { if ( startupsInLocation > 0 && country !== ISO_A2 ) onClick( ISO_A2 ); else onClick( '' ) }}
                  style={{
                    default: {
                      fill: colour,
                      outline: '#000000',
                      cursor: 'pointer',
                    },
                    hover: {
                      fill: theme.palette.primary.main,
                      outline: 'none',
                      cursor: 'pointer',
                    },
                    pressed: {
                      fill: '#E42',
                      outline: 'none',
                    },
                  }}
                />
              )
            } )}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}

MapChart.propTypes = {
  data: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
  setTooltipContent: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
  country: string.isRequired,
  mapsvgpath: string.isRequired,
  onClick: func.isRequired,
}

export default memo( MapChart )
