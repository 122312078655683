import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles( {
  header: {
    textAlign: 'center',
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  mainButton: {
    marginTop: '15px',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  skipStepContainer: {
    paddingTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  skipStepText: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  link: {
    color: '-webkit-link',
    cursor: 'pointer',
    textAlign: 'end',
    textDecoration: 'underline',
  },
} )

export default useStyles
