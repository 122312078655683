import { HomeForm } from '@kaiku/ui'
import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles( {
  loadingContainer: {
    textAlign: 'center',
  },
} )
const HomeFormLoading = () => {
  const classes = useStyles()
  return (
    <HomeForm logo>
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    </HomeForm>
  )
}

export default HomeFormLoading
