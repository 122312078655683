import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { bool, func } from 'prop-types'
import React from 'react'

export default function TutorialModal( { open, close, skip } ) {
  return (
    <div>
      <Dialog
        onClose={close}
        open={open}
      >
        <DialogTitle>
          Welcome Investor!
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Thank you for signing up to the Kaiku platform where you will be able to
            discover the most successful startups raising rounds across diverse
            industries and product specialities.
          </Typography>
          <Typography gutterBottom>
            Thanks to detailed startup and investor profiles, our platform makes smart matches,
            based on individual preferences, detailed sign-up procedures and changing trends.
          </Typography>
          <Typography gutterBottom>
            Below you have the option to skip the tutorial and dive into the platform where you
            will be able to view matches, book meetings and engage with startups.
            Alternativley, you can proceed with the tour where you will recieve guidance
            concerning navigating and using the investor browsing space.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={skip}>
            Skip Tour
          </Button>
          <Button autoFocus onClick={close}>
            Go!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

TutorialModal.propTypes = {
  open: bool.isRequired,
  close: func.isRequired,
  skip: func.isRequired,
}
