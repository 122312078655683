import { TextField } from '@material-ui/core'
import { fieldToTextField } from 'formik-material-ui'
import React from 'react'

import formikPropTypes from './formik-prop-types'

const autocompleteToFormik = ( Component ) => {
  const SubComponent = ( {
    textFieldProps,
    onChange,
    ...props
  } ) => {
    const { form: { setFieldTouched, setFieldValue } } = props
    const { error, helperText, disabled, label, ...field } = fieldToTextField( props )
    const { name } = field

    const handleChange = onChange || ( ( _, value ) => setFieldValue( name, value ) )

    return (
      <Component
        {...props}
        {...field}
        onChange={handleChange}
        onBlur={() => setFieldTouched( name )}
        renderInput={( inputProps ) => (
          <TextField
            {...inputProps}
            {...textFieldProps}
            label={label}
            helperText={( helperText || {} )[ name ] || helperText}
            error={error}
          />
        )}
      />
    )
  }

  SubComponent.propTypes = formikPropTypes
  return SubComponent
}

export default autocompleteToFormik
