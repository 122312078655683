const { origin } = window.location

export const API_URL = new URL( '/api', origin )

export const {
  REACT_APP_HOTJAR_SITE_ID: HOTJAR_SITE_ID,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_ENVIRONMENT: ENVIRONMENT,
  REACT_APP_FIREBASE_AUTH_EMULATOR_HOST: FIREBASE_AUTH_EMULATOR_HOST,
  REACT_APP_FIREBASE_WEB_API_KEY: FIREBASE_WEB_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN,
  NODE_ENV,
} = process.env

export const IS_DEVELOPMENT = NODE_ENV === 'development'
