import { METRICS } from '@kaiku/shared'
import numeral from 'numeral'
import * as yup from 'yup'

const CURRENCY_METRICS = [
  METRICS.mrr,
  METRICS.arr,
  METRICS.cac,
  METRICS.ltv,
  METRICS.grossProfit,
]

const PERCENTAGE_METRICS = [
  METRICS.activation,
  METRICS.retention,
]

const METRIC_CONFIG = {
  ...Object.values( METRICS ).reduce( ( metrics, metric ) => ( {
    ...metrics,
    [ metric ]: {
      type: 'number',
      schema: yup.number().min( 0 ),
      inputProps: { min: 0 },
      getDisplay: ( value ) => numeral( value ).format( '0.[0a]a' ),
    },
  } ), {} ),
  ...CURRENCY_METRICS.reduce( ( metrics, metric ) => ( {
    ...metrics,
    [ metric ]: {
      type: 'number',
      schema: yup.number().min( 0 ),
      prefix: '£',
      inputProps: { step: 100, min: 0 },
      getDisplay: ( value ) => numeral( value ).format( '0.[0a]a' ),
    },
  } ), {} ),
  ...PERCENTAGE_METRICS.reduce( ( metrics, metric ) => ( {
    ...metrics,
    [ metric ]: {
      type: 'number',
      schema: yup.number().min( 0 ).max( 100 ),
      postfix: '%',
      inputProps: { min: 0, max: 100 },
      getDisplay: ( value ) => value,
    },
  } ), {} ),
}

export default METRIC_CONFIG
