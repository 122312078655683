import { makeStyles, Typography } from '@material-ui/core'
import { string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2em',
    width: '100%',
    height: '100%',
    fontSize: '1.25em',
    opacity: '0.8',
  },
} )

const EmptyMessage = ( { children } ) => {
  const classes = useStyles()

  return <Typography className={classes.empty} variant="button">{children}</Typography>
}

EmptyMessage.propTypes = {
  children: string.isRequired,
}

export default EmptyMessage
