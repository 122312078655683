import { MEETING_FILTERS, qs } from '@kaiku/shared'
import { formatDateAndTime, useFetchAuth, usePaginatedFetch } from '@kaiku/ui'
import { Avatar, Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { string } from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getDeclineMeetingForInvestorUrl, getInvestorMeetingsUrl } from '../../../../api'
import MeetingInfo from './Modals/MeetingInfo'

const noMeetingsMap = {
  [ MEETING_FILTERS.meetingBooked ]: 'No booked meetings.',
  [ MEETING_FILTERS.meetingRequested ]: 'No requested meetings.',
  [ MEETING_FILTERS.met ]: 'No completed meetings.',
  [ MEETING_FILTERS.declined ]: 'No declined meetings.',
}

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    marginTop: '3em',
    maxWidth: '1200px',
    alignSelf: 'center',
    width: '100%',
  },
  messageContainer: {
    padding: '2em',
  },
  logo: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    borderRadius: '50%',
    borderWidth: '1px',
    marginRight: '15px',
    '& > svg': {
      fontSize: '1.25em',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.primary.main,
    fontSize: '1.25em',
    textAlign: 'center',
  },
  noMeetings: {
    padding: '2em 1em 2em 1em',
  },
} ) )

const PAGE_SIZE = 12

const allColumns = [ 'Startup', 'Meeting Time', 'Status', null ]

const Meetings = ( { filter } ) => {
  const classes = useStyles()
  const fetch = useFetchAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [ hasMeetings, setHasMeetings ] = useState( true )
  const [ selectedMeeting, setSelectedMeeting ] = useState()
  const [ declining, setDeclining ] = useState( false )

  const {
    results: meetings = [],
    total = 0, setPage, page, mutate,
  } = usePaginatedFetch(
    [ getInvestorMeetingsUrl(), qs.stringify( { filter } ) ].join( '?' ),
    PAGE_SIZE,
  )

  const declineMeeting = ( startupId ) => {
    setDeclining( true )
    fetch( getDeclineMeetingForInvestorUrl( startupId ), { method: 'POST', body: {} } )
      .then( () => {
        enqueueSnackbar( 'Meeting has been declined.', { variant: 'success' } )
        setSelectedMeeting()
        mutate()
      } )
      .catch( () => enqueueSnackbar( 'Something went wrong. Please try again.', { variant: 'error' } ) )
      .finally( () => setDeclining( false ) )
  }

  // Synchronise state with query parameter
  useEffect( () => {
    if ( filter === MEETING_FILTERS.all ) {
      if ( total === 0 ) {
        setHasMeetings( false )
      } else {
        setHasMeetings( true )
      }
    }
  }, [ filter, total ] )

  if ( !hasMeetings ) {
    return (
      <Paper className={`${classes.messageContainer} ${classes.root}`}>
        <Typography align="center" variant="h4">
          <strong>Thank you for joining the Kaiku community!</strong>
          <br />
          Once you have engaged with a startup, they will appear here and
          you will be able to track view your meeting information here.
        </Typography>
      </Paper>
    )
  }

  if ( meetings.length === 0 ) {
    return (
      <Paper className={`${classes.root} ${classes.noMeetings}`}>
        {noMeetingsMap[ filter ]}
      </Paper>
    )
  }

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {allColumns.map( ( header ) => (
                <TableCell key={header} align="center">{header}</TableCell>
              ) )}
            </TableRow>
          </TableHead>

          <TableBody>
            {meetings.map( ( { meetingInfo, startup } ) => (
              <TableRow
                key={startup.id}
              >
                <TableCell scope="row">
                  <div className={classes.rowContainer}>
                    <Avatar
                      alt={`${startup.name} logo`}
                      className={classes.logo}
                      src={startup.logoUrl}
                    >
                      <Business fontSize="large" />
                    </Avatar>
                    {startup.name}
                  </div>
                </TableCell>
                <TableCell align="center">{( meetingInfo.acceptedTime && formatDateAndTime( meetingInfo.acceptedTime ) ) || '-'}</TableCell>
                <TableCell align="center">{meetingInfo.status}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="text"
                    onClick={() => setSelectedMeeting( { meetingInfo, startup } )}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ) )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={total}
                rowsPerPage={PAGE_SIZE}
                page={+page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={( _, page ) => setPage( +page )}
                ActionsComponent={undefined}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <MeetingInfo
        meeting={selectedMeeting}
        onClose={() => setSelectedMeeting()}
        declineMeeting={declineMeeting}
        declining={declining}
      />

    </Paper>
  )
}

Meetings.propTypes = {
  filter: string.isRequired,
}

export default Meetings
