import { getCountryName, getRegionsXorCountries } from '@kaiku/shared'
import { Box, Chip, makeStyles } from '@material-ui/core'
import { arrayOf, shape, string } from 'prop-types'
import React from 'react'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import DownloadButton from '../../DownloadButton'
import fields from './fields'

const useStyles = makeStyles( {
  files: {
    margin: '1.5em 0',
    '& > *': {
      marginRight: '3em',
    },
  },
} )

const DefaultAboutBlock = ( {
  oneLiner,
  description,
  stage,
  stageDescription,
  pitchDeckUrl,
  onePagerUrl,
  headquartersLocation,
  operationsLocations,
} ) => {
  const classes = useStyles()

  return (
    <ContentBlock title="About">
      <CaptionField {...fields.oneLiner} value={oneLiner} multiline />
      <CaptionField {...fields.description} value={description} multiline />
      <CaptionField {...fields.stage} value={stage} />
      <CaptionField {...fields.stageDescription} value={stageDescription} multiline />
      <CaptionField
        {...fields.headquartersLocation}
        value={getCountryName( headquartersLocation )}
      />

      {!!operationsLocations.length && (
        <CaptionField
          {...fields.operationsLocations}
          value={operationsLocations}
          renderValue={( { value } ) => (
            <Box>
              {getRegionsXorCountries( value.map( ( { name } ) => name ) )
                .map( ( name ) => <Chip key={name} label={name} /> )}
            </Box>
          )}
        />
      )}

      <Box className={classes.files}>
        {pitchDeckUrl && <DownloadButton name="Pitch Deck" href={pitchDeckUrl} />}
        {onePagerUrl && <DownloadButton name="One Pager" href={onePagerUrl} />}
      </Box>

    </ContentBlock>
  )
}

DefaultAboutBlock.propTypes = {
  oneLiner: string,
  description: string,
  stage: string,
  stageDescription: string,
  pitchDeckUrl: string,
  onePagerUrl: string,
  headquartersLocation: string,
  operationsLocations: arrayOf( shape( { name: string } ) ),
}

DefaultAboutBlock.defaultProps = {
  oneLiner: null,
  description: null,
  stage: null,
  stageDescription: null,
  pitchDeckUrl: null,
  onePagerUrl: null,
  headquartersLocation: null,
  operationsLocations: [],
}

export default DefaultAboutBlock
