import { getChangedData, InvestorAboutBlock, InvestorAdditionalBlock, InvestorHeaderBlock, InvestorPortfolioBlock, InvestorThesisBlock, useFetchAuth } from '@kaiku/ui'
import { Container, Grid, makeStyles } from '@material-ui/core'
import merge from 'deepmerge'
import React from 'react'
import useSWR, { mutate } from 'swr'

import { getUserInvestorUrl } from '../../../api'

const useStyles = makeStyles( ( { breakpoints } ) => ( {
  mobileMainGrid: {
    [ breakpoints.down( 'sm' ) ]: {
      width: '100%',
      margin: 0,
      padding: '0 !important',
    },
  },
} ) )

const BLOCKS = [
  InvestorHeaderBlock,
  InvestorAboutBlock,
  InvestorThesisBlock,
  InvestorPortfolioBlock,
  InvestorAdditionalBlock,
]

const Profile = () => {
  const { data } = useSWR( getUserInvestorUrl() )
  const fetch = useFetchAuth()
  const classes = useStyles()
  const onSubmit = ( form ) => {
    const updatedData = merge( data, form, { arrayMerge: ( _, x ) => x } )

    const patch = getChangedData( data, updatedData, {
      thesisLocations: ( { name } ) => name,
      thesisIndustries: ( { name } ) => name,
      thesisStages: ( { name } ) => name,
      thesisHighlights: ( { name } ) => name,
      portfolio: ( { id } ) => id,
      taxResidencies: ( { country } ) => country,
    } )

    if ( !patch ) return

    fetch( getUserInvestorUrl(), { method: 'PATCH', body: patch } )
    mutate( getUserInvestorUrl(), updatedData, false )
  }

  return (
    <Container>
      <Grid container spacing={6} justify="center" className={classes.mobileMainGrid}>

        <Grid item md={12} xs={12} className={classes.mobileMainGrid}>
          <Container maxWidth="md" disableGutters>
            {BLOCKS
              .map( ( Block, index ) => (
                <Block
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  {...data}
                  onSubmit={onSubmit}
                />
              ) )}
          </Container>
        </Grid>

      </Grid>
    </Container>
  )
}

export default Profile
