import { INVESTOR_ACCOUNT_TYPE, routes, withRouteParams } from '@kaiku/shared'
import { FabIcon, StartupAboutBlock, StartupBusinessBlock, StartupFundraiseBlock, StartupHeaderBlock, StartupLegalsBlock, StartupTeamBlock, useFetchAuth, useUser } from '@kaiku/ui'
import { Box, colors, Container, Grid, makeStyles } from '@material-ui/core'
import { Add, Check, Close, Schedule } from '@material-ui/icons'
import { string } from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { getBookmarkedMatchUrl, getIgnoredMatchUrl, getMatchDetailsUrl, getStartupUrl } from '../../../../api'
import Badge from '../../../../components/Badge'
import { getEngagedIcon } from '../../../../components/StartupCard/EngageButton'
import TrialExpiredDialog from '../TrialExpiredDialog'

const useStyles = makeStyles( {
  rightGutter: {
    position: 'sticky',
    top: '10em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: '5em',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: '2.5em',
    },
  },
  ignore: {
    backgroundColor: colors.orange.A400,
  },
} )

const BLOCKS = [
  StartupHeaderBlock,
  StartupAboutBlock,
  StartupBusinessBlock,
  StartupFundraiseBlock,
  StartupLegalsBlock,
  StartupTeamBlock,
]

const ViewStartup = ( { startupId } ) => {
  const fetch = useFetchAuth()

  const { data } = useSWR( getStartupUrl( startupId ) )
  const [ user ] = useUser()
  const history = useHistory()
  const { data: matchData = {}, mutate } = useSWR( getMatchDetailsUrl( startupId ) )

  const { match, engaged, ignored, bookmarked } = matchData

  const handleIgnoreClick = () => {
    fetch(
      getIgnoredMatchUrl( startupId ),
      { method: ignored ? 'DELETE' : 'PUT', body: {} },
    )

    mutate( { ...matchData, ignored: !ignored }, false )
  }

  const handleBookmarkClick = () => {
    fetch(
      getBookmarkedMatchUrl( startupId ),
      { method: bookmarked ? 'DELETE' : 'PUT', body: {} },
    )

    mutate( { ...matchData, bookmarked: !bookmarked }, false )
  }

  const [ showTrialExpiredModal, setShowTrialExpiredModal ] = useState( false )

  const handleMeetClick = () => {
    if ( engaged ) return

    if ( user.account === INVESTOR_ACCOUNT_TYPE.trialExpired ) {
      setShowTrialExpiredModal( true )
      return
    }
    history.push( withRouteParams(
      routes.INVESTOR_BOOKING,
      { startupId },
    ) )
  }

  const classes = useStyles()

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container justify="center" spacing={6}>

        <Grid item lg={9} md={8} xs={12}>
          <Container maxWidth="md">
            {BLOCKS
              .map( ( Block, index ) => (
                <Block
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  {...data}
                  notEditable
                />
              ) )}
          </Container>
        </Grid>

        <Grid item lg={2} md={3} xs={12}>
          <Box className={classes.rightGutter}>
            <Badge
              content={match}
              postfix="%"
              label="Match"
              tooltip="The match % is calculated based on your investment thesis."
              progress={match}
            />

            <Box className={classes.actions}>
              {ignored
                ? <FabIcon onClick={handleIgnoreClick} className={classes.ignore} color="inherit" icon={<Check />} label="Ignored" />
                : <FabIcon onClick={handleIgnoreClick} color="inherit" icon={<Close />} label="Ignore" />}

              {bookmarked
                ? <FabIcon onClick={handleBookmarkClick} color="primary" icon={<Check />} label="Bookmarked" />
                : <FabIcon onClick={handleBookmarkClick} color="inherit" icon={<Add />} label="Bookmark" />}

              {engaged
                ? <FabIcon color="primary" icon={getEngagedIcon( engaged )} label="Meeting Requested" />
                : <FabIcon onClick={() => handleMeetClick( true )} color="inherit" icon={<Schedule />} label="Schedule Meeting" />}
            </Box>

          </Box>
        </Grid>

        <TrialExpiredDialog
          open={showTrialExpiredModal}
          onClose={() => setShowTrialExpiredModal( false )}
        />
      </Grid>

    </Container>
  )
}

ViewStartup.propTypes = {
  startupId: string.isRequired,
}

export default ViewStartup
