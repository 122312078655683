import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { bool, func } from 'prop-types'
import React from 'react'

const TrialExpiredDialog = ( { open, onClose } ) => (
  <Dialog open={open} onClose={() => onClose()}>
    <DialogTitle>Trial Expired!</DialogTitle>

    <DialogContent>
      <DialogContentText>
        Trial has expired.
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button onClick={() => onClose()} variant="text" color="primary">Cancel</Button>
      <Button onClick={() => onClose()} variant="text" color="primary" autoFocus>Ok</Button>
    </DialogActions>

  </Dialog>
)

TrialExpiredDialog.propTypes = {
  open: bool,
  onClose: func,
}

TrialExpiredDialog.defaultProps = {
  open: false,
  onClose: () => {},
}

export default TrialExpiredDialog
