import { countries, CRITERIA, FUNDRAISE_STAGES, INDUSTRIES } from '@kaiku/shared'
import numeral from 'numeral'
import * as yup from 'yup'

const textDefaults = {
  type: 'text',
  schema: yup.string().required(),
  getDisplay: ( value ) => value,
}

const listDefaults = {
  type: 'list',
  schema: yup.object().shape( { name: yup.string(), value: yup.mixed().required() } ).required(),
  getDisplay: ( { value } ) => value,
  items: [],
}

const currencyDefaults = {
  type: 'number',
  schema: yup.number().integer().min( 0 ).required(),
  prefix: '£',
  inputProps: { min: 0, step: 100 },
  getDisplay: ( value ) => `>£${value}`,
}

const CRITERIA_CONFIG = {
  [ CRITERIA.keyword ]: textDefaults,
  [ CRITERIA.minimumTicketSize ]: {
    ...currencyDefaults,
    getDisplay: ( value ) => `>£${numeral( value ).format( '0.[0a]a' )}`,
  },
  [ CRITERIA.maximumTicketSize ]: {
    ...currencyDefaults,
    getDisplay: ( value ) => `<£${numeral( value ).format( '0.[0a]a' )}`,
  },
  [ CRITERIA.fundraiseStage ]: {
    ...listDefaults,
    items: Object.values( FUNDRAISE_STAGES ).map( ( value ) => ( { name: value, value } ) ),
  },
  [ CRITERIA.geography ]: {
    ...listDefaults,
    items: countries.map( ( { code, ...rest } ) => ( { value: code, ...rest } ) ),
    getDisplay: ( { name } ) => name,
  },
  [ CRITERIA.industry ]: {
    ...listDefaults,
    items: Object.values( INDUSTRIES ).map( ( value ) => ( { name: value, value } ) ),
    getDisplay: ( { name } ) => name,
  },
  [ CRITERIA.patents ]: {
    ...listDefaults,
    items: [ { name: 'Has patents', value: true }, { name: 'No patents', value: false } ],
    getDisplay: ( { name } ) => name,
  },
}

export default CRITERIA_CONFIG
