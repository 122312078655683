import { Box, Fab, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { node, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  root: {
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
  },
  label: {
    width: '100%',
  },
  icon: {
    display: 'flex',
    marginRight: '0.75rem',
  },
} )

const FabIcon = ( { className, icon, label, ...props } ) => {
  const classes = useStyles()

  return (
    <Fab className={clsx( className, classes.root )} variant="extended" {...props}>
      <Box className={classes.icon}>{icon}</Box>
      <span className={classes.label}>{label}</span>
    </Fab>
  )
}

FabIcon.propTypes = {
  className: string,
  icon: node,
  label: string.isRequired,
}

FabIcon.defaultProps = {
  className: null,
  icon: null,
}

export default FabIcon
