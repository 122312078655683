import { countries, FUNDRAISE_STAGES, regions } from '@kaiku/shared'
import { PlaceholderAutocomplete, PlaceholderGroupedAutocomplete } from '@kaiku/ui'
import { Grid, InputAdornment } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { capitalize } from 'lodash'
import React from 'react'

const countryOptions = countries.map( ( {
  name,
  code,
  ...rest
} ) => ( { name: code, displayName: name, ...rest } ) )
const countryGroups = Object
  .entries( regions )
  .map( ( [ name, value ] ) => ( {
    name,
    value: value.map(
      ( { name, code, ...rest } ) => ( { name: code, displayName: name, ...rest } ),
    ),
  } ) )

const InvestmentThesis = () => (

  <Grid container spacing={1} direction="row">
    <Grid item xs={12}>
      <Field
        component={PlaceholderGroupedAutocomplete}
        name="thesisLocations"
        options={countryOptions}
        mainLabel="Countries"
        label="Geography"
        placeholder="Global"
        groupLabel="Regions"
        groups={countryGroups}
        disableCloseOnSelect
        getComparisonValue={( { name } ) => name}
        getGroupValue={( { name } ) => name}
        getOptionLabel={( { displayName } ) => displayName}
        getOptionSelected={( { name }, { name: selected } ) => selected === name}
        getPlaceholderOption={() => ( { name: 'Global', displayName: 'Global' } )}
        renderTags={( tags ) => tags.map( ( { displayName } ) => displayName ).map( capitalize ).join( ', ' )}
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        component={PlaceholderAutocomplete}
        name="thesisStages"
        placeholder="Any"
        label="Fundraise Round"
        options={Object.values( FUNDRAISE_STAGES ).map( ( name ) => ( { name } ) )}
        multiple
        disableCloseOnSelect
        getOptionLabel={( { name } ) => name}
        getOptionSelected={( { name }, { name: selected } ) => selected === name}
        getPlaceholderOption={() => ( { name: 'Any' } )}
        renderTags={( tags ) => ( tags.map( ( { name } ) => name ).map( capitalize ).join( ', ' ) )}
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        component={TextField}
        name="ticketSizeMinimum"
        label="Minimum Ticket Size"
        type="number"
        inputProps={{ min: 0, step: 1000 }}
        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
        fullWidth
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        component={TextField}
        name="ticketSizeMaximum"
        label="Maximum Ticket Size"
        type="number"
        inputProps={{ min: 0, step: 1000 }}
        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
        fullWidth
      />
    </Grid>
  </Grid>

)

export default InvestmentThesis
