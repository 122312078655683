import { ROLES } from '@kaiku/shared'
import { Accordion, EditAvatar, UPLOAD_TYPES, UploadButton, ValidatedSelect } from '@kaiku/ui'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { FastField, Field, FieldArray, getIn } from 'formik'
import { TextField } from 'formik-material-ui'
import { arrayOf, bool, number, shape, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  form: {
    marginTop: '2em',
  },
  avatar: {
    width: '5rem',
    height: '5rem',
    fontSize: '0.75em',
    margin: 'auto',
  },
} )

const initialMemberValues = {
  firstName: '',
  lastName: '',
  role: '',
  linkedinUrl: '',
  cvUrl: '',
  pictureUrl: '',
  permanent: false,
}

const Title = ( { firstName, lastName, index, error } ) => {
  const name = ( firstName || lastName )
    ? `${firstName} ${lastName}${index === 0 ? ' (You)' : ''}`
    : 'New Employee'

  return (
    <Typography color={error ? 'error' : 'inherit'}>
      {name}
    </Typography>
  )
}

Title.propTypes = {
  firstName: string,
  lastName: string,
  index: number.isRequired,
  error: bool,
}

Title.defaultProps = {
  firstName: null,
  lastName: null,
  error: false,
}

const Details = ( { index, errors, touched } ) => {
  const classes = useStyles()

  return (
    <Grid container spacing={1} direction="row">
      <Grid item xs={12}>
        <FastField
          name={`members[${index}].pictureUrl`}
          component={EditAvatar}
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={12}>

        <Field
          component={TextField}
          name={`members[${index}].firstName`}
          label="First Name*"
          disabled={index === 0}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name={`members[${index}].lastName`}
          label="Last Name*"
          disabled={index === 0}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ValidatedSelect
          options={Object.values( ROLES )}
          error={getIn( touched, `members[${index}].role` ) && getIn( errors, `members[${index}].role` )}
          label="Role"
          name={`members[${index}].role`}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name={`members[${index}].linkedinUrl`}
          label="LinkedIn Profile"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={UploadButton}
          name={`members[${index}].cvUrl`}
          label="CV"
          {...UPLOAD_TYPES.documents}
        />
      </Grid>
    </Grid>
  )
}

Details.propTypes = {
  index: number.isRequired,
  errors: shape( { stage: string } ).isRequired,
  touched: shape( { stage: bool } ).isRequired,
}

const Team = () => (
  <FieldArray name="members">
    {( { form: { errors, touched, values: { members } }, push, remove } ) => (
      <Accordion
        onAdd={() => push( { ...initialMemberValues } )}
        onDelete={remove}
        items={members}
        renderTitle={( props, index ) => (
          <Title
            {...props}
            index={index}
          />
        )}
        renderDetails={( _, index ) => (
          <Details
            index={index}
            errors={errors}
            touched={touched}
          />
        )}
      />
    )}
  </FieldArray>
)

Team.propTypes = {
  members: arrayOf( shape( {
    id: string.isRequired,
    firstName: string,
    lastName: string,
    role: string,
    linkedinUrl: string,
    cvUrl: string,
    pictureUrl: string,
  } ) ).isRequired,
}

export default Team
