import { INVESTOR_TYPES } from './enums'

const investmentCompanyTypes = new Set( [
  INVESTOR_TYPES.ventureCapital,
  INVESTOR_TYPES.corporateVentureCapital,
  INVESTOR_TYPES.family,
] )

export const isInvestmentCompany = ( type ) => ( investmentCompanyTypes.has( type ) )

export const isFirebaseError = ( err ) => ( err.code && err.code.startsWith( 'auth/' ) )

export const getRepresentativeName = ( { representative } ) => `${representative.firstName} ${representative.lastName}`

export const getStartupAccountHolderName = ( { members } ) => {
  const accountHolder = members.find( ( m ) => m.accountHolder )

  return `${accountHolder.firstName} ${accountHolder.lastName}`
}

export const getStartupAccountHolderNameSafe = ( { members } ) => {
  if ( !members ) return ''
  return getStartupAccountHolderName( { members } )
}

export const getStartupAccountHolderEmail = ( { members } ) => members
  .find( ( m ) => m.accountHolder ).email

export const getStartupAccountHolderEmailSafe = ( { members } ) => {
  if ( !members ) return ''
  return getStartupAccountHolderEmail( { members } )
}

export const getMemberName = ( member ) => `${member.firstName} ${member.lastName}`

export const getRepresentativeEmail = ( { representative } ) => representative.email

export const getInvestorName = ( { type, companyName, representative } ) => {
  const isCompany = isInvestmentCompany( type )

  if ( isCompany ) {
    return companyName
  }
  return getRepresentativeName( { representative } )
}

export const getRepresentativeLogo = ( { representative } ) => representative.pictureUrl

export const getInvestorLogo = ( { type, companyLogoUrl, representative } ) => {
  const isCompany = isInvestmentCompany( type )

  if ( isCompany ) {
    return companyLogoUrl
  }

  return getRepresentativeLogo( { representative } )
}

export const getAnyInvestorLogo = ( { type, companyLogoUrl, representative } ) => {
  const isCompany = isInvestmentCompany( type )

  if ( isCompany && companyLogoUrl ) {
    return companyLogoUrl
  }

  return representative.pictureUrl
}
