import { isSubsetOf } from '@kaiku/shared'
import { Box, List, ListItem, ListItemText, ListSubheader, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes, { arrayOf, func, shape, string } from 'prop-types'
import React, { forwardRef } from 'react'

const useStyles = makeStyles( {
  root: {
    display: 'flex',
  },
  list: {
    maxHeight: '40vh',
    overflow: 'auto',
    width: '50%',
    flexGrow: 1,
  },
  subheader: {
    backgroundColor: '#FFFFFF',
  },
} )

const events = {
  selectOption: 'select-option',
  removeOption: 'remove-option',
}

const GroupedAutocomplete = ( {
  value,
  mainLabel,
  groupLabel,
  groups,
  getGroupValue,
  getComparisonValue,
  onChange,
  ...props
} ) => {
  // Get the raw values to compare against group values
  const comparisonValue = value.map( getComparisonValue )
  const selectedGroups = groups.reduce( ( acc, { name, value: groupValue } ) => ( {
    ...acc,
    [ name ]: !!value.length && isSubsetOf( groupValue.map( getGroupValue ), comparisonValue ),
  } ), {} )

  const bindGroupClick = ( { name, value: selectedValue } ) => ( event ) => {
    const group = groups.find( ( group ) => name === group.name )
    const isSelected = selectedGroups[ name ]

    const listEvent = isSelected ? events.removeOption : events.selectOption
    const nextValue = isSelected
      ? value.filter(
        ( x ) => !group.value.find( ( v ) => getComparisonValue( x ) === getGroupValue( v ) ),
      )
      : [ ...value, ...selectedValue ]

    onChange( event, nextValue, listEvent )
  }

  const classes = useStyles()

  return (
    <Autocomplete
      {...props}
      value={value}
      multiple
      groupBy={() => mainLabel}
      onChange={onChange}
      ListboxComponent={forwardRef( ( props, ref ) => (
        <Box className={classes.root}>
          <ul ref={ref} {...props} />

          <List {...props} className={classes.list} disablePadding dense>
            <ListSubheader className={classes.subheader}>{groupLabel}</ListSubheader>
            {groups.map( ( { name, value } ) => (
              <ListItem
                key={name}
                className={classes.listItem}
                button
                selected={selectedGroups[ name ]}
                onClick={bindGroupClick( { name, value } )}
              >
                <ListItemText primary={name} primaryTypographyProps={{ variant: 'body1' }} />
              </ListItem>
            ) )}
          </List>
        </Box>
      ) )}
    />
  )
}

GroupedAutocomplete.propTypes = {
  value: arrayOf( PropTypes.objectOf( PropTypes.object ) ),
  mainLabel: string,
  groupLabel: string,
  groups: arrayOf( shape( { name: string, value: string } ) ),
  getGroupValue: func,
  getComparisonValue: func,
  onChange: func,
}

GroupedAutocomplete.defaultProps = {
  value: [],
  mainLabel: null,
  groupLabel: null,
  groups: [],
  getGroupValue: ( x ) => x,
  getComparisonValue: ( x ) => x,
  onChange: () => {},
}

export default GroupedAutocomplete
