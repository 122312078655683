import { Button, CircularProgress } from '@material-ui/core'
import { bool, node } from 'prop-types'
import React from 'react'

const LoaderButton = ( { loading, children, ...props } ) => (
  // eslint-disable-next-line react/prop-types
  <Button {...props} disabled={loading || props.disabled}>
    {loading
      ? <CircularProgress size={24} />
      : children}
  </Button>
)

LoaderButton.propTypes = {
  loading: bool,
  children: node,
}

LoaderButton.defaultProps = {
  loading: false,
  children: null,
}

export default LoaderButton
