import { useUser } from '@kaiku/ui'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import { bool } from 'prop-types'
import React, { useState } from 'react'

import dummy from './dummy'
import StartupCard from './StartupCard'

const useStyles = makeStyles( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  alertContainer: {
    width: '100%',
    background: '#F4F4F4',
    position: 'sticky',
    top: '64px',
    zIndex: 1,
    paddingTop: '2em',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  alert: {
    width: 'fit-content',
  },
  alertBody: {
    fontSize: '1.15rem',
  },
  link: {
    color: '-webkit-link',
    cursor: 'pointer',
  },
  divider: {
    borderBottom: '2px solid #ccc',
    padding: '0 10px 20px 10px',
    marginTop: '50px',
    width: '100%',
  },
  dummyCardGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    opacity: 0.5,
  },
} )

const NotOnboardedWarning = ( { engaged, ignored, bookmarked } ) => {
  const [ emailVerificationSent, setEmailVerificationSent ] = useState( false )
  const [ { email_verified: emailVerified } ] = useUser()
  const classes = useStyles()

  const handleResendEmailVerification = () => {
    sendEmailVerification( getAuth().currentUser )
    setEmailVerificationSent( true )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.alertContainer}>
        <Alert severity={emailVerified ? 'info' : 'warning'} className={classes.alert}>
          <AlertTitle>
            <Typography variant="h4">
              <strong>
                {emailVerified ? 'Please note' : 'Account not onboarded'}
              </strong>
            </Typography>
          </AlertTitle>
          <Typography className={classes.alertBody}>
            Your account has not been onboarded yet.
            <br />
            {emailVerified ? 'Please wait until our team has validated your account.'
              : (
                <>
                  Please verify your email so that our team can onboard your account.
                  <br />
                  {emailVerificationSent ? 'Email sent! Please check your inbox.'
                    : (
                      <>
                        {'Click '}
                        <span
                          className={classes.link}
                          onClick={handleResendEmailVerification}
                        >
                          here
                        </span>
                        {' to resend the verification email.'}
                      </>
                    )}
                </>
              )}

            <br />
            Note:
            {' '}
            <strong>
              The startups below aren&apos;t real. They are a short glimpse at the UI of our
              platform. You will see real startup data as soon as your account has been
              onboarded.
            </strong>
          </Typography>
        </Alert>
        <div className={classes.divider} />
      </Box>
      <Box className={classes.dummyCardGrid}>
        {dummy.map( ( item ) => (
          <Box m={3}>
            <StartupCard
              key={item.id}
              {...item}
              engaged={engaged}
              ignored={ignored}
              bookmarked={bookmarked}
              website=""
            />
          </Box>
        ) )}
      </Box>
    </Box>
  )
}

NotOnboardedWarning.propTypes = {
  engaged: bool,
  ignored: bool,
  bookmarked: bool,
}

NotOnboardedWarning.defaultProps = {
  engaged: false,
  ignored: false,
  bookmarked: false,

}

export default NotOnboardedWarning
