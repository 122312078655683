import { INVESTOR_TYPES, isInvestmentCompany, PORTFOLIO_TYPES } from '@kaiku/shared'
import { array, date, lazy, mixed, number, object, ref, string } from 'yup'

const schemas = [
  () => object().shape( {
    companyLogoUrl: string(),
    type: string().oneOf( Object.values( INVESTOR_TYPES ) ).required(),
    companyName: string().when( 'type', ( type, schema ) => (
      isInvestmentCompany( type ) ? schema.required() : schema
    ) ),
    companyWebsite: string().when( 'type', ( type, schema ) => (
      isInvestmentCompany( type ) ? schema.url().required() : schema
    ) ),
    representative: object().when( 'type', ( type, schema ) => (
      isInvestmentCompany( type ) ? schema : schema.shape( {
        id: string().required(),
        firstName: string().required(),
        lastName: string().required(),
        website: string().url().required(),
      } ).required()
    ) ),
  } ),
  () => object().shape( {
    thesisIndustries: array().of( string().required() ).min( 1, 'Please select at least one industry.' ),
  } ),
  () => object().shape( {
    thesisHighlights: array().of( string().required() ),
  } ),
  () => object().shape( {
    thesisLocations: array().of( lazy( ( value ) => {
      switch ( typeof value ) {
        case 'number':
          return object().shape( { name: string() } )
        case 'string':
          return string()
        default:
          return mixed()
      }
    } ) ),
    thesisStages: array().of( lazy( ( value ) => {
      switch ( typeof value ) {
        case 'number':
          return object().shape( { name: string() } )
        case 'string':
          return string()
        default:
          return mixed()
      }
    } ) ),
    ticketSizeMinimum: number()
      .min( 0 )
      .nullable()
      .when( 'ticketSizeMaximum', {
        is: ( value ) => !!value,
        then: number().min( 0 ).max( ref( 'ticketSizeMaximum' ), 'Minimum Ticket size cannot be more than the Maximum Ticket Size' ),
      } )
      .nullable(),
    ticketSizeMaximum: number().min( 0 ).nullable(),
  } ),
  () => object().shape( {
    portfolio: array().of( object().shape( {
      company: string().required(),
      amount: number().min( 0 ).nullable(),
      type: string().oneOf( Object.values( PORTFOLIO_TYPES ) ).required(),
      //! Doesn't convert incoming timestamp string to date properly if type is date
      date: lazy( ( value ) => ( value instanceof Date ? date() : string().nullable() ) ),
    } ) ).nullable(),
  } ),
]

export default schemas
