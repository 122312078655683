import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider, useFetchAuth, UserProvider } from '@kaiku/ui'
import { CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ErrorBoundary } from '@sentry/react'
import { SnackbarProvider } from 'notistack'
import { node } from 'prop-types'
import React from 'react'
import { SWRConfig } from 'swr'

const SWRAuthConfig = ( { children } ) => {
  const fetcher = useFetchAuth()

  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
}

SWRAuthConfig.propTypes = { children: node.isRequired }

// Generate a context wrapper function
const withContexts = [
  [ ErrorBoundary, { fallback: 'An error occurred. Please refresh the page' } ],
  [ SnackbarProvider ],
  [ ThemeProvider ],
  [ UserProvider ],
  [ SWRAuthConfig ],
  [ MuiPickersUtilsProvider, { utils: DateFnsUtils } ],
  [ CssBaseline ],
].reduce( ( withContexts, [ Provider, props ] ) => ( children ) => withContexts(
  <Provider {...props}>{children}</Provider>,
), ( context ) => context )

export default withContexts
