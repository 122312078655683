import { getCountryName, getNationality } from '@kaiku/shared'
import { Avatar, Box, Button, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import { LinkedIn, LinkSharp, LocationOn } from '@material-ui/icons'
import clsx from 'clsx'
import { string } from 'prop-types'
import React, { forwardRef } from 'react'

import DownloadButton from './DownloadButton'
import ExternalLink from './ExternalLink'
import FlagIcon from './FlagIcon'

const useStyles = makeStyles( ( { palette, breakpoints } ) => ( {
  persona: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  avatar: {
    [ breakpoints.down( 'sm' ) ]: {
      width: '1.5em',
      height: '1.5em',
    },
    width: '2.5em',
    height: '2.5em',
    backgroundColor: palette.primary.main,
    margin: 'auto',
  },
  name: {
    fontSize: '1.6em',
    marginBottom: '-0.2em',
  },
  description: {
    whiteSpace: 'pre-line',
    marginTop: '0.25em',
  },
  links: {
    marginLeft: 'auto',
    marginRight: '1em',
    '& > *': {
      marginLeft: '2em',
    },
  },
  linkedIn: {
    minWidth: 'auto',
    padding: 0,
    color: '#007dbb',
    '& svg': {
      fontSize: '2.1em',
    },
  },
  subheader: {
    '& > *:not(:first-child)': {
      marginLeft: '1em',
    },
  },
  location: {
    color: 'black',
    display: 'flex',
    alignItems: 'center',
  },
  locationIcon: {
    fontSize: '1.5em',
  },
  nationality: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nationalityText: {
    marginTop: '0.75em',
    lineHeight: '1.2em',
    textAlign: 'center',
  },
  nationalityFlag: {
    fontSize: '1.5em',
    borderRadius: '0.25em',
  },
} ) )

const Persona = ( {
  className,
  firstName,
  lastName,
  role,
  description,
  pictureUrl,
  location,
  nationality,
  cvUrl,
  linkedinUrl,
  website,
} ) => {
  const classes = useStyles()

  return (
    <Grid className={clsx( className, classes.persona )} container spacing={3}>

      <Grid item xs={2}>
        <Box className={classes.leftGutter}>

          <Avatar className={classes.avatar} src={pictureUrl} />

          {nationality && (
            <Box className={classes.nationality}>
              <FlagIcon className={classes.nationalityFlag} code={nationality || ''} />
              <Typography className={classes.nationalityText} variant="overline">{getNationality( nationality )}</Typography>
            </Box>
          )}

        </Box>
      </Grid>

      <Grid item xs>
        <Grid container>

          <Grid item><Typography className={classes.name}>{`${firstName} ${lastName}`}</Typography></Grid>

          <Grid className={classes.links} item>
            {cvUrl && <DownloadButton href={cvUrl} name="CV" size="small" />}

            {linkedinUrl && (
              <Link
                component={forwardRef( ( props, ref ) => <Button ref={ref} {...props} variant="text" /> )}
                className={classes.linkedIn}
                color="inherit"
                href={linkedinUrl}
                target="_blank"
              >
                <LinkedIn />
              </Link>
            )}
          </Grid>

        </Grid>

        <Box className={classes.subheader} display="flex" alignItems="center">
          <Typography variant="subtitle1">{role || 'Unknown role'}</Typography>

          {location && (
            <Typography variant="overline" className={classes.location}>
              <LocationOn className={classes.locationIcon} />
              {getCountryName( location )}
            </Typography>
          )}

          {website && (
            <ExternalLink
              variant="overline"
              href={website}
            >
              <LinkSharp fontSize="small" />
            </ExternalLink>
          )}
        </Box>

        <Typography className={classes.description}>{description || 'No description'}</Typography>

      </Grid>
    </Grid>
  )
}

Persona.propTypes = {
  className: string,
  firstName: string,
  lastName: string,
  role: string,
  description: string,
  linkedinUrl: string,
  cvUrl: string,
  pictureUrl: string,
  location: string,
  nationality: string,
  website: string,
}

Persona.defaultProps = {
  className: null,
  firstName: null,
  lastName: null,
  role: null,
  description: null,
  linkedinUrl: null,
  cvUrl: null,
  pictureUrl: null,
  location: null,
  nationality: null,
  website: null,
}

export default Persona
