import { countries, getCountryName } from '@kaiku/shared'
import { Field } from 'formik'
import { arrayOf, func, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import { Autocomplete } from '../../Formik/Autocomplete'
import ValidatedForm from '../../Formik/Form'
import withAutocompleteDialog from '../../Formik/with-autocomplete-dialog'
import withOptionAction from '../../Formik/with-option-action'
import ValueChip from '../../ValueChip'
import fields from './fields'

const countryOptions = countries.map( ( { name, code } ) => ( { name: code, displayName: name } ) )

const taxResidenciesConfig = countryOptions.reduce( ( acc, { name } ) => ( {
  ...acc,
  [ name ]: { label: 'Enter your tax identification number:', type: 'text', schema: yup.string().required(), getDisplay: ( value ) => value },
} ), {} )

const TaxResidencyAutocomplete = withOptionAction(
  Autocomplete,
  withAutocompleteDialog( taxResidenciesConfig ),
)

const createSchema = () => yup.object().shape( {
  taxResidencies: yup.array().of( yup.object().shape( {
    country: yup.string(),
    id: yup.string(),
  } ) ),
} )

const AdditionalBlock = ( {
  taxResidencies,
  withSubmit,
  onSubmit,
} ) => {
  const initialValues = {
    taxResidencies: taxResidencies.map( ( { country: name, id: value } ) => ( { name, value } ) ),
  }

  const schema = createSchema()

  const doSubmit = ( { taxResidencies, ...data } ) => onSubmit( schema.submit( {
    ...data,
    taxResidencies: taxResidencies.map( ( { name: country, value: id } ) => ( { id, country } ) ),
  } ) )

  return (
    <ContentBlock title="Additional Information">
      <ValidatedForm
        schema={schema}
        initialValues={initialValues}
        onSubmit={doSubmit}
      >
        {withSubmit( () => (
          <>

            <CaptionField
              {...fields.taxResidencies}
              renderValue={() => (
                <Field
                  component={TaxResidencyAutocomplete}
                  name="taxResidencies"
                  options={countryOptions}
                  multiple
                  getOptionLabel={( { displayName } ) => displayName}
                  getOptionSelected={( { name: selected }, { name } ) => selected === name}
                  renderTags={( tags, getProps ) => tags.map( ( { name, value }, index ) => (
                    <ValueChip
                      {...taxResidenciesConfig[ name ]}
                      {...getProps( { index } )}
                      key={name}
                      label={getCountryName( name )}
                      value={value}
                    />
                  ) )}
                />
              )}
            />

          </>
        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

AdditionalBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  taxResidencies: arrayOf( shape( { country: string, id: string } ) ),
}

AdditionalBlock.defaultProps = {
  taxResidencies: [],
}

export default AdditionalBlock
