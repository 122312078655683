import { Chip, makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { number, oneOfType, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  root: {
    maxWidth: '110px',
    transition: '0.3s all ease-in-out',
    '&:hover': {
      flex: 'auto',
      maxWidth: '100%',
    },
  },
} )

const ExpandableChip = ( {
  label,
  tooltip,
  className,
  ...props
} ) => {
  const classes = useStyles()

  return (
    <Tooltip title={tooltip || label}>
      <Chip className={clsx( classes.root, className )} label={label} {...props} />
    </Tooltip>
  )
}

ExpandableChip.propTypes = {
  className: string,
  label: oneOfType( [ string, number ] ).isRequired,
  tooltip: string,
}

ExpandableChip.defaultProps = {
  className: null,
  tooltip: null,
}

export default ExpandableChip
