import { Link, makeStyles, Tooltip } from '@material-ui/core'
import { HelpRounded } from '@material-ui/icons'
import { node, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  labelContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    color: palette.text.primary,
  },
  helpIcon: {
    fontSize: ( { iconSize } ) => iconSize,
    marginLeft: '0.5em',
  },
} ) )

const WithTooltip = ( { tooltip, iconSize, children } ) => {
  const classes = useStyles( { iconSize } )

  return (
    tooltip ? (
      <Tooltip title={tooltip} placement="right">
        <Link className={classes.labelContainer} target="_blank">
          {children}
          <HelpRounded className={classes.helpIcon} />
        </Link>
      </Tooltip>
    )
      : children
  )
}

WithTooltip.propTypes = {
  tooltip: string,
  children: node,
  iconSize: string,
}

WithTooltip.defaultProps = {
  tooltip: null,
  children: null,
  iconSize: '1em',
}

export default WithTooltip
