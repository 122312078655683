import { Container } from '@material-ui/core'
import React from 'react'

const MeetingDeclined = () => (
  <Container>
    This meeting has been declined.
  </Container>
)

export default MeetingDeclined
