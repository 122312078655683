import { Box, Button, makeStyles } from '@material-ui/core'
import { Edit, Save } from '@material-ui/icons'
import { bool } from 'prop-types'
import React, { useRef, useState } from 'react'

const useStyles = makeStyles( {
  editContainer: ( { isEditing, fixed } ) => ( {
    ...( isEditing && !fixed
      ? { position: 'sticky', bottom: '25%', paddingBottom: '0.5em' }
      : { position: 'absolute', right: 0, top: 0 }
    ),
    zIndex: 1,
    marginTop: '3.5em',
  } ),
  editButton: {
    borderRadius: '10em',
    padding: '1em',
    maxWidth: '3.5em',
    minWidth: '3.5em',
    maxHeight: '3.5em',
    right: ( { buttonOffsetRight } ) => `calc(-10px + ${buttonOffsetRight})`,
    top: ( { buttonOffsetTop } ) => `calc(-3.5em + ${buttonOffsetTop})`,
    position: 'absolute',
  },
} )

const withEdit = ( Default, Editing, { fixed, buttonOffsetTop = '-2em', buttonOffsetRight = '2em', boxProps = {} } = {} ) => {
  const Component = ( props ) => {
    const { notEditable } = props
    const [ isEditing, setEditing ] = useState( false )

    // Store formik props so we can submit when the edit is toggled
    const formProps = useRef( {} )

    const toggleEditing = async () => {
      const { submitForm, isValid } = formProps.current

      if ( isEditing && submitForm ) {
        await submitForm()
        if ( !isValid ) return
      }

      setEditing( !isEditing )
    }

    // Saves the formik props
    const withSubmit = ( renderChildren ) => ( formikProps ) => {
      formProps.current = formikProps

      return renderChildren( formikProps )
    }

    const classes = useStyles( { buttonOffsetTop, buttonOffsetRight, isEditing, fixed } )

    if ( notEditable ) return <Default {...props} />

    const Icon = isEditing ? Save : Edit
    const Component = isEditing ? Editing : Default

    return (
      <Box position="relative" {...boxProps}>

        <Component {...props} withSubmit={withSubmit} />

        <Box className={classes.editContainer}>
          <Button className={classes.editButton} onClick={toggleEditing}><Icon /></Button>
        </Box>

      </Box>
    )
  }

  Component.propTypes = {
    notEditable: bool,
  }

  Component.defaultProps = {
    notEditable: false,
  }
  return Component
}

export default withEdit
