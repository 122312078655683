import 'cross-fetch/polyfill'

class FetchError extends Error {
  constructor( response, message, ...params ) {
    super( ...params )

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if ( Error.captureStackTrace ) Error.captureStackTrace( this, FetchError )

    this.name = 'FetchError'

    // Custom debugging information
    this.statusCode = response.status || null
    this.message = message
  }
}

// Ensure HTTP status codes >= 400 are thrown as errors
const handleStatus = ( response ) => ( response.ok ? response : Promise.reject( response ) )

// Generate errors where there is a server response
const handleAPIError = ( response ) => response
  .json()
  .then( ( { error } ) => Promise.reject( new FetchError( response, error ) ) )

// Generate errors caused by local exceptions
const handleClientError = ( error ) => Promise.reject( new FetchError( error, error.message ) )

// Wrap object in FormData
const asFormData = ( data ) => {
  // Do this optionally + move into sepearate function
  const formData = new FormData()
  Object.entries( data ).forEach( ( [ key, value ] ) => formData.append( key, value ) )

  return formData
}

const fetcher = ( url, { headers, body, ...options } = {}, isJson = true ) => fetch( url, {
  ...options,
  headers: {
    ...headers,
    ...( isJson && { 'Content-Type': 'application/json' } ),
  },
  ...( body && { body: isJson ? JSON.stringify( body ) : asFormData( body ) } ),
} )
  .then( handleStatus )
  .then( ( response ) => response.json() )
  .catch( ( response ) => ( response.json ? handleAPIError : handleClientError )( response ) )

export default fetcher
