import { routes } from '@kaiku/shared'
import { kaikuShrunk, Navbar, SideNavbar } from '@kaiku/ui'
import { Avatar, Badge, Button, Container, IconButton, makeStyles, MenuItem } from '@material-ui/core'
import { Inbox, Notifications, Search, Star } from '@material-ui/icons'
import Menu from 'material-ui-popup-state/HoverMenu'
import React from 'react'

import StartupCard from '../../../components/StartupCard'

const NAVIGATION_ITEMS = [
  [ 'Matching', routes.INVESTOR_MATCHING ],
  [ 'My Profile', routes.INVESTOR_PROFILE ],
]

const SIDENAVIGATION_ITEMS = [
  {
    name: 'Startups',
    icon: Inbox,
  },
  {
    name: 'Investors',
    icon: Star,
  },
  {
    name: 'Filter',
    icon: Search,
  },
]

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    width: '95%',
    flexGrow: 1,
    backgroundColor: '#F4F4F4',
  },
  grid: {
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
} )

const Example = () => {
  const classes = useStyles()
  return (
    <div>
      <Navbar
        items={NAVIGATION_ITEMS}
        renderContent={() => (
          <>
            <>
              <IconButton color="inherit">
                <Badge color="error">
                  <Notifications />
                </Badge>
              </IconButton>

              <Menu>
                <MenuItem>No Notifications</MenuItem>
              </Menu>
            </>

            <>
              <Button variant="text">
                <Avatar alt="Avatar" />
              </Button>

              <Menu>
                <MenuItem>Settings</MenuItem>
                <MenuItem>Log out</MenuItem>
              </Menu>
            </>

          </>
        )}
      />

      <Container className={classes.container} maxWidth={false}>
        <SideNavbar
          items={SIDENAVIGATION_ITEMS}
        />

        <Container className={classes.content} maxWidth={false}>
          <StartupCard
            name="Kaiku"
            logoUrl={kaikuShrunk}
            website="https://www.kaiku.co"
            industries={[ 'Finance', 'Fintech', 'Investment Services' ]}
            foundedYear={2018}
            oneLiner="Kaiku is for investors who understand such risks associated with early stage investment and as such make their own informed investment decisions"
            targetEquity={5}
            targetRaise={5}
            stage="Ideation"
            fundraiseStage="Revenue Generation"
            locations={[ 'GB' ]}
            match={87}
            mini
          />
        </Container>
      </Container>
    </div>
  )
}

export default Example
