import { makeStyles, Paper } from '@material-ui/core'
import { func, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( theme ) => ( {
  statsPaper: {
    borderRadius: '0.35em',
    height: 100,
    padding: '1.25%',
  },
  statCardTitle: {
    marginTop: 0,
    marginBottom: 1,
  },
  statCardValue: {
    margin: 1,
  },
  statCardSubTitle: {
    margin: 0,
  },
  statCardImg: {
    margin: 'auto 3%',
    objectPosition: 'centre',
    color: theme.palette.primary.main,
    objectFit: 'cover',
    fontSize: '2rem',
  },
  pad: {
    display: 'flex',
  },
} ) )

const StatCard = ( { title, subtitle, value, Icon } ) => {
  const classes = useStyles()
  return (
    <Paper className={classes.statsPaper}>
      <div className={classes.pad}>
        <Icon className={classes.statCardImg} />
        <div>
          <h4 className={classes.statCardTitle}>{title}</h4>
          <h1 className={classes.statCardValue}>{value}</h1>
        </div>
      </div>
      <p className={classes.statCardSubTitle}>{subtitle}</p>
    </Paper>
  )
}

StatCard.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  value: string.isRequired,
  Icon: func.isRequired,
}

export default StatCard
