import { Box, Container, makeStyles, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { bool, func, node, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    zIndex: 1,
    position: 'sticky',
    top: '0',
    background: `linear-gradient(${palette.background.default} 80%, transparent 100%)`,
    paddingBottom: '2em',
    marginBottom: '-1em',
  },
  paper: {
    borderRadius: '10em',
    position: 'relative',
  },
  container: {
    padding: '2em',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  fallback: {
    textAlign: 'center',
    padding: '1em',
    fontSize: '1.25em',
  },
} ) )

const HeaderBlock = ( { className, fallback, fallbackMessage, renderAvatar, children } ) => {
  const classes = useStyles()

  return (
    <Box className={clsx( classes.root, className )}>

      {renderAvatar()}

      <Paper className={classes.paper} elevation={15}>
        <Container className={classes.container}>

          {fallback
            ? <Typography className={classes.fallback}>{fallbackMessage}</Typography>
            : children}

        </Container>
      </Paper>

    </Box>
  )
}

HeaderBlock.propTypes = {
  className: string,
  fallback: bool,
  fallbackMessage: string,
  renderAvatar: func,
  children: node,
}

HeaderBlock.defaultProps = {
  className: null,
  fallback: false,
  fallbackMessage: null,
  renderAvatar: () => null,
  children: null,
}

export default HeaderBlock
