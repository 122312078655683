import { Avatar, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { getIn } from 'formik'
import { useSnackbar } from 'notistack'
import { node, string } from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { useFetchAuth } from '../../hooks'
import FileUpload from './FileUpload'
import formikPropTypes from './formik-prop-types'

const FORMATS = [ 'image/jpeg', 'image/png' ]
const ENDPOINT = '/api/uploads/images'

const useStyles = makeStyles( ( { typography } ) => ( {
  avatar: {
    position: 'relative',
    cursor: 'pointer',
    '&:after': {
      ...typography.button,
      fontSize: '1em',
      content: '"Edit"',
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      width: '100%',
      textAlign: 'center',
      bottom: 0,
      padding: '10% 0',
      color: 'white',
    },
  },
  label: {
    position: 'relative',
    marginBottom: '7px',
  },
  buttonText: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
} ) )

const EditAvatar = ( { className, field, form, defaultIcon } ) => {
  const { name, value } = field
  const { errors } = form
  const error = getIn( errors, name )

  // Use ref to open file dialog
  const fileUpload = useRef()
  const openFileDialog = () => fileUpload.current.click()

  const fetch = useFetchAuth()

  const { enqueueSnackbar } = useSnackbar()

  useEffect( () => {
    if ( error ) enqueueSnackbar( error, { variant: 'error' } )
  }, [ enqueueSnackbar, error ] )

  // Upload image to microservice
  const upload = async ( file ) => {
    const { url } = await fetch( ENDPOINT, { method: 'POST', body: { file } }, false )

    return url
  }

  const classes = useStyles()

  return (
    <>

      <FileUpload
        ref={fileUpload}
        formats={FORMATS}
        upload={upload}
        form={form}
        field={field}
      />

      <Avatar
        className={clsx( classes.avatar, className )}
        src={value}
        onClick={openFileDialog}
      >
        {defaultIcon}
      </Avatar>

    </>
  )
}

EditAvatar.propTypes = {
  className: string,
  defaultIcon: node,
  ...formikPropTypes,
}

EditAvatar.defaultProps = {
  className: null,
  defaultIcon: null,
}

export default EditAvatar
