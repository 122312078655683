import { PORTFOLIO_TYPES } from '@kaiku/shared'
import { Accordion, ValidatedSelect } from '@kaiku/ui'
import { FormControl, Grid, InputAdornment, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { Field, FieldArray, getIn } from 'formik'
import { TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import { bool, instanceOf, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'

const initialPortfolioValues = {
  amount: '',
  company: '',
  date: new Date(),
  type: '',
  permanent: false,
}

const getDate = ( dateStr ) => ( dateStr
  ? format( new Date( dateStr ), 'MMM yyyy' )
  : 'Previous Investments' )

const Title = ( { date, error } ) => (
  <Typography color={error ? 'error' : 'inherit'} variant="subtitle1">
    {getDate( date )}
  </Typography>
)

Title.propTypes = {
  date: oneOfType( [ string, instanceOf( Date ) ] ),
  error: bool,
}

Title.defaultProps = {
  date: null,
  error: false,
}

const Entry = ( { type, index, touched, errors } ) => (
  <FormControl>
    <Grid container spacing={1} direction="row">

      <Grid item xs={12}>
        <Field
          component={DatePicker}
          name={`portfolio[${index}].date`}
          label="Month"
          format="MMMM yyyy"
          openTo="year"
          disableFuture
          autoOk
          views={[ 'year', 'month' ]}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>

        <ValidatedSelect
          options={Object.values( PORTFOLIO_TYPES )}
          error={getIn( touched, `portfolio[${index}].type` ) && getIn( errors, `portfolio[${index}].type` )}
          label="Type of Involvement"
          name={`portfolio[${index}].type`}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>

        <Field
          component={TextField}
          name={`portfolio[${index}].company`}
          label="Company"
          fullWidth
        />
      </Grid>

      {type === PORTFOLIO_TYPES.investor && (
        <Grid item xs={12}>
          <Field
            component={TextField}
            name={`portfolio[${index}].amount`}
            label="Investment Amount"
            type="number"
            inputProps={{ min: 0, step: 1000 }}
            InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
            fullWidth
          />
        </Grid>
      )}
    </Grid>

  </FormControl>
)

Entry.propTypes = {
  index: number.isRequired,
  type: string.isRequired,
  errors: shape( { stage: string } ).isRequired,
  touched: shape( { stage: bool } ).isRequired,
}

const Portfolio = () => (
  <FieldArray name="portfolio">
    {( { form: { values: { portfolio }, errors, touched }, push, remove } ) => (
      <Accordion
        onAdd={() => push( { ...initialPortfolioValues } )}
        onDelete={remove}
        items={portfolio}
        renderTitle={( props, index ) => {
          const setOfErrors = new Set( Object.keys( getIn( errors, [ 'portfolio', index ], {} ) ) )
          const indexTouched = getIn( touched, [ 'portfolio', index ], {} )
          const titleError = Object.keys( indexTouched )
            .some( ( item ) => indexTouched[ item ] && setOfErrors.has( item ) )
          return (
            <Title
              {...props}
              index={index}
              error={titleError}
            />
          )
        }}
        renderDetails={( item, index ) => (
          <Entry
            {...item}
            index={index}
            errors={errors}
            touched={touched}
          />
        )}
      />
    )}
  </FieldArray>
)

export default Portfolio
