
import { random } from 'lodash'
import PropTypes, { arrayOf } from 'prop-types'
import React, { useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'

import { reshapeIndustriesData } from '../../lib/helpers'

const renderActiveShape = ( props ) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value,
  } = props
  const name = payload.code.toLowerCase().replace( /(^\w{1})|(\s{1}\w{1})/g, ( match ) => match.toUpperCase() )
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {name}
      </text>
      <text x={cx} y={cy} dy={30} textAnchor="middle" fill="#999">
        {`${value} Startups`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  )
}

const IndustriesPieChart = ( { data } ) => {
  const [ activeIndex, setIndex ] = useState( 0 )
  if ( data === [] ) return null
  const industriesData = reshapeIndustriesData( data )

  const onPieEnter = ( _, index ) => {
    setIndex( index )
  }

  const colour = [ '#71CC98', '#DBBC92', '#6F9296', '#4B4B50' ]

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={industriesData}
          cx="50%"
          cy="50%"
          innerRadius="50%"
          outerRadius="80%"
          fill={colour[ random( 4 ) ]}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {industriesData.map( ( e, index ) => (
            <Cell fill={colour[ index % colour.length ]} />
          ) )}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

IndustriesPieChart.propTypes = {
  data: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
}

export default IndustriesPieChart
