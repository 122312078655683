import { makeStyles, StepConnector } from '@material-ui/core'
import clsx from 'clsx'
import { arrayOf, node } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  connector: {
    paddingTop: '0.5em',
    margin: '-1.5em 0',
    marginLeft: '1.75em',
  },
  firstConnector: {
    minHeight: '3em',
  },
  circle: {
    borderRadius: '100em',
    backgroundColor: palette.primary.main,
    width: '3.5em',
    height: '3.5em',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '2em',
    '& > *': {
      fontSize: '1em',
      color: 'white',
      textAlign: 'center',
    },
  },
} ) )

const LineSplitter = ( { children } ) => {
  const classes = useStyles()

  return [
    ...children,
    ...( !children.length ? [ null ] : [] ), // Prevents reduce of an empty array
  ].reduce( ( prev, curr, index ) => [
    prev,
    <StepConnector
      // eslint-disable-next-line react/no-array-index-key
      key={`${index}-connector`}
      className={classes.connector}
      classes={{ line: clsx( index === 1 && classes.firstConnector ) }}
      orientation="vertical"
    />,
    curr,
  ] )
}

LineSplitter.propTypes = {
  children: arrayOf( node ),
}

LineSplitter.defaultProps = {
  items: [],
}

export default LineSplitter
