import { routes } from '@kaiku/shared'
import { Autocomplete, CaptionField, StartupResult, useInfiniteFetch, ValidatedForm, withAutocompleteDialog, withOptionAction } from '@kaiku/ui'
import { Box, Chip, Container, makeStyles, Paper } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { Field } from 'formik'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getSearchMatchUrl } from '../../../../api'
import NotOnboardedWarning from '../../../../components/NotOnboardedWarning'
import Pagination from '../../../../components/Pagination'
import EmptyMessage from '../EmptyMessage'
import CRITERIA_CONFIG from './criteria-config'

const PAGE_SIZE = 12

const useStyles = makeStyles( {
  root: {
    position: 'relative',
    marginTop: '2em',
  },
  gutter: {
    position: 'fixed',
    top: '25em',
    left: '3em',
    width: '15em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  arrow: {
    fontSize: '4em',
    marginBottom: '0.3em',
    opacity: 0.4,
  },
  filters: {
    padding: '0 1em',
    borderRadius: '1em',
    width: '100%',
  },
  autocomplete: {
    paddingRight: '1em !important',
    '& input': {
      height: '7px',
      marginBottom: '5px',
    },
  },
  popupIndicator: {
    position: 'absolute',
    right: '-0.25em',
    bottom: '-2%',
  },
} )

const CriteriaAutocomplete = withOptionAction( ( props ) => {
  const classes = useStyles()

  return (
    <Autocomplete
      {...props}
      closeIcon={null}
      classes={{ inputRoot: classes.autocomplete, popupIndicator: classes.popupIndicator }}
    />
  )
}, withAutocompleteDialog( CRITERIA_CONFIG ) )

const initialValues = { criteria: [] }

const Find = () => {
  const classes = useStyles()

  const [ criteria, setCriteria ] = useState( [] )

  const params = useMemo( () => ( { method: 'POST', body: criteria } ), [ criteria ] )
  const { data = [], next, previous, setPage, page, error } = useInfiniteFetch(
    getSearchMatchUrl(),
    PAGE_SIZE,
    params,
  )

  const onCriteriaChange = ( { criteria } ) => {
    setCriteria( criteria )
    setPage( 0 )
  }

  const history = useHistory()

  const handleClick = ( { id } ) => () => history.push( `${routes.INVESTOR_VIEW_STARTUP}/${id}` )

  if ( error ) {
    switch ( error.statusCode ) {
      case 403:
        // not onboarded yet
        return <NotOnboardedWarning />
      default:
        return (
          <Box className={classes.root}>
            <div>Something went wrong, please try again later</div>
          </Box>
        )
    }
  }

  return (
    <Container className={classes.root}>
      {!data.length && (
        <EmptyMessage>
          No startups found. Try to broaden your filter selection.
        </EmptyMessage>
      )}

      {data.map( ( startup ) => (
        <Box key={startup.id} m={3}>
          <StartupResult {...startup} onClick={handleClick( startup )} />
        </Box>
      ) )}

      {!!data.length && (
        <Pagination
          onNext={next}
          page={page}
          onPrevious={previous}
        />
      )}

      <Box className={classes.gutter}>
        <ArrowDropDown className={classes.arrow} />

        <Paper className={classes.filters} elevation={8}>
          <ValidatedForm initialValues={initialValues} onSubmit={onCriteriaChange} prompt={false}>
            {( { submitForm } ) => (
              <CaptionField
                label="Filters"
                tooltip="Choose filters to narrow down your search results"
                renderValue={() => (
                  <Field
                    component={CriteriaAutocomplete}
                    name="criteria"
                    onChange={submitForm}
                    options={Object.keys( CRITERIA_CONFIG ).map( ( name ) => ( { name } ) )}
                    multiple
                    getOptionLabel={( { name } ) => name}
                    getOptionSelected={( selected, { display } ) => selected.display === display}
                    renderTags={( tags, getProps ) => tags.map( ( { display }, index ) => (
                      <Chip {...getProps( { index } )} key={display} label={display} />
                    ) )}
                  />
                )}
              />
            )}
          </ValidatedForm>
        </Paper>
      </Box>

    </Container>
  )
}

export default Find
