import { Box, makeStyles, Typography } from '@material-ui/core'
import numeral from 'numeral'
import { number, oneOfType, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  value: {
    fontWeight: 'bold',
    fontSize: '1.125rem',
    lineHeight: '1rem',
  },
  label: {
    fontSize: '0.75rem',
    lineHeight: '0.2rem',
  },
} )

const Value = ( { label, value, prefix, postfix } ) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography className={classes.value} variant="h3">
        {prefix}
        {Number.isNaN( parseFloat( value ) ) ? value : numeral( value ).format( '0.[0a]a' )}
        {postfix}
      </Typography>
      <Typography className={classes.label} variant="overline">{label}</Typography>
    </Box>
  )
}

Value.propTypes = {
  label: string,
  value: oneOfType( [ number, string ] ).isRequired,
  prefix: string,
  postfix: string,
}

Value.defaultProps = {
  prefix: null,
  postfix: null,
  label: null,
}

export default Value
