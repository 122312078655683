import { rateStartup } from '@kaiku/shared'
import { getChangedData, MissingFieldsAccordion, StartupAboutBlock, StartupBusinessBlock, StartupFundraiseBlock, StartupHeaderBlock, StartupLegalsBlock, StartupTeamBlock, useFetchAuth } from '@kaiku/ui'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import merge from 'deepmerge'
import React, { createRef } from 'react'
import useSWR, { mutate } from 'swr'

import { getUserStartupUrl } from '../../../api'
import Badge from '../../../components/Badge'

const useStyles = makeStyles( ( { breakpoints } ) => ( {
  profileCompleteness: {
    position: 'sticky',
    top: '10em',
    display: 'flex',
    justifyContent: 'center',
  },
  profileGuide: {
    [ breakpoints.down( 'sm' ) ]: {
      display: 'none',
    },
  },
  missingFields: {
    position: 'sticky',
    top: '25em',
    width: '65%',
    marginLeft: '10%',
  },
  gridContainerPadding: {
    [ breakpoints.down( 'sm' ) ]: {
      padding: '0px 0px',
      width: '100% !important',
      margin: 'auto !important',
    },
  },
  gridItemPadding: {
    [ breakpoints.down( 'sm' ) ]: {
      padding: '0px !important',
    },
  },
  containerReducedPadding: {
    [ breakpoints.down( 'sm' ) ]: {
      padding: '0 8px',
    },
  },
} ) )

const BLOCKS = [
  StartupHeaderBlock,
  StartupAboutBlock,
  StartupBusinessBlock,
  StartupFundraiseBlock,
  StartupLegalsBlock,
  StartupTeamBlock,
]

const blockRefs = BLOCKS.map( createRef )

const Profile = () => {
  const classes = useStyles()
  const { data } = useSWR( getUserStartupUrl() )
  const fetch = useFetchAuth()

  const onSubmit = ( form ) => {
    const updatedData = merge( data, form, { arrayMerge: ( _, x ) => x } )

    const patch = getChangedData( data, updatedData, {
      members: ( { id } ) => id,
      fundraisers: ( { id } ) => id,
      metrics: ( { name } ) => name,
      productHighlights: ( { name } ) => name,
      operationsLocations: ( { name } ) => name,
    } )

    if ( !patch ) return

    fetch( getUserStartupUrl(), { method: 'PATCH', body: patch } )
    mutate( getUserStartupUrl(), updatedData, false )
  }

  // ? This is also sent by the startups service, so we may not need it in the future
  const rating = rateStartup.completeness( data )

  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={6} className={classes.gridContainerPadding}>

        <Grid item md={9} xs={12} className={classes.gridItemPadding}>
          <Container maxWidth="md" className={classes.containerReducedPadding}>
            {BLOCKS
              .map( ( Block, index ) => (
                <div ref={blockRefs[ index ]}>
                  <Block
                // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    {...data}
                    onSubmit={onSubmit}
                  />
                </div>
              ) )}
          </Container>
        </Grid>

        <Grid item lg={3} md={3} xs={12} className={classes.profileGuide}>
          <Box className={classes.profileCompleteness}>
            <Badge
              tooltip="This rating is calculated based on completeness of the profile."
              label="Profile Strength"
              progress={rating * 100}
              content={Math.round( rating * 10 )}
              postfix="/10"
            />
          </Box>

          <Box className={classes.missingFields}>
            <MissingFieldsAccordion
              data={data}
              refs={blockRefs}
            />
          </Box>

        </Grid>

      </Grid>
    </Container>
  )
}

export default Profile
