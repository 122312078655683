// import 'flag-icon-css/css/flag-icon.css'

import clsx from 'clsx'
import { string } from 'prop-types'
import React from 'react'

const FlagIcon = ( { className, code } ) => <div className={clsx( className, 'flag-icon', `flag-icon-${code.toLowerCase()}` )} />

FlagIcon.propTypes = {
  className: string,
  code: string,
}

FlagIcon.defaultProps = {
  className: null,
  code: '',
}

export default FlagIcon
