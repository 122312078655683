import { routes } from '@kaiku/shared'
import { handleAuthError, HomeForm, LoaderButton, OrDivider, ValidatedForm } from '@kaiku/ui'
import { Button, FormHelperText, makeStyles, Typography } from '@material-ui/core'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { object, string } from 'yup'

import useCommonStyles from './styles'

const useStyles = makeStyles( {
  forgotPasswordText: {
    textAlign: 'end',
  },
} )

const createSchema = () => object().shape( {
  email: string().email().required(),
  password: string().required(),
} )

const initialValues = { email: '', password: '' }

const Login = () => {
  const [ error, setError ] = useState()

  const onLoginSubmit = ( { email, password } ) => signInWithEmailAndPassword(
    getAuth(), email, password,
  )
    .catch( ( error ) => setError( handleAuthError( error ) ) )

  const classes = { ...useCommonStyles(), ...useStyles() }

  return (
    <HomeForm logo>
      <Typography className={classes.header} variant="h3">Login</Typography>

      <ValidatedForm
        initialValues={initialValues}
        schema={createSchema()}
        onSubmit={onLoginSubmit}
      >
        {( { isSubmitting, submitForm } ) => (
          <>

            <Field
              component={TextField}
              name="email"
              label="Email"
            />

            <Field
              component={TextField}
              name="password"
              label="Password"
              type="password"
            />

            <FormHelperText className={classes.errorText} error>
              {error}
            </FormHelperText>
            <RouterLink
              className={classes.forgotPasswordText}
              to={routes.RESET_PASSWORD}
            >
              Forgot your password?
            </RouterLink>
            <LoaderButton
              type="submit"
              className={classes.mainButton}
              loading={isSubmitting}
              onClick={submitForm}
            >
              Log in
            </LoaderButton>

          </>
        ) }
      </ValidatedForm>

      <OrDivider light />
      <Button variant="outlined" component={RouterLink} to={routes.REGISTER}>
        Create an account
      </Button>
    </HomeForm>

  )
}

export default Login
