import { fetcher, MEETING_ERROR_STATUSES, routes } from '@kaiku/shared'
import { formatDateAndTime, HomeForm, LoaderButton, ValidatedForm } from '@kaiku/ui'
import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import { arrayOf, func } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { object, string } from 'yup'

import { getAcceptMeetingUsingTokenUrl, getDeclineMeetingUsingTokenUrl, getMeetingInfo } from '../../api'
import HomeFormLoading from '../HomeFormLoading'

const createSchema = () => object().shape( {
  acceptedTime: string().required(),
} )

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginTop: '0.5em',
    },
    '& > :last-child': {
      marginTop: '1.5em',
    },
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  decline: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
} )

const MeetingRequests = ( { meetingTimes, onSelect } ) => {
  const classes = useStyles()

  return (
    <>
      {meetingTimes.map( ( time ) => (
        <div className={classes.timeContainer}>
          {formatDateAndTime( time )}
          <Button onClick={() => onSelect( time )}>
            Select
          </Button>
        </div>
      ) )}
    </>
  )
}

MeetingRequests.propTypes = {
  meetingTimes: arrayOf( string ).isRequired,
  onSelect: func.isRequired,
}

const AcceptMeeting = ( { actionCode } ) => {
  const [ error, setError ] = useState()
  const [ accepted, setAccepted ] = useState()
  const [ declined, setDeclined ] = useState()
  const [ meetingInfo, setMeetingInfo ] = useState()
  const [ declining, setDeclining ] = useState( false )

  const classes = useStyles()

  useEffect( () => {
    fetcher( getMeetingInfo( actionCode ), {
      method: 'GET',
    } )
      .then( ( { error, ...rest } ) => {
        setError( error )
        setMeetingInfo( rest )
      } )
      .catch( () => setError( 'unexpected' ) )
  }, [ actionCode ] )

  const declineMeeting = () => fetcher(
    getDeclineMeetingUsingTokenUrl( actionCode ), {
      method: 'POST',
      body: {},
    },
  )
    .then( ( { error } ) => {
      if ( error ) {
        setError( error )
      } else {
        setDeclined( true )
      }
    } )

  const acceptMeeting = ( { acceptedTime } ) => fetcher(
    getAcceptMeetingUsingTokenUrl( actionCode ), {
      method: 'POST',
      body: { acceptedTime },
    },
  )
    .then( ( { error, ...rest } ) => {
      setError( error )
      setAccepted( rest )
    } )
    .catch( () => setError( 'unexpected' ) )

  if ( error ) {
    return (
      <HomeForm logo>
        <Typography variant="h3">Respond to Meeting</Typography>
        {error === MEETING_ERROR_STATUSES.invalid && (
          'This meeting action link is invalid.'
        )}
        {error === MEETING_ERROR_STATUSES.alreadyAccepted && (
          'This meeting has already been accepted.'
        )}
        {error === MEETING_ERROR_STATUSES.alreadyDeclined && (
          'This meeting has already been declined.'
        )}
        {error === 'unexpected' && (
          'An unexpected error has occurred.'
        )}
        <Button component={RouterLink} to={routes.HOME}>
          Return to platform
        </Button>
      </HomeForm>
    )
  }

  if ( accepted ) {
    return (
      <HomeForm logo>
        <Typography variant="h3">Respond to Meeting</Typography>
        {`Meeting with ${accepted.investor} has been accepted for 
          ${formatDateAndTime( accepted.acceptedTime )}.`}
        <br />
        <br />
        You will receive an email with the meeting link shortly.
        <Button component={RouterLink} to={routes.HOME}>
          Return to platform
        </Button>
      </HomeForm>
    )
  }

  if ( declined ) {
    return (
      <HomeForm logo>
        <Typography variant="h3">Meeting</Typography>
        {`Meeting with ${meetingInfo.investor} has been declined.`}

        <Button component={RouterLink} to={routes.HOME}>
          Return to platform
        </Button>
      </HomeForm>
    )
  }

  if ( meetingInfo ) {
    return (
      <HomeForm logo>
        <Typography variant="h3">Respond to Meeting</Typography>
        <ValidatedForm
          initialValues={{ acceptedTime: null }}
          schema={createSchema()}
          onSubmit={acceptMeeting}
        >
          {( { submitForm, isSubmitting, setFieldValue, values } ) => (
            <Container className={classes.container}>
              {`${meetingInfo.investor} would like to book a meeting with you. These are the available times:`}
              <MeetingRequests meetingTimes={meetingInfo.meetingTimes} onSelect={( value ) => setFieldValue( 'acceptedTime', value )} />
              <span>
                {values.acceptedTime ? `Selected time: ${formatDateAndTime( values.acceptedTime )}` : 'Please select a time.'}
              </span>

              <LoaderButton
                disabled={!values.acceptedTime}
                loading={isSubmitting}
                onClick={submitForm}
              >
                Accept
              </LoaderButton>

              <Button variant="outlined" component={RouterLink} to={routes.HOME}>
                Return to platform
              </Button>

              {declining
                ? (
                  <span>
                    {'Please click '}
                    <span
                      className={classes.decline}
                      onClick={declineMeeting}
                    >
                      here
                    </span>
                    {' if you are sure you want to decline this meeting.'}
                  </span>
                )
                : (
                  <span
                    className={classes.decline}
                    onClick={() => setDeclining( true )}
                  >
                    I don&apos;t want to meet this investor
                  </span>
                )}
            </Container>
          )}

        </ValidatedForm>
      </HomeForm>
    )
  }

  return <HomeFormLoading />
}

AcceptMeeting.propTypes = {
  actionCode: string.isRequired,
}

export default AcceptMeeting
