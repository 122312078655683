const FIELDS = {
  productHighlights: {
    label: 'Product Highlights',
    tooltip: 'Further details on the startup\'s product focus, product goals, and roadmap',
  },
  businessModel: {
    label: 'Business Model',
    tooltip: 'Insight into operations and target customers',
  },
  revenueModel: {
    label: 'Revenue Model',
    tooltip: 'Insight into revenue source(s)',
  },
  productProgress: {
    label: 'Product Progress',
    tooltip: 'If applicable, additional details on current features and use cases that the startup is developing',
  },
  metrics: {
    label: 'Metrics',
    tooltip: 'Key metrics of the startup',
  },
}

export default FIELDS
