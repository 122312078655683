import { Container, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles( {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    margin: '3rem 0',
    padding: '0 10rem',
    fontSize: '0.75rem',
    '& br': {
      display: 'block',
      content: '""',
      margin: '0.5em 0',
    },
  },
  paper: {
    width: '100%',
    padding: '1.5em',
  },
} )

const RiskDisclaimer = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth={false}>
      <Paper className={classes.paper} variant="outlined">
        <Typography variant="inherit">
          Please be aware that making an investment into small businesses
          and startups involves notable risks, including but not limited to:
          dilution and loss of capital. Kaiku (previously AGORA) is for investors
          who understand such risks associated with early stage investment
          and as such make their own informed investment decisions,
          as part of a range of investments.
          <br />
          Any investment should be made on the full understanding
          of the full campaign. Any investment activities are intended
          to take place within the United Kingdom,
          and any person resident outside should be aware of any
          additional local regulations before investing.
          Tax treatment depends on individual circumstances
          and is subject to change in future, so please be aware
          of additional factors that you may be subject to.
          <br />
          The Services by Kaiku are not intended to be a
          substitute for legal, tax, accounting, regulatory
          or financial advice and guidance that can be
          provided by your own solicitor, lawyer, accountant,
          tax or regulatory counsel and/or financial advisor.
          If you have any questions with respect to legal, tax or
          financial matters relevant to your interactions with Kaiku,
          you should consult a professional advisor. For further details, please read
          <Link href="https://www.kaiku.co/risk-warnings" target="_blank">{' here.'}</Link>
        </Typography>
      </Paper>
    </Container>
  )
}

export default RiskDisclaimer
