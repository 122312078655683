import { countries, getCountryName, isInvestmentCompany, uuid } from '@kaiku/shared'
import { makeStyles } from '@material-ui/core'
import { FastField, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { mapValues } from 'lodash'
import { func, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import { Autocomplete } from '../../Formik/Autocomplete'
import EditAvatar from '../../Formik/EditAvatar'
import ValidatedForm from '../../Formik/Form'
import UploadButton, { UPLOAD_TYPES } from '../../Formik/UploadButton'
import fields from './fields'

const useStyles = makeStyles( {
  avatar: {
    width: '5rem',
    height: '5rem',
    fontSize: '0.75em',
    margin: 'auto',
  },
} )

const createSchema = () => yup.object().shape( {
  companyDescription: yup.string().min( 300 ).max( 1000 ).nullable(),
  representative: yup.object().shape( {
    id: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.string().nullable(),
    description: yup.string().nullable(),
    linkedinUrl: yup.string().url().nullable(),
    cvUrl: yup.string().nullable(),
    pictureUrl: yup.string().nullable(),
    identityDocumentUrl: yup.string().nullable(),
    location: yup.string().nullable(),
    nationality: yup.string().nullable(),
    website: yup.string().url().nullable(),
  } ),
} )

const initialRepresentativeValues = {
  id: uuid(),
  firstName: '',
  lastName: '',
  role: '',
  description: '',
  linkedinUrl: '',
  cvUrl: '',
  pictureUrl: '',
  identityDocumentUrl: '',
  location: '',
  nationality: '',
  website: '',
}

const EditAboutBlock = ( {
  companyDescription,
  type,
  representative,
  withSubmit,
  onSubmit,
} ) => {
  const initialValues = {
    ...mapValues( { companyDescription }, ( value ) => value || '' ),
    representative: mapValues(
      initialRepresentativeValues,
      ( initialValue, key ) => representative[ key ] || initialValue,
    ),
  }

  const classes = useStyles()
  const schema = createSchema()

  return (
    <ContentBlock title="About">
      <ValidatedForm
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <>

            {isInvestmentCompany( type ) && (
              <>
                <CaptionField
                  {...fields.description}
                  renderValue={() => <Field component={TextField} name="companyDescription" multiline />}
                />

                <CaptionField {...fields.representative} value=" " />
              </>
            )}

            <FastField
              name="representative.pictureUrl"
              component={EditAvatar}
              className={classes.avatar}
            />

            <Field
              component={TextField}
              name="representative.firstName"
              label="First Name"
              disabled
            />

            <Field
              component={TextField}
              name="representative.lastName"
              label="Last Name"
              disabled
            />

            <Field
              component={TextField}
              name="representative.role"
              label="Role"
            />

            <FastField
              component={TextField}
              name="representative.description"
              label="Profile Summary"
              multiline
            />

            <Field
              component={Autocomplete}
              name="representative.location"
              options={countries.map( ( { code } ) => code )}
              getOptionLabel={( code ) => getCountryName( code ) || ''}
              label="Location"
            />

            <Field
              component={Autocomplete}
              name="representative.nationality"
              options={countries
                .filter( ( { nationality } ) => nationality )
                .map( ( { code } ) => code )}
              getOptionLabel={( code ) => getCountryName( code ) || ''}
              label="Nationality"
            />

            <Field
              component={TextField}
              name="representative.linkedinUrl"
              label="LinkedIn Profile"
            />

            <Field
              component={TextField}
              name="representative.website"
              label="Website"
            />

            <Field
              component={UploadButton}
              name="representative.cvUrl"
              label="CV"
              {...UPLOAD_TYPES.documents}
            />

            <Field
              component={UploadButton}
              name="representative.identityDocumentUrl"
              label="Identity Document"
              {...UPLOAD_TYPES.images}
            />
          </>
        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

EditAboutBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  companyDescription: string,
  type: string,
  representative: shape( { id: string } ),
}

EditAboutBlock.defaultProps = {
  companyDescription: null,
  type: null,
  representative: {},
}

export default EditAboutBlock
