import { get } from 'lodash'

export const completeness = ( data ) => {
  const getField = ( ...params ) => get( data, ...params )

  const evaluators = [
    getField( 'name' ),
    getField( 'logoUrl' ),
    getField( 'industries', [] ).length,
    getField( 'website' ),
    getField( 'foundedYear' ),
    getField( 'oneLiner' ),
    getField( 'description' ),
    getField( 'stage' ),
    getField( 'stageDescription' ),
    getField( 'pitchDeckUrl' ) || getField( 'onePagerUrl' ),
    getField( 'businessModel' ),
    getField( 'incorporatedDate' ),
    getField( 'incorporatedLocation' ),
    getField( 'headquartersLocation' ),
    getField( 'patents' ) !== undefined,
    getField( 'seisStatus' ),
    getField( 'fundraisers[0].amountRaised' ) !== null,
    getField( 'members[0].description' ),
  ]

  if ( !data ) return 0

  const sum = evaluators.reduce( ( sum, isComplete ) => ( isComplete ? sum + 1 : sum ), 0 )

  return ( sum / evaluators.length ).toFixed( 2 )
}
