import './index.css'

import { getStartupAccountHolderNameSafe, routes } from '@kaiku/shared'
// import 'react-calendar/dist/Calendar.css'
import { formatDateAndTime, LoaderButton, RedButton, useFetchAuth, ValidatedForm } from '@kaiku/ui'
import { Avatar, Container, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { useHistory, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { getInitiateUrl, getStartupUrl, getUserInvestorUrl } from '../../../../api'

const useStyles = makeStyles( {
  root: {
    margin: '2em 0 0 0',
    alignSelf: 'center',
  },
  gridContainer: {
    justifyContent: 'center',
  },
  main: {
    display: 'flex',
    margin: '50px 0 0 50px',
    width: '60vw',
  },
  logo: {
    width: '5.5em',
    height: '5.5em',
    border: '4px solid #EEEEEE',
    borderRadius: '100%',
    boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
    backgroundColor: '#FFFFFF',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  formContainer: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  margin5: {
    margin: '0px 0px 5px 10px',
  },
  calendarBox: {
    maxWidth: '400px',
  },
  calendar: {
    margin: '10px 0px 0px 0px',
    textAlign: 'center',
  },
  calendarTile: {
    fontFamily: 'Futura Light',
    borderRadius: '100%',
    height: '50px',
    width: '80%',
    margin: '2.5px 0',
    border: 'none',
  },
  dateSelectedText: {
    width: '85%',
    margin: '20px auto',
    fontSize: '16px',
  },
  selectField: {
    width: 'calc(100%)',
    fontSize: '0.875rem',
    lineHeight: 1.63,
  },
  button: {
    width: '40%',
    margin: 'auto',
  },
  timeContainer: {
    padding: '0 1em 0 1em',
  },
  time: {
    display: 'flex',
    flexdirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    margin: '0.5em 0 0.5em',
  },
} )

const initialValues = {
  date: '',
  times: [],
  message: '',
}

const availableTimes = [ ...Array( 9 ).keys() ].map( ( h ) => h + 9 )

const BookMeeting = () => {
  const classes = useStyles()
  const fetch = useFetchAuth()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { data: { representative } = {} } = useSWR( getUserInvestorUrl() )
  const { startupId } = useParams()
  const [ startup, setStartup ] = useState( {} )

  useEffect( () => {
    fetch( getStartupUrl( startupId ), { method: 'GET' } )
      .then( setStartup )
      .catch( () => history.push( routes.INVESTOR_MATCHING_RECOMMENDED ) )
  }, [ fetch, startupId, history ] )

  const handleSubmit = ( { times, message } ) => fetch(
    getInitiateUrl( startupId ),
    { method: 'PUT', body: { times: times.map( ( t ) => new Date( t ).toISOString() ), message } },
  )
    .then( () => {
      enqueueSnackbar( 'Meeting has been requested! You will get an email when the startup has responded.', { variant: 'success' } )
      history.goBack()
    } )
    .catch( () => enqueueSnackbar( 'Something went wrong. Please try again.', { variant: 'error' } ) )
  // .catch( ( err ) => {
  //   if ( err.statusCode === 403 ) {
  //     setShowTrialExpiredModal( true )
  //     refreshToken()
  //   }
  // } )

  const addTime = ( { date, times }, time, setTimes ) => {
    const unixUtcTimeMs = date.valueOf() + ( time * 60 * 60 * 1000 )
    if ( !times.includes( unixUtcTimeMs ) ) {
      setTimes( [ ...times, unixUtcTimeMs ] )
    }
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <ValidatedForm
        prompt={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {( { values, setFieldValue, submitForm, isSubmitting } ) => (
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12}>
              <div className={classes.info}>
                <div>
                  <Avatar className={classes.logo} src={startup.logoUrl} alt={`${startup.name} logo`} />
                </div>
                <div>
                  <h2 className={classes.margin5}>{`Request Meeting with ${startup.name}`}</h2>
                  <p className={classes.margin5}>{getStartupAccountHolderNameSafe( startup )}</p>
                  <p className={classes.margin5}>(30 mins)</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.calendarBox}>
              <div>
                Select a Date & Time
                <Calendar
                  className={classes.calendar}
                  tileClassName={classes.calendarTile}
                  // showNavigation={false}
                  onClickDay={( value ) => { setFieldValue( 'date', value ) }}
                  prev2Label={null}
                  next2Label={null}
                  minDetail="month"
                  minDate={new Date()}
                  calendarType="ISO 8601"
                  showNeighboringMonth={false}
                />

              </div>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.formContainer}>

              <Field
                component={TextField}
                name="date"
                disabled
                value={values.date
                  ? values.date.toLocaleDateString() : 'No date selected'}
                label="Date Selected"
                type="string"
                className={classes.field}
              />

              <FormControl>
                <InputLabel>
                  Select time
                </InputLabel>
                <Select
                  disabled={!values.date}
                  value=""
                  onChange={( e ) => addTime( values, e.target.value, ( times ) => setFieldValue( 'times', times ) )}
                >
                  {availableTimes.map( ( h ) => (
                    <MenuItem key={h} value={h}>{`${h < 10 ? '0' : ''}${h}:00`}</MenuItem>
                  ) )}
                </Select>
              </FormControl>
              <Container className={classes.timeContainer}>

                Availability:
                {values.times.length > 0 ? values.times.map( ( time ) => (
                  <div className={classes.time} key={time}>

                    <span>{formatDateAndTime( time ) }</span>
                    <RedButton onClick={() => setFieldValue( 'times', values.times.filter( ( t ) => t !== time ) )}>
                      delete
                    </RedButton>
                  </div>
                ) ) : ' Please select all availabilities.'}
              </Container>

              <Field
                component={TextField}
                disabled
                name="email"
                value={representative ? representative.email : 'email'}
                label="Email"
                type="string"
                className={classes.field}
              />

              <Field
                component={TextField}
                name="message"
                label="Message"
                type="string"
                multiline
                rows={3}
                className={classes.field}
              />

              <LoaderButton
                className={classes.button}
                loading={isSubmitting}
                onClick={submitForm}
                disabled={values.times.length === 0}
              >
                Request
              </LoaderButton>

            </Grid>

          </Grid>
        ) }

      </ValidatedForm>

    </Container>

  )
}

export default BookMeeting
