import { countries, FUNDRAISE_STAGES, ROLES } from '@kaiku/shared'
import { array, number, object, ref, string } from 'yup'

const schemas = [
  () => object().shape( {
    name: string().required(),
    fundraise: object().shape( {
      stage: string().oneOf( Object.values( FUNDRAISE_STAGES ) ).required(),
    } ),
    foundedYear: number()
      .integer( 'Enter a year' )
      .moreThan( 2000, 'Your company is too old to be a startup' )
      .lessThan( new Date().getFullYear() + 1, 'You haven\'t founded your startup yet!' )
      .required(),
    website: string().url().required(),
    headquartersLocation: string()
      .oneOf( countries.map( ( { code } ) => code ) )
      .required( 'Required' ),
    oneLiner: string().required(),
    logoUrl: string().nullable(),
  } ),
  () => object().shape( {
    industries: array().of( string().required() )
      .min( 1, 'Please select at least one industry.' )
      .max( 3, 'You can only select up to three industries.' ),
  } ),
  () => object().shape( {
    productHighlights: array().of( string().required() ),
  } ),
  () => object().shape( {
    fundraise: object().shape( {
      stage: string().oneOf( Object.values( FUNDRAISE_STAGES ) ).required(),
      targetRaise: number().min( 0 ).required(),
      amountRaised: number().min( 0 ).required(),
      investors: number().min( 0 ).required(),
      targetEquity: number().min( 0 ).max( 100 ).required(),
      ticketSize: number().min( 0 ).max( ref( 'targetRaise' ), 'Ticket size cannot be more than the target raise' ).required(),
    } ),
  } ),
  () => object().shape( {
    members: array().of( object().shape( {
      firstName: string().required(),
      lastName: string().required(),
      role: string().oneOf( Object.values( ROLES ) ).required(),
      linkedinUrl: string().url().nullable(),
      cvUrl: string().nullable(),
      pictureUrl: string().nullable(),
    } ) ),
  } ),
]

export default schemas
