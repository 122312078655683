import { Box, makeStyles } from '@material-ui/core'
import { node } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  circle: {
    borderRadius: '100em',
    backgroundColor: palette.primary.main,
    width: '3.5em',
    height: '3.5em',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '2em',
    '& > *': {
      fontSize: '1em',
      color: 'white',
      textAlign: 'center',
    },
  },
} ) )

const ContentCircle = ( { children } ) => {
  const classes = useStyles()

  return <Box className={classes.circle}>{children}</Box>
}

ContentCircle.propTypes = {
  children: node,
}

ContentCircle.defaultProps = {
  children: null,
}

export default ContentCircle
