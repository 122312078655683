import { FUNDRAISE_TYPES, qs, routes } from '@kaiku/shared'
import { FormStepper, HomeForm, LoaderButton, logout, useFetchAuth, useUser, ValidatedForm } from '@kaiku/ui'
import { Box, FormHelperText, Grid, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { object } from 'yup'

import { getCompleteStartupRegistrationUrl, getUserInfomationUrl } from '../../../api'
import useCommonStyles from '../../Login/styles'
import BasicInformation from './BasicInformation'
import CurrentFundraise from './CurrentFundraise'
import Industries from './Industries'
import ProductHighlights from './ProductHighlights'
import schemas from './schemas'
import Team from './Team'

const steps = [
  { title: 'About you', label: 'Basic Information', form: BasicInformation, optional: false },
  { title: 'Industries', label: 'Industries', form: Industries, props: { helperText: 'Please select between one and three industries.' }, optional: false },
  { title: 'Product Highlights', label: 'Product Highlights', form: ProductHighlights, optional: false },
  { title: 'Current Fundraise', label: 'Fundraise', form: CurrentFundraise, optional: true, skipped: false },
  { title: 'Team', label: 'Team', form: Team, optional: true, skipped: false },
]

// testing purposes
// eslint-disable-next-line no-unused-vars
const updateQuery = ( updatedQuery ) => {
  const urlSearchParams = new URLSearchParams( window.location.search )
  const params = Object.fromEntries( urlSearchParams.entries() )
  Object.entries( updatedQuery ).forEach( ( [ key, value ] ) => {
    params[ key ] = value
  } )
  return qs.stringify( params )
}

const initialValues = {
  name: '',
  foundedYear: new Date().getFullYear(),
  website: '',
  headquartersLocation: '',
  oneLiner: '',
  logoUrl: '',
  industries: [],
  productHighlights: [],
  fundraise: {
    fundraiseType: FUNDRAISE_TYPES.raise,
    stage: '',
    targetRaise: '',
    amountRaised: '',
    targetEquity: '',
    investors: '',
    ticketSize: '',
  },
  members: [ {
    firstName: '',
    lastName: '',
    role: '',
    linkedinUrl: '',
    cvUrl: '',
    pictureUrl: '',
    permanent: true,
  } ],
}

const numberValues = [ 'foundedYear', 'targetRaise', 'amountRaise', 'targetEquity', 'investors', 'ticketSize' ]
// for 'draft' profile implementation
const getInitialValues = () => {
  const urlSearchParams = new URLSearchParams( window.location.search )
  const params = Object.fromEntries( urlSearchParams.entries() )
  const finalInitialValues = {}
  Object.entries( initialValues ).forEach( ( [ key, value ] ) => {
    finalInitialValues[ key ] = ( numberValues.includes( key )
      ? parseInt( params[ key ], 10 )
      : params[ key ] )
       || value
  } )
  return finalInitialValues
}

const RegisterStartup = () => {
  const fetch = useFetchAuth()
  const history = useHistory()
  const classes = useCommonStyles()
  const [ error, setError ] = useState()
  const [ currentStep, setCurrentStep ] = useState( 0 )
  const { data: userData } = useSWR( getUserInfomationUrl() )
  const [ , refreshToken ] = useUser()

  if ( !userData ) {
    return (
      <HomeForm logout={logout}>
        <FormStepper steps={steps} activeStep={currentStep} />
        <Typography className={classes.formHeader} variant="h3">{steps[ currentStep ].title}</Typography>
      </HomeForm>
    )
  }

  initialValues.members[ 0 ].firstName = userData.firstName
  initialValues.members[ 0 ].lastName = userData.lastName
  const completeSchema = schemas.reduce( ( prev, current ) => prev.concat( current() ), object() )

  const nextPage = async ( values, setSubmitting ) => {
    if ( error ) {
      setError( null )
    }
    if ( currentStep === steps.length - 1 ) {
      setSubmitting( true )
      const cloneValues = { ...values }
      if ( steps[ 3 ].skipped ) {
        cloneValues.fundraise = { stage: values.fundraise.stage }
      }
      if ( steps[ 4 ].skipped ) {
        cloneValues.members = [ {
          firstName: userData.firstName,
          lastName: userData.lastName,
        } ]
      }
      const finalValues = completeSchema.submit( cloneValues )
      try {
        await fetch( getCompleteStartupRegistrationUrl(), { method: 'post', body: finalValues } )
        refreshToken()
      } catch ( err ) {
        setError( 'Failed to submit' )
        setSubmitting( false )
      }
      return
    }
    setCurrentStep( currentStep + 1 )
  }

  const previousPage = () => {
    if ( error ) {
      setError( null )
    }
    if ( currentStep === 0 ) {
      history.push( routes.REGISTER_DETAILS )
      return
    }
    setCurrentStep( currentStep - 1 )
  }

  const nextStep = ( values, setSubmitting ) => {
    if ( steps[ currentStep ].skipped ) {
      steps[ currentStep ].skipped = false
    }
    return nextPage( values, setSubmitting )
  }

  const skipStep = ( values, setSubmitting ) => {
    if ( !steps[ currentStep ].optional ) {
      return
    }
    steps[ currentStep ].skipped = true
    nextPage( values, setSubmitting )
  }

  const schema = schemas[ currentStep ]()
  const FormComponent = steps[ currentStep ].form

  return (
    <HomeForm logout={logout}>
      <FormStepper steps={steps} activeStep={currentStep} />
      <Typography className={classes.formHeader} variant="h3">{steps[ currentStep ].title}</Typography>
      <ValidatedForm
        initialValues={getInitialValues()}
        schema={schema}
        onSubmit={async ( values, { setTouched, setSubmitting } ) => {
          setTouched( {} )
          await nextStep( values, setSubmitting )
        }}
        prompt={false}
      >
        {( { isSubmitting, setSubmitting, submitForm, errors, touched, values } ) => (
          <>
            <FormComponent
              {...steps[ currentStep ].props}
              errors={errors}
              touched={touched}
            />
            <Grid container spacing={1} direction="row-reverse">
              <Grid item xs={6}>
                <LoaderButton
                  className={classes.mainButton}
                  onClick={submitForm}
                  loading={isSubmitting}
                  fullWidth
                >
                  {currentStep === steps.length - 1 ? 'Complete Registration' : 'Next'}
                </LoaderButton>
              </Grid>

              <Grid item xs={6}>
                <LoaderButton
                  className={classes.mainButton}
                  fullWidth
                  onClick={previousPage}
                  variant="outlined"
                >
                  Back
                </LoaderButton>

              </Grid>

            </Grid>
            {steps[ currentStep ].optional && (
            <Box
              className={classes.skipStepContainer}
            >
              <div
                style={{ display: isSubmitting ? 'none' : 'flex' }}
                className={classes.skipStepText}
                onClick={() => skipStep( values, setSubmitting )}
              >
                {currentStep === steps.length - 1 ? 'Skip for now and complete registration' : 'Skip for now'}
                <NavigateNextIcon fontSize="small" />

              </div>
            </Box>
            )}
            {error && (
              <FormHelperText error className={classes.errorText}>
                {error}
              </FormHelperText>
            )}
          </>
        )}

      </ValidatedForm>
    </HomeForm>
  )
}

export default RegisterStartup
