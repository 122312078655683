import { number } from 'prop-types'
import React from 'react'

import Badge from './Badge'

const MatchBadge = ( { value } ) => <Badge value={value} text="Match" isPercentage />

MatchBadge.propTypes = {
  value: number,
}

MatchBadge.defaultProps = {
  value: 0,
}

export default MatchBadge
