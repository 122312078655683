import { getRegionsXorCountries } from '@kaiku/shared'
import { Box, Chip } from '@material-ui/core'
import numeral from 'numeral'
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import fields from './fields'

const toScale = ( value ) => numeral( value ).format( '0.[0a]a' )

const getTicketSizeRange = ( minimum, maximum ) => {
  const minAmount = minimum && toScale( minimum )
  const maxAmount = maximum && toScale( maximum )

  if ( !minAmount && !maxAmount ) return null
  if ( !maxAmount ) return `>£${minAmount}`
  if ( !minAmount ) return `<£${maxAmount}`

  return `£${minAmount} - £${maxAmount}`
}

const ChipValues = ( { value } ) => !!value.length && (
  <Box>{value.map( ( { name } ) => <Chip key={name} label={name} /> )}</Box>
)

const ChipPlaceholder = ( { placeholder } ) => <Box><Chip label={placeholder} /></Box>

const valuePropType = arrayOf( shape( { name: string, value: oneOfType( [ string, number ] ) } ) )
ChipValues.propTypes = {
  value: valuePropType.isRequired,
}

ChipPlaceholder.propTypes = {
  placeholder: string.isRequired,
}

const ThesisBlock = ( {
  thesisIndustries,
  thesisHighlights,
  thesisLocations,
  seisRequired,
  thesisStages,
  roundPreference,
  followUpRounds,
  ticketSizeMinimum,
  ticketSizeMaximum,
} ) => (
  <ContentBlock title="Investment Thesis">

    <CaptionField
      {...fields.thesisIndustries}
      value={thesisIndustries}
      renderValue={ChipValues}
      renderPlaceholder={ChipPlaceholder}
    />

    <CaptionField
      {...fields.thesisHighlights}
      value={thesisHighlights}
      renderValue={ChipValues}
      renderPlaceholder={ChipPlaceholder}
    />

    <CaptionField
      {...fields.thesisLocations}
      value={thesisLocations}
      renderValue={( { value } ) => !!value.length && (
        <Box>
          {getRegionsXorCountries( value.map( ( { name } ) => name ) )
            .map( ( name ) => <Chip key={name} label={name} /> )}
        </Box>
      )}
      renderPlaceholder={ChipPlaceholder}
    />

    <CaptionField
      {...fields.thesisStages}
      value={thesisStages}
      renderValue={ChipValues}
      renderPlaceholder={ChipPlaceholder}
    />

    <CaptionField
      {...fields.ticketSizeRange}
      value={getTicketSizeRange( ticketSizeMinimum, ticketSizeMaximum )}
    />

    <CaptionField {...fields.roundPreference} value={roundPreference} />

    <CaptionField {...fields.followUpRounds} value={followUpRounds ? 'Yes' : 'No'} />

    <CaptionField {...fields.seisRequired} value={seisRequired ? 'Yes' : 'No'} />

  </ContentBlock>
)

ThesisBlock.propTypes = {
  thesisIndustries: arrayOf( shape( { name: string } ) ),
  thesisHighlights: arrayOf( shape( { name: string } ) ),
  thesisLocations: arrayOf( shape( { name: string } ) ),
  seisRequired: bool,
  thesisStages: arrayOf( shape( { name: string } ) ),
  roundPreference: string,
  followUpRounds: bool,
  ticketSizeMinimum: number,
  ticketSizeMaximum: number,
}

ThesisBlock.defaultProps = {
  thesisIndustries: [],
  thesisHighlights: [],
  thesisLocations: [],
  seisRequired: null,
  thesisStages: [],
  roundPreference: null,
  followUpRounds: null,
  ticketSizeMinimum: null,
  ticketSizeMaximum: null,
}

export default ThesisBlock
