import { Button, makeStyles } from '@material-ui/core'
import { func } from 'prop-types'
import React, { useState } from 'react'

import Example from './Example'
import Modal from './Modal'
import ToolTip from './ToolTip'
import tourData from './TourData'

const useStyles = makeStyles( {
  main: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    background: 'rgba(128, 128, 128, 0.5)',
    zIndex: 1200,
  },
  skip: {
    position: 'fixed',
    right: '5%',
    bottom: '5%',
  },
} )

const Tutorial = ( { onDone } ) => {
  const [ openTip, changeTip ] = useState( -1 )
  const classes = useStyles()
  return (
    <div>
      <div className={classes.main}>
        <Modal open={openTip === -1} close={() => changeTip( 0 )} skip={onDone} />
        {tourData.map( ( tutorialStage, index ) => (
          <ToolTip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            pos={tutorialStage.pos}
            width={250}
            arrowPos={tutorialStage.arrowPos}
            open={openTip === index}
            prevClick={index === 0 ? null : () => changeTip( index - 1 )}
            nextClick={index === tourData.length - 1 ? onDone : () => changeTip( index + 1 )}
            text={tutorialStage.text}
            progress={{ index: index + 1, length: tourData.length }}
          />
        ) )}
        <Button className={classes.skip} onClick={onDone}> Skip Tour </Button>
      </div>
      <Example />
    </div>
  )
}

Tutorial.propTypes = {
  onDone: func,
}

Tutorial.defaultProps = {
  onDone: null,
}

export default Tutorial
