import { generate } from 'json-merge-patch'

// Gets JSON patch, but first maps any arrays specified by keyMappers to objects
const getChangedData = ( original, next, keyMappers = {} ) => generate(
  ...[ original, next ].map( ( data ) => Object.entries( data ).reduce(
    ( data, [ key, value ] ) => ( {
      ...data,
      [ key ]: keyMappers[ key ]
        ? value.reduce( ( obj, item ) => ( { ...obj, [ keyMappers[ key ]( item ) ]: item } ), {} )
        : value,
    } ), {},
  ) ),
)

export default getChangedData
