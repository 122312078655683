import { PRODUCT_HIGHLIGHTS } from '@kaiku/shared'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { mapValues } from 'lodash'
import PropTypes, { arrayOf, func, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import { Autocomplete } from '../../Formik/Autocomplete'
import ValidatedForm from '../../Formik/Form'
import withAutocompleteDialog from '../../Formik/with-autocomplete-dialog'
import withOptionAction from '../../Formik/with-option-action'
import ValueChip from '../../ValueChip'
import fields from './fields'
import METRIC_CONFIG from './metric-config'

const MetricAutocomplete = withOptionAction( Autocomplete, withAutocompleteDialog( METRIC_CONFIG ) )

const createSchema = () => yup.object().shape( {
  productHighlights: yup.array().of( yup.object().shape( { name: yup.string() } ) ).required(),
  businessModel: yup.string().nullable(),
  revenueModel: yup.string().nullable(),
  productProgress: yup.string().nullable(),
  metrics: yup.array().of( yup.object().shape( {
    name: yup.string(),
    value: yup.number().min( 0 ),
  } ) ),
} )

const BusinessBlock = ( {
  productHighlights,
  businessModel,
  revenueModel,
  productProgress,
  metrics,
  withSubmit,
  onSubmit,
} ) => {
  const initialValues = {
    ...mapValues( { businessModel, revenueModel, productProgress }, ( value ) => value || '' ),
    metrics,
    productHighlights,
  }

  const schema = createSchema()

  return (
    <ContentBlock title="Business">
      <ValidatedForm
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <>

            <CaptionField
              {...fields.businessModel}
              renderValue={() => <Field component={TextField} name="businessModel" multiline />}
            />

            <CaptionField
              {...fields.revenueModel}
              renderValue={() => <Field component={TextField} name="revenueModel" multiline />}
            />

            <CaptionField
              {...fields.productHighlights}
              renderValue={() => (
                <Field
                  component={Autocomplete}
                  name="productHighlights"
                  options={Object.values( PRODUCT_HIGHLIGHTS ).map( ( name ) => ( { name } ) )}
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={( { name } ) => name}
                  getOptionSelected={( { name: selected }, { name } ) => selected === name}
                />
              )}
            />

            <CaptionField
              {...fields.productProgress}
              renderValue={() => <Field component={TextField} name="productProgress" multiline />}
            />

            <CaptionField
              {...fields.metrics}
              renderValue={() => (
                <Field
                  component={MetricAutocomplete}
                  name="metrics"
                  options={Object.keys( METRIC_CONFIG ).map( ( name ) => ( { name } ) )}
                  multiple
                  getOptionLabel={( { name } ) => name}
                  getOptionSelected={( { name: metricName }, { name } ) => metricName === name}
                  renderTags={( tags, getProps ) => tags.map( ( { name, value }, index ) => (
                    <ValueChip
                      {...METRIC_CONFIG[ name ]}
                      {...getProps( { index } )}
                      key={name}
                      label={name}
                      value={METRIC_CONFIG[ name ].getDisplay( value )}
                    />
                  ) )}
                />
              )}
            />

          </>
        ) )}

      </ValidatedForm>
    </ContentBlock>
  )
}

BusinessBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  productHighlights: arrayOf( shape( { name: string } ) ),
  businessModel: string,
  revenueModel: string,
  productProgress: string,
  metrics: arrayOf( PropTypes.objectOf( PropTypes.object ) ),
}

BusinessBlock.defaultProps = {
  productHighlights: [],
  businessModel: null,
  revenueModel: null,
  productProgress: null,
  metrics: [],
}

export default BusinessBlock
