import { Box, makeStyles } from '@material-ui/core'
import { PaginationItem } from '@material-ui/lab'
import clsx from 'clsx'
import { func, number, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  root: {
    margin: '4em 0',
    display: 'flex',
    justifyContent: 'center',
  },
} )

const Pagination = ( { onPrevious, page, onNext, className } ) => {
  const classes = useStyles()

  return (
    <Box className={clsx( className, classes.root )}>
      <PaginationItem disabled={!onPrevious} onClick={onPrevious} type="previous" size="large" />
      <PaginationItem disabled={page === null} selected={page !== null} size="large" page={page + 1} />
      <PaginationItem disabled={!onNext} onClick={onNext} type="next" size="large" />
    </Box>
  )
}

Pagination.propTypes = {
  className: string,
  onPrevious: func,
  page: number,
  onNext: func,
}

Pagination.defaultProps = {
  className: null,
  page: null,
  onPrevious: undefined,
  onNext: undefined,
}

export default Pagination
