import { routes } from '@kaiku/shared'
import { logout, Navbar } from '@kaiku/ui'
import { Avatar, Badge, Button, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { Dashboard as DashboardIcon, ExitToApp, Notifications, Person, Settings, Store } from '@material-ui/icons'
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks'
import Menu from 'material-ui-popup-state/HoverMenu'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { getUserInvestorUrl } from '../../api'
import Dashboard from './Dashboard'
import Declaration from './Declaration'
import InvestorSettings from './InvestorSettings'
import Matching from './Matching'
import Profile from './Profile'
import Tutorial from './Tutorial'

const NAVIGATION_ITEMS = [
  [ 'Dashboard', routes.INVESTOR_DASHBOARD, DashboardIcon ],
  [ 'Matching', routes.INVESTOR_MATCHING, Store ],
  [ 'My Profile', routes.INVESTOR_PROFILE, Person ],
]

const ROUTES = [
  [ routes.INVESTOR_DASHBOARD, Dashboard ],
  [ routes.INVESTOR_MATCHING, Matching ],
  [ routes.INVESTOR_PROFILE, Profile ],
  [ routes.INVESTOR_DECLARATION, Declaration ],
  [ routes.INVESTOR_SETTINGS_ROOT, InvestorSettings ],
]

const useStyles = makeStyles( {
  dropdownLabel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginLeft: '12px',
    marginRight: '12px',
  },
  profileAvatar: {
    width: 24,
    height: 24,
    marginRight: '12px',
  },
  dropDownIcon: {
    marginRight: '12px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  separatorDropDown: {
    border: 0,
    height: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
} )

const getMenuProps = ( state ) => ( {
  ...bindMenu( state ),
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
} )

const InvestorRoot = () => {
  const classes = useStyles()
  const userPopupState = usePopupState( { variant: 'popover', popupId: 'user-menu' } )
  const notificationsPopupState = usePopupState( { variant: 'popover', popupId: 'notifications-menu' } )
  const [ tutorial, setTutorial ] = useState( true )
  const {
    data: { representative, riskAcceptedAt = true } = {},
    error,
  } = useSWR( getUserInvestorUrl() )
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()
  if ( error && error.statusCode === 404 ) {
    enqueueSnackbar( 'Account does not exist', { variant: 'error' } )
    logout()
    return null
  }

  if ( !riskAcceptedAt && tutorial ) return <Tutorial onDone={() => setTutorial( false )} />

  const doLogout = () => {
    userPopupState.close()
    logout()
  }

  const goToProfile = () => {
    userPopupState.close()
    history.push( routes.INVESTOR_PROFILE )
  }

  const goToSettings = () => {
    userPopupState.close()
    history.push( routes.INVESTOR_SETTINGS_ROOT )
  }

  const { pictureUrl, firstName, lastName } = representative || {}

  const AVATAR_NAV_ITEMS = {
    nav: [
      [ 'Profile', goToProfile, () => <Avatar className={classes.profileAvatar} alt="Avatar" src={pictureUrl} /> ],
      [ 'Settings', goToSettings, () => <Settings className={classes.dropDownIcon} /> ],
      [ 'Sign Out', doLogout, () => <ExitToApp className={classes.dropDownIcon} /> ],
    ],
    name: `${firstName} ${lastName}`,
    pictureUrl,
  }

  return (
    <>
      <Navbar
        items={NAVIGATION_ITEMS}
        avatarItems={AVATAR_NAV_ITEMS}
        renderContent={() => (
          <>

            <>
              <IconButton color="inherit" {...bindHover( notificationsPopupState )}>
                <Badge color="error">
                  <Notifications />
                </Badge>
              </IconButton>

              <Menu {...getMenuProps( notificationsPopupState )}>
                <MenuItem>No Notifications</MenuItem>
              </Menu>
            </>

            <>

              <Button variant="text" {...bindHover( userPopupState )}>
                <Typography className={classes.dropdownLabel}>
                  {firstName}
                  {' '}
                  {lastName}
                </Typography>
                <Avatar alt="Avatar" src={pictureUrl} />
              </Button>

              <Menu {...getMenuProps( userPopupState )}>
                {AVATAR_NAV_ITEMS.nav.map( ( [ name, onClick, Icon ] ) => (
                  <MenuItem onClick={onClick}>
                    <Icon />
                    <Typography>{name}</Typography>
                  </MenuItem>
                ) )}
              </Menu>
            </>

          </>
        )}
      />

      <Switch>

        {riskAcceptedAt && ROUTES.map( ( [ path, Component ] ) => (
          <Route
            key={path}
            path={path}
            render={() => <Component />}
          />
        ) )}

        <Redirect to={routes.INVESTOR_MATCHING} />
      </Switch>

      {!riskAcceptedAt && <Declaration />}

    </>
  )
}

export default InvestorRoot
