import { Box, Chip } from '@material-ui/core'
import { arrayOf, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import ValueChip from '../../ValueChip'
import fields from './fields'
import METRIC_CONFIG from './metric-config'

const BusinessBlock = ( {
  productHighlights,
  businessModel,
  revenueModel,
  productProgress,
  metrics,
} ) => (
  <ContentBlock title="Business">
    <CaptionField {...fields.businessModel} value={businessModel} multiline />

    {revenueModel && (
      <CaptionField {...fields.revenueModel} value={revenueModel} multiline />
    )}

    <CaptionField
      {...fields.productHighlights}
      value={productHighlights}
      renderValue={( { value } ) => !!value.length && (
      <Box>
        {value.map( ( { name } ) => (
          <Chip key={name} label={name} />
        ) )}
      </Box>
      )}
    />

    {productProgress && (
      <CaptionField
        {...fields.productProgress}
        value={productProgress}
        multiline
      />
    )}

    {!!metrics.length && (
      <CaptionField
        {...fields.metrics}
        value={metrics}
        renderValue={( { value } ) => (
          <Box>
            {value.map( ( { name, value } ) => (
              <ValueChip
                key={name}
                label={name}
                value={METRIC_CONFIG[ name ].getDisplay( value )}
              />
            ) )}
          </Box>
        )}
      />
    )}
  </ContentBlock>
)

BusinessBlock.propTypes = {
  productHighlights: arrayOf( shape( { name: string } ) ),
  businessModel: string,
  revenueModel: string,
  productProgress: string,
  metrics: arrayOf( shape( { name: string, value: oneOfType( [ string, number ] ) } ) ),
}

BusinessBlock.defaultProps = {
  productHighlights: [],
  businessModel: null,
  revenueModel: null,
  productProgress: null,
  metrics: [],
}

export default BusinessBlock
