import { MuiThemeProvider } from '@material-ui/core'
import { node } from 'prop-types'
import React from 'react'

import theme from '../lib/theme'

const ThemeProvider = ( { children } ) => (
  <MuiThemeProvider theme={theme}>
    {children}
  </MuiThemeProvider>
)

ThemeProvider.propTypes = {
  children: node.isRequired,
}

export default ThemeProvider
