import { getInvestorLogo, getInvestorName, MEETING_FILTERS, MEETING_STATUSES, qs } from '@kaiku/shared'
import { formatDateAndTime, useFetchAuth, usePaginatedFetch } from '@kaiku/ui'
import { Avatar, Button, Chip, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

import { getDeclineMeetingForStartupUrl, getStartupMeetingsUrl } from '../../../api'
import MeetingInfo from './MeetingInfo'

const useStyles = makeStyles( ( { palette } ) => ( {
  messageContainer: {
    padding: '2em',
  },
  logo: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    borderRadius: '50%',
    borderWidth: '1px',
    marginRight: '15px',
    '& > svg': {
      fontSize: '1.25em',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.primary.main,
    fontSize: '1.25em',
    textAlign: 'center',
  },
} ) )

const PAGE_SIZE = 12

const allColumns = [ 'Investor', 'Message', 'Availability', 'Accepted Time', 'Status', null ]

const Meetings = () => {
  const classes = useStyles()
  const fetch = useFetchAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [ hasMeetings, setHasMeetings ] = useState( true )
  const [ selectedMeeting, setSelectedMeeting ] = useState()
  const [ declining, setDeclining ] = useState( false )

  const [ query, setQuery ] = useState( {} )

  const { page = 0, filter = MEETING_FILTERS.all } = query

  const updateQuery = ( newQ ) => {
    setQuery( { ...query, ...newQ } )
  }

  const {
    results: meetings = [],
    total = 0, setPage, mutate,
  } = usePaginatedFetch(
    [ getStartupMeetingsUrl(), qs.stringify( { filter, page } ) ].join( '?' ),
    PAGE_SIZE,
  )

  // Synchronise state with query parameter
  useEffect( () => {
    setPage( +page )
  }, [ page, setPage ] )

  // Synchronise state with query parameter
  useEffect( () => {
    if ( filter === MEETING_FILTERS.all ) {
      if ( total === 0 ) {
        setHasMeetings( false )
      } else {
        setHasMeetings( true )
      }
    }
  }, [ filter, total ] )

  const declineMeeting = ( investorId ) => {
    setDeclining( true )
    fetch( getDeclineMeetingForStartupUrl( investorId ), { method: 'POST', body: { } } )
      .then( () => {
        enqueueSnackbar( 'Meeting has been declined.', { variant: 'success' } )
        setSelectedMeeting()
        mutate()
      } )
      .catch( () => enqueueSnackbar( 'Something went wrong. Please try again.', { variant: 'error' } ) )
      .finally( () => setDeclining( false ) )
  }

  if ( !hasMeetings ) {
    return (
      <Paper className={classes.messageContainer}>
        <Typography align="center" variant="h4">
          <strong>Thank you for joining the Kaiku community!</strong>
          <br />
          You will be notified by the Kaiku team
          when an investor indicates interest in your fundraise.
        </Typography>
      </Paper>
    )
  }

  return (
    <Paper>
      <Toolbar>
        {Object.values( MEETING_FILTERS ).map( ( f ) => (
          <Chip
            key={f}
            variant={filter === f ? 'default' : 'outlined'}
            onClick={() => updateQuery( { filter: f, page: 0 } )}
            label={f}
          />
        ) )}
      </Toolbar>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {allColumns.map( ( header ) => (
                <TableCell key={header} align="center">{header}</TableCell>
              ) )}
            </TableRow>
          </TableHead>

          <TableBody>
            {meetings.map( ( { meetingInfo, investor } ) => (
              <TableRow
                key={`${investor.id}`}
              >
                <TableCell scope="row">
                  <div className={classes.rowContainer}>
                    <Avatar
                      alt={`${getInvestorName( investor )} logo`}
                      className={classes.logo}
                      src={getInvestorLogo( investor )}
                    >
                      <Business fontSize="large" />
                    </Avatar>
                    {getInvestorName( investor )}
                  </div>
                </TableCell>
                <TableCell align="center">{meetingInfo.message || '-'}</TableCell>
                <TableCell align="center">
                  {meetingInfo.status === MEETING_STATUSES.meetingRequested
                        || meetingInfo.status === MEETING_STATUSES.meetingBooked
                    ? meetingInfo.meetingTimes.map( ( time ) => (
                      <div key={time}>
                        {formatDateAndTime( time )}
                      </div>
                    ) ) : <div>-</div>}
                </TableCell>
                <TableCell align="center">{( meetingInfo.acceptedTime && formatDateAndTime( meetingInfo.acceptedTime ) ) || '-'}</TableCell>
                <TableCell align="center">{meetingInfo.status}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="text"
                    onClick={() => setSelectedMeeting( { meetingInfo, investor } )}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ) )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={total}
                rowsPerPage={PAGE_SIZE}
                page={+page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={( _, page ) => updateQuery( { page: +page } )}
                onChangeRowsPerPage={( { target: { value } } ) => updateQuery( { size: value } )}
                ActionsComponent={undefined}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <MeetingInfo
        meeting={selectedMeeting}
        onClose={() => setSelectedMeeting()}
        declineMeeting={declineMeeting}
        declining={declining}
      />
    </Paper>
  )
}

export default Meetings
