import { Box, Button, Link } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { string } from 'prop-types'
import React from 'react'

const DownloadButton = ( { href, name, ...props } ) => (
  <Link
    component={Button}
    href={href}
    underline="none"
    download
    target="_blank"
    {...props}
  >
    {name && <Box marginRight="0.75em">{name}</Box>}
    <CloudDownload fontSize="small" />
  </Link>
)

DownloadButton.propTypes = {
  href: string.isRequired,
  name: string,
}

DownloadButton.defaultProps = {
  name: null,
}

export default DownloadButton
