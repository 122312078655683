import { PORTFOLIO_TYPES, uuid } from '@kaiku/shared'
import { FormControl, InputAdornment, InputLabel, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { Field, FieldArray, getIn } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import { mapValues } from 'lodash'
import PropTypes, { arrayOf, bool, func, instanceOf, number, oneOfType, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import Accordion from '../../Accordion'
import ContentBlock from '../../ContentBlock'
import ValidatedForm from '../../Formik/Form'
import PORTFOLIO_TOOLTIPS from './tooltips'

const useStyles = makeStyles( {
  form: {
    marginTop: '2em',
  },
} )

const createSchema = () => yup.object().shape( {
  portfolio: yup.array().of( yup.object().shape( {
    id: yup.string(),
    amount: yup.number().min( 0 ).nullable(),
    type: yup.string().oneOf( Object.values( PORTFOLIO_TYPES ) ).nullable(),
    //! Doesn't convert incoming timestamp string to date properly if type is date
    date: yup.lazy( ( value ) => ( value instanceof Date ? yup.date() : yup.string().nullable() ) ),
    company: yup.string().required(),
  } ) ),
} )

const initialPortfolioValues = {
  id: uuid(),
  amount: '',
  company: '',
  date: null,
  type: PORTFOLIO_TYPES.investor,
}

const getDate = ( dateStr ) => ( dateStr
  ? format( new Date( dateStr ), 'MMM yyyy' )
  : 'Previous Investments' )

const Title = ( { date, error } ) => (
  <Typography color={error ? 'error' : 'inherit'} variant="subtitle1">
    {getDate( date )}
  </Typography>
)

Title.propTypes = {
  date: oneOfType( [ string, instanceOf( Date ) ] ),
  error: bool,
}

Title.defaultProps = {
  date: null,
  error: false,
}

const Entry = ( { type, index } ) => (
  <>

    <Field
      component={DatePicker}
      name={`portfolio[${index}].date`}
      label="Month"
      format="MMMM yyyy"
      openTo="year"
      disableFuture
      autoOk
      views={[ 'year', 'month' ]}
    />

    <FormControl>
      <InputLabel>Type of Involvement</InputLabel>

      <Field
        component={Select}
        name={`portfolio[${index}].type`}
      >
        {Object.values( PORTFOLIO_TYPES ).map( ( value ) => (
          <MenuItem key={value} value={value}>{value}</MenuItem>
        ) )}
      </Field>
    </FormControl>

    <Field
      component={TextField}
      name={`portfolio[${index}].company`}
      label="Company"
    />

    {type === PORTFOLIO_TYPES.investor && (
      <Field
        component={TextField}
        name={`portfolio[${index}].amount`}
        label="Investment Amount"
        type="number"
        inputProps={{ min: 0, step: 1000 }}
        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
      />
    )}

  </>
)

Entry.propTypes = {
  index: number.isRequired,
  type: string.isRequired,
}

const EditPortfolioBlock = ( { portfolio, withSubmit, onSubmit, type } ) => {
  const initialValues = {
    portfolio: portfolio.length
      ? portfolio.map( ( entry ) => mapValues(
        initialPortfolioValues,
        ( initial, key ) => entry[ key ] || initial,
      ) )
      : [ initialPortfolioValues ]
    ,
  }

  const classes = useStyles()
  const schema = createSchema()

  return (
    <ContentBlock
      title="Portfolio"
      tooltip={PORTFOLIO_TOOLTIPS[ type ]}
    >
      <ValidatedForm
        className={classes.form}
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <FieldArray name="portfolio">
            {( { form: { values: { portfolio }, errors }, push, remove } ) => (
              <Accordion
                onAdd={() => push( { ...initialPortfolioValues, id: uuid() } )}
                onDelete={remove}
                items={portfolio}
                renderTitle={( props, index ) => <Title {...props} index={index} error={!!Object.keys( getIn( errors, [ 'portfolio', index ], {} ) ).length} />}
                renderDetails={( item, index ) => <Entry {...item} index={index} />}
              />

            )}
          </FieldArray>

        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

EditPortfolioBlock.propTypes = {
  portfolio: arrayOf( PropTypes.objectOf( PropTypes.object ) ),
  type: PropTypes.string,
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
}

EditPortfolioBlock.defaultProps = {
  portfolio: [],
  type: null,
}

export default EditPortfolioBlock
