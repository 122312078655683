import { Button, Radio } from '@material-ui/core'
import { node, string } from 'prop-types'
import React from 'react'

const RadioButton = ( { className, children, ...props } ) => (
  <Radio
    {...props}
    className={className}
    disableRipple
    disableFocusRipple
    checkedIcon={<Button className={className}>{children}</Button>}
    icon={<Button className={className} variant="outlined">{children}</Button>}
  />
)

RadioButton.propTypes = {
  className: string,
  children: node,
}

RadioButton.defaultProps = {
  className: null,
  children: null,
}

export default RadioButton
