const API_ROOT = '/api/matches'

export const getRecommendedMatchesUrl = () => `${API_ROOT}/recommended`

export const getBookmarkedMatchesUrl = () => `${API_ROOT}/bookmarks`
export const getBookmarkedMatchUrl = ( startupId ) => `${API_ROOT}/bookmark/${startupId}`

export const getIgnoredMatchesUrl = () => `${API_ROOT}/ignores`
export const getIgnoredMatchUrl = ( startupId ) => `${API_ROOT}/ignores/${startupId}`

export const getSearchMatchUrl = () => `${API_ROOT}/search`

export const getMatchDetailsUrl = ( startupId ) => `${API_ROOT}/match/${startupId}`
