import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { Select } from 'formik-material-ui'
import { arrayOf, string } from 'prop-types'
import React from 'react'

const ValidatedSelect = ( { options, error, label, name, ...props } ) => (
  <FormControl {...props} error={!!error}>
    <InputLabel>{label}</InputLabel>
    <Field component={Select} fullWidth name={name}>
      {options.map( ( stage ) => (
        <MenuItem key={stage} value={stage}>{stage}</MenuItem>
      ) )}
    </Field>
    {error && (
      <FormHelperText>
        {error}
      </FormHelperText>
    )}
  </FormControl>
)

ValidatedSelect.propTypes = {
  options: arrayOf( string.isRequired ).isRequired,
  error: string,
  label: string.isRequired,
  name: string.isRequired,
}
ValidatedSelect.defaultProps = {
  error: '',
}

export default ValidatedSelect
