import { routes } from '@kaiku/shared'
import { SideNavbar } from '@kaiku/ui'
import { Container, makeStyles } from '@material-ui/core'
import { Group, Inbox, Search, Star } from '@material-ui/icons'
import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import Bookmarks from './Bookmarks'
import BookMeeting from './BookMeeting'
import Engagements from './Engagements'
import Find from './Find'
import Ignores from './Ignores'
import AllMeetings from './Meetings/AllMeetings'
import BookedMeetings from './Meetings/BookedMeetings'
import CompletedMeetings from './Meetings/CompletedMeetings'
import DeclinedMeetings from './Meetings/DeclinedMeetings'
import RequestedMeetings from './Meetings/RequestedMeetings'
import Recommended from './Recommended'
import RiskDisclaimer from './RiskDisclaimer'
import ViewStartup from './ViewStartup'

const NAVIGATION_ITEMS = [
  {
    name: 'Recommended',
    icon: Inbox,
    route: routes.INVESTOR_MATCHING_RECOMMENDED,
  },
  {
    name: 'Meetings',
    icon: Group,
    route: routes.INVESTOR_MATCHING_MEETINGS,
    subItems: [
      {
        name: 'Booked',
        route: routes.INVESTOR_MATCHING_MEETINGS_BOOKED,
      },
      {
        name: 'Requested',
        route: routes.INVESTOR_MATCHING_MEETINGS_REQUESTED,
      },
      {
        name: 'Completed',
        route: routes.INVESTOR_MATCHING_MEETINGS_COMPLETED,
      },
      {
        name: 'Declined',
        route: routes.INVESTOR_MATCHING_MEETINGS_DECLINED,
      },
    ],
  },
  {
    name: 'Engagements',
    icon: Star,
    route: routes.INVESTOR_MATCHING_ENGAGEMENTS,
    subItems: [
      {
        name: 'Bookmarks',
        route: routes.INVESTOR_MATCHING_BOOKMARKS,
      },
      {
        name: 'Ignores',
        route: routes.INVESTOR_MATCHING_IGNORES,
      },
    ],
  },
  {
    name: 'Find Startups',
    icon: Search,
    route: routes.INVESTOR_MATCHING_FIND,
  },
]

const ROUTES = [
  [ routes.INVESTOR_MATCHING_BOOKMARKS, Bookmarks ],
  [ routes.INVESTOR_MATCHING_IGNORES, Ignores ],
  [ routes.INVESTOR_MATCHING_ENGAGEMENTS, Engagements ],
  [ routes.INVESTOR_MATCHING_MEETINGS_BOOKED, BookedMeetings ],
  [ routes.INVESTOR_MATCHING_MEETINGS_REQUESTED, RequestedMeetings ],
  [ routes.INVESTOR_MATCHING_MEETINGS_DECLINED, DeclinedMeetings ],
  [ routes.INVESTOR_MATCHING_MEETINGS_COMPLETED, CompletedMeetings ],
  [ routes.INVESTOR_MATCHING_MEETINGS, AllMeetings ],
  [ routes.INVESTOR_MATCHING_RECOMMENDED, Recommended ],
  [ routes.INVESTOR_BOOKING, BookMeeting ],
  [ routes.INVESTOR_MATCHING_FIND, Find ],
  [ `${routes.INVESTOR_VIEW_STARTUP}/:startupId`, ViewStartup ],
]

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
} )

const Matching = () => {
  const classes = useStyles()
  const { pathname } = useLocation()

  return (
    <Container className={classes.container} maxWidth={false}>
      <SideNavbar
        items={NAVIGATION_ITEMS}
        withBackButton={pathname.startsWith( routes.INVESTOR_VIEW_STARTUP )}
      />

      <Container className={classes.content} maxWidth={false}>

        <Switch>
          {ROUTES.map( ( [ path, Component ] ) => (
            <Route
              key={path}
              path={path}
              render={( { match: { params } } ) => <Component {...params} />}
            />
          ) )}

          <Redirect to={routes.INVESTOR_MATCHING_RECOMMENDED} />
        </Switch>

        <RiskDisclaimer />

      </Container>

    </Container>
  )
}

export default Matching
