import { formatDateAndTime } from '@kaiku/ui'
import { Container } from '@material-ui/core'
import { objectOf, string } from 'prop-types'
import React from 'react'

const MeetingBooked = ( { meetingInfo } ) => (
  <Container>
    {`This meeting has been booked for ${formatDateAndTime( meetingInfo.acceptedTime )}.`}
    <br />
    Please check your email for the Zoom meeting link.
    <br />
    <br />
    If you can no longer attend the meeting at this time, please
    contact the startup directly. Note: The Zoom link is valid for 30
    days after the initial meeting date.
  </Container>
)

MeetingBooked.propTypes = {
  meetingInfo: objectOf( {
    acceptedTime: string,
  } ).isRequired,
}

export default MeetingBooked
