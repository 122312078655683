import { AppBar, Avatar, Drawer, IconButton, List, ListItem, ListItemText, makeStyles, Tab, Tabs, Toolbar } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import PropTypes, { arrayOf, func, number, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import logoUrl from '../assets/Kaiku_white.png'
import { headerFontFamily } from '../lib/theme'

const useStyles = makeStyles( ( { breakpoints } ) => ( {
  logo: {
    objectFit: 'contain',
    height: '30px',
  },
  indicator: {
    backgroundColor: 'white',
  },
  mobileNavMenu: {
    [ breakpoints.up( 'md' ) ]: {
      display: 'none',
    },
  },
  desktopNavMenu: {
    width: '100%',
    display: 'flex',
    [ breakpoints.down( 'sm' ) ]: {
      display: 'none',
    },
  },
  tabRoot: {
    width: '100%',
  },
  tabContainer: {
    height: '64px',
  },
  tab: {
    fontFamily: headerFontFamily,
    fontSize: '18px',
  },
  mobileToolbar: {
    justifyContent: 'space-between',
  },
  mobileIcon: {
    marginRight: '12px',
  },
} ) )

const Navbar = ( { items, avatarItems, renderContent } ) => {
  // Force a resize on mount to fix incorrect tab indicator size
  // https://github.com/mui-org/material-ui/issues/9337
  useEffect( () => {
    window.dispatchEvent( new CustomEvent( 'resize' ) )
  }, [] )

  const history = useHistory()
  const { pathname } = useLocation()

  const value = items.findIndex( ( [ , destination ] ) => pathname.includes( destination ) )

  const classes = useStyles()

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.mobileToolbar}>
        <img className={classes.logo} src={logoUrl} alt="Logo" />
        <NavbarMenu
          className={classes.mobileNavMenu}
          items={items}
          history={history}
          avatarItems={avatarItems}
          value={value}
          pathname={pathname}
        />
        <div className={classes.desktopNavMenu}>
          <NavbarTabs classes={classes} value={value} history={history} items={items} />
          {renderContent()}
        </div>
      </Toolbar>
    </AppBar>
  )
}

const NavbarTabs = ( { classes, value, history, items } ) => (
  <Tabs
    classes={{
      indicator: classes.indicator,
      flexContainer: classes.tabContainer,
      root: classes.tabRoot,
    }}
    value={value > -1 ? value : false}
    centered
  >
    {items.map( ( [ name, destination ] ) => (
      <Tab
        key={destination}
        className={classes.tab}
        label={name}
        onClick={() => history.push( destination )}
      />
    ) )}
  </Tabs>
)

const NavbarMenu = ( { items, avatarItems, history, pathname, className } ) => {
  const [ open, toggleOpen ] = useState( false )
  const classes = useStyles()
  return (
    <div className={className}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 2 }}
      >
        <Menu onClick={() => toggleOpen( true )} />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => toggleOpen( false )}
      >
        <List>
          {avatarItems && (
          <ListItem>
            <Avatar className={classes.mobileIcon} alt="Avatar" src={avatarItems.pictureUrl} />
            <ListItemText primary={avatarItems.name} />
          </ListItem>
          )}
          <hr />
          {items.map( ( [ name, destination, Icon ] ) => (
            <ListItem
              button
              key={destination}
              onClick={() => history.push( destination )}
              selected={pathname.includes( destination )}
            >
              <Icon className={classes.mobileIcon} />
              <ListItemText primary={name} />
            </ListItem>
          ) )}
          {avatarItems.nav && avatarItems.nav
            .filter( ( [ name ] ) => name !== 'Profile' )
            .map( ( [ name, onClick, Icon ] ) => (
              <ListItem
                button
                key={name}
                onClick={onClick}
                selected={pathname.includes( name.toLowerCase() )}
              >
                <Icon />
                <ListItemText primary={name} />
              </ListItem>
            ) )}
        </List>
      </Drawer>
    </div>
  )
}

NavbarMenu.propTypes = {
  items: arrayOf( arrayOf( string ) ),
  avatarItems: PropTypes.objectOf( PropTypes.object ),
  history: PropTypes.objectOf( PropTypes.object ).isRequired,
  pathname: string.isRequired,
  className: string,
}

NavbarMenu.defaultProps = {
  items: [],
  avatarItems: {},
  className: '',
}

NavbarTabs.propTypes = {
  items: arrayOf( arrayOf( string ) ).isRequired,
  value: number.isRequired,
  history: PropTypes.objectOf( PropTypes.object ).isRequired,
  classes: PropTypes.objectOf( PropTypes.object ).isRequired,
}

Navbar.propTypes = {
  items: arrayOf( arrayOf( string ) ),
  avatarItems: PropTypes.objectOf( PropTypes.object ),
  renderContent: func,
}

Navbar.defaultProps = {
  items: [],
  avatarItems: {},
  renderContent: () => null,
}

export default Navbar
