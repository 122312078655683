export default [
  { id: 'cc71943f-9198-427f-8022-d113cf957be8',
    startupId: 'cc71943f-9198-427f-8022-d113cf957be8',
    date: '2022-07-01T00:00:00.000Z',
    type: 'grant',
    targetRaise: 4849864,
    targetEquity: 9.39,
    ticketSize: 273916,
    investors: 1,
    amountRaised: 414235,
    dilutedEquity: 5.69,
    company: 'Perini Corp',
    createdAt: '2022-02-06T22:31:43.695Z',
    updatedAt: '2022-02-06T22:31:43.869Z',
    name: 'Ford Motor Co',
    foundedYear: 2021,
    description: 'De onihow dodzuc vazzoj dunpi wo hoomu kup hid apdi ro la oju su ojobefpi mehebosil gi. Ikniwzep ogtotmoc go da nukvipac od eka adili pauncon ise revzinfet uwsaow asa muwi mem duvhunu. Ji evu buez supeov amifuh wakgitez sap otutawa gunumu koma bucelli zeosevah. Vu enusapfe zid ta jorfugja kabiguab ipcak mof bejra cajsak edicujub dejebgoh ome zar lipew zom. Oveiloha aco ukma ruk zi cu we cuw gidipwo ozu pembugne heme ek witul nusuzlo buc etvabis kohfo. La wagmevcis do baladig riswo nahliwo jak jas jesouwa eso dovef zodariv hem vesi hugosvi ujbono. Dih sefzorce li ceeddo ka borot gecov hu fize mab cez cowemjuw ihgona sesmizun dumta.',
    stage: 'minimum viable product',
    stageDescription: 'Ke pacjok wu kal tese fiswur jaf va ra ijo meuwo dawjoco mobip geba hej fevfu et. Ovi ro nuboz enzo belo nad kuwaz agaete cah junednug mu ori nubu me. Vo mugomub mej de ha be li ackof eb oskud mocacne vializig tusu omazifa gocbir pil goicedo. Bicejbe ul motvej fecopud osficco fiji ler vanaf jesep susonuh tab vukceluw. To homado he diplob emape wuh kozzel pit kudlatabi ugnelimi hidu geroc lozomheh ra bit fefhe vaf ri. Noga vo zelotupob da nugop piled buku ge kurkijos os powjecjef fari gos ceko. Renepon foog poidoda salvo waot fevebic livev titgeg rojji wumehjur vawob etojejen hedawufu kohunu.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2022-03-01T00:00:00.000Z',
    incorporatedLocation: 'MF',
    patents: false,
    website: 'http://vetdep.eu/lasas',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.7265853663027437',
    oneLiner: 'Imizufav huv jub gomof tabi lijte jem ge ve akdibciz uz jizefe ojatilus roig re nulo bulo vipnade. Kovanu no os reg metuf at seimuzah ujvina',
    industries: [ 'beauty', 'other', 'government and public sector' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:25:32.356Z',
    members: [
      { id: 'df00e581-9c09-4200-9301-1fdadab6c8c2', startupId: 'cc71943f-9198-427f-8022-d113cf957be8', firstName: 'Willie', lastName: 'Ricci', email: 'member-79@kaiku.com', gender: 'male', createdAt: '2022-02-06T22:31:43.762Z', updatedAt: '2022-02-06T22:31:43.952Z', role: 'co-founder', description: 'Wuh fik hif une wew kuuzabic ji utuawde lotin nale korbo ka raseif. Valgo ruij foswi no ovadoci wak pitze diiw elu wi rom isucov bi dat picovoj ah loneze.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.03866726618531824', nationality: 'GU', location: 'AQ', website: null, identityDocumentUrl: null },
      { id: '7871bb8c-55d8-4997-ab12-fba8e6819003', startupId: 'cc71943f-9198-427f-8022-d113cf957be8', firstName: 'Sarah', lastName: 'Trambusti', email: 'member-80@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:44.219Z', updatedAt: null, role: 'co-founder', description: 'Ogi la ol odanuwel zug nizalu awe saccuv wesigoobi owno vucazu defuvwa sefe jorga. Opapevku kalleune sipnevtul vojiwcor ahi dos laf rubajef famubba sikkujdu pumgi koon faaz ga.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.6372210056940271', nationality: 'GA', location: 'OM', website: null, identityDocumentUrl: null } ],
    metrics: [],
    productHighlights: [],
    headquartersLocation: 'SM',
    operationsLocations: [],
    locations: [ 'GB' ],
    fundraiseStage: 'seed',
    raises: [],
    match: 85,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: '4d52c174-19ca-43e2-be24-3a11085f3bb2',
    startupId: '4d52c174-19ca-43e2-be24-3a11085f3bb2',
    date: '2022-01-01T00:00:00.000Z',
    type: 'grant',
    targetRaise: 5818285,
    targetEquity: 5.11,
    ticketSize: 182203,
    investors: 2,
    amountRaised: 431211,
    dilutedEquity: 8.69,
    company: 'Foster Wheeler Ltd.',
    createdAt: '2022-02-06T22:31:43.673Z',
    updatedAt: '2022-02-06T22:31:43.858Z',
    name: 'Viad Corp',
    foundedYear: 2018,
    description: 'Pah hopem numkewe lerju giz seok isugozi sadgecez verahij linurup ze mamtocov wu fojo ri sagu. Ur osgawna jeasopac joubooju ub hij huhiv henowwi ditij hicafdov sejvuf gubat uhkihzeg uca. Datizaode jihadoj zuwmu musotuh jako cihdu himaf su ilocusah dofu ewe mulin tu vi ha fejjihwo uzvuvno. Hev ef wu pizosfir amdeldaz sadufab bepsecbol reso wudal wifozij esa citfemiw ijuki jepojwon cokah nuzu.',
    stage: 'minimum viable product',
    stageDescription: 'Edawenoti una nukolot sa isdugra mikov okno iji diti lifful is neda ze. Fepte uzasubeg bitoglo fu melifoka hisizo ih hep piev iruke bufwef goktum hemcil bo. Pa ifove wephahjo ni ejo riaba fu afe ri ezuji cercowe gum fiaphup useba evmo sikbo. Ku humraf jusihran halgude sulwoil pusif ezguftup uwa karpip vi raljomem do aromus ep bihwaffu.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2017-06-01T00:00:00.000Z',
    incorporatedLocation: 'GI',
    patents: true,
    website: 'http://lokhotca.nl/ehudiv',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.18685803659307498',
    oneLiner: 'Cer kagef casfo ap norofwi ralihid kokfibza ili pali oteuc goz majo zom gideguji ic. Het irdeca ewavibzo lo up ebmoc abehehug ra afotat ar f',
    industries: [ 'healthcare', 'human resources', 'hardware' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:12:00.988Z',
    members: [
      { id: '609c113f-3834-45da-a2d8-0b3bf38961e9', startupId: '4d52c174-19ca-43e2-be24-3a11085f3bb2', firstName: 'Ollie', lastName: 'Louis', email: 'member-53@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:43.739Z', updatedAt: '2022-02-06T22:31:43.914Z', role: 'founder', description: 'Sadvuchep genjib un ba esijavu ubpo uwacurfo lujerku bah vu tamvadfu gujic uhfeg uhmatfe mah wumo. Jemgu tutemila fudbop joz ten bovvih etfehij linewaji wozji je lokle saonah zoofpop nut.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.473479664406276', nationality: 'NE', location: 'GU', website: null, identityDocumentUrl: null },
      { id: '38d2544f-8ff1-4581-96e9-77995a912203', startupId: '4d52c174-19ca-43e2-be24-3a11085f3bb2', firstName: 'Timothy', lastName: 'Marty', email: 'member-54@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:44.094Z', updatedAt: null, role: 'co-founder', description: 'Ukaip ko bupesjet budidup etrezu murwegaf fo be vaf net dudgoevo ulizikud dumcagi mi heho. Ofoijoko ededoze zowtic iprozira wu bu lojiw eb vekulup tu niw cani be pobrode jupnuflen.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.9049994031467765', nationality: 'KE', location: 'NZ', website: null, identityDocumentUrl: null } ],
    metrics: [],
    productHighlights: [],
    headquartersLocation: 'GQ',
    operationsLocations: [],
    locations: [ 'US' ],
    fundraiseStage: 'late-seed',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: '5d3633c8-c206-4948-999d-abe96d1c7c8f',
    startupId: '5d3633c8-c206-4948-999d-abe96d1c7c8f',
    date: '2018-11-01T00:00:00.000Z',
    type: 'grant',
    targetRaise: 3102399,
    targetEquity: 10.6,
    ticketSize: 30974,
    investors: 1,
    amountRaised: 433512,
    dilutedEquity: 13.45,
    company: 'Penn Traffic Co.',
    createdAt: '2022-02-06T22:31:43.677Z',
    updatedAt: '2022-02-06T22:31:43.859Z',
    name: 'Solectron Corp',
    foundedYear: 2020,
    description: 'Witamdeg hajja watote senhav efcerpa zek deec holajuk ja ublot jopig hug gol moapida. Leraw duluwtem cijab je seura nil gub hipu doj la zuhesogo setutumil etuhad. Zew apairid sinom hug uknuk segfo lof aw ikrec pulwu luemzar di ebocaog pum. Derta zo oda roolceb vubor waha vapeuk vihha maf piidni wu likuliza osesowoh sokife. Pikpo se tovfelan pimez hujge ciritcin liw afoidi kohgan kul saplog nus oni kid codkec. Gefiso faediner buza howo ikovjim udoijma zuf cafgi enpu dutfub uw kiteru weefohej hebej.',
    stage: 'minimum viable product',
    stageDescription: 'Ji edenewog har hed wafu wohuje duw wasaj ci bemu fifzev zimlehtuj. Be pa ikvo awama ukiru riv wi ziid fer fugwe hudca okput rasuiha hif jesevo. Wenrawhih fererus dobdane uphiwe luhiab gi sew neghu dete agopekip zihmuw da offuh lusugawo assic ziridelor renu mesa. Judog nehapob ripe nu ci rewpal doir lica hed nugpi obemiek apfunzam su umozotcav cen hef. Ufmiwu raudo jinge hul onafijbek peud emikazva wojac amoro hig gij ope moj piriofu sucivi aruhuzap fe cizcozca.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2016-11-01T00:00:00.000Z',
    incorporatedLocation: 'VN',
    patents: true,
    website: 'http://co.ae/fewocka',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.8934163936306565',
    oneLiner: 'Zevatajow toibahec gi jovijag hafakna fil inagik cut hitse hisna hadlah vohagev cilaga. Ib cegtemip ku awuma jopnanzi lah edi iwimum luzguk',
    industries: [ 'transportation', 'hardware', 'human resources' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:12:04.233Z',
    members: [
      { id: 'b4b6a8a2-ffa0-4511-8db8-885a48d3593c', startupId: '5d3633c8-c206-4948-999d-abe96d1c7c8f', firstName: 'Lena', lastName: 'Bush', email: 'member-55@kaiku.com', gender: 'other', createdAt: '2022-02-06T22:31:43.742Z', updatedAt: '2022-02-06T22:31:43.922Z', role: 'co-founder', description: 'Fu be cez fuvatje fira zubamuf sac lozjaba zepufhaj peuziko hedih co wibile itanoko cehpamgo. Inzesol rewve zum saaz liwhu pit sozva muzi wuikidi uso ra fej fefcic dozto.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.2282017750197487', nationality: 'ET', location: 'LY', website: null, identityDocumentUrl: null },
      { id: 'eb512535-89da-4e22-be4a-5e0fdd61a6bd', startupId: '5d3633c8-c206-4948-999d-abe96d1c7c8f', firstName: 'Sylvia', lastName: 'Frosali', email: 'member-56@kaiku.com', gender: 'other', createdAt: '2022-02-06T22:31:44.106Z', updatedAt: null, role: 'co-founder', description: 'Padac pu julkepra evufa ban feg rif ja octes tu hihlit houd hu. Hiz nezvon bol lesko duwbi lojus hoabe go fen ok tiwe sil deiza ficin taradi izu congis abokak.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.6196210523449597', nationality: 'FR', location: 'AT', website: null, identityDocumentUrl: null } ],
    metrics: [],
    productHighlights: [],
    headquartersLocation: 'NR',
    operationsLocations: [],
    locations: [ 'EE' ],
    fundraiseStage: 'series-b',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: '717ed88c-69bc-4177-9e9e-a6f3dcfa56d9',
    startupId: '717ed88c-69bc-4177-9e9e-a6f3dcfa56d9',
    date: '2018-08-01T00:00:00.000Z',
    type: 'award',
    targetRaise: 7519267,
    targetEquity: 5.85,
    ticketSize: 59000,
    investors: 2,
    amountRaised: 81400,
    dilutedEquity: 6.65,
    company: 'Sequa Corp',
    createdAt: '2022-02-06T22:31:43.689Z',
    updatedAt: '2022-02-06T22:31:43.864Z',
    name: 'Reliance Steel & Aluminum Co.',
    foundedYear: 2015,
    description: 'Eb tujetbep noin ol bawna atzuvol im ega buh jek umga itbadu giti ihsekmu gugok rontuge jad. Wesdi huane popuw nim vozozoc ike ga depkit fip no fomatan velucka zural wicifwa ab igwuota sutbas bi. Riovuhec sis me gegmeli zehehum sowu decka haj ohhuw zimisoega copuju zine roke lunho ha ti. Tiv zem ihki afufucret cinmefdan doj vegbapim ok novfoz senfu am ciab wet boov faor ewne ukozuga sitlin.',
    stage: 'revenue generation',
    stageDescription: 'Zuno ra bensu turwula medebe zobnec iparod cuktigam jatgez zaf evajitit gimuc dod rutov ehilimfa calmom ivfemfa tu. Gumoc ocwobow cemmewul sersa tagwag ne tavke tebmo novjisal matso norji egokab ta tibwe sabjide. Tu zazep tuhzawuv dibwid eb fuonugek ko ikus ga gislaha lit igmolef utlo. Usuna ja nib ra go ana difwa hume zit ewusastus wiv woh firag rasiloz om ow bovejfef red. Tunce rifzitbu bimuj oguzuf ep pa asoluova varav pih esi zidus cetuj fag tuceglos.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2020-06-01T00:00:00.000Z',
    incorporatedLocation: 'NE',
    patents: true,
    website: 'http://sa.bh/wu',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.9594306093662097',
    oneLiner: 'Amle ife duatza acu sumnab livego fo hukadcu ge kejozi jod woepwu maz zon kizhew pu hah. Zufo osobub lopa faw maptone jeluto asi pif foleuje',
    industries: [ 'construction', 'insurance', 'real estate' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:12:06.996Z',
    members: [
      { id: 'a276049c-0731-4df3-a6f3-3074e74675fb', startupId: '717ed88c-69bc-4177-9e9e-a6f3dcfa56d9', firstName: 'Martin', lastName: 'van den Heuvel', email: 'member-69@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:43.756Z', updatedAt: '2022-02-06T22:31:43.934Z', role: 'co-founder', description: 'Vuosojel muja bafbaz vutuj jipvurjab hovaosi pol kebcicic puhuow dougijed zoje zidferof. Zuve ofa mer gisi vegur humerlo lug ifnuk safalep bunjef rawutdop imcat unki dip ohide gi.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.4756264842733915', nationality: 'KR', location: 'SA', website: null, identityDocumentUrl: null },
      { id: 'f155660a-2700-4de6-a407-c1e68d9f55ff', startupId: '717ed88c-69bc-4177-9e9e-a6f3dcfa56d9', firstName: 'Pauline', lastName: 'Wells', email: 'member-70@kaiku.com', gender: 'other', createdAt: '2022-02-06T22:31:44.152Z', updatedAt: null, role: 'employee', description: 'Vajotko pojuore lagpu kov sekik le ohuco okiohsav kigzajo coz penpetma def vezap uhlop ehi nedef. Pupevmus ajiacdig se he voomiavi pidvi je wucup utilo ivimug ukwa wa ajenib arewen suerooh pepa pinu vobaewo.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.5024397471034225', nationality: 'ET', location: 'PE', website: null, identityDocumentUrl: null } ],
    metrics: [],
    productHighlights: [],
    headquartersLocation: 'NA',
    operationsLocations: [],
    locations: [ 'LV' ],
    fundraiseStage: 'pre-seed',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: '09c8b772-710f-45a5-a72d-e263b5feb725',
    startupId: '09c8b772-710f-45a5-a72d-e263b5feb725',
    date: '2021-03-01T00:00:00.000Z',
    type: 'award',
    targetRaise: 3487772,
    targetEquity: 9.72,
    ticketSize: 117157,
    investors: 0,
    amountRaised: 180331,
    dilutedEquity: 11.83,
    company: 'Manor Care Inc.',
    createdAt: '2022-02-06T22:31:43.678Z',
    updatedAt: '2022-02-06T22:31:43.860Z',
    name: 'National City Corp.',
    foundedYear: 2016,
    description: 'Biore oni sidzuh acesulu mi elcu zan lec pakazak hoiwku no tuf paguwiv hufu gozdutpav. Da muza siwawo ivkuze rehu cok tole uz cubipuf fer uvsar uzgoppe zefcodmuc ujogemu gurwekam. Acuwurub menawa oce dovi luod uricinvez onwucopi kiz soz ud zo behsismu gujavotu. If sukize sawmon jem sulbi ere biz pedsupe li laguos fum gefa. Gur obiuz eje moh muwanig zaj woldohfip ma bahaj patah sug bu san. Batikzal veh gu az aribasze rafwad himevda boces giliw zukten kijtamke vaz wip paf. Nipdos cesar sagenrik anuovno zozhu nuzkogus ajwih wef puvaz tig one afotivi ol jolmo.',
    stage: 'minimum viable product',
    stageDescription: 'Jejen lahepo tiw kin pulcuce not ohti omelilwi buv kuk fiecdi izsil negcobe kurugpub de. Ca ahnoudi so zehka wiktuk bojja uwi regap pawiisa dop kuoze leiru utrefdor sapi gukhu van ac wo. Fawu kut jeonju bada gijijah pi ecubsi nur ipi ca oncotgij it muca lu netlazcaz jo lir. Lekag zinemib hidnuffo zo ju kenu lamubek bofo uz judcisa zaw bejav. Ad ma wut kerfob rosmav tiofjig afojozpom co am vabhelhoh as ahwof. Ikevav de minca gelo oneruw karjeafo vecintit bemve ivsacta kek egoijela emacus mob revugiv pakewwof. Dofum hop il guja hosjih vaklop seje joku ulna ufbojis ackufu edvez nuzpe otbic ur sedin hokpa.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2017-11-01T00:00:00.000Z',
    incorporatedLocation: 'FM',
    patents: true,
    website: 'http://cej.dm/sewnu',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.384672218504732',
    oneLiner: 'Weuri bem gudke cij kuwigo gacakjot haroke kopavaf ca waut zer da up egfij. Wefiap sekcuhum lud umilouwa elmupte si ogosabjo dehor vuh nop c',
    industries: [ 'energy', 'construction', 'music' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:12:10.668Z',
    members: [
      { id: 'b6310bb5-6602-4ced-872d-fcf3cd67a4cc', startupId: '09c8b772-710f-45a5-a72d-e263b5feb725', firstName: 'Mattie', lastName: 'Wang', email: 'member-58@kaiku.com', gender: 'male', createdAt: '2022-02-06T22:31:44.117Z', updatedAt: null, role: 'founder', description: 'Loc os iseawivuc elifisnew ce aszuwih uviger gugrizbaz avcis vu zozvazi lic utiid kiri. Aj kerilo julucez jefvam kam cagi kirapok jotosle vahuwcal ezoedvoz zoful os simvarkad folcoz remjonaf uceubfu dewofbak ma.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.9163668912035008', nationality: 'KG', location: 'SC', website: null, identityDocumentUrl: null },
      { id: '9f83537d-2ec9-45dc-8872-3a67266260d5', startupId: '09c8b772-710f-45a5-a72d-e263b5feb725', firstName: 'Ann', lastName: 'Holmes', email: 'member-57@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:43.750Z', updatedAt: '2022-02-06T22:31:43.925Z', role: 'founder', description: 'Rivis oguno godirnu wekgip esa wuh hipbu gittaprij ho avo boftuwe vinwuvar luje lid suv lo vote. Ganpajof upuza zi ha avikurav kodu daszeduz difopu anci aloluv mooz dibmuedu.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.24799606732865187', nationality: 'DK', location: 'MP', website: null, identityDocumentUrl: null } ],
    metrics: [],
    productHighlights: [],
    headquartersLocation: 'UY',
    operationsLocations: [],
    locations: [ 'LT' ],
    fundraiseStage: 'pre-seed',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: '857f069a-58dc-48f4-bd3f-243e3562f56c',
    startupId: '857f069a-58dc-48f4-bd3f-243e3562f56c',
    date: '2022-11-01T00:00:00.000Z',
    type: 'award',
    targetRaise: 812615,
    targetEquity: 4.53,
    ticketSize: 170956,
    investors: 2,
    amountRaised: 283121,
    dilutedEquity: 6.91,
    company: "Casey's General Stores Inc.",
    createdAt: '2022-02-06T22:31:43.668Z',
    updatedAt: '2022-02-06T22:31:43.866Z',
    name: 'IT Group Inc.',
    foundedYear: 2016,
    description: 'Ibebufu pam lemobrot zul jejkevi cazko wifupu ekutih la dozkam adoapuni ojo ij ko pelec. Bak jamciro vovoje wohihha nidi eme mek afi dida ludbefrag cakonih je ozu zivuzra kenopse juip aniciak. Zahnis micbop far jujihuvi afuzuz kowen sozes ukad wujvehaj ufelogus gagcib kel calikma azricu wef. Kiz hermog ame ponepu pucke akabehil ki tave ali ot asutok iw. Mavve iljaluw di uje pupu bohzosa um asu ti dowsiw fa wekeuru holpergej uldu. Wamjof bafgu womsir ho fus mu ro ki sawze hadludhef wahbewa mume.',
    stage: 'revenue generation',
    stageDescription: 'Ker co lucril wihge galnu wev joevtu kacbinpad tag kopon biwo uzecaz raj huzgo foka. Si zotkunow ujo ce ze onajpa leasodew ikep ba lufukavub comhumit ciwpa ocoja kiujiev wa erje. Ija ubatulij ferosac to kek bubunra nu iltec zo lo oto rawa wedur sodahu ak jedezu.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2016-03-01T00:00:00.000Z',
    incorporatedLocation: 'BN',
    patents: false,
    website: 'http://zakcum.com/jec',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.08911896351055426',
    oneLiner: 'Juw zu ugiso dofivuc honopo vesgin ulumig gaztof timfu sovuffe udogo jog be ahocu jalbodbik jife. Ep ha eke uceihwil lugeb li ruh ge zep loz',
    industries: [ 'gaming', 'healthcare', 'real estate' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:25:35.127Z',
    members: [
      { id: '8af680ea-6f73-4dff-8322-0bcddeedcc24', startupId: '857f069a-58dc-48f4-bd3f-243e3562f56c', firstName: 'Glen', lastName: 'Fanetti', email: 'member-51@kaiku.com', gender: 'other', createdAt: '2022-02-06T22:31:43.738Z', updatedAt: '2022-02-06T22:31:43.943Z', role: 'co-founder', description: 'Dapve pegdak bajamo ron nid rutaro fecez gael usa fus wane ulegoka jitijuw pu di tuchobaf ina. Ze pomupma domu banak ce dun ojinus beecefu rod kawosi wewehoru fahiv kegu bu gag pubando eridivu.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.669566855752397', nationality: 'NE', location: 'FR', website: null, identityDocumentUrl: null },
      { id: 'ecc3ec63-5ef5-423c-b63f-71d09a8060ee', startupId: '857f069a-58dc-48f4-bd3f-243e3562f56c', firstName: 'Hannah', lastName: 'van Doorn', email: 'member-52@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:44.183Z', updatedAt: null, role: 'founder', description: 'Ojucif sedmi ruc owo bif taepe ke tenoj hudo gepfuj pihi jujsu zu bajomur suwer. Nupfin tu cewo cuwoku divkipzaz me bo ucta lip befmod luhoise egpidwa za co komiwipa haw hamwus zag.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.09344388473380194', nationality: 'CG', location: 'TH', website: null, identityDocumentUrl: null } ],
    metrics: [],
    productHighlights: [],
    headquartersLocation: 'MO',
    operationsLocations: [],
    locations: [ 'AT' ],
    fundraiseStage: 'series-a',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: 'f23016c8-0e34-45e1-b909-3f08b0ca3d31',
    startupId: 'f23016c8-0e34-45e1-b909-3f08b0ca3d31',
    date: '2020-08-01T00:00:00.000Z',
    type: 'award',
    targetRaise: 9833588,
    targetEquity: 5.46,
    ticketSize: 90158,
    investors: 2,
    amountRaised: 4980,
    dilutedEquity: 8.26,
    company: 'Cytec Industries Inc.',
    createdAt: '2022-02-06T22:31:43.694Z',
    updatedAt: '2022-02-06T22:31:43.868Z',
    name: 'MGM Mirage',
    foundedYear: 2013,
    description: 'Caji wowibu ru rijmi suori ajhedmad ojma zois wo tistep orgileh afcope pup kagpizfov fotsefu pigke dusoda. Wowupupe zucalake gojbenaj jolapwe ruli toisrad jivnifne ucuhuc jup pat alpepfi fofbewir wugsize tubreptu vokmagdop lawuwek petacage. Gaurbe sen uh ceutelus bavobha aja li ebvildos tot jeka sebfi ogiez to. Wawiliw ug zopu si bufulbi asuvopdu sap wip edi zenacar kijal uknugra gokowem. Cenec vaejte tupur nuzaz ju nuun masinoz amleiz eve ce litev og ejvivam rejehawav ado akupa jef. Pi nainoha pezuz na kevom uzavekanu vo wihiwim kov jabku api tal kerson walolaw vawtod.',
    stage: 'revenue generation',
    stageDescription: 'Fejojsu ahgu oveju eva lucigim fokoho seojsoz nisuzori foghe he te tocopo anzow ne. Va porsem episam irece lifki fomucrar pinufi ja wimeh kufim to jebbuupo furvoktu hi sonuj gad luciefi itabogre. Fahpidvi lo jecupe asiofufe vif romimna moh cozejafi zu gapwaji nes mopucsef apeupsu egipavdo josu pebbuw. Suz fioca ra erozufzi luj pat igirafo vejnufvil ugu hekewo uzhu efmomo inovi pasusni nogurimi ka gauje do. Ru ber tudeawo potna zarudi giv gepegpa sespazu saj cuzoeto zukof kucra tivtegaf kuwpet hubpi fapkaste vi ebeefe. Fibtatal puklewo gibulej ijode zicamo hoke lawepiwu irucubor hifos degupu ke zedkivu pa.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2018-07-01T00:00:00.000Z',
    incorporatedLocation: 'FI',
    patents: false,
    website: 'http://wa.cx/haasnur',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.37661349899643315',
    oneLiner: 'Vudolno ewca kipgic beujvah pitowej gamulto rum popa paj zom hetjojep jeuwu voocome fujezu. Sudfe pumgedut rutaoj pibi ifohe mipoma toito hu',
    industries: [ 'legal and regulation', 'government and public sector', 'aviation and aerospace' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:25:37.889Z',
    metrics: [],
    members: [
      { id: '971bd853-0d23-4cf2-8cd0-14f2a7dabe8b', startupId: 'f23016c8-0e34-45e1-b909-3f08b0ca3d31', firstName: 'Rosetta', lastName: 'Johnson', email: 'member-77@kaiku.com', gender: 'other', createdAt: '2022-02-06T22:31:43.761Z', updatedAt: '2022-02-06T22:31:43.950Z', role: 'founder', description: 'Hobwuc da hi udebwi hadbud kojomipu wasbi pipuvo lowog hotho bogegan nuhziduw uhiril ciheg. Vesefade kaziran na ti keikcuk tehu sunlo laini wijkef bobsic asgele gu.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.036927725214895846', nationality: 'XK', location: 'MS', website: null, identityDocumentUrl: null },
      { id: '41a37c9e-4718-479a-933a-827718eb6b85', startupId: 'f23016c8-0e34-45e1-b909-3f08b0ca3d31', firstName: 'Millie', lastName: 'Bosma', email: 'member-78@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:44.204Z', updatedAt: null, role: 'co-founder', description: 'Bucheomi tebwusude utbaom huzi node pizi vedo zot foijjow jiwjuvi so wiwipihip hoh jefun nuv. Kuozeeg uduro owja un ikolemu wopodkan ebi daj amapu do uphunfif uz reufaol ve.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.8272033049359846', nationality: 'MR', location: 'WF', website: null, identityDocumentUrl: null } ],
    productHighlights: [],
    headquartersLocation: 'BN',
    operationsLocations: [],
    locations: [ 'IT' ],
    fundraiseStage: 'seed',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false },
  { id: '9c911269-8f34-4ccf-b4b8-8e8a275ff01f',
    startupId: '9c911269-8f34-4ccf-b4b8-8e8a275ff01f',
    date: '2021-03-01T00:00:00.000Z',
    type: 'award',
    targetRaise: 2140493,
    targetEquity: 9.07,
    ticketSize: 247547,
    investors: 5,
    amountRaised: 10412,
    dilutedEquity: 12.92,
    company: 'Interstate Bakeries Corporation',
    createdAt: '2022-02-06T22:31:43.696Z',
    updatedAt: '2022-02-06T22:31:43.870Z',
    name: 'Boise Cascade Corporation',
    foundedYear: 2018,
    description: 'Zezka kat cilnopame rawiko kewsi tot beb lumhow jaw lepte vi lazozelo beve vudfefe. Ija ro resuca fol enafeb ja ofehabkec jo ud ve vit zen. Lusgic nezo ziwjeve wi uraokukoc colo fem ce ejamog wot nagiaj foimruc. Ji nirot wofudu mozov war kalrab ula asutoh jikov sec etupajpod irife esu toucigak vahi sino mezutoza. Wil kokbit tevo jise fuwop duoles juvhunal co afimaku hosoketi cejo furuh otdas lag os. Cillo wuego coshel jo de ri iwgumbel bo ku teva zep wuoluwe hosu er.',
    stage: 'ideation',
    stageDescription: 'Wucki ra roro licet og jor banine nourevoh locfaci nu hutponhan nedecled joobced zopufe avacudtiv kepoho. Wu asrieti elre bo fejne ciow divgol mif gow pageb emo pu heva isimabvik zaw. Utle fer ti si som oruaculul naguszo tegzo ejiluso lov jiken hokgubfe rodsag rip ubafzos za. Fednid pini lemapri ve wo ur acawavaje otabem ag mujkaut nihes ak. Paz zumumo lajsu ha buguckuj ba rafnow bukeaki kevu ba acuanobak dapuw.',
    seisStatus: null,
    incorporated: null,
    incorporatedDate: '2016-02-01T00:00:00.000Z',
    incorporatedLocation: 'MX',
    patents: true,
    website: 'http://alvilit.dk/awuhu',
    businessModel: null,
    revenueModel: null,
    productProgress: null,
    visible: true,
    pitchDeckUrl: 'http://ratizu.io/lahwe.pdf',
    onePagerUrl: 'http://op.uk/ci.pdf',
    logoUrl: 'https://source.unsplash.com/400x400/?logo,tech,0.495465256654271',
    oneLiner: 'Ivuaj fedrenni ma ogokufas watepa sihiv lajeame heh wu witka duduku mo gus owivolde. Wundij la kitjed bercop piffedof fuokejur dezak sek gis',
    industries: [ 'music', 'information technology and telecom', 'gaming' ],
    completenessRating: '0.89',
    onboardedAt: '2022-02-07T13:25:40.482Z',
    metrics: [],
    members: [
      { id: '3812f479-e31c-4f78-9bbc-0a00e6ce7b62', startupId: '9c911269-8f34-4ccf-b4b8-8e8a275ff01f', firstName: 'Mario', lastName: 'Reid', email: 'member-81@kaiku.com', gender: 'male', createdAt: '2022-02-06T22:31:43.762Z', updatedAt: '2022-02-06T22:31:43.955Z', role: 'founder', description: 'Lokaf geghef lur me fe nosdo limmuha ukodotje ero monoaga damezi zidpijura vo gulmailo jabapiin. Mittad da ar dug natuv kafahu jarhupwun menozep wu rejef zos pa ov bo folne je hugi agopowno.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.0059817870242568905', nationality: 'IE', location: 'RE', website: null, identityDocumentUrl: null },
      { id: '1b28719b-11f2-4113-b913-520e9e33ce98', startupId: '9c911269-8f34-4ccf-b4b8-8e8a275ff01f', firstName: 'Elsie', lastName: 'Alvarez', email: 'member-82@kaiku.com', gender: 'female', createdAt: '2022-02-06T22:31:44.230Z', updatedAt: null, role: 'employee', description: 'Tomawi fov modvoccuz oluja tesme wimuh nu raituev fo riwoffe hujiabu mioj eheso fuzcoj. Nopdehki liva nu sifnozju homoduni tuphakda towre ro pewdubuf hivoz ru sisvi fuuzeriz ege.', linkedinUrl: 'http://in.linkedin.com/ajuhalav', cvUrl: null, pictureUrl: 'https://source.unsplash.com/400x400/?professional,face,0.9661628607412176', nationality: 'GY', location: 'NA', website: null, identityDocumentUrl: null } ],
    productHighlights: [],
    headquartersLocation: 'QA',
    operationsLocations: [],
    locations: [ 'CH' ],
    fundraiseStage: 'late-seed',
    raises: [],
    match: 60,
    bookmarked: false,
    ignored: false,
    engaged: false } ]
