import { countries, getCountryName, regions, STARTUP_STAGES } from '@kaiku/shared'
import { Chip, MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { mapValues } from 'lodash'
import { arrayOf, func, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import { Autocomplete, GroupedAutocomplete } from '../../Formik/Autocomplete'
import ValidatedForm from '../../Formik/Form'
import UploadButton, { UPLOAD_TYPES } from '../../Formik/UploadButton'
import fields from './fields'

const countryOptions = countries.map( ( {
  name,
  code,
  ...rest
} ) => ( { name: code, displayName: name, ...rest } ) )
const countryGroups = Object
  .entries( regions )
  .map( ( [ name, value ] ) => ( {
    name,
    value: value.map(
      ( { name, code, ...rest } ) => ( { name: code, displayName: name, ...rest } ),
    ),
  } ) )

const createSchema = () => yup.object().shape( {
  oneLiner: yup.string().max( 150 ).required(),
  description: yup.string().min( 300 ).max( 1000 ).nullable(),
  stage: yup.string().oneOf( Object.values( STARTUP_STAGES ) ).nullable(),
  stageDescription: yup.string().nullable(),
  pitchDeckUrl: yup.string().nullable(),
  onePagerUrl: yup.string().nullable(),
  headquartersLocation: yup.string().required(),
  operationsLocations: yup.array().of( yup.object().shape( { name: yup.string() } ) ),
} )

const EditAboutBlock = ( {
  oneLiner,
  description,
  stage,
  stageDescription,
  pitchDeckUrl,
  onePagerUrl,
  headquartersLocation,
  operationsLocations,
  withSubmit,
  onSubmit,
} ) => {
  const initialValues = {
    ...mapValues( {
      oneLiner,
      description,
      stage,
      stageDescription,
      pitchDeckUrl,
      onePagerUrl,
      headquartersLocation,
    },
    ( value ) => value || '' ),
    operationsLocations,
  }

  const schema = createSchema()

  return (
    <ContentBlock title="About">
      <ValidatedForm
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <>
            <CaptionField
              {...fields.oneLiner}
              renderValue={() => <Field component={TextField} name="oneLiner" multiline />}
            />

            <CaptionField
              {...fields.description}
              renderValue={() => <Field component={TextField} name="description" multiline />}
            />

            <CaptionField
              {...fields.stage}
              renderValue={() => (
                <Field component={Select} name="stage">
                  {Object.values( STARTUP_STAGES ).map( ( stage ) => (
                    <MenuItem key={stage} value={stage}>{stage}</MenuItem>
                  ) )}
                </Field>
              )}
            />

            <CaptionField
              {...fields.stageDescription}
              renderValue={() => <Field component={TextField} name="stageDescription" />}
            />

            <CaptionField
              {...fields.headquartersLocation}
              renderValue={() => (
                <Field
                  component={Autocomplete}
                  name="headquartersLocation"
                  options={countries.map( ( { code } ) => code )}
                  getOptionLabel={( code ) => getCountryName( code ) || ''}
                />
              )}
            />

            <CaptionField
              {...fields.operationsLocations}
              renderValue={() => (
                <Field
                  component={GroupedAutocomplete}
                  name="operationsLocations"
                  options={countryOptions}
                  mainLabel="Countries"
                  groupLabel="Regions"
                  groups={countryGroups}
                  disableCloseOnSelect
                  getComparisonValue={( { name } ) => name}
                  getGroupValue={( { name } ) => name}
                  getOptionLabel={( { displayName } ) => displayName}
                  getOptionSelected={( { name }, { name: selected } ) => selected === name}
                  renderTags={( tags, getProps ) => tags.map( ( { name }, index ) => (
                    <Chip
                      {...getProps( { index } )}
                      key={name}
                      label={getCountryName( name ) || name}
                    />
                  ) )}
                />
              )}
            />

            <CaptionField
              {...fields.pitchDeckUrl}
              renderValue={() => <Field component={UploadButton} name="pitchDeckUrl" {...UPLOAD_TYPES.documents} />}
            />

            <CaptionField
              {...fields.onePagerUrl}
              renderValue={() => <Field component={UploadButton} name="onePagerUrl" {...UPLOAD_TYPES.documents} />}
            />

          </>
        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

EditAboutBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  oneLiner: string,
  description: string,
  stage: string,
  stageDescription: string,
  pitchDeckUrl: string,
  onePagerUrl: string,
  headquartersLocation: string,
  operationsLocations: arrayOf( shape( { name: string } ) ),
}

EditAboutBlock.defaultProps = {
  oneLiner: null,
  description: null,
  stage: null,
  stageDescription: null,
  pitchDeckUrl: null,
  onePagerUrl: null,
  headquartersLocation: null,
  operationsLocations: [],
}

export default EditAboutBlock
