import { routes, USER_TYPES } from '@kaiku/shared'
import { useUser } from '@kaiku/ui'
import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import ActionHandler from './components/Actions/ActionHandler'
import EmailVerificationReminder from './components/EmailVerificationReminder'
import withContexts from './lib/with-contexts'
import InvestorRoot from './pages/Investor'
import Login from './pages/Login/Login'
import ResetPasswordRequest from './pages/Login/ResetPasswordRequest'
import RegisterInvestor from './pages/Register/Investor/RegisterInvestor'
import Register from './pages/Register/Register'
import RegisterDetails from './pages/Register/RegisterDetails'
import RegisterStartup from './pages/Register/Startup/RegisterStartup'
import StartupRoot from './pages/Startup'

// Routes for each user type
const TYPE_ROUTES = {
  [ USER_TYPES.startup ]: { path: routes.STARTUP_ROOT, component: StartupRoot },
  [ USER_TYPES.investor ]: { path: routes.INVESTOR_ROOT, component: InvestorRoot },
  [ USER_TYPES.undetermined ]: { path: routes.REGISTER_DETAILS, component: RegisterDetails },
}

// Registration routes
const REGISTRATION_ROUTES = [
  { path: routes.REGISTER_STARTUP_DETAILS, component: RegisterStartup },
  { path: routes.REGISTER_INVESTOR_DETAILS, component: RegisterInvestor },
]
// Login routes, not accessible when signed in
const LOGIN_ROUTES = [
  { path: routes.REGISTER, component: Register },
  { path: routes.LOGIN, component: Login },
  { path: routes.RESET_PASSWORD, component: ResetPasswordRequest },
]

const useStyles = makeStyles( {
  app: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
} )

const App = () => {
  const classes = useStyles()

  const [ user ] = useUser()

  if ( user === false ) {
    return ( <div /> )
  }

  // Default URL changes if logged in
  const defaultUrl = {
    [ USER_TYPES.startup ]: routes.STARTUP_ROOT,
    [ USER_TYPES.investor ]: routes.INVESTOR_ROOT,
    [ USER_TYPES.undetermined ]: routes.REGISTER_DETAILS,
  }[ user && user.type ] || routes.LOGIN

  return (

    <Box className={classes.app}>
      {user && <EmailVerificationReminder />}

      <Router>
        <Switch>

          {( ( user && user.type ) === USER_TYPES.undetermined )
            && REGISTRATION_ROUTES.map( ( params ) => (
              <Route
                key={params.path}
                {...params}
              />
            ) )}

          {user
            ? <Route {...TYPE_ROUTES[ user.type ]} />
            : LOGIN_ROUTES.map( ( params ) => ( <Route key={params.path} {...params} /> ) )}

          <Route path={routes.FIREBASE_ACTION} component={ActionHandler} />
          <Redirect to={defaultUrl} />

        </Switch>
      </Router>

    </Box>

  )
}

export default () => withContexts( <App /> )
