import { Accordion as MuiAccordion, AccordionActions, AccordionDetails, AccordionSummary, IconButton, makeStyles } from '@material-ui/core'
import { Add, Delete, ExpandMore } from '@material-ui/icons'
import { arrayOf, func, shape, string } from 'prop-types'
import React, { useState } from 'react'

const useStyles = makeStyles( {
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded:not(:first-child):after': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      height: '1px',
      content: '""',
      top: '-1px',
      left: 0,
      right: 0,
      position: 'absolute',
    },
  },
  expansionDetails: {
    flexDirection: 'column',
    '&:first-child': {
      marginTop: '-20px',
    },
  },
} )

const Accordion = ( { items, onAdd, onDelete, renderTitle, renderDetails } ) => {
  const classes = useStyles()

  const [ expanded, setExpanded ] = useState( 0 )
  const toggleExpanded = ( index ) => () => (
    index === expanded ? setExpanded( null ) : setExpanded( index )
  )

  const handleAdd = ( index ) => () => {
    setExpanded( null )
    onAdd( index )

    setTimeout( () => setExpanded( items.length ), 500 )
  }

  const handleDelete = ( index ) => () => {
    setExpanded( index - 1 )

    setTimeout( () => onDelete( index ) )
  }

  return items.map( ( props, index ) => (
    <MuiAccordion
      // eslint-disable-next-line react/no-array-index-key
      key={props.id || index}
      expanded={expanded === index}
      className={classes.accordion}
    >

      <AccordionSummary expandIcon={<ExpandMore />} onClick={toggleExpanded( index )}>
        {renderTitle( props, index )}
      </AccordionSummary>

      <AccordionDetails className={classes.expansionDetails}>
        {renderDetails( props, index )}
      </AccordionDetails>

      <AccordionActions>

        {!props.permanent && (
          <IconButton onClick={handleDelete( index )}>
            <Delete />
          </IconButton>
        )}

        <IconButton onClick={handleAdd( index )}>
          <Add />
        </IconButton>

      </AccordionActions>

    </MuiAccordion>
  ) )
}

Accordion.propTypes = {
  items: arrayOf( shape( { id: string } ) ),
  renderDetails: func,
  renderTitle: func,
  onAdd: func,
  onDelete: func,
}

Accordion.defaultProps = {
  items: [],
  onAdd: () => {},
  onDelete: () => {},
  renderDetails: () => '',
  renderTitle: () => null,
}

export default Accordion
