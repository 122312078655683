import { Box, makeStyles } from '@material-ui/core'
import { arrayOf, element, oneOfType, shape, string } from 'prop-types'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Sidebar from './Sidebar'

const drawerWidth = '300px'

const useStyles = makeStyles( {
  container: {
    minHeight: 'calc(100vh - 64px)', // navbar height = 64px
    width: '100%',
    background: 'white',
    display: 'flex',
    paddingLeft: drawerWidth,
    flexDirection: 'column',
    overflowY: 'scroll',
  },
} )

const Settings = ( { sidebarItems, routes, redirect } ) => {
  const classes = useStyles()
  return (
    <>
      <Sidebar items={sidebarItems} />
      <Box className={classes.container}>
        <Switch>

          { routes.map( ( [ path, Component ] ) => (
            <Route
              key={path}
              path={path}
              render={() => <Component />}
            />
          ) )}

          <Redirect to={redirect} />
        </Switch>
      </Box>
    </>
  )
}

Settings.propTypes = {
  sidebarItems: arrayOf( shape( {
    label: string,
    subItems: arrayOf( shape( {
      label: string,
      path: string,
    } ) ),
  } ) ).isRequired,
  routes: arrayOf( arrayOf( oneOfType( [ string, element ] ) ) ).isRequired,
  redirect: string.isRequired,
}

export default Settings
