import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, makeStyles } from '@material-ui/core'
import { CloudUpload, Delete, Done } from '@material-ui/icons'
import { getIn } from 'formik'
import { arrayOf, string } from 'prop-types'
import React, { useRef, useState } from 'react'

import { useFetchAuth } from '../../hooks'
import DownloadButton from '../DownloadButton'
import FileUpload from './FileUpload'
import formikPropTypes from './formik-prop-types'

export const UPLOAD_TYPES = {
  documents: {
    formats: [ 'application/pdf' ],
    endpoint: '/api/uploads/documents',
  },
  images: {
    formats: [ 'image/jpeg', 'image/png' ],
    endpoint: '/api/uploads/images',
  },
}

const useStyles = makeStyles( {
  label: {
    position: 'relative',
    marginBottom: '7px',
  },
  buttonText: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fullWidth: {
    width: '100%',
  },
} )

/**
 * Uploads a file and returns URL.
 */
const UploadButton = ( {
  label,
  ...props
} ) => {
  const {
    formats,
    endpoint,
    field: { name, value },
    form: { touched, errors, setFieldValue },
  } = props

  // Use ref to open file dialog
  const fileUpload = useRef()
  const openFileDialog = () => fileUpload.current.click()

  const [ uploading, setUploading ] = useState( false )
  const fetch = useFetchAuth()

  // Upload PDFs to uploads microservice
  const upload = async ( file ) => {
    setUploading( true )

    const { url } = await fetch( endpoint, { method: 'POST', body: { file } }, false ).finally( () => setUploading( false ) )

    return url
  }

  const classes = useStyles()
  const uploaded = !uploading && value

  const UploadIcon = () => ( uploading
    ? <CircularProgress size={20} />
    : !value && <CloudUpload /> )

  const UploadedText = () => (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box marginRight="0.5em">Uploaded</Box>
      <Done fontSize="small" />
    </Box>
  )

  const showError = !uploading && getIn( touched, name ) && getIn( errors, name )

  return (
    <>

      <FileUpload
        ref={fileUpload}
        formats={formats}
        upload={upload}
        {...props}
      />

      <FormControl className={classes.fullWidth}>
        <InputLabel shrink className={classes.label} style={showError ? { color: '#f44336' } : {}}>
          {label}
        </InputLabel>

        <Box display="flex">
          <Button
            fullWidth
            classes={{ label: uploaded && classes.buttonText }}
            variant="outlined"
            style={showError ? { color: '#f44336', borderColor: '#f44336' } : {}}
            onClick={openFileDialog}
          >
            {uploaded ? <UploadedText /> : <UploadIcon />}
          </Button>

          {uploaded && <DownloadButton href={value} disableElevation />}
          {uploaded && <Button disableElevation onClick={() => setFieldValue( name, '' )}><Delete /></Button>}
        </Box>

        {showError && (
          <FormHelperText error>
            {getIn( errors, name )}
          </FormHelperText>
        )}

      </FormControl>

    </>
  )
}

UploadButton.propTypes = {
  label: string,
  formats: arrayOf( string ),
  endpoint: string.isRequired,
  ...formikPropTypes,
}

UploadButton.defaultProps = {
  label: null,
  formats: [],
}

export default UploadButton
