import { INVESTOR_ACCOUNT_TYPE, routes, withRouteParams } from '@kaiku/shared'
import { useFetchAuth, useUser } from '@kaiku/ui'
import { Box, makeStyles } from '@material-ui/core'
import { animated, useTransition } from '@react-spring/web'
import { arrayOf, func, shape, string } from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getBookmarkedMatchUrl, getIgnoredMatchUrl } from '../../../api'
import StartupCard from '../../../components/StartupCard'
import TrialExpiredDialog from './TrialExpiredDialog'

const AnimatedBox = animated( Box )

const useStyles = makeStyles( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
} )

const CardGrid = ( {
  children,
  onBookmarkClick,
  onIgnoreClick,
} ) => {
  const fetch = useFetchAuth()
  const history = useHistory()

  const [ showTrialExpiredModal, setShowTrialExpiredModal ] = useState( false )
  const [ user ] = useUser()

  const handleMeetClick = ( startup ) => () => {
    if ( startup.engaged ) return

    if ( user.account === INVESTOR_ACCOUNT_TYPE.trialExpired ) {
      setShowTrialExpiredModal( true )
      return
    }
    history.push( withRouteParams(
      routes.INVESTOR_BOOKING,
      { startupId: startup.id },
    ) )
  }

  const handleIgnoreClick = ( startup, index ) => () => {
    const { id, ignored } = startup

    fetch(
      getIgnoredMatchUrl( id ),
      { method: ignored ? 'DELETE' : 'PUT', body: {} },
    )

    onIgnoreClick( startup, index )
  }

  const handleBookmarkClick = ( startup, index ) => () => {
    const { id, bookmarked } = startup

    fetch(
      getBookmarkedMatchUrl( id ),
      { method: bookmarked ? 'DELETE' : 'PUT', body: {} },
    )

    onBookmarkClick( startup, index )
  }

  const handleClick = ( { id } ) => () => history.push( `${routes.INVESTOR_VIEW_STARTUP}/${id}` )

  const transition = useTransition( children, {
    trail: 200,
    keys: ( { id } ) => id,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' },
  } )

  const cards = transition( ( props, item, _, index ) => (
    <AnimatedBox m={3} style={props}>
      <StartupCard
        {...item}
        onClick={handleClick( item )}
        onBookmarkClick={handleBookmarkClick( item, index )}
        onMeetClick={handleMeetClick( item )}
        onIgnoreClick={handleIgnoreClick( item, index )}
      />
    </AnimatedBox>
  ) )

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {cards}
      <TrialExpiredDialog
        open={showTrialExpiredModal}
        onClose={() => setShowTrialExpiredModal( false )}
      />
    </Box>
  )
}

CardGrid.propTypes = {
  children: arrayOf( shape( { id: string } ) ).isRequired,
  onBookmarkClick: func,
  onIgnoreClick: func,
}

CardGrid.defaultProps = {
  onBookmarkClick: () => {},
  onIgnoreClick: () => {},
}

export default CardGrid
