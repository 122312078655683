const tourData = [
  {
    pos: [ 'calc(50% - 210px)', '56px' ],
    text: 'This is your matching page where you can browse and interact with your recommended startups, the startups on this page have a 75% and above matching rating. ',
    arrowPos: 'top',
  },
  {
    pos: [ '50px', '300px' ],
    text: 'This is a startup card, it contains a one liner describing the core function of the startup and other important information',
    arrowPos: 'right',
  },
  {
    pos: [ '50px', '200px' ],
    text: 'This is your match making rating this shows how suitable the startup is according to your thesis ',
    arrowPos: 'right',
  }, {
    pos: [ '745px', '170px' ],
    text: "Here are the startup's fundraising round size and equity dilution amount",
    arrowPos: 'left',
  },
  {
    pos: [ '700px', '300px' ],
    text: 'These tags let you know the current stage of the startup and where they’re based/ operating',
    arrowPos: 'left',
  },
  {
    pos: [ '260px', '550px' ],
    text: 'Allows you to bookmark startups you want to come back to',
    arrowPos: 'top',
  }, {
    pos: [ '420px', '550px' ],
    text: 'Allows you to book meetings with startups',
    arrowPos: 'top',
  },
  {
    pos: [ '570px', '550px' ],
    text: "Allows you to hide startups from your matching page that you're not interested in, helping improve your curated deals. You can also remove startups from your ignored list under 'Engagements'",
    arrowPos: 'top',
  }, {
    pos: [ '60px', '260px' ],
    text: 'Shows you the startups you’ve engaged with. You can also view your bookmarked and ignored startups in the tab.',
    arrowPos: 'top',
  },
  {
    pos: [ '60px', '350px' ],
    text: 'The search function allows you to look at all the startups on our platform. ',
    arrowPos: 'top',
  },
]

export default tourData
