import { getCountryName } from '@kaiku/shared'
import { format } from 'date-fns'
import { bool, string } from 'prop-types'
import React from 'react'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import fields from './fields'

const LegalsBlock = ( {
  incorporated,
  incorporatedDate,
  incorporatedLocation,
  seisStatus,
  patents,
} ) => (
  <ContentBlock title="Legals">

    <CaptionField {...fields.incorporated} value={incorporated ? 'Yes' : 'No'} />

    {incorporatedDate && (
      <CaptionField
        {...fields.incorporatedDate}
        value={format( new Date( incorporatedDate ), 'do MMMM yyyy' ).toString()}
      />
    )}

    {incorporatedLocation && (
      <CaptionField
        {...fields.incorporatedLocation}
        value={getCountryName( incorporatedLocation )}
      />
    )}

    <CaptionField {...fields.seisStatus} value={seisStatus} />
    <CaptionField {...fields.patents} value={patents ? 'Yes' : 'No'} />

  </ContentBlock>
)

LegalsBlock.propTypes = {
  incorporated: bool,
  incorporatedDate: string,
  incorporatedLocation: string,
  seisStatus: string,
  patents: bool,
}

LegalsBlock.defaultProps = {
  incorporated: null,
  incorporatedDate: null,
  incorporatedLocation: null,
  seisStatus: null,
  patents: null,
}

export default LegalsBlock
