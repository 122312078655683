import { ArrowBackIos } from '@material-ui/icons'
import { string } from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

import FabIcon from './FabIcon'

const BackButton = ( { className } ) => {
  const history = useHistory()
  return (
    <FabIcon
      className={className}
      onClick={() => history.goBack()}
      variant="extended"
      icon={<ArrowBackIos />}
      label="back"
    />
  )
}

BackButton.propTypes = {
  className: string,
}

BackButton.defaultProps = {
  className: null,
}

export default BackButton
