import { FUNDRAISE_STAGES, FUNDRAISE_TYPES, uuid } from '@kaiku/shared'
import { FormControl, InputAdornment, InputLabel, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { Field, FieldArray, getIn } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import { mapValues } from 'lodash'
import PropTypes, { arrayOf, bool, func, instanceOf, number, oneOfType, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import Accordion from '../../Accordion'
import ContentBlock from '../../ContentBlock'
import ValidatedForm from '../../Formik/Form'

const useStyles = makeStyles( {
  form: {
    marginTop: '2em',
  },
} )

const createSchema = () => yup.object().shape( {
  fundraisers: yup.array().of( yup.object().shape( {
    id: yup.string(),
    amountRaised: yup.number().min( 0 ).required(),
    // Current
    targetRaise: yup
      .number()
      .when( 'date', {
        is: ( date ) => !date,
        then: yup.number().min( yup.ref( 'amountRaised' ), 'Target raise cannot be less than the amount raised' ),
      } ).required(),
    type: yup.string().oneOf( Object.values( FUNDRAISE_TYPES ) ).nullable(),
    stage: yup.string().oneOf( Object.values( FUNDRAISE_STAGES ) ).required(),
    targetEquity: yup.number().min( 0 ).max( 100 ).required(),
    ticketSize: yup.number().min( 0 ).max( yup.ref( 'targetRaise' ), 'Ticket size cannot be more than the target raise' ).required(),
    investors: yup.number().min( 0 ).required(),
    // Past
    //! Doesn't convert incoming timestamp string to date properly if type is date
    date: yup.lazy( ( value ) => ( value instanceof Date ? yup.date() : yup.string().nullable() ) ),
    dilutedEquity: yup.number().min( 0 ).max( 100 ).nullable(),
    company: yup.string().nullable(),
  } ) ),
} )

const initialCurrentValues = {
  id: uuid(),
  amountRaised: 0,
  targetRaise: '',
  targetEquity: '',
  ticketSize: '',
  investors: '',
  type: FUNDRAISE_TYPES.raise,
  stage: '',
}

const initialPreviousValues = {
  id: '',
  amountRaised: '',
  date: null,
  type: FUNDRAISE_TYPES.raise,
  dilutedEquity: '',
  company: '',
}

const getDate = ( dateStr ) => ( dateStr
  ? format( new Date( dateStr ), 'MMM yyyy' )
  : 'Previous Fundraise' )

const Title = ( { date, live, error } ) => (
  <Typography color={error ? 'error' : 'inherit'} variant="subtitle1">
    {live ? 'Current Fundraise' : getDate( date )}
  </Typography>
)

Title.propTypes = {
  date: oneOfType( [ string, instanceOf( Date ) ] ),
  error: bool,
  live: bool,
}

Title.defaultProps = {
  date: null,
  error: false,
  live: false,
}

const CurrentFundraise = ( { index } ) => (
  <>

    <FormControl>
      <InputLabel>Fundraise Stage</InputLabel>

      <Field
        component={Select}
        name={`fundraisers[${index}].stage`}
      >
        {Object.values( FUNDRAISE_STAGES ).map( ( value ) => (
          <MenuItem key={value} value={value}>{value}</MenuItem>
        ) )}
      </Field>
    </FormControl>

    <Field
      component={TextField}
      name={`fundraisers[${index}].targetRaise`}
      label="Target Raise"
      type="number"
      inputProps={{ min: 0, step: 1000 }}
      InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
    />

    <Field
      component={TextField}
      name={`fundraisers[${index}].amountRaised`}
      label="Amount Raised"
      type="number"
      inputProps={{ min: 0, step: 1000 }}
      InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
    />

    <Field
      component={TextField}
      name={`fundraisers[${index}].investors`}
      label="Current Number of Investors"
      type="number"
      inputProps={{ min: 0 }}
    />

    <Field
      component={TextField}
      name={`fundraisers[${index}].targetEquity`}
      label="Target Equity %"
      type="number"
      inputProps={{ min: 0, max: 100 }}
    />

    <Field
      component={TextField}
      name={`fundraisers[${index}].ticketSize`}
      label="Minimum Ticket Size"
      type="number"
      inputProps={{ min: 0, step: 1000 }}
      InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
    />

  </>
)

CurrentFundraise.propTypes = {
  index: number.isRequired,
}

const PastFundraise = ( { type, index } ) => (
  <>

    <Field
      component={DatePicker}
      name={`fundraisers[${index}].date`}
      label="Month completed"
      format="MMMM yyyy"
      openTo="year"
      disableFuture
      autoOk
      views={[ 'year', 'month' ]}
    />

    <Field
      component={TextField}
      name={`fundraisers[${index}].amountRaised`}
      label="Amount Raised"
      type="number"
      inputProps={{ min: 0, step: 1000 }}
      InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
    />

    <FormControl>
      <InputLabel>Type of Funding</InputLabel>

      <Field
        component={Select}
        name={`fundraisers[${index}].type`}
      >
        {Object.values( FUNDRAISE_TYPES ).map( ( value ) => (
          <MenuItem key={value} value={value}>{value}</MenuItem>
        ) )}
      </Field>
    </FormControl>

    {type === FUNDRAISE_TYPES.raise && (
      <Field
        component={TextField}
        name={`fundraisers[${index}].dilutedEquity`}
        label="Equity % Diluted"
        type="number"
        inputProps={{ min: 0, max: 100 }}
      />
    )}

    {[ FUNDRAISE_TYPES.award, FUNDRAISE_TYPES.grant ].includes( type ) && (
      <Field
        component={TextField}
        name={`fundraisers[${index}].company`}
        label="Awarding Company"
      />
    )}

  </>
)

PastFundraise.propTypes = {
  index: number.isRequired,
  type: string.isRequired,
}

const EditFundraiseBlock = ( { fundraisers, withSubmit, onSubmit } ) => {
  const [ current, ...previous ] = fundraisers

  const initialValues = {
    fundraisers: [
      mapValues( initialCurrentValues, ( initial, key ) => ( current || {} )[ key ] || initial ),
      ...previous.map(
        ( ( fundraise ) => mapValues(
          initialPreviousValues,
          ( initialValue, key ) => fundraise[ key ] || initialValue,
        ) ),
      ),
    ],
  }

  const classes = useStyles()
  const schema = createSchema()

  return (
    <ContentBlock title="Fundraise">
      <ValidatedForm
        className={classes.form}
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <FieldArray name="fundraisers">
            {( { form: { values: { fundraisers }, errors }, push, remove } ) => (
              <Accordion
                onAdd={() => push( { ...initialPreviousValues, id: uuid() } )}
                onDelete={remove}
                items={fundraisers}
                renderTitle={( props, index ) => (
                  <Title
                    {...props}
                    index={index}
                    live={index === 0}
                    error={!!Object.keys( getIn( errors, [ 'fundraisers', index ], {} ) ).length}
                  />
                )}
                renderDetails={( item, index ) => ( index === 0
                  ? <CurrentFundraise index={index} />
                  : <PastFundraise {...item} index={index} /> )}
              />

            )}
          </FieldArray>

        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

EditFundraiseBlock.propTypes = {
  fundraisers: arrayOf( PropTypes.objectOf( PropTypes.object ) ),
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
}

EditFundraiseBlock.defaultProps = {
  fundraisers: [],
}

export default EditFundraiseBlock
