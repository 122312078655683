import { fetcher } from '@kaiku/shared'

const fetchWithAuth = ( token ) => ( url, { headers, ...options } = {}, ...rest ) => fetcher( url, {
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  ...options,
}, ...rest )

export default fetchWithAuth
