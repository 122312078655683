/* eslint-disable max-len */
import { Checkbox, useFetchAuth, ValidatedForm } from '@kaiku/ui'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { Field } from 'formik'
import { node } from 'prop-types'
import React from 'react'
import useSWR from 'swr'
import { bool, object } from 'yup'

import { getUserStartupUrl } from '../../api'

const useStyles = makeStyles( {
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1em',
  },
} )

const initialValues = {
  riskWarningDisclaimer: false,
}

const createSchema = () => object().shape( {
  riskWarningDisclaimer: bool().test( 'risk-warning-disclaimer', 'You must accept this to continue', ( v ) => !!v ),
} )

const SubTitle = ( { children } ) => (
  <DialogContentText>
    <Typography variant="h2" style={{ fontSize: '1.2rem' }}>
      {children}
    </Typography>
  </DialogContentText>
)

SubTitle.propTypes = {
  children: node.isRequired,
}

const ItalicBoldSubtitle = ( { children } ) => (
  <DialogContentText>
    <Typography variant="h4" style={{ fontSize: '1.1rem', fontWeight: 'bold', fontStyle: 'italic' }}>
      {children}
    </Typography>
  </DialogContentText>
)

ItalicBoldSubtitle.propTypes = {
  children: node.isRequired,
}

const Declaration = () => {
  const { data: { declarationAcceptedAt, ...data } = {}, mutate } = useSWR( getUserStartupUrl() )

  const fetch = useFetchAuth()

  const onAcceptDeclaration = () => {
    const declarationAcceptedAt = new Date()

    fetch( getUserStartupUrl(), { method: 'PATCH', body: { declarationAcceptedAt } } )
    mutate( { ...data, declarationAcceptedAt }, false )
  }

  const classes = useStyles()

  return (
    <Dialog open={!declarationAcceptedAt} maxWidth="md" fullWidth scroll="paper">

      <DialogTitle align="center">Terms of Use</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          Welcome to Kaiku. Before you start enjoying our platform and services
          we have to set out a few ground rules (the boring bit). We have done
          our best to make them as easy to understand as possible but if you have
          any questions at all, feel free to get in contact at
          {' '}
          <a href="mailto:hello@kaiku.co">hello@kaiku.co</a>
          .
        </DialogContentText>
        <div style={{ maxHeight: '450px', overflowY: 'scroll', padding: '1em', borderWidth: '1px', borderStyle: 'solid' }}>
          <SubTitle>
            WHO WE ARE
          </SubTitle>

          <DialogContentText>
            We are Kaiku Ltd, trading as Kaiku (and formerly AGORA), a company incorporated and registered in England and Wales with company number 11709697.
            {' '}
            <strong>Our registered office is at is 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, United Kingdom (Kaiku, we, our, us)</strong>
            .
          </DialogContentText>

          <SubTitle>
            THESE TERMS
          </SubTitle>

          <DialogContentText>
            This section tells you what the purpose of these terms are.
          </DialogContentText>
          <DialogContentText>
            These terms and conditions (together with the documents referred to in them) (the
            {' '}
            <strong>Terms</strong>
            ) govern your relationship with us when you access our website located at kaiku.co (the
            {' '}
            <strong>Website</strong>
            ) and, access our interactive platform via the Website or a third party platform (
            <strong>Web App</strong>
            ). We refer to our Website and Web App together as the
            {' '}
            <strong>Platform</strong>
            , and references to the Platform include either or both of them.
          </DialogContentText>
          <DialogContentText>
            We recommend that you read these Terms carefully before you start to use any part of our Platform, this is because once you start using our Platform in any way you are taken to have understood and accepted these Terms. They will form a binding agreement between you and us so if you have any questions, please let us know.
          </DialogContentText>

          <SubTitle>
            OTHER APPLICABLE TERMS
          </SubTitle>

          <DialogContentText>
            These terms also include our Privacy Policy [https://www.kaiku.co/privacy-policy] (
            <strong>the Privacy Policy</strong>
            ). We encourage you to give the Privacy Policy a read as it sets out the terms on which we process (collect, use, share, etc.) any personal data we collect from you or that you provide to us.
          </DialogContentText>

          <SubTitle>
            CHANGES TO THESE TERMS
          </SubTitle>

          <DialogContentText>
            We may from time to time amend these Terms to ensure that we remain compliant with relevant laws and regulations or to keep up to date with improvements or changes we might make to the services and experiences we can offer to you via the Platform.
          </DialogContentText>
          <DialogContentText>
            We don&apos;t expect you to check these Terms every time you use our Platform but equally we don&apos;t want to bombard you with every little update. We just ask that you check this page from time to time to take notice of any changes we have made. This is important because by continuing to use the Website and/or the App after changes are made, you are accepting those changes and will be bound by them.
          </DialogContentText>

          <SubTitle>
            THE PLATFORM
          </SubTitle>

          <DialogContentText>
            We offer the Platform mainly in order to:
          </DialogContentText>

          <DialogContentText component="ul">
            <li>
              tell you about the services we offer to startups, investors and other commercial partners (
              <strong>Services</strong>
              ) and access those Services;
            </li>

            <li>in particular, as a startup to be introduced to and be matched with investors, and as an investor to be introduced to and matched with startups to invest it;</li>

            <li>allow you to sign up and create an account with us and access those Services, where applicable once you have paid the relevant fees as outlined on the Platform;</li>

            <li>provide you with a method to contact us;</li>

            <li>
              consume and engage with
              {' '}
              <strong>Content</strong>
              {' '}
              (which means any audio, video, text, images, trademarks, logos or other content which may be made available to you by us and may sometimes include content which is owned or controlled by third parties which we are permitted to make available to you);
            </li>

            <li>search for and connect with potential investors, partners and investee companies (depending on the type of user you are);</li>

            <li>sign up for events, including digital events such as webinars and courses, and in-person events such as organised meetups and group learning sessions.</li>

            <li>receive notifications via push notifications, emails, SMS and when using the Platform. These messages may include information about your account, our Content, the Kaiku community, and general product information;</li>

            <li>provide you with a platform to connect with other people;</li>

            <li>click on links to our social media channels, such as on Facebook, LinkedIn and Instagram;</li>

            <li>access customer support from our support team; and</li>

            <li>access our legal and data protection policies.</li>
          </DialogContentText>

          <DialogContentText>
            Visitors to the Website may also sign up to receive newsletters from us. We will require only an email address for this purpose. Please refer to our Privacy Policy [https://www.kaiku.co/privacy-policy] for more information on how we process your personal data.
          </DialogContentText>
          <DialogContentText>
            The list above is not necessarily a complete list of the functions of the Platform and we may decide to offer additional Platform functions, or stop providing any Platform functions, at any time.
          </DialogContentText>

          <SubTitle>
            MEMBER REGISTRATION
          </SubTitle>

          <DialogContentText>

            This section provides information about how you can sign up to use the Platform, whether as a startup or an investor.
          </DialogContentText>
          <DialogContentText>
            To use the Platform and access the Web App as a member (Member), you may have to register to create an account and provide a series of information based on whether you are a startup seeking investment, or an investor reviewing possible future investments. You will be required to provide the relevant information when prompted, which will be processed in accordance with our privacy policy. In particular, we require accurate information so that we can use it to accurately match startups and their potential investors, based on criteria which we set.
          </DialogContentText>
          <DialogContentText>
            If you do not provide the information or provide untrue information, we will not be able to provide you with our services to the best of our capabilities. We reserve the right to temporarily or permanently suspend any user who we become aware has provided us with untruthful or incomplete information.
          </DialogContentText>
          <DialogContentText>
            Access to certain functions may only be available to paying Members.
          </DialogContentText>
          <DialogContentText>
            You are responsible for looking after your password and account information. This means that you are responsible for all activities that happen under your password or account and for any access to or use of Platform by you or any person using your password, even if that access or use has not been authorised by you.
          </DialogContentText>
          <DialogContentText>
            Please immediately notify us of any unauthorised use of your password or account or any other breach of security relating to the Platform. We are not responsible for any loss or damage caused by the disclosure of your login details to someone else.
          </DialogContentText>
          <DialogContentText>
            You are responsible for ensuring that the information we hold about you is up to date. Please amend your details as appropriate from time to time or email
            {' '}
            <a href="mailto:hello@kaiku.co">hello@kaiku.co</a>
            {' '}
            to notify us of any changes.
          </DialogContentText>

          <SubTitle>
            OUR ROLE IN CONNECTING STARTUPS, INVESTORS AND ADVISORS
          </SubTitle>

          <DialogContentText>
            A core part of our Platform exists to connect startups with investors and service-providers they may need (Connections). Part of this may be performed manually by our time, and part of this may be facilitated through our Platform and matchmaking technology. Once a Connection is made, either directly on the Platform, or manually by a member of the Kaiku team, any contractual or other relationship then initiated between a startup, an investor and/or other service provider is an entirely separate relationship which Kaiku is not a party to (unless otherwise agreed by Kaiku in writing). This includes where we charge a fee for making any such Connection. Our involvement in making Connections is limited to our obligations as set out in these Terms and any separate engagement letter, and anything beyond these obligations will be beyond our reasonable control. Kaiku is not a party to any startup-investor, or startup-supplier contract (or similar), regardless of whether such contract is explicit or implicit, including where we are referred to as an intermediary in any such contract.
          </DialogContentText>
          <DialogContentText>
            Startups, investors and service providers using our Platform acknowledge that Kaiku is an intermediary in arrangements between them but cannot guarantee the performance by either or any party of their respective obligations under a separate agreement. Kaiku holds no liability beyond what is stated in these Terms or other written agreements signed by an authorised signatory of Kaiku. Any disputes under third party contracts must be handled independently and if needed with competent authorities. This includes where any relevant amount has been discounted where a user has used a Kaiku discount voucher to access a third party’s services by virtue of a Kaiku separate commercial relationship with that third party.
          </DialogContentText>
          <DialogContentText>
            <strong style={{ textDecoration: 'underline' }}>
              You understand that Kaiku is not required to provide you with any minimum time commitment or service level, and Kaiku is not governed or regulated by the Financial Conduct Authority. As such Kaiku does not provide financial advice or investment promotional services on behalf of startups, investors or otherwise. Equally, we encourage users to please inform us as soon as possible should any incident occur or reason for complaint arise in respect of a particular startup, investor or service provider, so that we can take appropriate action on our Platform. We reserve full discretion to take such action as we deem appropriate in circumstances where we receive complaints, including to remove certain individuals or businesses from the Platform without notice.
            </strong>
          </DialogContentText>

          <SubTitle>
            PLATFORM FEES AND PAYMENT
          </SubTitle>

          <DialogContentText>
            This section describes where fees may be payable.
          </DialogContentText>
          <ItalicBoldSubtitle>
            Platform and Service Fees
          </ItalicBoldSubtitle>
          <DialogContentText>

            Access to some of our services and Platform functions can be obtained by Members on payment of the relevant one-off or subscription fees (the
            {' '}
            <strong>Fees</strong>
            {' '}
            and
            {' '}
            <strong>Subscription Fees</strong>
            ).
          </DialogContentText>
          <DialogContentText>
            Subscription Fees are payable on a monthly or annual basis, depending on the type of Membership or services you choose, and Fees for one-off services are payable on a pay as you go basis as applicable. In all cases prices will appear on the relevant page(s) of the Website or Web App before payment is confirmed.
          </DialogContentText>
          <DialogContentText>
            We may change our prices at any time by posting new prices on the Platform, but such changes will not affect you during the period of your existing subscription as at the date of any such change.
          </DialogContentText>
          <DialogContentText>
            We accept cards (via Stripe) and PayPal for payment. By paying using your credit or debit card you confirm that the card is yours and that there are sufficient funds or credit available to cover the relevant Fees or Subscription Fees. Alternatively, we may invoice you for any amounts agreed with you and due to us whether under these Terms or a separate binding contract.
          </DialogContentText>

          <ItalicBoldSubtitle>
            Commission Terms
          </ItalicBoldSubtitle>

          <SubTitle>
            PERIOD OF ENGAGEMENT
          </SubTitle>

          <DialogContentText component="ol">

            <li>These Terms apply from the date on which you sign up with Kaiku and continue for an initial term of 12 months, which will auto-renew for successive 12 month terms unless either party provides 30 days written notice to the other not to renew (the “Term”), unless otherwise terminated by Kaiku.</li>

            <li>You agree that Kaiku is not required to provide you with any minimum time commitment or service level, and Kaiku is not governed or regulated by the Financial Conduct Authority. As such Kaiku does not provide financial advice or investment promotional services on behalf of start-ups, investors or otherwise.</li>

          </DialogContentText>

          <SubTitle>
            FEES
          </SubTitle>

          <DialogContentText component="ol" start={3}>

            <li>
              You agree to pay Kaiku a 5% commission on all of the first sums or amounts received by you or any of your affiliates, subsidiaries or group companies as a direct or indirect result
              of an Introduction by Kaiku during the Introduction Period[ along with a nominal fee of £1].
            </li>

            <li>
              Any other charges relating to structuring of the Investment through Kaiku or a third-party provider are for the Client to pay separately and directly with Kaiku or such third-party
              provider.
            </li>

            <li>
              You agree to pay Kaiku commission on all Secondary Investment Income received or substantively agreed from or with an Investor at the rate of (a) 5% of the Secondary
              Investment Income received during the 12 months period commencing with the receipt by the Client of the first Primary Investment Income, and (b) 2.5% of the Secondary
              Investment income received thereafter during the Follow-On Period.
            </li>

            <li>
              All sums payable by you under the Terms (the “Fees”) shall be payable in GBP (unless we indicate otherwise) within 14 days of your receipt of the relevant funds. If an Investment
              payable for in instalments, Fees shall be payable in the same proportions as you receive the applicable investments. So long as the Investment is completed within the Introduction
              Period, Kaiku shall receive its proportion of the instalments payments set forth in the Relevant Contract (or the version of it that was executed during the Introduction Period).
            </li>

            <li>Kaiku may make Introductions to Investors so that they can then seek out and bring together other potential Investors for the purpose of making an Investment either together or independently. These are known as Secondary Investors. These Terms will apply to any Investment Income obtained from Secondary Investors, as well as the original Investors. For example, Kaiku may Introduce you to a potential Investor (such as an independent investor or corporate finance institution) which brings in or refers other investors to participate in the transaction. In this case, Income received from the original Investor and/or the Secondary Investors will be covered by the Terms.</li>

            <li>If Kaiku makes an Introduction during the Term which results in a form of non-cash investment another form of valuable opportunity, then we will agree with you the terms of any reward or commission payable in good faith between us on a case by case basis, prior to concluding the relevant transaction.</li>

            <li>All sums payable by you shall be paid gross, free and clear of any rights of counterclaim or set-off and without any deduction or withholding, unless the deduction or withholding is required by law. If any deduction or withholding is required by law, then you will pay such additional amount as shall be required to ensure that the net amount received and retained (free of any liability) by Kaiku equals the full amount which would have been received by it had no such deduction or withholding been required.</li>

            <li>We will provide you with estimates of anticipated fees, disbursements and charges for any services. These estimates are only indicative of the amounts you can expect to be charged. Estimates are not binding and may be subject to change.</li>

            <li>Any amounts quoted or payable by you does not include VAT, any VAT required will be charged to you in addition to the payment of Kaiku’s fees</li>

          </DialogContentText>

          <SubTitle>
            REFERRALS TO KAIKU
          </SubTitle>

          <DialogContentText>

            In addition to Kaiku making Connections (as defined in these Terms), we also provide a platform for certain third party’s to refer potential deals and transactions to us, which we may then refer to third parties (including our Members). All parties signing up to these Terms agree and understand that we apply a maximum 20% fee cap on any investment matters referred to us, and a maximum 15% fee cap on any non-investment matters referred to us, and that no third party shall be entitled to any payment of more than £10,000 (inc. VAT) as a result of making any referral to us, regardless of the circumstances. All such fee caps are calculated as a percentage of amounts charged by Kaiku to the applicable third party only, and shall only apply (i) to the first transaction concluded with as a result of the introduction, (ii) if the relevant transaction occurs within 12 months of the introduction being made to us, and (iii) to net amounts after deducing all applicable sales taxes and directly attributable overheads. You must have signed up via
            {' '}
            <a href="https://www.kaiku.co/referral/">https://www.kaiku.co/referral/</a>
            {' '}
            to be eligible to receive commission on referrals to us and confirmed the terms of any such referral commissions with us in writing in addition to having read this Referrals to Kaiku section.
          </DialogContentText>

          <SubTitle>
            DEFINITIONS
          </SubTitle>

          <DialogContentText>

            “Follow-On Period” means three years from the closing date of an Investment, made by an Investor (or Investors), under which commission is payable to Kaiku in accordance with this Agreement.
          </DialogContentText>
          <DialogContentText>
            “Income” means sums paid to the Client as an Investment whether directly or indirectly (including any sums payable to an affiliate company or other member of the Client’s Group in
            accordance with the terms of a Relevant Contract);
          </DialogContentText>
          <DialogContentText>
            “Introduction” means connecting the Client (or any member of the Client’s Group) to a Secondary Investor or their Representative either: (i) by access to the Platform; (ii) by
            passing on the potential Investor’s or their Representative’s contact details to the Client; (iii) by recommending the Client to make an application to a Secondary Investor via the
            Secondary Investor’s web-site; or (iv) in writing (including email), by phone or in person (and “Introduce” and “Introduces” shall be interpreted accordingly);
          </DialogContentText>
          <DialogContentText>
            “Introduction Date” means the date during the Term on which Kaiku first Introduces the Investor to the Client;
          </DialogContentText>
          <DialogContentText>
            “Introduction Period” means three years from the Introduction Date, irrespective of whether such period ends before or after the date of termination or expiry of this Agreement;
          </DialogContentText>
          <DialogContentText>
            “Investment” means any equity or debt injection of capital into the Client or any member of its Group or any of its affiliates or clients. Where the terms of an investment contemplate
            amounts being paid in tranches (including on different terms, for example where there is an advanced subscription), the Investment will include all tranches for the purposes of this
            Agreement;
          </DialogContentText>
          <DialogContentText>
            “Primary Investment Income” means the first sums provided to the Client as an Investment whether directly or indirectly (including any sums payable to an affiliate company or other
            member of the Client’s Group) following an Introduction, in accordance with the terms of a Relevant Contract;
          </DialogContentText>
          <DialogContentText>
            “Relevant Contract” means a binding contract between the Client and an Investor (along with any other parties who may be party to such an agreement) in respect of an Investment;
          </DialogContentText>
          <DialogContentText>
            “Secondary Investment Income” means any sums provided to the Client as an Investment, following receipt of any Primary Investment Income, whether directly or indirectly (including any sums payable to an affiliate company or other member of the Client’s Group), in accordance with the terms of a Relevant Contract.
          </DialogContentText>

          <SubTitle>
            PLATFORM LICENCE RESTRICTIONS
          </SubTitle>

          <DialogContentText>

            This section tells you what you&apos;re not allowed to do with the Platform or Content.
          </DialogContentText>
          <DialogContentText>
            Except as expressly allowed in these Terms, you may not:
          </DialogContentText>
          <DialogContentText component="ul">
            <li>copy the Platform or Content (except where such copying is expressly permitted by us);</li>

            <li>transfer the Platform or Content to anyone else;</li>

            <li>sub-license or otherwise make the Platform or Content available in whole or in part (and whether in object or source code form) to any person;</li>

            <li>make any alterations to, or modifications of, the Platform or Content; or</li>

            <li>disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the Platform or Content or attempt to do so,</li>
          </DialogContentText>
          <DialogContentText>
            (together the
            {' '}
            <strong>Platform Licence Restrictions</strong>
            ).
          </DialogContentText>

          <SubTitle>
            ACCEPTABLE USE RESTRICTIONS
          </SubTitle>

          <DialogContentText>
            You may use the Platform only for personal and lawful purposes. In particular, but without limitation, you agree not to:
          </DialogContentText>
          <DialogContentText component="ul">
            <li>use any part of the Platform in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</li>

            <li>use, share, or otherwise exploit any part of the Platform for any commercial, business, or monetised purpose whatsoever;</li>

            <li>reproduce, duplicate, copy, share, or re-sell any part of the Platform in contravention of these Terms;</li>

            <li>use the Platform in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;</li>

            <li>transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of the Platform; or</li>

            <li>access without authority, interfere with, damage or disrupt (a) any part of the Platform; (b) any equipment or network on which any part of the Platform is stored; (c) any software used in the provision of the Platform; or (d) any equipment, network or software owned or used by any third party,</li>
          </DialogContentText>
          <DialogContentText>
            (together the
            {' '}
            <strong>Acceptable Use Restrictions</strong>
            ).
          </DialogContentText>

          <SubTitle>
            TERMINATION OF YOUR RIGHTS
          </SubTitle>

          <DialogContentText>
            We may end your rights under these Terms immediately and without notice if:
          </DialogContentText>
          <DialogContentText component="ul">
            <li>you have breached any of the Platform Licence Restrictions or the Acceptable Use Restrictions;</li>

            <li>we believe in our sole discretion that your use of the Platform is unsuitable in any way; or</li>

            <li>you are otherwise in breach of these Terms.</li>
          </DialogContentText>
          <DialogContentText>
            If we end your rights under these Terms:
          </DialogContentText>
          <DialogContentText component="ul">
            <li>you must immediately stop all activities authorised by these Terms, including your access to all parts of the Platform; and</li>

            <li>you must immediately delete or remove any part of the Platform from all devices then in your possession, custody or control and, if required, confirm to us that you have done so.</li>
          </DialogContentText>
          <DialogContentText>
            You agree that You are responsible for and will indemnify us and hold us harmless for all losses, expenses, damages and costs, including legal fees reasonably incurred, resulting from any violation of these Terms by you, or any wrongful or negligent activity related to your use of our Platform and Services, whether or not we elect to terminate your account with us.
          </DialogContentText>
          <DialogContentText>
            Unauthorised use of this Platform may give rise to a claim for damages and/or be a criminal offence, and you agree that we may be entitled to seek injunctive or other equitable relief if we believe damages may not be a sufficient remedy.
          </DialogContentText>

          <SubTitle>
            INTELLECTUAL PROPERTY RIGHTS AND USER CONTENT
          </SubTitle>

          <DialogContentText>

            This section sets out who owns what in terms of the Platform, Content and User Content (as defined below). It also sets out how we will treat any Content that you provide to us and what your obligations are in relation to that Content.
          </DialogContentText>
          <DialogContentText>
            You agree that:
          </DialogContentText>
          <DialogContentText component="ul">
            <li>the Platform, and all material published on, in, or via all parts of it (including but not limited to the Content) is owned and controlled by or licensed to us;</li>

            <li>
              in respect of content that you create, upload, send or post to us (including but not limited to information about you or your startup, or your other commercial business or venture)  (the
              {' '}
              <strong>User Content</strong>
              ) that:
            </li>
            <DialogContentText component="ul">
              <li>you retain the ownership rights in the User Content;</li>

              <li>you grant us a perpetual, royalty free, non-exclusive licence (including the right to grant sub-licences) to use, copy, distribute, reproduce and publish any and all User Content (including, without limitation, on our sites, on other websites, on physical products and in promotional and/or marketing material developed in each case whether developed by us or on our behalf);</li>

              <li>we may disclose your identity to any third party who claims that the User Content posted or uploaded by you defames them, constitutes a breach of their intellectual property rights or breaches their right to privacy;</li>

              <li>we have the right to remove any User Content or posting you make on our Platform (where we permit this), if in our opinion, such material does not comply with our User Content standards;</li>

              <li>you make your User Content available to us in the manner envisaged by these Terms without payment or other compensation to you;</li>

              <li>we may, at our sole discretion, access, publish, delete, edit or remove and User Content without permission or notification to you.</li>
            </DialogContentText>
            You represent and warrant on an ongoing basis that you:
          </DialogContentText>
          <DialogContentText component="ul">
            <li>are the owner or authorised licensee of all User Content;</li>

            <li>have all necessary rights (including, but not limited to, all intellectual property rights) and consents required to  publish the User Content and to grant us the rights in the User Content as set out in these Terms;</li>

            <li>will not upload or post User Content that violates applicable law or regulations; and</li>

            <li>have all required permissions and consents from any third party whose personal information is included in any User Content.</li>
          </DialogContentText>

          <SubTitle>
            INTERACTION AND REPORTING
          </SubTitle>

          <DialogContentText>
            From time to time we may make available interactive services such as webinars or forums on the Platform. Where we do so we will provide clear information as to whether it is moderated or not.
          </DialogContentText>
          <DialogContentText>
            Please note that we may partner with third parties to provide educational or other information to our users and community generally and you understand: (i) those third parties are independent from Kaiku and we are not liable or responsible for the information they provide, (ii) we cannot moderate webinars, forums and educational sessions in real time and so things that are said or posted (except by us directly) are outside of our reasonable control, and (ii) all such educational content or other information is for information purposes only and at no point may be considered formally engaged and actionable advice (whether financial, tax, legal or otherwise). Should you wish to obtain actionable advice (i.e. advice which is custom to you and which you could legally enforce against the provider of it, such as for negligence), then you will need to enter formal engagement terms with the relevant third party.
          </DialogContentText>
          <DialogContentText>
            If you see any Content or User Content which appears on our Platform which you find inappropriate then you can notify us and request that such content is removed by emailing hello@kaiku.co. Content that does not comply with our standards from time to time will be removed as soon as reasonably practicable following notification.
          </DialogContentText>
          <DialogContentText />

          <SubTitle>
            AVAILABILITY OF THE PLATFORM
          </SubTitle>

          <DialogContentText>
            This section explains that we try and keep the Website and Web App up and running at all times, but cannot promise that this will always be the case.
          </DialogContentText>
          <DialogContentText>
            The Website and Web App are provided on an &quot;as is&quot; basis. We make no representations, warranties or guarantees of any kind regarding the availability or operation of the Website or the Web App, or that the Website or the Web App will be secure, uninterrupted or free of defects.
          </DialogContentText>
          <DialogContentText>
            Your access to the Website and/or the Web App may be suspended or restricted occasionally to allow for maintenance, repairs, upgrades, or the introduction of new functions or services. We will not be liable to you if for any reason the Website and/or the App is unavailable at any time or for any period.
          </DialogContentText>
          <DialogContentText />

          <SubTitle>
            CANCELLING ORDERS AND MEMBERSHIP
          </SubTitle>

          <DialogContentText>
            If you paid a Fee for a one-off purchase of Services, that fee is non-refundable, unless we agree with you that we are unable to provide the relevant service. Memberships are available for commercial business purposes and as such 14-day cooling off periods are not applicable to our Services.
          </DialogContentText>
          <DialogContentText>
            If you wish to cancel a Membership, then you can do so and the Membership payment will not auto-renew, and your Membership will continue until the end of the relevant period. Afterwards, you will revert back to being a free user.
          </DialogContentText>

          <SubTitle>
            LINKS AND COOKIES
          </SubTitle>

          <DialogContentText>
            We may link to other third party websites from which third party services can be obtained (such as those of startups seeking investment, professional service providers, event booking sites). Whilst we reasonably believe that these are reputable sources, you acknowledge that these other websites are independent from us and we make no representations or warranties as to the legitimacy, accuracy or quality of such third party services, and we do not accept any responsibility for their content, safety, practices or privacy policies. Essentially, we will do our best to ensure they are safe but you access any third party at your own risk.
          </DialogContentText>
          <DialogContentText>
            Our Platform uses cookies to monitor browsing preferences. If you do allow cookies to be used, information may be stored by us for use by third parties in accordance with our Privacy Policy.
          </DialogContentText>

          <SubTitle>
            COMPUTER VIRUSES
          </SubTitle>

          <DialogContentText>
            We take steps to ensure that no part of the Platform will contain or spread any viruses or other malicious code but this section explains how you can best protect your devices.
          </DialogContentText>
          <DialogContentText>
            We recommend that you ensure that equipment used to access the Platform run up-to-date anti-virus software as a precaution, and you are advised to virus-check any materials downloaded from the Platform and regularly check for the presence of viruses and other malicious code.
          </DialogContentText>
          <DialogContentText>
            To the full extent permitted by law we exclude liability for damage or loss of any kind caused by viruses or other harmful components originating or contracted from the Website and/or the App.
          </DialogContentText>

          <SubTitle>
            NO RELIANCE ON INFORMATION
          </SubTitle>

          <DialogContentText>
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on our Platform for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. Any action you take based on such information is taken at your own risk.
          </DialogContentText>
          <DialogContentText>
            It shall be your own responsibility to ensure that any products, services or information available through this Platform meet your specific requirements.
          </DialogContentText>

          <SubTitle>
            IF THERE IS A PROBLEM WITH THE PLATFORM
          </SubTitle>

          <DialogContentText>
            If you have any questions or complaints about the Website, Web App, Content or any Services, please contact us. You can contact us at
            {' '}
            <a href="mailto:hello@kaiku.co">hello@kaiku.co</a>
            .
          </DialogContentText>

          <SubTitle>
            OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
          </SubTitle>

          <DialogContentText>
            We do not exclude or limit our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
          </DialogContentText>
          <DialogContentText>
            We provide the Platform for private use only. You agree not to use Platform for any commercial or business purposes beyond the scope of these Terms, and we will have no liability to you for any direct loss, loss of profit, loss of revenue, loss of business or contracts, loss of anticipated savings, loss of data, loss of goodwill, business interruption, wasted management or office time, loss of business opportunity or any other loss or damage however arising whether through tort (including negligence), breach of contract or otherwise, whether direct or indirect and including where such loss or damage is foreseeable. To the fullest extent permitted by law we exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or equity.
          </DialogContentText>
          <DialogContentText>
            We are not responsible for the acts or omissions of any user or third party.
          </DialogContentText>
          <DialogContentText>
            Except as set out in these Terms, you accept and agree we will not be liable for any harmful effect that accessing the Platform and/or any Content may have on you, and you agree that you access Platform at your own risk.
          </DialogContentText>

          <DialogContentText style={{ fontStyle: 'italic' }}>
            Technical Matters
          </DialogContentText>

          <DialogContentText>
            If any Content delivered by us using the Platform damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow download, installation, or usage instructions or to have in place the minimum system requirements and anti-virus software advised by us.
          </DialogContentText>

          <DialogContentText style={{ fontStyle: 'italic' }}>
            Our Platform and Services
          </DialogContentText>

          <DialogContentText>
            Each user understands that even if Kaiku has expertise in startup and investment matters, that Kaiku is not a Financial Conduct Authority (or equivalent) regulated entity and that any Content, education, connecting services or other assistance provided is not regulated financial advice and as such users will never under any circumstances have a right to make a claim against Kaiku relating to financial advice (whether as an investor or startup or other user).
          </DialogContentText>
          <DialogContentText>
            Equally, each user understands that no part of the Platform or Services will ever constitute any other form of professional advice, such as investment advice or legal advice or tax advice or accounting advice. For these reasons, all professional liability in this regard is expressly disclaimed to the greatest extent permissible by law. Kaiku will never be considered responsible or liable for a user’s financial position, decision-making, losses, or other actions, and all users understand the scope and limitations of Kaiku’s role and purpose.
          </DialogContentText>
          <DialogContentText>
            We are not intended to be a substitute for legal, tax, accounting, regulatory or financial advice & guidance that can be provided by your own solicitor, lawyer, accountant, tax or regulatory counsel and/or financial adviser. We are not licenced, authorised or insured to provide such advice and as such we do not do so.
          </DialogContentText>
          <DialogContentText>
            Where we offer guidance and support, including in exchange for Fees, you are taken to have understood and acknowledged the scope of our Services and our status as a non-regulated provider.
          </DialogContentText>

          <SubTitle>
            COMMUNICATIONS BETWEEN US
          </SubTitle>

          <DialogContentText>
            If you wish to contact us for any reason, you can do so by e-mail at
            {' '}
            <a href="mailto:hello@kaiku.co">hello@kaiku.co</a>
            .  We will remain in contact with you in accordance with our privacy policy.
          </DialogContentText>

          <SubTitle>
            OTHER IMPORTANT TERMS
          </SubTitle>

          <DialogContentText>
            We may transfer our rights and obligations under these Terms to another organisation, but this will not affect your rights or our obligations under these Terms.
          </DialogContentText>
          <DialogContentText>
            If we do not enforce our rights against you, or if we delay in doing so, that does not mean that we have waived our rights against you, and it does not mean that you are relieved of your obligations under these Terms. If we do waive a breach by you, we will only do so in writing, and that will not mean that we will automatically waive any later breach by you.
          </DialogContentText>
          <DialogContentText>
            Each of the terms and conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining terms and conditions will remain in full force and effect.
          </DialogContentText>
          <DialogContentText>
            These Terms (including all incorporated terms such as our Privacy Policy) form the entire agreement between us and supersedes all prior agreement between us.
          </DialogContentText>
          <DialogContentText>
            These Terms are governed by English law and the courts of England and Wales have non-exclusive jurisdiction.
          </DialogContentText>
          <br />
          <DialogContentText>
            Last updated on 27th November 2020.
          </DialogContentText>

        </div>

      </DialogContent>

      <DialogActions>
        <ValidatedForm
          initialValues={initialValues}
          schema={createSchema()}
          onSubmit={onAcceptDeclaration}
        >
          {( { submitForm } ) => (
            <Box>
              <Field
                component={Checkbox}
                name="riskWarningDisclaimer"
                label={(
                  <span>

                    I hereby declare that I have read, understood, and agree to be bound by Kaiku’s
                    {' '}
                    <strong>Commission Terms</strong>
                    {' '}
                    including any mandatory and binding additions as contained in the
                    {' '}
                    <strong>Terms of Use</strong>
                    {' '}
                    and on
                    {' '}
                    <Link key="warning" href="https://www.kaiku.co/terms-of-use" target="_blank">www.kaiku.co</Link>
                    .
                  </span>
                )}
              />

              <Box className={classes.submit}>
                <Typography paragraph>{format( Date.now(), 'dd/MM/yyyy' )}</Typography>
                <Button onClick={submitForm}>Accept and Continue</Button>
              </Box>

            </Box>
          )}

        </ValidatedForm>
      </DialogActions>

    </Dialog>

  )
}

export default Declaration
