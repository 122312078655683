import { withStopPropagation } from '@kaiku/ui'
import { Button } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { bool, func, node, string } from 'prop-types'
import React from 'react'

const ActionButton = ( {
  complete,
  state,
  onClick,
  incomplete,
  icon,
  ...props
} ) => (
  <Button
    key={complete}
    size="small"
    color={state ? 'primary' : 'inherit'}
    startIcon={state ? <Check /> : icon}
    onClick={withStopPropagation( onClick )}
    variant="text"
    {...props}
  >
    {state ? complete : incomplete}
  </Button>
)

ActionButton.propTypes = {
  complete: string.isRequired,
  state: bool.isRequired,
  onClick: func,
  icon: node.isRequired,
  incomplete: string.isRequired,
}

ActionButton.defaultProps = {
  onClick: () => {},
}

export default ActionButton
