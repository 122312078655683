import { INVESTOR_TYPES, isInvestmentCompany } from '@kaiku/shared'
import { EditAvatar, ValidatedSelect } from '@kaiku/ui'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { FastField, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { bool, shape, string } from 'prop-types'
import React from 'react'

import useCommonStyles from '../../Login/styles'

const useStyles = makeStyles(
  ( {
    avatarContainer: {
      alignSelf: 'center',
      width: '8em',
      height: '8em',
      border: '4px solid #EEEEEE',
      borderRadius: '100%',
      boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
      background: '#FFFFFF',
    },
    avatar: {
      width: '100%',
      height: '100%',
      fontSize: '0.8em',
    },
  } ),
)

const BasicInformation = ( { errors, touched, values: { type } } ) => {
  const classes = { ...useStyles(), ...useCommonStyles() }
  return (
    <>
      <Box className={classes.avatarContainer}>
        <FastField
          component={EditAvatar}
          className={classes.avatar}
          defaultIcon={<Business fontSize="large" />}
          name="companyLogoUrl"
        />
      </Box>

      <Grid container spacing={1} direction="row">
        <Grid item xs={12}>
          <ValidatedSelect
            options={Object.values( INVESTOR_TYPES )}
            error={touched.type && errors.type}
            label="Investor Type"
            name="type"
            fullWidth
          />
        </Grid>
        { type && ( isInvestmentCompany( type ) ? (
          <>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                name="companyName"
                label="Company Name"
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                name="companyWebsite"
                label="Company Website"
              >
                <TextField name="companyWebsite" label="Company Website" fullWidth />
              </Field>
            </Grid>
          </>
        )
          : (
            <>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  disabled
                  component={TextField}
                  name="representative.firstName"
                  label="First Name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  disabled
                  component={TextField}
                  name="representative.lastName"
                  label="Last Name"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  name="representative.website"
                  label="Website"
                />
              </Grid>
            </>
          ) )}

      </Grid>
    </>
  )
}

BasicInformation.propTypes = {
  errors: shape( { stage: string } ).isRequired,
  touched: shape( { stage: bool } ).isRequired,
  values: shape( { type: string } ).isRequired,
}

export default BasicInformation
