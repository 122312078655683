import { isFirebaseError } from '@kaiku/shared'
import { HomeForm, LoaderButton, ValidatedForm } from '@kaiku/ui'
import { FormHelperText, makeStyles, Typography } from '@material-ui/core'
import { confirmPasswordReset, getAuth, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { object, ref, string } from 'yup'

import HomeFormLoading from '../HomeFormLoading'

const useStyles = makeStyles( {
  header: {
    textAlign: 'center',
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  mainButton: {
    marginTop: '15px',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  skipStepContainer: {
    paddingTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  skipStepText: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
} )

const initialValues = {
  password: '',
  confirmPassword: '',
}

const createSchema = () => object().shape( {
  password: string().required().min( 6 ),
  confirmPassword: string().oneOf( [ ref( 'password' ), null ], 'Passwords do not match' ).required( 'Passwords do not match' ),
} )

const ResetPassword = ( { actionCode } ) => {
  const [ accountEmail, setAccountEmail ] = useState()
  const [ error, setError ] = useState()
  const history = useHistory()

  const classes = useStyles()

  useEffect( () => {
    verifyPasswordResetCode( getAuth(), actionCode )
      .then( ( email ) => setAccountEmail( email ) )
      .catch( () => {
        setError( 'Invalid or expired link. Please request password reset again.' )
      } )
  }, [ actionCode ] )

  const resetPassword = ( { password } ) => confirmPasswordReset(
    getAuth(), actionCode, password,
  )
    .then( () => {
      signInWithEmailAndPassword( getAuth(), accountEmail, password )
    } )
    .then( () => history.push( '' ) )
    .catch( ( err ) => {
      if ( isFirebaseError( err ) ) {
        setError( 'An error occurred during password reset. Please try again.' )
      } else {
        setError( 'An unexpected error occurred. Please try again' )
      }
    } )

  if ( error ) {
    return (
      <HomeForm logo>
        <Typography className={classes.header} variant="h3">Reset Password</Typography>
        <div className={classes.header}>
          {error}
        </div>
      </HomeForm>
    )
  }
  if ( accountEmail ) {
    return (
      <HomeForm logo>
        <Typography className={classes.header} variant="h3">Reset Password</Typography>
        <Typography className={classes.header}>
          {`Resetting password for ${accountEmail}`}
        </Typography>
        <ValidatedForm
          initialValues={initialValues}
          schema={createSchema()}
          onSubmit={resetPassword}
          prompt={false}
        >
          {( { isSubmitting, submitForm } ) => (
            <>
              <Field
                component={TextField}
                name="password"
                label="New Password"
                type="password"
              />

              <Field
                component={TextField}
                name="confirmPassword"
                label="Confirm Password"
                type="password"
              />

              <FormHelperText className={classes.errorText} error>
                {error && error.message}
              </FormHelperText>

              <LoaderButton
                className={classes.mainButton}
                loading={isSubmitting}
                onClick={submitForm}
              >
                Reset Password
              </LoaderButton>

            </>
          ) }

        </ValidatedForm>
      </HomeForm>
    )
  }
  return <HomeFormLoading />
}

ResetPassword.propTypes = {
  actionCode: PropTypes.string.isRequired,
}

export default ResetPassword
