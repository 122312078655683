const API_ROOT = '/api/engagements'

export const getInitiateUrl = ( id ) => `${API_ROOT}/initiate/${id}`

export const getStartupMeetingsUrl = () => `${API_ROOT}/meetings/startup`

export const getInvestorMeetingsUrl = () => `${API_ROOT}/meetings/investor`

export const getAcceptMeetingUsingTokenUrl = ( token ) => `${API_ROOT}/meetings/${token}/accept`

export const getDeclineMeetingUsingTokenUrl = ( token ) => `${API_ROOT}/meetings/${token}/decline`

export const getAcceptMeetingUrl = ( investorId ) => `${API_ROOT}/meetings/startup/accept/${investorId}`

export const getDeclineMeetingForStartupUrl = ( investorId ) => `${API_ROOT}/meetings/startup/decline/${investorId}`

export const getDeclineMeetingForInvestorUrl = ( startupId ) => `${API_ROOT}/meetings/investor/decline/${startupId}`

export const getMeetingInfo = ( token ) => `${API_ROOT}/meetings/${token}/info`
