import { useUser } from '@kaiku/ui'
import React from 'react'
import { useHistory } from 'react-router-dom'

import HomeFormLoading from '../HomeFormLoading'
import AcceptMeeting from './AcceptMeeting'
import ResetPassword from './ResetPassword'
import VerifyEmail from './VerifyEmail'

const ActionHandler = () => {
  const url = new URL( window.location.href )
  const history = useHistory()
  const [ user ] = useUser()

  const mode = url.searchParams.get( 'mode' )
  const actionCode = url.searchParams.get( 'oobCode' )

  switch ( mode ) {
    case 'resetPassword':
      if ( user ) {
        history.push( '' )
      }
      return <ResetPassword actionCode={actionCode} />
    case 'verifyEmail':
      return <VerifyEmail actionCode={actionCode} />
    case 'bookMeeting':
      return <AcceptMeeting actionCode={actionCode} />
    default:
      history.push( '' )
  }

  return <HomeFormLoading />
}

export default ActionHandler
