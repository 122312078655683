import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'

import Grouped from '../GroupedAutocomplete'
import withAutocompletePlaceholder from '../with-autocomplete-placeholder'
import autocompleteToFormik from './autocomplete-to-formik'

export const [
  Autocomplete,
  GroupedAutocomplete,
  PlaceholderAutocomplete,
  PlaceholderGroupedAutocomplete,
] = [
  MuiAutocomplete,
  Grouped,
  withAutocompletePlaceholder( MuiAutocomplete ),
  withAutocompletePlaceholder( Grouped ),
].map( autocompleteToFormik )

