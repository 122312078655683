// Use this when we don't care about IDE hints or it's a long list of enums
const toObj = ( list ) => list.reduce( ( obj, value ) => ( { ...obj, [ value ]: value } ), {} )

export const GENDERS = {
  male: 'male',
  female: 'female',
  other: 'other',
}

export const USER_TYPES = {
  startup: 'startup',
  investor: 'investor',
  undetermined: 'undetermined',
  admin: 'admin',
}

export const INVESTOR_TYPES = {
  individual: 'angel investor',
  ventureCapital: 'venture capital',
  corporateVentureCapital: 'corporate venture capital',
  family: 'family office',
}

export const MEETING_STATUSES = {
  meetingRequested: 'meeting requested',
  meetingBooked: 'meeting booked',
  met: 'met',
  declined: 'meeting declined',
}

export const MEETING_ERROR_STATUSES = {
  invalid: 'invalid',
  alreadyAccepted: 'accepted',
  alreadyDeclined: 'declined',
  invalidTime: 'invalid time',
}

export const MEETING_FILTERS = {
  all: 'all meetings',
  ...MEETING_STATUSES,
}

export const INVESTOR_ACCOUNT_TYPE = {
  unvalidated: 'unvalidated',
  trial: 'trial',
  trialExpired: 'trial expired',
}

export const INVESTOR_ONBOARDING_STATUS = {
  onboarded: 'onboarded',
  rejected: 'rejected',
}

export const SEIS_PROGRESS = {
  yes: 'yes',
  no: 'no',
  inProgress: 'in progress',
}

export const STARTUP_STAGES = toObj( [
  'ideation',
  'minimum viable product',
  'commercial pilot',
  'revenue generation',
] )

export const INDUSTRIES = {
  ...toObj( [
    'agriculture',
    'aviation and aerospace',
    'beauty',
    'biotechnology',
    'construction',
    'dating',
    'e-commerce',
    'education',
    'energy',
    'environment and climate',
    'events and hospitality',
    'fashion, clothing and apparel',
    'financial services',
    'food and beverage',
    'gaming',
    'government and public sector',
    'hardware',
    'healthcare',
    'human resources',
    'information technology and telecom',
    'insurance',
    'legal and regulation',
    'lifestyle and wellness',
    'logistics and supply chain',
    'manufacturing',
    'marketing',
    'media',
    'music',
    'privacy and security',
    'real estate',
    'robotics',
    'fitness and sports',
    'transportation',
    'travel and tourism',
  ] ),
  other: 'other',
}

export const ROLES = {
  employee: 'employee',
  founder: 'founder',
  cofounder: 'co-founder',
}

export const FUNDRAISE_TYPES = {
  raise: 'raise',
  award: 'award',
  grant: 'grant',
}

export const PORTFOLIO_TYPES = {
  investor: 'investor',
  mentor: 'mentor',
  boardMember: 'board member',
}

export const FUNDRAISE_STAGES = toObj( [
  'pre-seed',
  'seed',
  'late-seed',
  'series-a',
  'series-b',
] )

export const METRICS = {
  mrr: 'revenue/month',
  arr: 'revenue/year',
  cac: 'acquisition cost/customer',
  ltv: 'customer value/lifetime',
  grossProfit: 'gross profit',
  dau: 'users/day',
  mau: 'users/month',
  ...toObj( [
    'users',
    'partnerships',
    'advisors',
    'retention',
    'activation',
  ] ),
}

export const PRODUCT_HIGHLIGHTS = toObj( [
  'artificial intelligence',
  'augmented reality',
  'big data',
  'blockchain',
  'B2B',
  'B2C',
  'deep tech',
  'hardware',
  'internet of things',
  'machine learning',
  'software-as-a-service',
  'virtual reality',
] )

export const ROUND_PREFERENCE = {
  any: 'any',
  leadInvestor: 'lead investor',
  coInvestor: 'co-investor',
}

export const CRITERIA = {
  keyword: 'keyword',
  fundraiseStage: 'stage',
  minimumTicketSize: 'minimum ticket size',
  maximumTicketSize: 'maximum ticket size',
  geography: 'geography',
  industry: 'industry',
  patents: 'patents',
}

export const ONBOARDING_STATUSES = {
  notStarted: 'not started',
  inProgress: 'in progress',
  complete: 'complete',
}

export const ONBOARDING_STAGES = {
  legalVetting: 'legal vetting',
  businessVetting: 'business vetting',
  engagementLetter: 'engagement letter',
}
