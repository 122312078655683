import { Avatar, Box, makeStyles, Typography } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { arrayOf, number, string } from 'prop-types'
import React from 'react'

import { HeaderBlockCommonStyles } from '../../../..'
import ExternalLink from '../../ExternalLink'
import HeaderBlock from '../../HeaderBlock'
import HorizontalItems from '../../HorizontalItems'

const useStyles = makeStyles( {
  industries: {
    textTransform: 'capitalize',
  },
} )

const DefaultHeaderBlock = ( { foundedYear, industries, name, website, logoUrl } ) => {
  const fallback = ![
    foundedYear,
    industries.length,
    name,
    website,
    logoUrl,
  ].some( ( x ) => x )

  const classes = { ...HeaderBlockCommonStyles(), ...useStyles() }

  const Content = () => (
    <>
      <Typography variant="h3" align="center">{name}</Typography>

      <HorizontalItems>
        {!!foundedYear && <Typography>{foundedYear}</Typography>}
        <Typography className={classes.industries}>{industries.join( ', ' )}</Typography>
        {website && <ExternalLink href={website}>Website</ExternalLink>}
      </HorizontalItems>
    </>
  )

  return (
    <>
      <HeaderBlock
        className={`${classes.block} startup`}
        fallbackMessage="Get started by filling in some core details."
        fallback={fallback}
        renderAvatar={() => (
          <Box className={`${classes.avatarContainer} startup`}>
            <Avatar className={classes.avatar} src={logoUrl} alt={name}>
              <Business fontSize="large" />
            </Avatar>
          </Box>
        )}
      >
        <Content />
      </HeaderBlock>
    </>
  )
}

DefaultHeaderBlock.propTypes = {
  foundedYear: number,
  name: string,
  industries: arrayOf( string ),
  website: string,
  logoUrl: string,
}

DefaultHeaderBlock.defaultProps = {
  foundedYear: null,
  industries: [],
  name: null,
  website: null,
  logoUrl: null,
}

export default DefaultHeaderBlock
