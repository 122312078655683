import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import fetchWithAuth from '../lib/fetch-with-auth'
import { logout } from '../lib/firebase-auth'
import useUser from './use-user'

const useFetchAuth = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [ user ] = useUser()

  return useCallback( async ( ...params ) => fetchWithAuth(
    user && await user.user.getIdToken( true ),
  )( ...params )
    .catch( ( err ) => {
      if ( err.statusCode === 401 ) {
        enqueueSnackbar( 'Your session has expired. Please log in.', { variant: 'error' } )
        logout()
      }

      throw err
    } ), [ enqueueSnackbar, user ] )
}

export default useFetchAuth
