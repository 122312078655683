import { Button, Dialog, DialogActions, DialogContent, DialogContentText, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import { Autocomplete } from './Autocomplete'
import ValidatedForm from './Form'

const getProps = ( {
  text: ( { inputProps, prefix, postfix } ) => ( {
    component: TextField,
    inputProps,
    InputProps: {
      startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
      endAdornment: postfix && <InputAdornment position="start">{postfix}</InputAdornment>,
    },
  } ),
  number: ( { inputProps, prefix, postfix } ) => ( {
    component: TextField,
    type: 'number',
    inputProps,
    InputProps: {
      startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
      endAdornment: postfix && <InputAdornment position="start">{postfix}</InputAdornment>,
    },
  } ),
  list: ( { items } ) => ( {
    component: Autocomplete,
    closeIcon: null,
    options: items,
    getOptionLabel: ( { name } ) => name || '',
    children: items.map( ( { name, value } ) => (
      <MenuItem key={value} value={value}>{name}</MenuItem>
    ) ),
  } ),
} )

const useStyles = makeStyles( {
  dialog: {
    width: '100%',
    maxWidth: '250px',
  },
  dialogText: {
    textTransform: 'capitalize',
  },
  field: {
    width: '100%',
    '& input': {
      textTransform: ( { type } ) => ( type === 'list' ? 'capitalize' : 'initial' ),
    },
  },
} )

const withAutocompleteDialog = ( config ) => {
  const InputDialog = ( { open, onSubmit, value, field: { value: previous } } ) => {
    // Get name from the latest value
    const { name } = value[ value.length - 1 ]

    const { schema: schemaValue, getDisplay, type, label, ...options } = config[ name ]

    const initialValues = { value: '' }
    const schema = yup.object().shape( { value: schemaValue } )

    const doSubmit = ( { value } ) => onSubmit( [
      ...previous,
      {
        name,
        value: type === 'list' ? value.value : value,
        display: getDisplay( value ),
      },
    ] )

    const classes = useStyles( { type } )

    return (
      <Dialog classes={{ paper: classes.dialog }} open={open}>

        <ValidatedForm schema={schema} initialValues={initialValues} onSubmit={doSubmit}>
          {( { submitForm, errors } ) => (
            <>
              <DialogContent>
                <DialogContentText className={classes.dialogText}>
                  {label || name}
                </DialogContentText>

                <Field
                  className={classes.field}
                  {...getProps[ type ]( options )}
                  name="value"
                  autoFocus
                />
              </DialogContent>

              <DialogActions>

                <Button variant="text" onClick={() => onSubmit( null )}>
                  Cancel
                </Button>

                <Button disabled={!!errors.value} type="submit" variant="text" onClick={submitForm}>
                  Save
                </Button>

              </DialogActions>

            </>
          )}
        </ValidatedForm>
      </Dialog>
    )
  }

  const valuePropType = arrayOf( shape( { name: string, value: oneOfType( [ string, number ] ) } ) )

  InputDialog.propTypes = {
    open: bool,
    onSubmit: func,
    value: valuePropType,
    field: shape( { value: valuePropType } ),
  }

  InputDialog.defaultProps = {
    open: false,
    onSubmit: () => {},
    value: [],
    field: { value: [] },
  }

  return InputDialog
}

export default withAutocompleteDialog
