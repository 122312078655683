import { Box, Divider, makeStyles } from '@material-ui/core'
import { arrayOf, shape, string } from 'prop-types'
import React from 'react'

import ContentBlock from '../../ContentBlock'
import Persona from '../../Persona'

const useStyles = makeStyles( {
  divider: {
    width: '100%',
  },
} )

const TeamBlock = ( { members } ) => {
  const classes = useStyles()

  return (
    <ContentBlock
      title="Team"
      fallback={!members.length}
      fallbackMessage="Please fill in information about your your team members."
    >

      {members.map( ( { id, ...props }, index ) => (
        <Box key={id}>
          <Persona {...props} />

          {index < members.length - 1 && <Divider className={classes.divider} />}
        </Box>
      ) )}

    </ContentBlock>
  )
}

TeamBlock.propTypes = {
  members: arrayOf( shape( {
    id: string,
    firstName: string.isRequired,
    lastName: string.isRequired,
  } ) ),
}

TeamBlock.defaultProps = {
  members: [],
}

export default TeamBlock
