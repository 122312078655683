import { getInvestorName } from '@kaiku/shared'
import { formatDateAndTime, LoaderButton, useFetchAuth } from '@kaiku/ui'
import { Button, Container, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { arrayOf, func, objectOf, string } from 'prop-types'
import React, { useState } from 'react'

import { getAcceptMeetingUrl } from '../../../api'
import { meetingInfoPropTypes } from '../../Investor/Matching/Meetings/propTypes'

const useStyles = makeStyles( {
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '1em 0 1em',
  },
  accept: {
    marginTop: '1em',
    width: 'fit-content',
    marginBottom: '2em',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  decline: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  differentTime: {
    marginBottom: '1em',
    width: '100%',
  },
} )

const MeetingRequests = ( { meetingTimes, onSelect } ) => {
  const classes = useStyles()

  return (
    <>
      {meetingTimes.map( ( time ) => (
        <div className={classes.timeContainer}>
          {formatDateAndTime( time )}
          <Button onClick={() => onSelect( time )}>
            Select
          </Button>
        </div>
      ) )}
    </>
  )
}

MeetingRequests.propTypes = {
  meetingTimes: arrayOf( string ).isRequired,
  onSelect: func.isRequired,
}

const MeetingRequest = ( { investor, meetingInfo, declineMeeting, onClose } ) => {
  const classes = useStyles()
  const fetch = useFetchAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [ selectedTime, setSelectedTime ] = useState()
  const [ declining, setDeclining ] = useState( false )
  const [ isSubmitting, setIsSubmitting ] = useState( false )

  const acceptMeeting = () => {
    setIsSubmitting( true )
    fetch( getAcceptMeetingUrl( investor.id ), { method: 'POST', body: { acceptedTime: selectedTime } } )
      .then( () => {
        enqueueSnackbar( `Meeting with ${getInvestorName( investor )} has been accepted.`, { variant: 'success' } )
        onClose()
      } )
      .catch( ( e ) => enqueueSnackbar( `Something went wrong. Please try again. ${e}`, { variant: 'error' } ) )
      .finally( () => setIsSubmitting( false ) )
  }

  return (
    <Container>
      Select a time:
      <MeetingRequests
        meetingTimes={meetingInfo.meetingTimes}
        onSelect={setSelectedTime}
      />
      {selectedTime
        ? <div>{`Selected time: ${formatDateAndTime( selectedTime )}`}</div>
        : <div>Select a time</div>}
      <div className={classes.footer}>
        <LoaderButton
          loading={isSubmitting}
          className={classes.accept}
          disabled={!selectedTime}
          onClick={acceptMeeting}
        >
          Accept
        </LoaderButton>
        <span className={classes.differentTime}>
          If you are busy at all of these times, please accept the
          latest time and contact the investor directly using the
          email address above to arrange another time.
        </span>
        {declining
          ? (
            <span>
              {'Please click '}
              <span
                className={classes.decline}
                onClick={() => declineMeeting( investor.id )}
              >
                here
              </span>
              {' if you are sure you want to decline this meeting.'}
            </span>
          )
          : (
            <span
              className={classes.decline}
              onClick={() => setDeclining( true )}
            >
              I don&apos;t want to meet this investor
            </span>
          )}

      </div>

    </Container>
  )
}

MeetingRequest.propTypes = {
  investor: objectOf( {
    id: string,
  } ).isRequired,
  meetingInfo: meetingInfoPropTypes.isRequired,
  declineMeeting: func.isRequired,
  onClose: func.isRequired,
}

export default MeetingRequest
