import { Container, makeStyles, Paper, Typography } from '@material-ui/core'
import { bool, node, string } from 'prop-types'
import React from 'react'

import WithTooltip from './WithTooltip'

const useStyles = makeStyles( ( { typography } ) => ( {
  root: {
    margin: '3em',
    padding: '1em 0.5em',
  },
  title: {
    fontFamily: typography.h3.fontFamily,
  },
  fallback: {
    marginTop: '1em',
  },
} ) )

const ContentBlock = ( { title, fallback, fallbackMessage, tooltip, children } ) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Container>
        <WithTooltip tooltip={tooltip} iconSize="1.5em">
          <Typography className={classes.title} variant="h4">{title}</Typography>
        </WithTooltip>

        {fallback
          ? <Typography className={classes.fallback}>{fallbackMessage}</Typography>
          : children}

      </Container>
    </Paper>
  )
}

ContentBlock.propTypes = {
  title: string.isRequired,
  fallbackMessage: string,
  fallback: bool,
  tooltip: string,
  children: node,
}

ContentBlock.defaultProps = {
  fallbackMessage: null,
  fallback: false,
  tooltip: null,
  children: null,
}

export default ContentBlock
