import { INDUSTRIES } from '@kaiku/shared'
import { ChipCheckboxes } from '@kaiku/ui'
import { shape, string } from 'prop-types'
import React from 'react'

const Industries = ( { errors, helperText, fieldName } ) => (
  <ChipCheckboxes
    error={errors.industries}
    helperText={helperText}
    fieldName={fieldName}
    options={Object.values( INDUSTRIES )}
  />
)

Industries.propTypes = {
  errors: shape( { industries: string } ).isRequired,
  helperText: string.isRequired,
  fieldName: string,
}

Industries.defaultProps = {
  fieldName: 'industries',
}

export default Industries
