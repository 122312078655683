import PropTypes, { func, objectOf, shape, string } from 'prop-types'

export default {
  field: shape( { name: string.isRequired } ).isRequired,
  form: shape( {
    touched: objectOf( PropTypes.any ),
    errors: objectOf( string ).isRequired,
    setFieldTouched: func.isRequired,
    setFieldError: func.isRequired,
    setFieldValue: func.isRequired,
  } ).isRequired,
}
