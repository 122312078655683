import { LoaderButton, ValidatedForm } from '@kaiku/ui'
import { Container, FormHelperText, makeStyles, Typography } from '@material-ui/core'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import * as yup from 'yup'

const useStyles = makeStyles( {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '50px',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  description: {
    marginBottom: '10px',
  },
  button: {
    margin: 'auto',
    maxWidth: 'fit-content',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  success: {
    marginTop: '30px',
    textAlign: 'center',
  },
} )

const createSchema = () => yup.object().shape( {
  currentPassword: yup.string().required(),
  newPassword: yup.string().min( 8, 'New password must be at least 8 characters' ).required(),
  confirmNewPassword: yup.string().oneOf( [ yup.ref( 'newPassword' ), null ], 'Passwords do not match' ).required( 'Passwords do not match' ),
} )

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}

const ChangePassword = () => {
  const classes = useStyles()
  const [ success, setSuccess ] = useState( false )
  const [ error, setError ] = useState()

  const handleSubmit = ( { currentPassword, newPassword }, { setFieldError, resetForm } ) => {
    setError()
    return reauthenticateWithCredential(
      getAuth().currentUser,
      EmailAuthProvider.credential( getAuth().currentUser.email, currentPassword ),
    )
      .then( () => updatePassword( getAuth().currentUser, newPassword ) )
      .then( () => signInWithEmailAndPassword(
        getAuth(), getAuth().currentUser.email, newPassword,
      ) )
      .then( () => setSuccess( true ) )
      .then( () => resetForm() )
      .catch( ( err ) => {
        if ( err.code === 'auth/wrong-password' ) {
          setFieldError( 'currentPassword', 'Password incorrect' )
        } else {
          setError( 'Something went wrong, please try again.' )
        }
      } )
  }

  return (
    <Container className={classes.container}>

      <Container className={classes.main} maxWidth="sm">

        <Typography variant="h3" className={classes.title}>Change Password</Typography>
        <Typography variant="body1" className={classes.description}>
          Use this section to change your password.
          Your new password must be at least 8 characters long.
        </Typography>
        <ValidatedForm
          schema={createSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          prompt={false}
        >
          {( { submitForm, isSubmitting } ) => (
            <>

              <Field
                component={TextField}
                name="currentPassword"
                label="Current Password"
                type="password"
                className={classes.field}
              />

              <Field
                component={TextField}
                name="newPassword"
                label="New Password"
                type="password"
                className={classes.field}
              />

              <Field
                component={TextField}
                name="confirmNewPassword"
                label="Confirm New Password"
                type="password"
                className={classes.field}
              />

              <FormHelperText className={classes.errorText} error>
                {error}
              </FormHelperText>

              <LoaderButton
                disabled={success}
                loading={isSubmitting}
                onClick={submitForm}
                className={classes.button}
              >
                Change Password
              </LoaderButton>
              {success && <Typography variant="h4" className={classes.success}>Password Changed!</Typography>}
            </>
          ) }

        </ValidatedForm>
      </Container>
    </Container>

  )
}

export default ChangePassword
