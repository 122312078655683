import { routes, USER_TYPES } from '@kaiku/shared'
import { HomeForm, LoaderButton, logout, ValidatedForm } from '@kaiku/ui'
import { FormControl, FormControlLabel, makeStyles, Typography } from '@material-ui/core'
import { Field } from 'formik'
import { RadioGroup } from 'formik-material-ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { object, string } from 'yup'

import RadioButton from '../../components/RadioButton'
import useCommonStyles from '../Login/styles'

const useStyles = makeStyles( {
  typeRadioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '15px',
  },
  radioButton: {
    width: '100%',
    minHeight: '150px',
  },
  radioButtonContainer: {
    margin: '0',
    width: '45%',
    height: '150px',
  },
} )

const initialValues = {
  type: USER_TYPES.startup,
}

const registerDetailsRoutes = {
  [ USER_TYPES.startup ]: routes.REGISTER_STARTUP_DETAILS,
  [ USER_TYPES.investor ]: routes.REGISTER_INVESTOR_DETAILS,
}

const createSchema = () => object().shape( {
  type: string().oneOf( Object.values( USER_TYPES ) ).required(),
} )

const RegisterDetails = () => {
  const history = useHistory()

  const handleSubmit = ( { type } ) => {
    history.push( registerDetailsRoutes[ type ] )
  }

  const classes = { ...useStyles(), ...useCommonStyles() }

  return (
    <HomeForm logout={logout}>
      <ValidatedForm
        initialValues={initialValues}
        schema={createSchema()}
        onSubmit={handleSubmit}
        prompt={false}
      >
        {( { isSubmitting, submitForm } ) => (
          <>
            <FormControl>
              <Typography className={classes.header} variant="h2">I am a...</Typography>
              <Field
                className={classes.typeRadioGroup}
                component={RadioGroup}
                name="type"
              >
                <FormControlLabel
                  className={classes.radioButtonContainer}
                  value={USER_TYPES.startup}
                  control={(
                    <RadioButton className={classes.radioButton}>
                      Startup
                    </RadioButton>
                    )}
                />
                <FormControlLabel
                  className={classes.radioButtonContainer}
                  value={USER_TYPES.investor}
                  control={(
                    <RadioButton className={classes.radioButton}>
                      Investor
                    </RadioButton>
                    )}
                />
              </Field>
            </FormControl>
            <LoaderButton
              className={classes.mainButton}
              loading={isSubmitting}
              onClick={submitForm}
            >
              Create Account
            </LoaderButton>
          </>
        ) }
      </ValidatedForm>
    </HomeForm>
  )
}

export default RegisterDetails
