import { makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core'
import { arrayOf, bool, number, shape, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles(
  ( {
    stepperContainer: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
      paddingTop: '25px',
    },
    stepperActive: {
      fontFamily: 'Futura Regular!important',
    },
    optionalTag: {
      display: 'block',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.36)',
    },
  } ),
)

const FormStepper = ( { steps, activeStep } ) => {
  const classes = useStyles()
  return (
    <Stepper className={classes.stepperContainer} activeStep={activeStep} alternativeLabel>
      {steps.map( ( { label, optional }, index ) => (
        <Step key={label}>
          <StepLabel
            classes={{ label: activeStep === index ? classes.stepperActive : null }}
            optional={optional ? <Typography className={classes.optionalTag} variant="caption">Optional</Typography> : null}
          >
            {label}

          </StepLabel>
        </Step>
      ) )}

    </Stepper>
  )
}

FormStepper.propTypes = {
  steps: arrayOf( shape( { label: string, optional: bool } ) ).isRequired,
  activeStep: number.isRequired,
}

export default FormStepper
