const FIELDS = {
  description: {
    label: 'Description',
    tooltip: 'A brief description of the investor',
  },
  representative: {
    label: 'Representative',
    tooltip: 'The representative of the investor',
  },
}

export default FIELDS
