import { Container } from '@material-ui/core'
import React from 'react'

const Met = () => (
  <Container>
    You have met with this investor.
  </Container>
)

export default Met
