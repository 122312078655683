import { INDUSTRIES } from '@kaiku/shared'
import { Box, FormControl, Grid } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { FastField, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { capitalize, mapValues } from 'lodash'
import { arrayOf, func, number, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import { HeaderBlockCommonStyles } from '../../../..'
import { Autocomplete } from '../../Formik/Autocomplete'
import EditAvatar from '../../Formik/EditAvatar'
import ValidatedForm from '../../Formik/Form'
import HeaderBlock from '../../HeaderBlock'

const createSchema = () => yup.object().shape( {
  name: yup.string().required(),
  foundedYear: yup
    .number()
    .integer( 'Enter a year' )
    .moreThan( 2000, 'Your company is too old to be a startup' )
    .lessThan( new Date().getFullYear() + 1, 'You haven\'t founded your startup yet!' )
    .required(),
  logoUrl: yup.string().nullable(),
  website: yup.string().url().required(),
  industries: yup.array().of( yup.string().oneOf( Object.values( INDUSTRIES ) ) )
    .min( 1 ).max( 3 )
    .required(),
} )

const EditHeaderBlock = ( {
  withSubmit,
  onSubmit,
  name,
  foundedYear,
  logoUrl,
  website,
  industries,
} ) => {
  const initialValues = {
    ...mapValues( { name, logoUrl, website }, ( value ) => value || '' ),
    industries: industries.length ? industries : [ INDUSTRIES.other ],
    foundedYear: foundedYear || new Date().getFullYear(),
  }

  const classes = HeaderBlockCommonStyles()
  const schema = createSchema()

  return (
    <ValidatedForm
      schema={schema}
      initialValues={initialValues}
      onSubmit={( data ) => onSubmit( schema.submit( data ) )}
    >
      {withSubmit( () => (
        <HeaderBlock
          className={`${classes.block} startup`}
          renderAvatar={() => (
            <Box className={`${classes.avatarContainer} startup`}>
              <FastField
                component={EditAvatar}
                className={classes.avatar}
                defaultIcon={<Business fontSize="large" />}
                name="logoUrl"
              />
            </Box>
          )}
        >
          <Grid container spacing={1} direction="row" className={classes.container}>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="name"
                label="Startup Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="foundedYear"
                label="Founded Year"
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.noMargin}>
                <Field
                  component={Autocomplete}
                  name="industries"
                  label="Industries"
                  options={Object.values( INDUSTRIES )}
                  multiple
                  disableCloseOnSelect
                  renderTags={( tags ) => tags.map( capitalize ).join( ', ' )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="website"
                label="Website"
              />

            </Grid>

          </Grid>
        </HeaderBlock>
      ) )}
    </ValidatedForm>
  )
}

EditHeaderBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  name: string,
  foundedYear: number,
  industries: arrayOf( string ),
  website: string,
  logoUrl: string,
}

EditHeaderBlock.defaultProps = {
  foundedYear: null,
  industries: [],
  name: null,
  website: null,
  logoUrl: null,
}

export default EditHeaderBlock
