import { INVESTOR_TYPES, isInvestmentCompany } from '@kaiku/shared'
import { Box, FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { FastField, Field } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { flatten, pick, update } from 'lodash'
import { func, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import HeaderBlockCommonStyles from '../../../lib/header-block-common-styles'
import EditAvatar from '../../Formik/EditAvatar'
import ValidatedForm from '../../Formik/Form'
import HeaderBlock from '../../HeaderBlock'

const createSchema = () => yup.object().shape( {
  companyName: yup.string().when( 'type', ( type, schema ) => (
    isInvestmentCompany( type ) ? schema.required() : schema
  ) ),
  companyWebsite: yup.string().when( 'type', ( type, schema ) => (
    isInvestmentCompany( type ) ? schema.url().required() : schema
  ) ),
  type: yup.string().oneOf( Object.values( INVESTOR_TYPES ) ).required(),
  representative: yup.object().when( 'type', ( type, schema ) => (
    isInvestmentCompany( type ) ? schema : schema.shape( {
      id: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      pictureUrl: yup.string().nullable(),
      website: yup.string().url().required(),
    } ).required()
  ) ),
} )

const companyBaseFields = {
  type: 'type',
  name: 'companyName',
  logoUrl: 'companyLogoUrl',
  website: 'companyWebsite',
}

const TYPE_FIELDS = {
  [ INVESTOR_TYPES.ventureCapital ]: companyBaseFields,
  [ INVESTOR_TYPES.corporateVentureCapital ]: companyBaseFields,
  [ INVESTOR_TYPES.family ]: companyBaseFields,
  [ INVESTOR_TYPES.individual ]: {
    type: 'type',
    id: 'representative.id',
    firstName: 'representative.firstName',
    lastName: 'representative.lastName',
    logoUrl: 'representative.pictureUrl',
    website: 'representative.website',
  },
}

const EditHeaderBlock = ( {
  withSubmit,
  onSubmit,
  ...props
} ) => {
  const paths = flatten( Object.values( TYPE_FIELDS ).map( ( fields ) => Object.values( fields ) ) )

  const initialValues = Object
    .values( paths )
    .reduce(
      ( acc, path ) => update( acc, path, ( value ) => value || null ),
      pick( props, paths ),
    )

  const classes = HeaderBlockCommonStyles()
  const schema = createSchema()

  return (
    <ValidatedForm
      schema={schema}
      initialValues={initialValues}
      onSubmit={( data ) => onSubmit( schema.submit( data ) )}
    >
      {withSubmit( ( { values: { type } } ) => (
        <HeaderBlock
          className={`${classes.block} investor`}
          renderAvatar={() => (
            <Box className={`${classes.avatarContainer} investor`}>
              <FastField
                component={EditAvatar}
                className={classes.avatar}
                defaultIcon={<Business fontSize="large" />}
                name={TYPE_FIELDS[ type ].logoUrl}
              />
            </Box>
          )}
        >
          <Grid container spacing={1} direction="row" className={classes.container}>
            {isInvestmentCompany( type )
              ? (
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name={TYPE_FIELDS[ type ].name}
                    label="Name"
                  />
                </Grid>
              )
              : (
                <>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name={TYPE_FIELDS[ type ].firstName}
                      label="First Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name={TYPE_FIELDS[ type ].lastName}
                      label="Last Name"
                    />
                  </Grid>
                </>
              )}

            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel>Investor Type</InputLabel>

                <Field component={Select} name={TYPE_FIELDS[ type ].type}>
                  {Object.values( INVESTOR_TYPES ).map( ( name ) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ) )}
                </Field>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name={TYPE_FIELDS[ type ].website}
                label="Website"
              />
            </Grid>

          </Grid>
        </HeaderBlock>
      ) )}
    </ValidatedForm>
  )
}

EditHeaderBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  companyName: string,
  companyWebsite: string,
  companyLogoUrl: string,
  type: string,
  representative: shape( { id: string } ),
}

EditHeaderBlock.defaultProps = {
  companyName: null,
  companyWebsite: null,
  companyLogoUrl: null,
  type: null,
  representative: {},
}

export default EditHeaderBlock
