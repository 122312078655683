import '../fonts/index.css'

import { createMuiTheme } from '@material-ui/core/styles'

export const headerFontFamily = 'Code Bold'
export const bodyFontFamily = 'Futura Light'

const colors = {
  white: '#FFFFFF',
  green: '#71CC98',
  lightGrey: '#F4F4F4',
  darkGrey: '#3D3B3B',
  black: '#000000',
}

const theme = createMuiTheme( {
  palette: {
    background: { default: colors.lightGrey },
    primary: { main: colors.green, contrastText: colors.white },
    secondary: { main: colors.green, dark: colors.lightGrey },
  },
  typography: {
    fontFamily: bodyFontFamily,
    h1: { fontFamily: headerFontFamily, fontSize: 40 },
    h2: { fontFamily: headerFontFamily, fontSize: 34 },
    h3: { fontFamily: headerFontFamily, fontSize: 28 },
    h4: { fontSize: 22 },
    h6: { fontFamily: headerFontFamily },
    button: { fontFamily: headerFontFamily, letterSpacing: '0.075em' },
    subtitle1: { fontFamily: headerFontFamily, color: colors.green, textTransform: 'uppercase', letterSpacing: '0.075em' },
    overline: { fontFamily: headerFontFamily },
  },
  props: {
    MuiPaper: {
      square: true,
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiChip: {
      color: 'primary',
    },
  },
  overrides: {
    MuiAutocomplete: {
      listbox: { textTransform: 'capitalize' },
    },
    MuiButton: {
      root: { borderRadius: 0 },
    },
    MuiListItem: {
      root: { textTransform: 'capitalize' },
    },
    MuiSelect: {
      root: { textTransform: 'capitalize' },
    },
    MuiTooltip: {
      tooltip: { fontSize: '1rem' },
    },
    MuiChip: {
      root: {
        overflow: 'hidden',
        borderRadius: '0.75em',
        textTransform: 'uppercase',
        fontFamily: headerFontFamily,
        letterSpacing: '0.006em',
        marginRight: '2em',
        marginTop: '0.35em',
        marginBottom: '0.35em',
      },
    },
    MuiDialogContentText: {
      root: {
        color: colors.black,
      },
    },
    MuiFab: {
      root: {
        color: colors.darkGrey,
        backgroundColor: colors.white,
      },
    },
  },
} )

export default theme
