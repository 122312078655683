import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import useFetchAuth from './use-fetch-auth'

const DEFAULT_PARAMS = {}

const useInfiniteFetch = ( url, pageSize, params = DEFAULT_PARAMS ) => {
  const [ page, setPage ] = useState( 0 )
  const [ limit, setLimit ] = useState( false )

  const fetch = useFetchAuth()
  const { data, mutate, ...rest } = useSWR(
    url ? [ url, page, params ] : null,
    () => url && fetch( `${url}?page=${page}`, params ),
  )

  const previous = useMemo(
    () => ( page > 0 ? ( () => { setPage( page - 1 ); setLimit( false ) } ) : undefined ),
    [ page ],
  )
  const next = !limit && data && data.length === pageSize
    ? ( () => setPage( page + 1 ) )
    : undefined

  // In case of over-scroll, move the page back and disable next
  useEffect( () => {
    if ( data && !data.length && page > 0 ) {
      previous()
      if ( data ) setLimit( true )
    }
  }, [ page, previous, data ] )

  return { data, mutate, next, previous, setPage, page, ...rest }
}

export default useInfiniteFetch
