import { routes } from '@kaiku/shared'
import React from 'react'

import ChangePassword from '../Settings/ChangePassword'
import Settings from '../Settings/Settings'

const ROUTES = [
  [ routes.INVESTOR_SETTINGS_PASSWORD, ChangePassword ],
]

const sidebarItems = [
  {
    label: 'Account',
    subItems: [
      { label: 'Change Password', path: routes.INVESTOR_SETTINGS_PASSWORD },
    ],
  },
]

const InvestorSettings = () => (
  <Settings
    routes={ROUTES}
    sidebarItems={sidebarItems}
    redirect={routes.INVESTOR_SETTINGS_PASSWORD}
  />
)

export default InvestorSettings
