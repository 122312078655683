import { Link, makeStyles, Typography } from '@material-ui/core'
import { node, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > svg': {
      marginRight: '0.25em',
    },
  },
} )

const ExternalLink = ( { href, children, ...props } ) => {
  const classes = useStyles()

  return (
    <Typography {...props}>
      <Link className={classes.link} href={href} rel="noopener" target="_blank">
        {children || href.replace( /^http(s?):\/\//i, '' )}
      </Link>
    </Typography>
  )
}

ExternalLink.propTypes = {
  href: string.isRequired,
  children: node,
}

ExternalLink.defaultProps = {
  children: null,
}

export default ExternalLink
