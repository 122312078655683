import { Container, makeStyles } from '@material-ui/core'
import { objectOf, string } from 'prop-types'
import React, { useState } from 'react'

const useStyles = makeStyles( {
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '1em 0 1em',
  },
  accept: {
    marginTop: '1em',
    width: 'fit-content',
    marginBottom: '2em',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  decline: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  differentTime: {
    marginBottom: '1em',
    width: '100%',
  },
} )

const MeetingRequest = ( { startup, declineMeeting } ) => {
  const classes = useStyles()
  const [ declining, setDeclining ] = useState( false )

  return (
    <Container>
      <div className={classes.footer}>
        <span className={classes.differentTime}>
          Please wait until the startup has responded to your invitation.
        </span>
        {declining
          ? (
            <span>
              {'Please click '}
              <span
                className={classes.decline}
                onClick={() => declineMeeting( startup.id )}
              >
                here
              </span>
              {' if you are sure you want to decline this meeting.'}
            </span>
          )
          : (
            <span
              className={classes.decline}
              onClick={() => setDeclining( true )}
            >
              I don&apos;t want to meet this startup anymore.
            </span>
          )}

      </div>

    </Container>
  )
}

MeetingRequest.propTypes = {
  startup: objectOf( {
    id: string.isRequired,
  } ).isRequired,
  declineMeeting: string.isRequired,
}

export default MeetingRequest
