import { useInfiniteFetch } from '@kaiku/ui'
import { Box, makeStyles } from '@material-ui/core'
import { func, string } from 'prop-types'
import React from 'react'

import NotOnboardedWarning from '../../../components/NotOnboardedWarning'
import Pagination from '../../../components/Pagination'
import CardGrid from './CardGrid'
import EmptyMessage from './EmptyMessage'

const PAGE_SIZE = 12

const useStyles = makeStyles( {
  root: {
    margin: '2em 0',
    width: '100%',
  },
} )

const Collection = ( { getMatchesUrl, emptyPlaceholder } ) => {
  const { data = [], mutate, next, previous, page, error } = useInfiniteFetch(
    getMatchesUrl(),
    PAGE_SIZE,
  )

  const onBookmarkClick = ( _, removedIndex ) => {
    mutate( data.filter( ( _, index ) => index !== removedIndex ), false )
    setTimeout( mutate, 2000 )
  }

  const onIgnoreClick = ( startup, index ) => {
    const nextData = data.slice()
    nextData[ index ] = { ...startup, ignored: !startup.ignored }

    mutate( nextData, true )
  }

  const classes = useStyles()

  if ( error ) {
    switch ( error.statusCode ) {
      case 403:
        // not onboarded yet
        return <NotOnboardedWarning engaged bookmarked ignored />
      default:
        return (
          <Box className={classes.root}>
            <div>Something went wrong, please try again later</div>
          </Box>
        )
    }
  }

  return (
    <Box className={classes.root}>
      {data.length
        ? (
          <CardGrid
            key={page}
            onBookmarkClick={onBookmarkClick}
            onIgnoreClick={onIgnoreClick}
          >
            {data}
          </CardGrid>
        )
        : <EmptyMessage>{emptyPlaceholder}</EmptyMessage>}

      {!!data.length && <Pagination onNext={next} onPrevious={previous} page={page} />}
    </Box>
  )
}

Collection.propTypes = {
  emptyPlaceholder: string.isRequired,
  getMatchesUrl: func.isRequired,
}

export default Collection

