import { Checkbox, useFetchAuth, ValidatedForm } from '@kaiku/ui'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { Field } from 'formik'
import React from 'react'
import useSWR from 'swr'
import { bool, object } from 'yup'

import { getUserInvestorUrl } from '../../api'

const useStyles = makeStyles( {
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1em',
  },
} )

const defaultWarning = [ 'You must accept this to continue', ( v ) => !!v ]

const initialValues = {
  firstRiskWarning: false,
  secondRiskWarning: false,
  riskWarningDisclaimer: false,
}

const createSchema = () => object().shape( {
  firstRiskWarning: bool().test( 'first-risk-warning', ...defaultWarning ),
  secondRiskWarning: bool().test( 'second-risk-warning', ...defaultWarning ),
  riskWarningDisclaimer: bool().test( 'risk-warning-disclaimer', ...defaultWarning ),
} )

const Declaration = () => {
  const { data: { riskAcceptedAt, ...data } = {}, mutate } = useSWR( getUserInvestorUrl() )

  const fetch = useFetchAuth()

  const onAcceptDeclaration = () => {
    const riskAcceptedAt = new Date()

    fetch( getUserInvestorUrl(), { method: 'PATCH', body: { riskAcceptedAt } } )
    mutate( { ...data, riskAcceptedAt }, false )
  }

  const classes = useStyles()

  return (
    <Dialog open={!riskAcceptedAt} maxWidth="md" fullWidth scroll="paper">

      <DialogTitle align="center">High Net Worth Investor Statement</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          I make this statement so that I can receive promotional communications
          which are exempt from the restriction on promotion of non-mainstream pooled investments.
          The exemption relates to certified high net worth investors and
          I declare that I qualify as such because at least one of the following applies to me:
        </DialogContentText>

        <DialogContentText component="ul">
          <li>
            I had, throughout the financial year immediately preceding the date below,
            an annual
            <strong>{' income ' }</strong>
            to the value of £100,000 or more.
            Annual income for these purposes does not include
            money withdrawn from my pension savings
            (except where the withdrawals are used directly for income in retirement).
          </li>
          <li>
            I held, throughout the financial year immediately preceding the date below,
            <strong>{' net assets '}</strong>
            to the value of
            <strong>{' £250,000 or more.'}</strong>
          </li>
        </DialogContentText>

        <DialogContentText>
          Net assets for these purposes do not include:
        </DialogContentText>

        <DialogContentText component="ol">
          <li>
            the property which is my primary residence
            or any money raised through a loan secured on that property; or
          </li>
          <li>any rights of mine under a qualifying contract of insurance; or</li>
          <li>
            any benefits (in the form of pensions or otherwise) which are payable
            on the termination of my service or on my death or retirement
            and to which I am (or my dependants are), or may be, entitled; or
          </li>
          <li>
            any withdrawals from my pension savings
            (except where the withdrawals are used directly for income in retirement).
          </li>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <ValidatedForm
          initialValues={initialValues}
          schema={createSchema()}
          onSubmit={onAcceptDeclaration}
        >
          {( { submitForm } ) => (
            <Box>
              <Field
                component={Checkbox}
                name="firstRiskWarning"
                label={(
                  <strong>
                    I accept that the investments to which the promotions will relate
                    may expose me to a significant risk of losing all of the money or
                    other property invested.
                  </strong>
                )}
              />

              <Field
                component={Checkbox}
                name="secondRiskWarning"
                label="I am aware that it is open to me to seek advice from an authorised person who specialises in advising on non-readily realisable securities and non-mainstream pooled investments."
              />

              <Field
                component={Checkbox}
                name="riskWarningDisclaimer"
                label={[
                  'I understand and accept the',
                  <Link key="warning" href="https://www.kaiku.co/risk-warnings" target="_blank">Risk Warnings.</Link>,
                ].reduce( ( current, next ) => [ current, ' ', next ] )}
              />

              <Box className={classes.submit}>
                <Typography paragraph>{format( Date.now(), 'dd/MM/yyyy' )}</Typography>
                <Button onClick={submitForm}>Accept and Continue</Button>
              </Box>

            </Box>
          )}

        </ValidatedForm>
      </DialogActions>

    </Dialog>

  )
}

export default Declaration
