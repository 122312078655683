import { Container, Fab, makeStyles, Paper } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { bool, elementType, func } from 'prop-types'
import React from 'react'

import kaikuLogo from '../assets/Kaiku_normal.png'

const useStyles = makeStyles( ( { palette, breakpoints } ) => ( {
  root: {
    background: `linear-gradient(bottom left, ${palette.primary.main}, ${palette.primary.dark})`,
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [ breakpoints.only( 'xs' ) ]: {
      padding: '0',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    '& > *': {
      margin: '10px',
    },
    textAlign: 'center',
  },
  logoutFab: {
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  logo: {
    objectFit: 'contain',
    height: '50px',
  },
  formContainer: {
    marginTop: '40px',
    marginBottom: '40px',
  },
} ) )

const HomeForm = ( { logout, logo, children } ) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Container className={classes.formContainer} maxWidth="sm">
        {logout && (
        <Fab className={classes.logoutFab} size="small" color="primary" onClick={logout}>
          <Close fontSize="small" />
        </Fab>
        )}
        <Paper className={classes.paper} elevation={10}>
          {logo && <img src={kaikuLogo} className={classes.logo} alt="logo" />}

          {children}
        </Paper>
      </Container>
    </Container>
  )
}

HomeForm.propTypes = {
  children: elementType.isRequired,
  logout: func,
  logo: bool,
}

HomeForm.defaultProps = {
  logout: null,
  logo: false,
}

export default HomeForm
