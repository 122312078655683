import { qs } from '@kaiku/shared'
import { useState } from 'react'
import useSWR from 'swr'

import useFetchAuth from './use-fetch-auth'

const DEFAULT_PARAMS = {}

const augmentUrl = ( url, page, size ) => {
  const [ core, search ] = url.split( '?' )

  const query = qs.stringify( {
    ...qs.parse( search ),
    page,
    size,
  } )

  return `${core}?${query}`
}

const usePaginatedFetch = ( url, pageSize, params = DEFAULT_PARAMS ) => {
  const [ page, setPage ] = useState( 0 )

  const fetch = useFetchAuth()
  const { data, mutate, ...rest } = useSWR(
    url ? [ url, page, pageSize, params ] : null,
    () => url && fetch( augmentUrl( url, page, pageSize ), params ),
  )

  const { results, total } = data || {}

  const isFirstPage = page <= 0
  const isLastPage = results && results.length < pageSize

  const previous = !isFirstPage && ( () => setPage( page - 1 ) )
  const next = total && !isLastPage && ( () => setPage( page + 1 ) )

  // In case of over-scroll, move the page back and disable next
  return { results, total, mutate, next, previous, setPage, page, ...rest }
}

export default usePaginatedFetch
