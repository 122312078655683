import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

const message = 'Are you sure you want to leave? You have unsaved changes.'

const LeavePrompt = () => {
  const { dirty } = useFormikContext()

  useEffect( () => {
    const onBeforeUnload = ( event ) => {
      if ( !dirty ) return

      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message
    }

    window.addEventListener( 'beforeunload', onBeforeUnload )
    return () => window.removeEventListener( 'beforeunload', onBeforeUnload )
  }, [ dirty ] )

  return <Prompt when={dirty} message={message} />
}

export default LeavePrompt
