import countryList, { overwrite } from 'country-list'
import { getName, registerLocale } from 'i18n-nationality'
import englishNationality from 'i18n-nationality/langs/en.json'
import { isSubsetOf } from 'is-subset-of'

registerLocale( englishNationality )

export const regionNames = {
  dach: 'DACH',
  benelux: 'Benelux',
  easternEurope: 'Eastern Europe',
  nordic: 'Nordic',
  baltic: 'Baltic',
  southEasternEurope: 'South Eastern Europe',
  iberia: 'Iberia',
}

const newCountries = [
  { code: 'XK', name: 'Kosovo', regions: [ regionNames.southEasternEurope ] },
]

overwrite( [
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'EE', name: 'Estonia', regions: [ regionNames.baltic ] },
  { code: 'LV', name: 'Latvia', regions: [ regionNames.baltic ] },
  { code: 'LT', name: 'Lithuania', regions: [ regionNames.baltic ] },
  { code: 'BE', name: 'Belgium', regions: [ regionNames.benelux ] },
  { code: 'LU', name: 'Luxembourg', regions: [ regionNames.benelux ] },
  { code: 'NL', name: 'Netherlands', regions: [ regionNames.benelux ] },
  { code: 'AT', name: 'Austria', regions: [ regionNames.dach ] },
  { code: 'DE', name: 'Germany', regions: [ regionNames.dach ] },
  { code: 'CH', name: 'Switzerland', regions: [ regionNames.dach ] },
  { code: 'BG', name: 'Bulgaria', regions: [ regionNames.easternEurope ] },
  { code: 'CY', name: 'Cyprus', regions: [ regionNames.easternEurope ] },
  { code: 'CZ', name: 'Czech Republic', regions: [ regionNames.easternEurope ] },
  { code: 'HU', name: 'Hungary', regions: [ regionNames.easternEurope ] },
  { code: 'PL', name: 'Poland', regions: [ regionNames.easternEurope ] },
  { code: 'RO', name: 'Romania', regions: [ regionNames.easternEurope ] },
  { code: 'SK', name: 'Slovakia', regions: [ regionNames.easternEurope ] },
  { code: 'BY', name: 'Belarus', regions: [ regionNames.easternEurope ] },
  { code: 'MD', name: 'Moldova', regions: [ regionNames.easternEurope ] },
  { code: 'RU', name: 'Russia', regions: [ regionNames.easternEurope ] },
  { code: 'TR', name: 'Turkey', regions: [ regionNames.easternEurope ] },
  { code: 'UA', name: 'Ukraine', regions: [ regionNames.easternEurope ] },
  { code: 'PT', name: 'Portugal', regions: [ regionNames.iberia ] },
  { code: 'ES', name: 'Spain', regions: [ regionNames.iberia ] },
  { code: 'AD', name: 'Andorra', regions: [ regionNames.iberia ] },
  { code: 'GI', name: 'Gibraltar', regions: [ regionNames.iberia ] },
  { code: 'DK', name: 'Denmark', regions: [ regionNames.nordic ] },
  { code: 'FI', name: 'Finland', regions: [ regionNames.nordic ] },
  { code: 'SE', name: 'Sweden', regions: [ regionNames.nordic ] },
  { code: 'FO', name: 'Faroe Islands', regions: [ regionNames.nordic ] },
  { code: 'IS', name: 'Iceland', regions: [ regionNames.nordic ] },
  { code: 'NO', name: 'Norway', regions: [ regionNames.nordic ] },
  { code: 'GR', name: 'Greece', regions: [ regionNames.southEasternEurope ] },
  { code: 'SI', name: 'Slovenia', regions: [ regionNames.southEasternEurope ] },
  { code: 'AL', name: 'Albania', regions: [ regionNames.southEasternEurope ] },
  { code: 'BA', name: 'Bosnia', regions: [ regionNames.southEasternEurope ] },
  { code: 'HR', name: 'Croatia', regions: [ regionNames.southEasternEurope ] },
  { code: 'ME', name: 'Montenegro', regions: [ regionNames.southEasternEurope ] },
  { code: 'MK', name: 'North Macedonia', regions: [ regionNames.southEasternEurope ] },
  { code: 'RS', name: 'Serbia', regions: [ regionNames.southEasternEurope ] },
] )

// Exclude blocked countries
const blockedCountries = [ 'AF', 'BY', 'CU', 'KP', 'LY', 'HT',
  'MM', 'RU', 'SD', 'SO', 'SS', 'SY', 'IR', 'IQ', 'VE', 'YE', 'ZW' ]

const countryListSafe = countryList.getData()
  .filter( ( item ) => !blockedCountries.includes( item.code ) )

// Add nationality and regions to country list
export const countries = [
  ...countryListSafe,
  ...newCountries,
]
  .sort( ( ( { name: nameA }, { name: nameB } ) => nameA.localeCompare( nameB ) ) )
  .map( ( { code, name, regions = [] } ) => ( {
    name,
    code,
    nationality: getName( code, 'en' ),
    regions,
  } ) )

const codeCountries = countries.reduce( ( codes, country ) => ( {
  ...codes,
  [ country.code ]: country,
} ), {} )

const codeRegions = countries.reduce( ( codes, { code, regions } ) => ( {
  ...codes,
  [ code ]: regions,
} ), {} )

export const getCountry = ( code ) => codeCountries[ ( code || '' ).toUpperCase() ] || {}

export const getCountryName = ( code ) => getCountry( code ).name
export const getNationality = ( code ) => getCountry( code ).nationality

export const regions = countries.reduce( ( byRegions, { regions, code } ) => ( {
  ...byRegions,
  ...regions.reduce( ( acc, region ) => ( {
    ...acc,
    [ region ]: [ ...byRegions[ region ], getCountry( code ) ],
  } ), {} ),
} ), Object
  .values( regionNames )
  .reduce( ( regions, region ) => ( { ...regions, [ region ]: [] } ), {} ) )

export const getRegions = ( codes ) => Object
  .entries( regions )
  .reduce( ( acc, [ region, allCountries ] ) => [
    ...acc,
    ...( isSubsetOf( allCountries.map( ( { code } ) => code ), codes ) ? [ region ] : [] ),
  ], [] )

// Get regions and any remaining countries
export const getRegionsXorCountries = ( codes ) => {
  const calculatedRegions = getRegions( codes )

  return [
    ...calculatedRegions,
    ...codes
      .filter( ( code ) => {
        const regions = codeRegions[ code.toUpperCase() ]

        return regions.length ? !isSubsetOf( regions, calculatedRegions ) : true
      } )
      .map( getCountryName ),
  ]
}
