import { handleAuthError, HomeForm, LoaderButton, ValidatedForm } from '@kaiku/ui'
import { FormHelperText, Typography } from '@material-ui/core'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import { object, string } from 'yup'

import useCommonStyles from './styles'

const createSchema = () => object().shape( {
  email: string().email().required(),
} )

const initialValues = { email: '' }

const ResetPasswordRequest = () => {
  const [ error, setError ] = useState()
  const [ requestSuccess, setRequestSuccess ] = useState()

  const onSubmit = ( { email } ) => sendPasswordResetEmail( getAuth(), email )
    .then( () => setRequestSuccess( true ) )
    .catch( ( error ) => setError( handleAuthError( error ) ) )

  const classes = useCommonStyles()

  return (
    <HomeForm logo>
      <Typography className={classes.header} variant="h3">Reset Password</Typography>

      <ValidatedForm
        initialValues={initialValues}
        schema={createSchema()}
        onSubmit={onSubmit}
        prompt={false}
      >
        {( { isSubmitting, submitForm } ) => (
          <>

            <Field
              component={TextField}
              name="email"
              label="Email"
            />

            <FormHelperText error className={classes.errorText}>
              {!requestSuccess && error}
            </FormHelperText>

            { requestSuccess && (
            <FormHelperText className={classes.helperText}>
              Reset password request successful! Please check your inbox.
            </FormHelperText>
            )}

            <LoaderButton
              type="submit"
              className={classes.mainButton}
              loading={isSubmitting}
              onClick={submitForm}
              disabled={requestSuccess}
            >
              Request Password Reset
            </LoaderButton>

          </>
        ) }
      </ValidatedForm>
    </HomeForm>

  )
}

export default ResetPasswordRequest
