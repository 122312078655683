import { Box, Container, Link, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { HelpRounded } from '@material-ui/icons'
import { any, bool, func, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    margin: '1em 0',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControl-root': {
      margin: '0',
    },
  },
  labelContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    color: palette.text.primary,
  },
  label: {
    marginBottom: '-0.15em',
    fontSize: '0.875rem',
  },
  helpIcon: {
    fontSize: '1.15em',
    marginLeft: '0.5em',
  },
  value: {
    fontSize: '1.15rem',
    textTransform: ( { multiline, value } ) => ( !value || multiline ? 'none' : 'capitalize' ),
    whiteSpace: ( { multiline } ) => ( multiline ? 'pre-line' : 'normal' ),
    overflowWrap: ( { multiline } ) => ( multiline ? 'break-word' : 'normal' ),
  },
} ) )

const CaptionField = ( props ) => {
  const {
    value,
    label,
    tooltip,
    href,
    renderValue,
    renderPlaceholder,
    placeholder,
    multiline,
  } = props
  const classes = useStyles( { multiline, value } )

  const valueDisplay = renderValue
    ? renderValue( props )
    : value && <Typography className={classes.value}>{value.trim()}</Typography>

  const placeholderDisplay = !valueDisplay && ( renderPlaceholder
    ? renderPlaceholder( props )
    : <Typography className={classes.value}>{placeholder}</Typography>
  )

  return (
    <Container className={classes.root}>

      <Box>
        <Tooltip
          title={<Link color="inherit" href={href} target="_blank">{tooltip}</Link>}
          placement="right"
          interactive
        >
          <Link className={classes.labelContainer} href={href} target="_blank">
            <Typography className={classes.label} variant="overline">{label}</Typography>
            <HelpRounded className={classes.helpIcon} />
          </Link>
        </Tooltip>
      </Box>

      {valueDisplay}
      {placeholderDisplay}

    </Container>
  )
}

CaptionField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
  label: string.isRequired,
  tooltip: string.isRequired,
  href: string,
  renderValue: func,
  renderPlaceholder: func,
  multiline: bool,
  placeholder: string,
}

CaptionField.defaultProps = {
  value: null,
  href: null,
  renderValue: null,
  renderPlaceholder: null,
  multiline: false,
  placeholder: 'Not specified',
}

export default CaptionField
