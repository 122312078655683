import { isInvestmentCompany } from '@kaiku/shared'
import { shape, string } from 'prop-types'
import React from 'react'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import Persona from '../../Persona'
import fields from './fields'

const DefaultAboutBlock = ( {
  companyDescription,
  representative,
  type,
} ) => (
  <ContentBlock title="About">
    {isInvestmentCompany( type ) && (
      <>
        <CaptionField {...fields.description} value={companyDescription} multiline />
        <CaptionField {...fields.representative} value=" " />
      </>
    )}

    <Persona {...representative} />

  </ContentBlock>
)

DefaultAboutBlock.propTypes = {
  companyDescription: string,
  type: string,
  representative: shape( { id: string } ),
}

DefaultAboutBlock.defaultProps = {
  companyDescription: null,
  type: null,
  representative: {},
}

export default DefaultAboutBlock
