export const withRouteParams = ( url, parameters = {} ) => Object
  .entries( parameters )
  .reduce( ( url, [ name, value ] ) => url.replace( `:${name}`, value ), url )

export const HOME = '/'
export const LOGIN = '/login'
export const FIREBASE_ACTION = '/action'
export const RESET_PASSWORD = '/action/resetpassword'
export const REGISTER = '/register'
export const REGISTER_DETAILS = `${REGISTER}/details`
export const REGISTER_STARTUP_DETAILS = `${REGISTER_DETAILS}/startup`
export const REGISTER_INVESTOR_DETAILS = `${REGISTER_DETAILS}/investor`

export const STARTUP_ROOT = '/startup'
export const STARTUP_DASHBOARD = `${STARTUP_ROOT}/dashboard`
export const STARTUP_PROFILE = `${STARTUP_ROOT}/profile`

export const STARTUP_SETTINGS_ROOT = `${STARTUP_ROOT}/settings`
export const STARTUP_SETTINGS_PASSWORD = `${STARTUP_SETTINGS_ROOT}/change-password`

export const INVESTOR_ROOT = '/investor'
export const INVESTOR_DECLARATION = `${INVESTOR_ROOT}/declaration`
export const INVESTOR_PROFILE = `${INVESTOR_ROOT}/profile`

export const INVESTOR_MATCHING = `${INVESTOR_ROOT}/matching`
export const INVESTOR_MATCHING_RECOMMENDED = `${INVESTOR_MATCHING}/recommended`
export const INVESTOR_MATCHING_MEETINGS = `${INVESTOR_MATCHING}/meetings`
export const INVESTOR_MATCHING_MEETINGS_BOOKED = `${INVESTOR_MATCHING_MEETINGS}/booked`
export const INVESTOR_MATCHING_MEETINGS_REQUESTED = `${INVESTOR_MATCHING_MEETINGS}/requested`
export const INVESTOR_MATCHING_MEETINGS_DECLINED = `${INVESTOR_MATCHING_MEETINGS}/declined`
export const INVESTOR_MATCHING_MEETINGS_COMPLETED = `${INVESTOR_MATCHING_MEETINGS}/completed`
export const INVESTOR_MATCHING_ENGAGEMENTS = `${INVESTOR_MATCHING}/engagements`
export const INVESTOR_MATCHING_BOOKMARKS = `${INVESTOR_MATCHING_ENGAGEMENTS}/bookmarks`
export const INVESTOR_MATCHING_IGNORES = `${INVESTOR_MATCHING_ENGAGEMENTS}/ignores`
export const INVESTOR_MATCHING_FIND = `${INVESTOR_MATCHING}/find`
export const INVESTOR_VIEW_STARTUP = `${INVESTOR_MATCHING}/view`
export const INVESTOR_DASHBOARD = `${INVESTOR_MATCHING}/dashboard`

export const INVESTOR_SETTINGS_ROOT = `${INVESTOR_ROOT}/settings`
export const INVESTOR_SETTINGS_PASSWORD = `${INVESTOR_SETTINGS_ROOT}/change-password`

export const INVESTOR_BOOKING = `${INVESTOR_VIEW_STARTUP}/booking/:startupId`

export const ADMIN_HOME = '/'
export const ADMIN_LOGIN = '/login'
export const ADMIN_OVERVIEW = '/overview'

export const ADMIN_BROWSE = '/browse'
export const ADMIN_BROWSE_STARTUPS = `${ADMIN_BROWSE}/startups`
export const ADMIN_BROWSE_INVESTORS = `${ADMIN_BROWSE}/investors`
export const ADMIN_BROWSE_MEETINGS = `${ADMIN_BROWSE}/meetings`
export const ADMIN_BROWSE_FILTER = `${ADMIN_BROWSE}/filter`

export const ADMIN_ONBOARDING = '/onboarding'

export const ADMIN_ONBOARDING_STARTUP_ROOT = `${ADMIN_ONBOARDING}/startup`
export const ADMIN_ONBOARDING_STARTUP_NEW = `${ADMIN_ONBOARDING_STARTUP_ROOT}/new`
export const ADMIN_ONBOARDING_STARTUP_COMPLETED = `${ADMIN_ONBOARDING_STARTUP_ROOT}/completed`
export const ADMIN_ONBOARDING_STARTUP_REJECTED = `${ADMIN_ONBOARDING_STARTUP_ROOT}/rejected`

export const ADMIN_ONBOARDING_INVESTOR_ROOT = `${ADMIN_ONBOARDING}/investor`
export const ADMIN_ONBOARDING_INVESTOR_NEW = `${ADMIN_ONBOARDING_INVESTOR_ROOT}/new`
export const ADMIN_ONBOARDING_INVESTOR_COMPLETED = `${ADMIN_ONBOARDING_INVESTOR_ROOT}/completed`
export const ADMIN_ONBOARDING_INVESTOR_REJECTED = `${ADMIN_ONBOARDING_INVESTOR_ROOT}/rejected`

export const ADMIN_PARTNERS = '/partners'

export const ADMIN_STARTUP_PROFILE = '/startup/:startupId'
export const ADMIN_INVESTOR_PROFILE = '/investor/:investorId'
