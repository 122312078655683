import { Button, makeStyles } from '@material-ui/core'
import { string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  red: {
    backgroundColor: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      backgroundColor: palette.error.dark,
    },
    '&:disabled': {
      backgroundColor: palette.error.light,
    },
  },
  orange: {
    backgroundColor: palette.warning.main,
    color: palette.warning.contrastText,
    '&:hover': {
      backgroundColor: palette.warning.dark,
    },
    '&:disabled': {
      backgroundColor: palette.warning.light,
    },
  },
}
) )

export const RedButton = ( { className, ...props } ) => {
  const classes = useStyles()
  return <Button className={`${classes.red} ${className}`} {...props} />
}

export const OrangeButton = ( { className, ...props } ) => {
  const classes = useStyles()
  return <Button className={`${classes.orange} ${className}`} {...props} />
}

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

RedButton.propTypes = propTypes

RedButton.defaultProps = defaultProps

OrangeButton.propTypes = propTypes

OrangeButton.defaultProps = defaultProps
