import { useTheme } from '@material-ui/core/styles'
import PropTypes, { arrayOf } from 'prop-types'
import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const RaisesBarChart = ( { data } ) => {
  const theme = useTheme()
  const raiseData = [
    { raise: '0 - 250k', value: data.group1 },
    { raise: '250k - 500k', value: data.group2 },
    { raise: '500k - 1M', value: data.group3 },
    { raise: '1M - 10M', value: data.group4 },
    { raise: '>10M', value: data.group5 },
  ]

  return (
    <ResponsiveContainer height="80%">
      <BarChart
        data={raiseData}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={( o ) => o.raise} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill={theme.palette.primary.main} />
      </BarChart>
    </ResponsiveContainer>
  )
}

RaisesBarChart.propTypes = {
  data: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
}

export default RaisesBarChart
