import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { ErrorMessage } from 'formik'
import { fieldToCheckbox } from 'formik-material-ui'
import { node } from 'prop-types'
import React from 'react'

const CheckboxWithLabel = ( { label, ...props } ) => {
  const { disabled, ...checkboxProps } = fieldToCheckbox( props )
  const { name } = checkboxProps

  return (
    <FormControl>
      <FormControlLabel label={label} control={<Checkbox {...checkboxProps} />} />
      <ErrorMessage name={name}>
        {( error ) => <FormHelperText error>{error}</FormHelperText>}
      </ErrorMessage>
    </FormControl>
  )
}

CheckboxWithLabel.propTypes = {
  label: node,
}

CheckboxWithLabel.defaultProps = {
  label: null,
}

export default CheckboxWithLabel
