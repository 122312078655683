import constate from 'constate'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'

const getUseUser = constate( () => {
  const [ user, setUser ] = useState( false )

  const setToken = async ( currentUser ) => {
    if ( currentUser ) {
      await currentUser.getIdTokenResult( true )
        .then( ( { claims } ) => setUser( {
          user: currentUser,
          ...claims,
        } ) )
    } else {
      setUser( null )
    }
  }

  useEffect( () => {
    onAuthStateChanged( getAuth(), ( user ) => {
      setToken( user )
    } )
  }, [ ] )

  // used when claims need to be updated
  const refreshToken = async () => {
    await setToken( getAuth().currentUser )
  }

  return [ user, refreshToken ]
} )

export const [ UserProvider ] = getUseUser

const [ , useUser ] = getUseUser
export default useUser
