import { getCountryName } from '@kaiku/shared'
import { Box } from '@material-ui/core'
import { arrayOf, shape, string } from 'prop-types'
import React from 'react'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import ValueChip from '../../ValueChip'
import fields from './fields'

const AdditionalBlock = ( {
  taxResidencies,
} ) => (
  <ContentBlock title="Additional Information">

    <CaptionField
      {...fields.taxResidencies}
      value={taxResidencies}
      renderValue={( { value } ) => !!value.length && (
        <Box>
          {value.map( ( { country, id } ) => (
            <ValueChip
              key={country}
              label={getCountryName( country )}
              value={id}
            />
          ) )}
        </Box>
      )}
    />

  </ContentBlock>
)

AdditionalBlock.propTypes = {
  taxResidencies: arrayOf( shape( { country: string, id: string } ) ),

}

AdditionalBlock.defaultProps = {
  taxResidencies: [],
}

export default AdditionalBlock
