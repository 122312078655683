import { getMemberName, MEETING_STATUSES } from '@kaiku/shared'
import { Avatar, Box, CircularProgress, Container, Dialog, makeStyles, Typography } from '@material-ui/core'
import { Business, Close } from '@material-ui/icons'
import { arrayOf, bool, func, objectOf, string } from 'prop-types'
import React from 'react'

import { meetingInfoPropTypes } from '../propTypes'
import MeetingBooked from './MeetingBooked'
import MeetingDeclined from './MeetingDeclined'
import MeetingRequest from './MeetingRequest'
import Met from './Met'

const useStyles = makeStyles( {
  container: {
    padding: '2em',
    minWidth: '500px',
    textAlign: 'center',
  },
  avatarContainer: {
    width: '7em',
    height: '7em',
    border: '4px solid #EEEEEE',
    borderRadius: '100%',
    boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
    background: '#FFFFFF',
    marginRight: '2em',
  },
  avatar: {
    width: '100%',
    height: '100%',
    fontSize: '0.8em',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginBottom: '1em',
    justifyContent: 'center',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  representative: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
  },
  logo: {
    marginRight: '0.5em',
  },
  meetingHeader: {
    marginBottom: '0.2em',
  },
  close: {
    position: 'absolute',
    top: '0.5em',
    right: '0.5em',
    color: 'grey',
    cursor: 'pointer',
  },
} )

const modalBodyMap = {
  [ MEETING_STATUSES.meetingRequested ]: MeetingRequest,
  [ MEETING_STATUSES.declined ]: MeetingDeclined,
  [ MEETING_STATUSES.meetingBooked ]: MeetingBooked,
  [ MEETING_STATUSES.met ]: Met,
}

const MeetingInfo = ( {
  meeting: { startup, meetingInfo } = {}, onClose, declineMeeting, declining,
} ) => {
  const classes = useStyles()
  const Body = meetingInfo && modalBodyMap[ meetingInfo.status ]
  console.log( startup )

  return (
    <Dialog
      transitionDuration={0}
      open={!!meetingInfo}
      onClose={onClose}
    >
      <Close className={classes.close} onClick={onClose} />

      <Container className={classes.container}>

        {meetingInfo && (
          <>
            <div className={`${classes.header} ${classes.rowContainer}`}>
              <Box className={classes.avatarContainer}>
                <Avatar
                  className={classes.avatar}
                  src={startup.logoUrl}
                  alt={startup.name}
                >
                  <Business fontSize="large" />
                </Avatar>
              </Box>

              <div className={classes.representative}>
                <Typography className={classes.meetingHeader} variant="h4">{`Meeting with ${startup.name}`}</Typography>
                <div className={classes.rowContainer}>
                  <Avatar
                    alt={`${getMemberName( startup.members[ 0 ] )} logo`}
                    className={classes.logo}
                    src={startup.members[ 0 ].picureUrl}
                  />
                  {`${getMemberName( startup.members[ 0 ] )}, ${startup.members[ 0 ].email}`}
                </div>
              </div>
            </div>
            <Typography className={classes.header}>
              {'Meeting Status: '}
              <strong>{meetingInfo.status}</strong>
            </Typography>
            {declining ? <CircularProgress />
              : (
                <Body
                  startup={startup}
                  meetingInfo={meetingInfo}
                  declineMeeting={declineMeeting}
                />
              )}
          </>
        )}
      </Container>
    </Dialog>
  )
}

MeetingInfo.propTypes = {
  meeting: objectOf( {
    startup: objectOf( {
      name: string,
      logoUrl: string,
      members: arrayOf( objectOf(
        {
          firstName: string,
          lastName: string,
          email: string,
        },
      ) ),
    } ),
    meetingInfo: meetingInfoPropTypes,
  } ).isRequired,
  onClose: func.isRequired,
  declineMeeting: func.isRequired,
  declining: bool.isRequired,
}

export default MeetingInfo
