import React, { useState } from 'react'

import formikPropTypes from './formik-prop-types'

const withOptionAction = ( Component, ActionComponent ) => {
  const SubComponent = ( {
    onChange = () => {},
    ...props
  } ) => {
    const {
      form: { setFieldValue },
      field: { name },
    } = props

    const [ value, setValue ] = useState( null )

    const onDelete = ( value ) => {
      setFieldValue( name, value )
      onChange( value )
    }

    const reasonMethods = {
      'select-option': ( value ) => setValue( value ),
      'remove-option': onDelete,
      clear: onDelete,
    }

    const handleOpen = ( _, value, reason ) => reasonMethods[ reason ]( value )

    const onSubmit = ( value ) => {
      setValue( null )

      if ( !value ) return

      setFieldValue( name, value )

      onChange( value )
    }

    return (
      <>
        {value && <ActionComponent {...props} open={!!value} onSubmit={onSubmit} value={value} />}
        <Component {...props} onChange={handleOpen} />
      </>
    )
  }
  SubComponent.propTypes = formikPropTypes
  return SubComponent
}
export default withOptionAction
