import { makeStyles } from '@material-ui/core'
import { arrayOf, func, number, string } from 'prop-types'
import React, { forwardRef } from 'react'

import formikPropTypes from './formik-prop-types'

const useStyles = makeStyles( {
  fileInput: {
    display: 'none',
  },
} )

const FileUpload = forwardRef( ( {
  maxSize,
  formats,
  field: { name },
  form: { setFieldTouched, setFieldValue, setFieldError },
  upload,
}, ref ) => {
  const onChange = async ( event ) => {
    event.preventDefault()

    const { target: { files: [ file ] } } = event

    // Check if file exists and is valid
    if ( !file ) return

    setFieldTouched( name, true, false )

    if ( file.size > maxSize ) {
      setFieldError( name, `File is larger than ${maxSize / 1024 / 1024}MB limit` )
      return
    }

    if ( !formats.includes( file.type ) ) {
      setFieldError( name, 'Unsupported file type' )
      return
    }

    try {
      // Upload the form
      const url = await upload( file )
      setFieldValue( name, url )
    } catch ( error ) {
      setFieldError( name, 'Unable to upload file' )
    }
  }

  const classes = useStyles()

  return (
    <input
      ref={ref}
      className={classes.fileInput}
      name={name}
      type="file"
      accept={formats}
      onChange={onChange}
    />
  )
} )

FileUpload.propTypes = {
  upload: func,
  formats: arrayOf( string ),
  maxSize: number,
  ...formikPropTypes,
}

FileUpload.defaultProps = {
  formats: [],
  maxSize: 1024 * 1024 * 50, // 50MB default max
  upload: () => {},
}

export default FileUpload
