import { Box, CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core'
import { bool, number, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  innerBadge: {
    position: 'absolute',
    border: `0.15em solid ${palette.primary.main}`,
    borderRadius: '100em',
    top: 0,
    margin: '0.475em',
    width: '5em',
    height: '5em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.primary.main,
  },
  label: {
    fontSize: '0.875em',
    position: 'absolute',
    bottom: '0.75em',
  },
  value: {
    marginTop: '-0.3em',
    fontSize: '1.3em',
  },
} ) )

const Badge = ( { text, value, isPercentage } ) => {
  const classes = useStyles()
  const percentageSymbol = isPercentage ? '%' : ''

  return (
    <Box display="inline-block">

      <Box display="inline-block" position="relative">

        <CircularProgress variant="determinate" value={value} size="6em" thickness={4} />

        <Paper className={classes.innerBadge} elevation={10}>

          <Box display="flex">
            <Typography variant="h2" className={classes.value}>
              {Math.round( value )}
              {percentageSymbol}
            </Typography>
          </Box>

          <Typography className={classes.label}>{text}</Typography>

        </Paper>

      </Box>

    </Box>
  )
}

Badge.propTypes = {
  text: string,
  value: number,
  isPercentage: bool,
}

Badge.defaultProps = {
  value: 0,
  text: 'Match',
  isPercentage: false,
}

export default Badge
