import { businessIcon } from '@kaiku/ui'
import { makeStyles, Paper } from '@material-ui/core'
import PropTypes, { arrayOf, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  hsFlex: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8.5,
    marginLeft: 0,
    overflowY: 'hidden',
  },
  hotStartupsDiv: {
    borderRadius: '0.35em',
    padding: '15px',
    height: '400px',
    overflowY: 'hidden',
  },
  mapTitle: {
    margin: 0,
  },
  logo: {
    height: '45px',
    width: '45px',
    marginRight: '10px',
    borderRadius: '100%',
    objectFit: 'cover',
  },
  marginZero: {
    margin: 0,
  },
} )

const HotStartup = ( { startup } ) => {
  const classes = useStyles()
  return (
    <div className={classes.hsFlex}>
      <object data={businessIcon} type="image/png" className={classes.logo}>
        <img src={startup.logoUrl} alt="logo" />
      </object>
      <div>
        <strong><h4 className={classes.marginZero}>{startup.name}</h4></strong>
        <p className={classes.marginZero}>{startup.oneLiner}</p>
      </div>
    </div>
  )
}

const HotStartupCard = ( { title, startupData } ) => {
  const classes = useStyles()
  return (
    <Paper className={classes.hotStartupsDiv}>
      <h3 className={classes.mapTitle}>{title}</h3>
      {startupData[ 0 ] && <HotStartup startup={startupData[ 0 ]} />}
      {startupData[ 1 ] && <HotStartup startup={startupData[ 1 ]} />}
      {startupData[ 2 ] && <HotStartup startup={startupData[ 2 ]} />}
    </Paper>
  )
}

HotStartup.propTypes = {
  startup: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
}

HotStartupCard.propTypes = {
  title: string.isRequired,
  startupData: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
}

export default HotStartupCard
