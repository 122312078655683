import { PRODUCT_HIGHLIGHTS } from '@kaiku/shared'
import { ChipCheckboxes } from '@kaiku/ui'
import { string } from 'prop-types'
import React from 'react'

const ProductHighlights = ( { fieldName } ) => (
  <ChipCheckboxes
    fieldName={fieldName}
    options={Object.values( PRODUCT_HIGHLIGHTS )}
  />
)

ProductHighlights.propTypes = {
  fieldName: string,
}

ProductHighlights.defaultProps = {
  fieldName: 'productHighlights',
}
export default ProductHighlights
