import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

import withEdit from '../../Formik/with-edit'
import Default from './Default'
import Edit from './Edit'

export default ( props ) => {
  const { breakpoints } = useTheme()
  const isDesktop = useMediaQuery( breakpoints.up( 'md' ) )

  const Component = withEdit(
    Default,
    Edit,
    { buttonOffsetTop: isDesktop ? '4em' : '9em', fixed: true, boxProps: { position: 'relative', zIndex: 10 } },
  )

  return <Component {...props} />
}
