import { makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import PropTypes, { arrayOf } from 'prop-types'
import React from 'react'
import { CartesianGrid, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'

import { dataFormatter } from '../../lib/helpers'

const useStyles = makeStyles( {
  container: {
    padding: '10px',
  },
} )

const EquityScatterGraph = ( { data } ) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <ResponsiveContainer className={classes.container}>
      <ScatterChart>
        <CartesianGrid />
        <XAxis type="number" dataKey="amountRaised" name="Amount Raised" tickFormatter={dataFormatter} />
        <YAxis type="number" dataKey="targetRaise" name="Target Raise" tickFormatter={dataFormatter} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter data={data} fill={theme.palette.primary.main} />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

EquityScatterGraph.propTypes = {
  data: arrayOf( PropTypes.objectOf( PropTypes.object ) ).isRequired,
}

export default EquityScatterGraph
