import { countries, FUNDRAISE_STAGES, getCountryName, INDUSTRIES, PRODUCT_HIGHLIGHTS, regions, ROUND_PREFERENCE } from '@kaiku/shared'
import { Chip, InputAdornment, MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { mapValues } from 'lodash'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import CaptionField from '../../CaptionField'
import ContentBlock from '../../ContentBlock'
import { PlaceholderAutocomplete, PlaceholderGroupedAutocomplete } from '../../Formik/Autocomplete'
import ValidatedForm from '../../Formik/Form'
import fields from './fields'

const countryOptions = countries.map( ( {
  name,
  code,
  ...rest
} ) => ( { name: code, displayName: name, ...rest } ) )
const countryGroups = Object
  .entries( regions )
  .map( ( [ name, value ] ) => ( {
    name,
    value: value.map(
      ( { name, code, ...rest } ) => ( { name: code, displayName: name, ...rest } ),
    ),
  } ) )

const createSchema = () => yup.object().shape( {
  thesisIndustries: yup.array().of( yup.object().shape( { name: yup.string() } ) ),
  thesisLocations: yup.array().of( yup.object().shape( { name: yup.string() } ) ),
  seisRequired: yup.boolean().nullable(),
  thesisStages: yup.array().of( yup.object().shape( { name: yup.string() } ) ),
  thesisHighlights: yup.array().of( yup.object().shape( { name: yup.string() } ) ),
  roundPreference: yup.string().oneOf( Object.values( ROUND_PREFERENCE ) ).nullable(),
  followUpRounds: yup.boolean().nullable(),
  ticketSizeMinimum: yup
    .number()
    .min( 0 )
    .nullable()
    .when( 'ticketSizeMaximum', {
      is: ( value ) => !!value,
      then: yup.number().min( 0 ).max( yup.ref( 'ticketSizeMaximum' ), 'Minimum Ticket size cannot be more than the Maximum Ticket Size' ),
    } ),
  ticketSizeMaximum: yup.number().min( 0 ).nullable(),
} )

const ThesisBlock = ( {
  thesisIndustries,
  thesisLocations,
  thesisHighlights,
  seisRequired,
  thesisStages,
  roundPreference,
  followUpRounds,
  ticketSizeMinimum,
  ticketSizeMaximum,
  withSubmit,
  onSubmit,
} ) => {
  const initialValues = {
    ...mapValues( {
      seisRequired,
      roundPreference,
      followUpRounds,
      ticketSizeMinimum,
      ticketSizeMaximum,
    }, ( value ) => value || '' ),
    thesisIndustries,
    thesisLocations,
    thesisStages,
    thesisHighlights,
  }

  const schema = createSchema()

  return (
    <ContentBlock title="Investment Thesis">
      <ValidatedForm
        schema={schema}
        initialValues={initialValues}
        onSubmit={( data ) => onSubmit( schema.submit( data ) )}
      >
        {withSubmit( () => (
          <>

            <CaptionField
              {...fields.thesisIndustries}
              renderValue={( { placeholder } ) => (
                <Field
                  component={PlaceholderAutocomplete}
                  name="thesisIndustries"
                  options={Object.values( INDUSTRIES ).map( ( name ) => ( { name } ) )}
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={( { name } ) => name}
                  getOptionSelected={( { name: selected }, { name } ) => selected === name}
                  getPlaceholderOption={() => ( { name: placeholder } )}
                />
              )}
            />

            <CaptionField
              {...fields.thesisHighlights}
              renderValue={( { placeholder } ) => (
                <Field
                  component={PlaceholderAutocomplete}
                  name="thesisHighlights"
                  options={Object.values( PRODUCT_HIGHLIGHTS ).map( ( name ) => ( { name } ) )}
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={( { name } ) => name}
                  getOptionSelected={( { name }, { name: selected } ) => selected === name}
                  getPlaceholderOption={() => ( { name: placeholder } )}
                />
              )}
            />

            <CaptionField
              {...fields.thesisLocations}
              renderValue={( { placeholder } ) => (
                <Field
                  component={PlaceholderGroupedAutocomplete}
                  name="thesisLocations"
                  options={countryOptions}
                  mainLabel="Countries"
                  groupLabel="Regions"
                  groups={countryGroups}
                  disableCloseOnSelect
                  getComparisonValue={( { name } ) => name}
                  getGroupValue={( { name } ) => name}
                  getOptionLabel={( { displayName } ) => displayName}
                  getOptionSelected={( { name }, { name: selected } ) => selected === name}
                  getPlaceholderOption={() => ( { name: placeholder, displayName: placeholder } )}
                  renderTags={( tags, getProps ) => tags.map( ( { name }, index ) => (
                    <Chip
                      {...getProps( { index } )}
                      key={name}
                      label={getCountryName( name ) || name}
                    />
                  ) )}
                />
              )}
            />

            <CaptionField
              {...fields.ticketSizeMinimum}
              renderValue={() => (
                <Field
                  component={TextField}
                  name="ticketSizeMinimum"
                  type="number"
                  inputProps={{ min: 0, step: 1000 }}
                  InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                />
              )}
            />

            <CaptionField
              {...fields.ticketSizeMaximum}
              renderValue={() => (
                <Field
                  component={TextField}
                  name="ticketSizeMaximum"
                  type="number"
                  inputProps={{ min: 0, step: 1000 }}
                  InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                />
              )}
            />

            <CaptionField
              {...fields.thesisStages}
              renderValue={( { placeholder } ) => (
                <Field
                  component={PlaceholderAutocomplete}
                  name="thesisStages"
                  options={Object.values( FUNDRAISE_STAGES ).map( ( name ) => ( { name } ) )}
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={( { name } ) => name}
                  getOptionSelected={( { name }, { name: selected } ) => selected === name}
                  getPlaceholderOption={() => ( { name: placeholder } )}
                />
              )}
            />

            <CaptionField
              {...fields.roundPreference}
              renderValue={() => (
                <Field component={Select} name="roundPreference">
                  {Object.values( ROUND_PREFERENCE ).map( ( name ) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ) )}
                </Field>
              )}
            />

            <CaptionField
              {...fields.followUpRounds}
              renderValue={() => (
                <Field component={Select} name="followUpRounds">
                  <MenuItem value>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Field>
              )}
            />

            <CaptionField
              {...fields.seisRequired}
              renderValue={() => (
                <Field component={Select} name="seisRequired">
                  <MenuItem value>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Field>
              )}
            />

          </>
        ) )}
      </ValidatedForm>
    </ContentBlock>
  )
}

ThesisBlock.propTypes = {
  withSubmit: func.isRequired,
  onSubmit: func.isRequired,
  thesisIndustries: arrayOf( shape( { name: string } ) ),
  thesisHighlights: arrayOf( shape( { name: string } ) ),
  thesisLocations: arrayOf( shape( { name: string } ) ),
  seisRequired: bool,
  thesisStages: arrayOf( shape( { name: string } ) ),
  roundPreference: string,
  followUpRounds: bool,
  ticketSizeMinimum: number,
  ticketSizeMaximum: number,
}

ThesisBlock.defaultProps = {
  thesisIndustries: [],
  thesisHighlights: [],
  thesisLocations: [],
  seisRequired: null,
  thesisStages: [],
  roundPreference: null,
  followUpRounds: null,
  ticketSizeMinimum: null,
  ticketSizeMaximum: null,
}

export default ThesisBlock
