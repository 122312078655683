import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import useSWR from 'swr'

import { getRecommendedMatchesUrl } from '../../../api'
import NotOnboardedWarning from '../../../components/NotOnboardedWarning'
import CardGrid from './CardGrid'
import EmptyMessage from './EmptyMessage'

const useStyles = makeStyles( {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '2em 0',
    width: '100%',
  },
} )

const Recommended = () => {
  const { data = [], mutate, error } = useSWR( getRecommendedMatchesUrl() )

  const onBookmarkClick = ( startup, index ) => {
    const nextData = data.slice()
    nextData[ index ] = { ...startup, bookmarked: !startup.bookmarked }

    mutate( nextData, false )
  }

  const onIgnoreClick = ( startup, index ) => {
    const nextData = data.slice()
    nextData[ index ] = { ...startup, ignored: !startup.ignored }

    mutate( nextData, false )
  }

  const classes = useStyles()

  if ( error ) {
    switch ( error.statusCode ) {
      case 403:
        // not onboarded yet
        return <NotOnboardedWarning />
      default:
        return (
          <Box className={classes.root}>
            <div>Something went wrong, please try again later</div>
          </Box>
        )
    }
  }

  return (
    <Box className={classes.root}>
      {data.length
        ? (
          <CardGrid
            key={data}
            onBookmarkClick={onBookmarkClick}
            onIgnoreClick={onIgnoreClick}
          >
            {data}
          </CardGrid>
        )
        : <EmptyMessage>Looking for recommendations, check back later!</EmptyMessage>}
    </Box>
  )
}

export default Recommended
