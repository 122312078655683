import { Grid, InputAdornment } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'

const CurrentFundraise = () => (

  <Grid container spacing={1} direction="row">
    <Grid item xs={12}>
      <Field
        inputProps={{ style: { textTransform: 'capitalize' } }}
        component={TextField}
        label="Fundrasise Stage"
        name="fundraise.stage"
        fullWidth
        disabled
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        component={TextField}
        name="fundraise.targetRaise"
        label="Target Raise"
        type="number"
        inputProps={{ min: 0, step: 1000 }}
        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
        fullWidth
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        component={TextField}
        name="fundraise.amountRaised"
        label="Amount Raised"
        type="number"
        inputProps={{ min: 0, step: 1000 }}
        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
        fullWidth
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        fullWidth
        component={TextField}
        name="fundraise.investors"
        label="Current Number of Investors"
        type="number"
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        fullWidth
        component={TextField}
        name="fundraise.targetEquity"
        label="Target Equity %"
        type="number"
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        component={TextField}
        name="fundraise.ticketSize"
        label="Minimum Ticket Size"
        type="number"
        inputProps={{ min: 0, step: 1000 }}
        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
        fullWidth
      />
    </Grid>

  </Grid>

)

export default CurrentFundraise
