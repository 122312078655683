import { Divider, makeStyles, Typography } from '@material-ui/core'
import { string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    flexGrow: 1,
  },
  or: {
    width: 'auto',
    margin: 0,
    marginLeft: '20px',
    marginRight: '20px',
  },
} )

const OrDivider = ( { text, ...props } ) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} {...props} />
      <Typography className={classes.or} variant="overline">{text}</Typography>
      <Divider className={classes.divider} {...props} />
    </div>
  )
}

OrDivider.propTypes = {
  text: string,
}

OrDivider.defaultProps = {
  text: 'Or',
}

export default OrDivider
