import { getInvestorLogo, getInvestorName, getRepresentativeEmail, getRepresentativeLogo, getRepresentativeName, isInvestmentCompany, MEETING_STATUSES } from '@kaiku/shared'
import { Avatar, Box, CircularProgress, Container, Dialog, makeStyles, Typography } from '@material-ui/core'
import { Business, Close } from '@material-ui/icons'
import { bool, func, objectOf, string } from 'prop-types'
import React from 'react'

import { meetingInfoPropTypes } from '../../Investor/Matching/Meetings/propTypes'
import MeetingBooked from './MeetingBooked'
import MeetingDeclined from './MeetingDeclined'
import MeetingRequest from './MeetingRequest'
import Met from './Met'

const useStyles = makeStyles( {
  container: {
    padding: '2em',
    minWidth: '500px',
    textAlign: 'center',
  },
  avatarContainer: {
    width: '7em',
    height: '7em',
    border: '4px solid #EEEEEE',
    borderRadius: '100%',
    boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
    background: '#FFFFFF',
    marginRight: '2em',
  },
  avatar: {
    width: '100%',
    height: '100%',
    fontSize: '0.8em',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginBottom: '1em',
    justifyContent: 'center',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  representative: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
  },
  logo: {
    marginRight: '0.5em',
  },
  meetingHeader: {
    marginBottom: '0.2em',
  },
  close: {
    position: 'absolute',
    top: '0.5em',
    right: '0.5em',
    color: 'grey',
    cursor: 'pointer',
  },
} )

const modalBodyMap = {
  [ MEETING_STATUSES.meetingRequested ]: MeetingRequest,
  [ MEETING_STATUSES.declined ]: MeetingDeclined,
  [ MEETING_STATUSES.meetingBooked ]: MeetingBooked,
  [ MEETING_STATUSES.met ]: Met,
}

const MeetingInfo = ( {
  meeting: { investor, meetingInfo } = {}, onClose, declineMeeting, declining,
} ) => {
  const classes = useStyles()
  const Body = meetingInfo && modalBodyMap[ meetingInfo.status ]

  return (
    <Dialog
      transitionDuration={0}
      open={!!meetingInfo}
      onClose={onClose}
    >
      <Close className={classes.close} onClick={onClose} />

      <Container className={classes.container}>

        {meetingInfo && (
          <>
            <div className={`${classes.header} ${classes.rowContainer}`}>
              <Box className={classes.avatarContainer}>
                <Avatar
                  className={classes.avatar}
                  src={getInvestorLogo( investor )}
                  alt={getInvestorName( investor )}
                >
                  <Business fontSize="large" />
                </Avatar>
              </Box>

              {isInvestmentCompany( investor.type ) ? (
                <div className={classes.representative}>
                  <Typography className={classes.meetingHeader} variant="h4">{`Meeting with ${getInvestorName( investor )}`}</Typography>
                  <div className={classes.rowContainer}>
                    {getRepresentativeLogo( investor ) && (
                    <Avatar
                      alt={`${getRepresentativeName( investor )} logo`}
                      className={classes.logo}
                      src={getRepresentativeLogo( investor )}
                    >
                      <Business />
                    </Avatar>
                    )}
                    {`${getRepresentativeName( investor )}, ${getRepresentativeEmail( investor )}`}
                  </div>
                </div>
              ) : (
                <div className={classes.representative}>
                  <Typography variant="h4">{`Meeting with ${getInvestorName( investor )}`}</Typography>
                  {getRepresentativeEmail( investor )}
                </div>
              )}
            </div>
            <Typography className={classes.header}>
              {'Meeting Status: '}
              <strong>{meetingInfo.status}</strong>
            </Typography>
            {declining ? <CircularProgress />
              : (
                <Body
                  investor={investor}
                  meetingInfo={meetingInfo}
                  declineMeeting={declineMeeting}
                  onClose={onClose}
                />
              )}

          </>
        )}
      </Container>
    </Dialog>
  )
}

MeetingInfo.propTypes = {
  meeting: objectOf( {
    investor: objectOf( {
      type: string,
      companyName: string,
      companyLogoUrl: string,
      representative: objectOf( {
        firstName: string,
        lastName: string,
        email: string,
        pictureUrl: string,
      } ),
    } ),
    meetingInfo: meetingInfoPropTypes,
  } ).isRequired,
  onClose: func.isRequired,
  declineMeeting: func.isRequired,
  declining: bool.isRequired,
}

export default MeetingInfo
