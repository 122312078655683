import { Box, CircularProgress, Link, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import { HelpRounded } from '@material-ui/icons'
import { number, oneOfType, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  help: {
    verticalAlign: 'top',
  },
  innerBadge: {
    position: 'absolute',
    border: `0.15em solid ${palette.primary.main}`,
    borderRadius: '100em',
    top: 0,
    margin: '1em',
    width: ( { size } ) => `calc(${size} - 2em)`,
    height: ( { size } ) => `calc(${size} - 2em)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.primary.main,
  },
  label: {
    fontSize: ( { size, label } ) => `calc(${size} / ${label.length < 9 ? 8.5 : 13})`,
    position: 'absolute',
    bottom: ( { label } ) => ( label.length < 9 ? '1em' : '2em' ),
  },
  content: {
    fontSize: ( { size } ) => `calc(${size} / 3.5)`,
  },
  postfix: {
    fontSize: ( { size } ) => `calc(${size} / 8)`,
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '0.40em',
  },
} ) )

const Badge = ( { content, label, postfix, progress, href, tooltip, size } ) => {
  const classes = useStyles( { size, label } )

  return (
    <Box display="inline-block">

      <Box display="inline-block" position="relative">

        <CircularProgress variant="determinate" value={progress} size={size} thickness={4} />

        <Paper className={classes.innerBadge} elevation={10}>

          <Box display="flex">
            <Typography variant="h2" className={classes.content}>{content}</Typography>
            <Typography variant="h3" className={classes.postfix}>{postfix}</Typography>
          </Box>

          <Typography className={classes.label}>{label}</Typography>

        </Paper>

      </Box>

      <Tooltip title={tooltip} interactive placement="left">
        <Link className={classes.help} href={href} target="_blank">
          <HelpRounded />
        </Link>
      </Tooltip>

    </Box>
  )
}

Badge.propTypes = {
  progress: number,
  tooltip: string.isRequired,
  href: string,
  size: string,
  content: oneOfType( [ string, number ] ),
  label: string,
  postfix: string,
}

Badge.defaultProps = {
  progress: 0,
  href: null,
  size: '13em',
  content: null,
  label: null,
  postfix: null,
}

export default Badge
