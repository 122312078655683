import { Box, Chip, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { number, oneOfType, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles( ( { palette } ) => ( {
  labelContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1em',
    padding: 0,
  },
  content: {
    padding: '1em',
  },
  label: {
    paddingLeft: '0.25em',
  },
  value: {
    color: 'white',
    backgroundColor: palette.primary.main,
    paddingRight: '0.75em',
    marginRight: '0.25em',
    fontSize: '1.25em',
  },
} ) )

const ValueChip = ( { label, value, ...props } ) => {
  const classes = useStyles()

  return (
    <Chip
      className={classes.root}
      classes={{ label: classes.labelContainer }}
      variant="outlined"
      {...props}
      label={(
        <>
          <Box className={clsx( classes.value, classes.content )}>{value}</Box>
          <Box className={clsx( classes.label, classes.content )}>{label}</Box>
        </>
      )}
    />
  )
}

ValueChip.propTypes = {
  label: string.isRequired,
  value: oneOfType( [ string, number ] ),
}

ValueChip.defaultProps = {
  value: null,
}

export default ValueChip
