import { routes } from '@kaiku/shared'
import { HomeForm, useUser } from '@kaiku/ui'
import { makeStyles, Typography } from '@material-ui/core'
import { applyActionCode, getAuth } from 'firebase/auth'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import HomeFormLoading from '../HomeFormLoading'

const useStyles = makeStyles( {
  header: {
    textAlign: 'center',
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  mainButton: {
    marginTop: '15px',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  skipStepContainer: {
    paddingTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  skipStepText: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
} )

const VerifyEmail = ( { actionCode } ) => {
  const [ confirmed, setConfirmed ] = useState()
  const [ error, setError ] = useState()
  const [ user ] = useUser()

  const classes = useStyles()

  useEffect( () => {
    applyActionCode( getAuth(), actionCode )
      .then( () => setConfirmed( true ) )
      .catch( () => {
        setError( 'Invalid or expired link. Please request email verification again.' )
      } )
  }, [ actionCode ] )

  if ( error ) {
    return (
      <HomeForm logo>
        <Typography className={classes.header} variant="h3">Email Verification</Typography>
        <div className={classes.header}>
          {error}
        </div>
      </HomeForm>
    )
  }
  if ( confirmed ) {
    return (
      <HomeForm logo>
        <Typography className={classes.header} variant="h3">Email Verification Successful!</Typography>
        {user
          ? (
            <RouterLink
              className={classes.header}
              to=""
            >
              Return to platform
            </RouterLink>
          )
          : (
            <RouterLink
              className={classes.header}
              to={routes.LOGIN}
            >
              Login
            </RouterLink>
          )}
      </HomeForm>
    )
  }
  return <HomeFormLoading />
}

VerifyEmail.propTypes = {
  actionCode: PropTypes.string.isRequired,
}

export default VerifyEmail
