
export const formatTime = ( genericDate ) => {
  const date = new Date( genericDate )
  let hours = date.getHours()
  let minutes = date.getMinutes()
  if ( hours < 10 ) hours = `0${hours}`
  if ( minutes < 10 ) minutes = `0${minutes}`

  return `${hours}:${minutes}`
}

export const formatDateAndTime = ( genericDate ) => {
  const date = new Date( genericDate )

  return `${date.toLocaleDateString()} at ${formatTime( genericDate )}`
}

// map formatting helper methods

export const dataFormatter = ( number ) => {
  if ( number > 1000000000 ) {
    return `£${( number / 1000000000 ).toString()}b`
  } if ( number > 1000000 ) {
    return `£${( number / 1000000 ).toString()}m`
  } if ( number > 1000 ) {
    return `£${( number / 1000 ).toString()}k`
  }
  return number.toString()
}

export const reshapeIndustriesData = ( countryData ) => {
  if ( countryData.length < 1 ) return []
  return Object.keys( countryData.industries ).map(
    ( industryName ) => ( { code: industryName, value: countryData.industries[ industryName ] } ),
  )
}
