import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth, signOut } from 'firebase/auth'

const intialiseFirebaseAuth = ( { emulator, apiKey, authDomain } ) => {
  if ( emulator ) {
    initializeApp( { projectId: 'kaiku-platform-dev', apiKey: 'apikey', authDomain } )
    connectAuthEmulator( getAuth(), emulator, { disableWarnings: true } )
  } else {
    initializeApp( { apiKey, authDomain } )
  }
}

const handleAuthError = ( error ) => {
  switch ( error.code ) {
    case 'auth/user-not-found':
      return 'Incorrect credentials'
    case 'auth/wrong-password':
      return 'Incorrect credentials'
    case 'auth/too-many-requests':
      return ( 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.' )
    default:
      return ( error.message )
  }
}

const logout = () => signOut( getAuth() )

export {
  handleAuthError,
  intialiseFirebaseAuth,
  logout,
}
