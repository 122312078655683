import { INVESTOR_TYPES } from '@kaiku/shared'

const PORTFOLIO_TOOLTIPS = {
  [ INVESTOR_TYPES.ventureCapital ]: null,
  [ INVESTOR_TYPES.corporateVentureCapital ]: 'Only investments performed by the CVC.',
  [ INVESTOR_TYPES.family ]: null,
  [ INVESTOR_TYPES.individual ]: null,
}

export default PORTFOLIO_TOOLTIPS
