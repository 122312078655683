import { isInvestmentCompany, routes } from '@kaiku/shared'
import { FormStepper, HomeForm, LoaderButton, logout, useFetchAuth, useUser, ValidatedForm } from '@kaiku/ui'
import { Box, FormHelperText, Grid, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { object } from 'yup'

import { getCompleteInvestorRegistrationUrl, getUserInfomationUrl } from '../../../api'
import useCommonStyles from '../../Login/styles'
import Industries from '../Startup/Industries'
import ProductHighlights from '../Startup/ProductHighlights'
import BasicInformation from './BasicInformation'
import InvestmentThesis from './InvestmentThesis'
import Portfolio from './Portfolio'
import schemas from './schemas'

const steps = [
  { title: 'About you', label: 'Basic Information', form: BasicInformation, optional: false },
  { title: 'Industries', label: 'Industries', form: Industries, props: { helperText: 'Please select at least one industry.', fieldName: 'thesisIndustries' }, optional: false },
  { title: 'Product Highlights', label: 'Product Highlights', form: ProductHighlights, props: { fieldName: 'thesisHighlights' }, optional: false },
  { title: 'Investment Thesis', label: 'Investment Thesis', form: InvestmentThesis, optional: false },
  { title: 'Investments', label: 'Portfolio', form: Portfolio, optional: true, skipped: false },
]

const initialValues = {
  companyLogoUrl: '',
  type: '',
  companyName: '',
  companyWebsite: '',
  thesisIndustries: [],
  thesisHighlights: [],
  thesisLocations: [],
  thesisStages: [],
  ticketSizeMinimum: '',
  ticketSizeMaximum: '',
  representative: {
    id: '',
    firstName: '',
    lastName: '',
    website: '',
    email: '',
  },
  portfolio: [ {
    amount: '',
    company: '',
    date: new Date(),
    type: '',
    permanent: true,
  } ],
}

const RegisterInvestor = () => {
  const fetch = useFetchAuth()
  const history = useHistory()
  const classes = useCommonStyles()
  const [ error, setError ] = useState()
  const [ currentStep, setCurrentStep ] = useState( 0 )
  const [ , refreshToken ] = useUser()
  const completeSchema = schemas.reduce( ( prev, current ) => prev.concat( current() ), object() )
  const { data: userData } = useSWR( getUserInfomationUrl() )

  if ( !userData ) {
    return (
      <HomeForm logout={logout}>
        <FormStepper steps={steps} activeStep={currentStep} />
        <Typography className={classes.formHeader} variant="h3">{steps[ currentStep ].title}</Typography>
      </HomeForm>
    )
  }

  initialValues.representative = {
    ...userData,
    website: '',
  }

  const nextPage = async ( {
    industries,
    productHighlights,
    thesisLocations,
    thesisStages,
    ...body
  }, setSubmitting ) => {
    if ( error ) {
      setError( null )
    }
    if ( currentStep === steps.length - 1 ) {
      setSubmitting( true )
      const newBody = {
        thesisStages: thesisStages.map( ( { name } ) => name ),
        thesisLocations: thesisLocations.map( ( { name } ) => name ),
        ...body,
      }
      steps.forEach( ( step, index ) => {
        if ( step.skipped ) {
          Object.keys( schemas[ index ]().fields ).forEach( ( field ) => {
            newBody[ field ] = null
          } )
        }
      } )
      if ( isInvestmentCompany( newBody.type ) ) {
        newBody.representative.website = null
      } else {
        newBody.companyName = null
        newBody.companyWebsite = null
      }
      const finalBody = completeSchema.submit( newBody )
      try {
        await fetch( getCompleteInvestorRegistrationUrl(), { method: 'post', body: finalBody } )
        refreshToken()
      } catch ( err ) {
        setError( 'Failed to submit' )
        setSubmitting( false )
      }
      return
    }
    setCurrentStep( currentStep + 1 )
  }

  const nextStep = ( values, setSubmitting ) => {
    if ( steps[ currentStep ].skipped ) {
      steps[ currentStep ].skipped = false
    }
    return nextPage( values, setSubmitting )
  }

  const previousPage = () => {
    if ( error ) {
      setError( null )
    }
    if ( currentStep === 0 ) {
      history.push( routes.REGISTER_DETAILS )
      return
    }
    setCurrentStep( currentStep - 1 )
  }

  const skipStep = ( values, setSubmitting ) => {
    if ( !steps[ currentStep ].optional ) {
      return
    }
    steps[ currentStep ].skipped = true
    nextPage( values, setSubmitting )
  }

  const FormComponent = steps[ currentStep ].form

  const finalInitalValues = { ...initialValues,
    representative: {
      ...userData,
      website: '',
    } }

  return (
    <HomeForm logout={logout}>
      <FormStepper steps={steps} activeStep={currentStep} />
      <Typography className={classes.formHeader} variant="h3">{steps[ currentStep ].title}</Typography>
      <ValidatedForm
        initialValues={finalInitalValues}
        schema={schemas[ currentStep ]()}
        onSubmit={async ( values, { setTouched, setSubmitting } ) => {
          setTouched( {} )
          await nextStep( values, setSubmitting )
        }}
        prompt={false}
      >
        {( { isSubmitting, errors, setSubmitting, touched, submitForm,
          values, values: { portfolio } } ) => (
            <>
              <FormComponent
                {...steps[ currentStep ].props}
                errors={errors}
                touched={touched}
                portfolio={portfolio}
                values={values}
              />

              <Grid container spacing={1} direction="row-reverse">
                <Grid item xs={6}>
                  <LoaderButton
                    className={classes.mainButton}
                    onClick={submitForm}
                    loading={isSubmitting}
                    fullWidth
                  >
                    {currentStep === steps.length - 1 ? 'Complete Registration' : 'Next'}
                  </LoaderButton>
                </Grid>

                <Grid item xs={6}>
                  <LoaderButton
                    className={classes.mainButton}
                    fullWidth
                    onClick={previousPage}
                    variant="outlined"
                  >
                    Back
                  </LoaderButton>

                </Grid>
              </Grid>
              {steps[ currentStep ].optional && (
              <Box
                className={classes.skipStepContainer}
              >
                <div
                  style={{ display: isSubmitting ? 'none' : 'flex' }}
                  className={classes.skipStepText}
                  onClick={() => skipStep( values, setSubmitting )}
                >
                  {currentStep === steps.length - 1 ? 'Skip for now and complete registration' : 'Skip for now'}
                  <NavigateNextIcon fontSize="small" />
                </div>
              </Box>
              )}
              {error && (
              <FormHelperText error className={classes.errorText}>
                {error}
              </FormHelperText>
              )}
            </>
        )}
      </ValidatedForm>
    </HomeForm>
  )
}

export default RegisterInvestor
