import { Box, makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import PropTypes, { arrayOf, instanceOf, number, oneOfType, string } from 'prop-types'
import React from 'react'

import ContentBlock from '../../ContentBlock'
import ContentCircle from '../../ContentCircle'
import LineSplitter from '../../LineSplitter'
import PORTFOLIO_TOOLTIPS from './tooltips'
import Value from './Value'

const useStyles = makeStyles( {
  entry: {
    margin: '2em 0',
    position: 'relative',
  },
  atSeparator: {
    margin: '0 1em',
    fontSize: '1.25em',
  },
} )

const Entry = ( { amount, date, type, company } ) => {
  const classes = useStyles()

  return (
    <Box className={classes.entry} display="flex">

      <ContentCircle>
        <Typography variant="h3">{format( new Date( date ), 'MMM yyyy' )}</Typography>
      </ContentCircle>

      <Box display="flex" alignItems="center">
        <Value label={type} value={amount} prefix="£" />
        <Typography className={classes.atSeparator}>@</Typography>
        <Value label={company} />
      </Box>

    </Box>
  )
}

Entry.propTypes = {
  amount: oneOfType( [ string, number ] ),
  date: oneOfType( [ string, instanceOf( Date ) ] ),
  type: string.isRequired,
  company: string,
}

Entry.defaultProps = {
  company: null,
  amount: null,
  date: null,
}

const PortfolioBlock = ( { portfolio, type } ) => {
  const fallback = !portfolio.length

  return (
    <ContentBlock
      title="Portfolio"
      fallback={fallback}
      fallbackMessage="Please fill in some details about your previous investments and positions."
      tooltip={PORTFOLIO_TOOLTIPS[ type ]}
    >
      <LineSplitter>
        {portfolio.map( ( data ) => <Entry key={data.id} {...data} /> )}
      </LineSplitter>

    </ContentBlock>
  )
}

PortfolioBlock.propTypes = {
  portfolio: arrayOf( PropTypes.objectOf( PropTypes.object ) ),
  type: PropTypes.string,
}

PortfolioBlock.defaultProps = {
  portfolio: [],
  type: null,
}

export default PortfolioBlock
