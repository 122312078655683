import { countries, FUNDRAISE_STAGES, getCountryName } from '@kaiku/shared'
import { Autocomplete, EditAvatar, ValidatedSelect } from '@kaiku/ui'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import { FastField, Field, getIn } from 'formik'
import { TextField } from 'formik-material-ui'
import { bool, shape, string } from 'prop-types'
import React from 'react'

import useCommonStyles from '../../Login/styles'

const useStyles = makeStyles(
  ( {
    avatarContainer: {
      alignSelf: 'center',
      width: '8em',
      height: '8em',
      border: '4px solid #EEEEEE',
      borderRadius: '100%',
      boxShadow: '0 0.2em 0.4em 0.1em rgba(0, 0, 0, 0.25)',
      background: '#FFFFFF',
    },
    avatar: {
      width: '100%',
      height: '100%',
      fontSize: '0.8em',
    },
  } ),
)

const BasicInformation = ( { errors, touched } ) => {
  const classes = { ...useStyles(), ...useCommonStyles() }

  return (
    <>
      <Box className={classes.avatarContainer}>
        <FastField
          component={EditAvatar}
          className={classes.avatar}
          defaultIcon={<Business fontSize="large" />}
          name="logoUrl"
        />
      </Box>

      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={8}>
          <Field
            fullWidth
            component={TextField}
            name="name"
            label="Startup Name"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            component={TextField}
            name="foundedYear"
            label="Founded Year"
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Field
            fullWidth
            component={TextField}
            name="website"
            label="Website"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Field
            label="HQ"
            component={Autocomplete}
            fullWidth
            disableClearable
            name="headquartersLocation"
            options={countries.map( ( { code } ) => code )}
            getOptionLabel={( code ) => getCountryName( code ) || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatedSelect
            options={Object.values( FUNDRAISE_STAGES )}
            error={getIn( touched, 'fundraise.stage' ) && getIn( errors, 'fundraise.stage' )}
            label="Fundraise Stage"
            name="fundraise.stage"
            fullWidth
          />

        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            name="oneLiner"
            label="One Liner"
            placeholder="Add a brief of description of your startup."
          />
        </Grid>
      </Grid>
    </>
  )
}

BasicInformation.propTypes = {
  errors: shape( { stage: string } ).isRequired,
  touched: shape( { stage: bool } ).isRequired,
}

export default BasicInformation
