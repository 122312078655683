import { FUNDRAISE_TYPES } from '@kaiku/shared'
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { format } from 'date-fns'
import PropTypes, { arrayOf, bool, instanceOf, number, oneOfType, string } from 'prop-types'
import React from 'react'

import ContentBlock from '../../ContentBlock'
import ContentCircle from '../../ContentCircle'
import LineSplitter from '../../LineSplitter'
import Value from './Value'

const FUNDRAISE_TYPE_NAMES = {
  [ FUNDRAISE_TYPES.raise ]: 'raised',
  [ FUNDRAISE_TYPES.award ]: 'awarded',
  [ FUNDRAISE_TYPES.grant ]: 'granted',
}

const useStyles = makeStyles( {
  fundraise: {
    margin: '2em 0',
    position: 'relative',
  },
  progressBar: {
    width: '100%',
    height: '0.375em',
    marginRight: '2em',
  },
  details: {
    display: 'flex',
    position: 'absolute',
    bottom: '-1.755em',
    marginLeft: '5.5em',
    width: 'calc(100% - 10em)',
    justifyContent: 'space-between',
  },
  raised: {
    marginLeft: '4.25em',
    marginBottom: '-1.5em',
  },
  downArrow: {
    margin: '-0.2em 0',
  },
  atSeparator: {
    margin: '0 1em',
    fontSize: '1.25em',
  },
} )

const CurrentRaise = ( {
  stage,
  targetRaise,
  targetEquity,
  ticketSize,
  amountRaised,
  investors,
} ) => {
  const classes = useStyles()

  const progress = Math.min( amountRaised / targetRaise, 100 )

  return (
    <Box className={classes.fundraise} display="flex" flexDirection="column">

      <Box className={classes.raised} paddingLeft={`${progress * 76}%`}>
        <Value label="Raised" value={+amountRaised} prefix="£" />
        <ArrowDropDown className={classes.downArrow} fontSize="large" />
      </Box>

      <Box display="flex" width="100%">

        <ContentCircle>
          <Typography variant="h3">Live</Typography>
        </ContentCircle>

        <Box display="flex" alignItems="center" width="100%">
          <LinearProgress className={classes.progressBar} variant="determinate" value={progress * 100} />
          <Value label="Target" value={+targetRaise} prefix="£" />
        </Box>

      </Box>

      <Box className={classes.details}>
        <Value label="Stage" value={stage} />
        <Value label="Target Equity" value={+targetEquity} postfix="%" />
        <Value label="Minimum Ticket Size" value={+ticketSize} prefix="£" />
        <Value label="Investors" value={+investors} />
      </Box>

    </Box>
  )
}

CurrentRaise.propTypes = {
  investors: oneOfType( [ string, number ] ),
  targetRaise: oneOfType( [ string, number ] ),
  targetEquity: oneOfType( [ string, number ] ),
  ticketSize: oneOfType( [ string, number ] ),
  amountRaised: oneOfType( [ string, number ] ),
  stage: string,
}

CurrentRaise.defaultProps = {
  investors: null,
  targetRaise: null,
  targetEquity: null,
  ticketSize: null,
  amountRaised: null,
  stage: null,
}

const PastFundraise = ( { amountRaised, dilutedEquity, date, type, company } ) => {
  const classes = useStyles()

  return (
    <Box className={classes.fundraise} display="flex">

      <ContentCircle>
        <Typography variant="h3">{format( new Date( date ), 'MMM yyyy' )}</Typography>
      </ContentCircle>

      <Box display="flex" alignItems="center">
        <Value label={FUNDRAISE_TYPE_NAMES[ type ]} value={+amountRaised} prefix="£" />
        <Typography className={classes.atSeparator}>@</Typography>

        {type === FUNDRAISE_TYPES.raise && <Value label="Equity Diluted" value={+dilutedEquity} postfix="%" />}
        {[
          FUNDRAISE_TYPES.award,
          FUNDRAISE_TYPES.grant,
        ].includes( type ) && <Value value={company} />}
      </Box>

    </Box>
  )
}

PastFundraise.propTypes = {
  amountRaised: oneOfType( [ string, number ] ),
  date: oneOfType( [ string, instanceOf( Date ) ] ),
  dilutedEquity: oneOfType( [ string, number ] ),
  type: string.isRequired,
  company: string,
}

PastFundraise.defaultProps = {
  company: null,
  amountRaised: null,
  date: null,
  dilutedEquity: null,
}

const FundraiseBlock = ( { fundraisers, notEditable } ) => {
  const [ current, ...past ] = fundraisers

  const showCurrent = current && current.targetRaise

  const fallback = !( past.length || showCurrent )

  return (
    <ContentBlock
      title="Fundraise"
      fallback={fallback}
      fallbackMessage={notEditable
        ? 'This startup will be fundraising soon. Check back later for more details.'
        : 'Please fill in some details about your desired fundraise and any previous fundraises.'}
    >
      <LineSplitter>
        {[
          ...( showCurrent ? [ <CurrentRaise key={current.id} {...current} /> ] : [] ),
          ...( past ? past.map( ( data ) => <PastFundraise key={data.id} {...data} /> ) : [] ),
        ]}
      </LineSplitter>

    </ContentBlock>
  )
}

FundraiseBlock.propTypes = {
  fundraisers: arrayOf( PropTypes.objectOf( PropTypes.object ) ),
  notEditable: bool,
}

FundraiseBlock.defaultProps = {
  fundraisers: [],
  notEditable: false,
}

export default FundraiseBlock
