import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import PropTypes, { bool, func, string } from 'prop-types'
import React from 'react'
import { object } from 'yup'

import { useIsMounted } from '../../hooks'
import LeavePrompt from './LeavePrompt'

const useStyles = makeStyles( {
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    '& .MuiFormControl-root': {
      margin: '0.5em',
    },
  },
} )

const ValidatedForm = ( {
  className,
  initialValues,
  schema,
  onSubmit,
  prompt,
  children,
  ...formProps
} ) => {
  const isMounted = useIsMounted()

  const onSubmitValidatedForm = async ( values, formikBag ) => {
    const { setSubmitting } = formikBag
    setSubmitting( true )

    try {
      await onSubmit( values, formikBag )
    } finally {
      if ( isMounted() ) setSubmitting( false )
    }
  }

  const classes = useStyles()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmitValidatedForm}
      validateOnMount
      enableReinitialize
    >
      {( renderProps ) => (
        <Form className={clsx( classes.form, className )} {...formProps}>
          {children( renderProps )}

          {prompt && <LeavePrompt />}
        </Form>
      )}
    </Formik>
  )
}

ValidatedForm.propTypes = {
  schema: PropTypes.objectOf( PropTypes.any ),
  initialValues: PropTypes.objectOf( PropTypes.any ),
  onSubmit: func,
  prompt: bool,
  children: func,
  className: string,
}

ValidatedForm.defaultProps = {
  schema: object().shape( {} ),
  initialValues: {},
  onSubmit: () => {},
  prompt: true,
  children: () => null,
  className: null,
}

export default ValidatedForm
